"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var SubmittableFormActions;
(function (SubmittableFormActions) {
    SubmittableFormActions["RecordAdding"] = "RecordAdding";
    SubmittableFormActions["RecordAdded"] = "RecordAdded";
    SubmittableFormActions["RecordEditing"] = "RecordEditing";
    SubmittableFormActions["RecordEdited"] = "RecordEdited";
    SubmittableFormActions["SubmissionFailure"] = "SubmissionFailure";
    SubmittableFormActions["RecordLoaded"] = "RecordLoaded";
    SubmittableFormActions["RecordLoading"] = "RecordLoading";
    SubmittableFormActions["RecordLoadFailure"] = "RecordLoadFailure";
    SubmittableFormActions["Cancel"] = "Cancel";
})(SubmittableFormActions || (SubmittableFormActions = {}));
exports.default = SubmittableFormActions;
