"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var EventAttendeesTileAction;
(function (EventAttendeesTileAction) {
    EventAttendeesTileAction["Toggled"] = "Toggled";
    EventAttendeesTileAction["DataLoading"] = "DataLoading";
    EventAttendeesTileAction["DataLoaded"] = "DataLoaded";
    EventAttendeesTileAction["EditModeActivated"] = "EditModeEnabled";
    EventAttendeesTileAction["EditModeDeactivated"] = "EditModeDeactivated";
    EventAttendeesTileAction["AttendeeRemoved"] = "AttendeeRemoved";
    EventAttendeesTileAction["AttendeesGrouped"] = "AttendeesGrouped";
    EventAttendeesTileAction["AttendeeRemoveCancelled"] = "AttendeeRemoveCancelled";
    EventAttendeesTileAction["AttendeeReferenceAlertShown"] = "AttendeeReferenceAlertShown";
    EventAttendeesTileAction["AttendeeReferenceAlertHidden"] = "AttendeeReferenceAlertHidden";
    EventAttendeesTileAction["AttendeeAdded"] = "AttendeeAdded";
    EventAttendeesTileAction["NonRemovableAttendeesCleared"] = "NonRemovableAttendeesCleared";
    EventAttendeesTileAction["NonRemovableAttendeesSet"] = "NonRemovableAttendeesSet";
})(EventAttendeesTileAction || (EventAttendeesTileAction = {}));
exports.default = EventAttendeesTileAction;
