"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g = Object.create((typeof Iterator === "function" ? Iterator : Object).prototype);
    return g.next = verb(0), g["throw"] = verb(1), g["return"] = verb(2), typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
var _1 = require(".");
var __1 = require("../..");
var bases_1 = require("../../bases");
var components_1 = require("../../components");
var utils_1 = require("../../utils");
var button_1 = require("../../components/button/button");
var HelpUrlsPage = /** @class */ (function (_super) {
    __extends(HelpUrlsPage, _super);
    function HelpUrlsPage(api) {
        var _this = _super.call(this, __1.PageTypes.HelpUrls, '_Help Urls') || this;
        _this.api = api;
        _this.buildNewForm = function (item) {
            return new __1.Form([
                new components_1.TextField('name', item.name, false, 200, 'required'),
                new components_1.TextField('description', item.description, false, 100),
                new components_1.TextField('hoverText', item.hoverText, false, 200),
                new components_1.TextField('pdfUrl', item.pdfUrl, false, 2000)
            ], item);
        };
        _this.loadUrls = function () { return __awaiter(_this, void 0, void 0, function () {
            var helpUrls, i, err_1;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        this._forms = [];
                        return [4 /*yield*/, this.api.getAllHelpUrls()];
                    case 1:
                        helpUrls = _a.sent();
                        for (i = 0; i < helpUrls.length; i++) {
                            this._forms.push(new __1.Form([
                                new components_1.TextField('name', '', false, 200, 'required'),
                                new components_1.TextField('description', '', false, 100),
                                new components_1.TextField('hoverText', '', false, 200),
                                new components_1.TextField('pdfUrl', '', false, 2000)
                            ], helpUrls[i]));
                        }
                        this._isLoading = false;
                        return [2 /*return*/, { items: helpUrls, totalRecordCount: 9999 }];
                    case 2:
                        err_1 = _a.sent();
                        return [3 /*break*/, 3];
                    case 3:
                        this.observationProvider.notifyObservers(_1.HelpUrlsPageActions.Loaded);
                        return [2 /*return*/];
                }
            });
        }); };
        _this.save = function () { return __awaiter(_this, void 0, void 0, function () {
            var isValid, helpUrlsToSubmit, i, err_2;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        isValid = true;
                        helpUrlsToSubmit = [];
                        this.observationProvider.notifyObservers(_1.HelpUrlsPageActions.Saving);
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 3, , 4]);
                        for (i = 0; i < this._forms.length; i++) {
                            this._forms[i].submit();
                            isValid = isValid && this._forms[i].isValid();
                            helpUrlsToSubmit.push(this._forms[i].model);
                        }
                        if (!isValid) {
                            this.saveButton.setProcessing(false);
                            return [2 /*return*/];
                        }
                        return [4 /*yield*/, this.api.post(__1.EndpointUrls.HelpUrls + '/Save', helpUrlsToSubmit)];
                    case 2:
                        _a.sent();
                        this.saveButton.setProcessing(false);
                        return [3 /*break*/, 4];
                    case 3:
                        err_2 = _a.sent();
                        this.saveButton.setProcessing(false);
                        return [3 /*break*/, 4];
                    case 4:
                        this.observationProvider.notifyObservers(_1.HelpUrlsPageActions.Saved);
                        return [2 /*return*/];
                }
            });
        }); };
        _this.observationProvider = new utils_1.ObservationProvider();
        _this.saveButton = new button_1.default({
            text: 'Save',
            onClick: _this.save,
            processingText: 'Saving'
        });
        _this.newButton = new button_1.default({
            text: 'New',
            leftIcon: components_1.Icons.Plus,
            onClick: function () {
                var newItem = { name: '', description: '', hoverText: '', pdfUrl: '' };
                _this.table.items.unshift(newItem);
                _this._forms.unshift(_this.buildNewForm(newItem));
                _this.observationProvider.notifyObservers(_1.HelpUrlsPageActions.New);
            }
        });
        _this._isLoading = true;
        _this.table = new components_1.Table(_this.loadUrls, _this.api);
        _this.table.pagingEnabled = false;
        _this.table.searchEnabled = false;
        _this.table.load();
        return _this;
    }
    Object.defineProperty(HelpUrlsPage.prototype, "isLoading", {
        get: function () {
            return this._isLoading;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(HelpUrlsPage.prototype, "forms", {
        get: function () {
            return this._forms;
        },
        enumerable: false,
        configurable: true
    });
    return HelpUrlsPage;
}(bases_1.BasePage));
exports.default = HelpUrlsPage;
