"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var DownloadButtonActions;
(function (DownloadButtonActions) {
    DownloadButtonActions["Clicked"] = "Clicked";
    DownloadButtonActions["DisabledChanged"] = "DisabledChanged";
    DownloadButtonActions["LoadingChanged"] = "LoadingChanged";
    DownloadButtonActions["VisibilityChanged"] = "VisibilityChanged";
    DownloadButtonActions["TextChanged"] = "TextChanged";
    DownloadButtonActions["UrlChanged"] = "UrlChanged";
    DownloadButtonActions["ProcessingChanged"] = "ProcessingChanged";
    DownloadButtonActions["CarouselClosed"] = "CarouselClosed";
    DownloadButtonActions["CarouselLoaded"] = "CarouselLoaded";
})(DownloadButtonActions || (DownloadButtonActions = {}));
exports.default = DownloadButtonActions;
