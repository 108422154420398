"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var ListOptionSlideContainerActions;
(function (ListOptionSlideContainerActions) {
    ListOptionSlideContainerActions["Hidden"] = "Hidden";
    ListOptionSlideContainerActions["Shown"] = "Shown";
    ListOptionSlideContainerActions["GroupSelectionRemoved"] = "GroupSelectionRemoved";
    ListOptionSlideContainerActions["OptionsApplied"] = "OptionsApplied";
    ListOptionSlideContainerActions["GroupSelectionChanged"] = "GroupSelectionChanged";
})(ListOptionSlideContainerActions || (ListOptionSlideContainerActions = {}));
exports.default = ListOptionSlideContainerActions;
