import { FieldActions, ActionUtils } from 'core.frontend';
import { Fragment, h, JSX } from 'preact';
import ObservingComponent from '../componentBases/observingComponent';
import FieldIcon from './fieldIcon';
import RichTextFieldProps from './richTextFieldProps';
import FieldLoadingPlaceholder from './fieldLoadingPlaceholder';
import { EmailTemplateSystemType } from 'core.frontend/dist/pages/emailTemplates/emailTemplateDetail';

class EmailTemplateField extends ObservingComponent<RichTextFieldProps> {
    private caretPosition: number = 0;

    constructor(props: RichTextFieldProps) {
        super(props);

        props.field.observationProvider.observe(this.setInnerHtmlValue, FieldActions.ValueChanged);
    }

    public componentWillMount(): void {
        const { field } = this.props;

        this.registerUpdateObserver(field.observationProvider, [FieldActions.Hidden, FieldActions.Shown]);
    }

    public componentDidMount(): void {
        if (this.props.field.value) {
            this.setInnerHtmlValue();
        }
    }

    public componentDidUpdate(): void {
        if (this.props.field.value) {
            this.setInnerHtmlValue();
        }
    }

    private setInnerHtmlValue = () => {
        const div = document.getElementById(this.props.field.uniqueKey);

        if (div) {
            div.innerHTML = this.props.field.value;
        }
    }

    private handleBlur = (e: any) => {
        const { field } = this.props;
        const newValue = e.target.innerHTML;

        field.setValue(newValue);
        field.blur();
    }

    private placeholderDropdownChanged = (e: any) => {
        if (e.target.value) {
            let newValue = '';

            if (!this.props.field.value) {
                newValue = e.target.value;
            } else {
                this.props.field.value
                newValue = [
                    this.props.field.value.slice(0, this.caretPosition),
                    e.target.value,
                    this.props.field.value.slice(this.caretPosition)
                ].join('');
            }
            this.props.field.setValue(newValue);
            e.target.value = '';
        }
    }

    private updateCursor = (e: any) => {

        var i = 0;
        do {
            var elm = e.path[i];

            if(!elm) {
                break;
            }

            if (elm.id.startsWith(this.props.field.uniqueKey)) {
                this.caretPosition = this.getCaretIndex(elm);
                break;
            }

            i++;
        } while(elm);
    }

    private getCaretIndex(element) {
        let position = 0;
        const isSupported = typeof window.getSelection !== "undefined";
        if (isSupported) {
          const selection = window.getSelection();
          if (selection.rangeCount !== 0) {
            const range = window.getSelection().getRangeAt(0);
            const preCaretRange = range.cloneRange();
            preCaretRange.selectNodeContents(element);
            preCaretRange.setEnd(range.endContainer, range.endOffset);
            position = preCaretRange.toString().length;
          }
        }
        return position;
    }

    public render({ field, labelClassName, isLoading, className = '', leftIcon = '', emailTemplateSystemType }: RichTextFieldProps): JSX.Element {

        labelClassName += field.isActive ? ' active' : '';
        labelClassName += field.errorMessage ? ' invalid' : '';

        if (!field.isVisible) {
            return null;
        }

        if (isLoading) {
            return <FieldLoadingPlaceholder className={className} />;
        }

        return (
            <div className={`input-field ${className} ${field.isDisabled ? 'disabled' : ''}`}>

                { leftIcon &&
                    <FieldIcon
                        icon={leftIcon}
                        invalid={!!field.errorMessage} />
                }

                <div class="input-field input-xs inline-label rich-editor-template-placeholder-select">

                    <label
                        class="v-center"
                        for={this.props.field.uniqueKey + '_placeholder_select'}>

                        <span>
                            <b>Placeholders:</b>
                            <br/>
                            <span class="text-sm">
                                Choose a data placeholder to be inserted into the template at the current cursor location.
                                You can also copy and paste the placeholder into the “Subject” line above.
                                <br/>
                                <br/>
                                Use the following commands to format selected text.
                                <br/>
                                <b><i>ctrl + b</i></b>: Bold | <b><i>ctrl + i</i></b>: Italic | <b><i>ctrl + u</i></b>: Underline
                            </span>
                        </span>
                    </label>

                    {emailTemplateSystemType === EmailTemplateSystemType.MeetingCalendarInvite && 
                        <select
                            id={this.props.field.uniqueKey + '_placeholder_select'}
                            name={this.props.field.uniqueKey + '_placeholder_select'}
                            class="v-center"
                            onChange={this.placeholderDropdownChanged}>

                            <option value=""></option>
                            <option value="[Event_Name]">[Event_Name]</option>
                            <option value="[Meeting_Name]">[Meeting_Name]</option>
                            <option value="[Meeting_StartDate]">[Meeting_StartDate]</option>
                            <option value="[Meeting_StartTime]">[Meeting_StartTime]</option>
                            <option value="[Meeting_EndTime]">[Meeting_EndTime]</option>
                            <option value="[Meeting_Venue]">[Meeting_Venue]</option>
                            <option value="[Meeting_Number]">[Meeting_Number]</option>
                            <option value="[Meeting_Subject]">[Meeting_Subject]</option>
                            <option value="[Meeting_Comments]">[Meeting_Comments]</option>
                            <option value="[Meeting_Attendee_List]">[Meeting_Attendee_List]</option>
                            <option value="[Meeting_Guest_List]">[Meeting_Guest_List]</option>
                            <option value="[POC_FullName]">[POC_FullName]</option>
                            <option value="[POC_Email]">[POC_Email]</option>
                        </select>
                    }

                    {emailTemplateSystemType !== EmailTemplateSystemType.MeetingCalendarInvite && 
                        
                        <select
                            id={this.props.field.uniqueKey + '_placeholder_select'}
                            name={this.props.field.uniqueKey + '_placeholder_select'}
                            class="v-center"
                            onChange={this.placeholderDropdownChanged}>

                            <option value=""></option>
                            <option value="[Timestamp]">[Timestamp]</option>
                            <option value="[AttendeeName]">[AttendeeName]</option>
                            <option value="[Username]">[Username]</option>
                            <option value="[ContactEmail]">[ContactEmail]</option>
                            <option value="[Phone]">[Phone]</option>
                            <option value="[OperatingCompany]">[OperatingCompany]</option>
                            <option value="[Title]">[Title]</option>
                            <option value="[HotelConfirmationNumber]">[HotelConfirmationNumber]</option>
                            <option value="[TypeOfBooking]">[TypeOfBooking]</option>
                            <option value="[CheckInDate]">[CheckInDate]</option>
                            <option value="[CheckOutDate]">[CheckOutDate]</option>
                            <option value="[EarlyArrival]">[EarlyArrival]</option>
                            <option value="[LateCheckOut]">[LateCheckOut]</option>
                            <option value="[NoOfGuestsInRoom]">[NoOfGuestsInRoom]</option>
                            <option value="[AttendeeHotelComments]">[AttendeeHotelComments]</option>
                            <option value="[AirportToHotelRequested]">[AirportToHotelRequested]</option>
                            <option value="[HotelToAirportRequested]">[HotelToAirportRequested]</option>
                            <option value="[DailyHotelEventTransfer]">[DailyHotelEventTransfer]</option>
                            <option value="[DailyHotelEventTransferStart]">[DailyHotelEventTransferStart]</option>
                            <option value="[DailyHotelEventTransferEnd]">[DailyHotelEventTransferEnd]</option>
                            <option value="[RequestedCarType]">[RequestedCarType]</option>
                            <option value="[RentalCarStartDate]">[RentalCarStartDate]</option>
                            <option value="[RentalCarEndDate]">[RentalCarEndDate]</option>
                            <option value="[RentalCarSharingWith]">[RentalCarSharingWith]</option>
                            <option value="[AttendeeTransportationComments]">[AttendeeTransportationComments]</option>
                            <option value="[ArrivalDate]">[ArrivalDate]</option>
                            <option value="[ArrivalTime]">[ArrivalTime]</option>
                            <option value="[ArrivalType]">[ArrivalType]</option>
                            <option value="[ArrivalCarrier]">[ArrivalCarrier]</option>
                            <option value="[ArrivalOrigin]">[ArrivalOrigin]</option>
                            <option value="[ArrivalDestination]">[ArrivalDestination]</option>
                            <option value="[ArrivalNumber]">[ArrivalNumber]</option>
                            <option value="[ArrivalComments]">[ArrivalComments]</option>
                            <option value="[DepartureDate]">[DepartureDate]</option>
                            <option value="[DepartureTime]">[DepartureTime]</option>
                            <option value="[DepartureType]">[DepartureType]</option>
                            <option value="[DepartureCarrier]">[DepartureCarrier]</option>
                            <option value="[DepartureOrigin]">[DepartureOrigin]</option>
                            <option value="[DepartureDestination]">[DepartureDestination]</option>
                            <option value="[DepartureNumber]">[DepartureNumber]</option>
                            <option value="[DepartureComments]">[DepartureComments]</option>
                            <option value="[GuestComments]">[GuestComments]</option>
                            <option value="[HotelRoom]">[HotelRoom]</option>
                            <option value="[HotelName]">[HotelName]</option>
                            <option value="[HotelAddress]">[HotelAddress]</option>
                            <option value="[HotelCheckInTime]">[HotelCheckInTime]</option>
                            <option value="[HotelCheckOutTime]">[HotelCheckOutTime]</option>
                            <option value="[HotelCity]">[HotelCity]</option>
                            <option value="[HotelComments]">[HotelComments]</option>
                            <option value="[HotelCountry]">[HotelCountry]</option>
                            <option value="[HotelContact]">[HotelContact]</option>
                            <option value="[HotelFaxNumber]">[HotelFaxNumber]</option>
                            <option value="[HotelPhone]">[HotelPhone]</option>
                            <option value="[HotelState]">[HotelState]</option>
                            <option value="[HotelZip]">[HotelZip]</option>
                            <option value="[HotelVendorComments]">[HotelVendorComments]</option>
                            <option value="[TransportationVendorComments]">[TransportationVendorComments]</option>
                            <option value="[DailyTransportStatus]">[DailyTransportStatus]</option>
                            <option value="[HotelStatus]">[HotelStatus]</option>
                            <option value="[EventName]">[EventName]</option>
                            <option value="[EventAddress]">[EventAddress]</option>
                            <option value="[EventCity]">[EventCity]</option>
                            <option value="[EventState]">[EventState]</option>
                            <option value="[EventZip]">[EventZip]</option>
                            <option value="[EventCountry]">[EventCountry]</option>
                            <option value="[EventStartDate]">[EventStartDate]</option>
                            <option value="[EventEndDate]">[EventEndDate]</option>
                            <option value="[EventType]">[EventType]</option>
                            <option value="[EventTimezone]">[EventTimezone]</option>
                            <option value="[EventProducerName]">[EventProducerName]</option>
                            <option value="[EventProducerEmail]">[EventProducerEmail]</option>
                            <option value="[EventProducerPhone]">[EventProducerPhone]</option>
                            <option value="[LoggedInUserName]">[LoggedInUserName]</option>
                            <option value="[LoggedInUserEmail]">[LoggedInUserEmail]</option>
                            <option value="[LoggedInUserPhone]">[LoggedInUserPhone]</option>
                            <option value="[Url]">[Url]</option>
                        </select>
                    }
                </div>

                <label
                    className={labelClassName}
                    for={field.uniqueKey}>

                    <span class="fill">{field.label}</span>

                    {field.labelButtonText && <a onClick={field.handleLabelButtonClick}>{field.labelButtonText}</a>}

                    { field.errorMessage && (
                        <span className="invalid">{field.errorMessage}</span>
                    )}
                </label>


                <div
                    id={field.uniqueKey}
                    onFocus={field.focus}
                    onBlur={this.handleBlur}
                    className={(field.errorMessage ? 'invalid' : '') + ' rich-text-editor'}
                    contentEditable={!field.isDisabled}
                    onMouseDown={this.updateCursor}
                    onMouseUp={this.updateCursor}
                    onKeyDown={this.updateCursor}
                    onKeyUp={this.updateCursor}>
                </div>

                { field.isActive && !field.stringValue && field.placeholderLabel &&
                    <label class="placeholder-label">{ field.placeholderLabel }</label>
                }
            </div>
        );
    }
}

export default EmailTemplateField;
