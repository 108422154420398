"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g = Object.create((typeof Iterator === "function" ? Iterator : Object).prototype);
    return g.next = verb(0), g["throw"] = verb(1), g["return"] = verb(2), typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
var __1 = require("../../..");
var button_1 = require("../../../components/button/button");
var components_1 = require("../../../components");
var helpButton_1 = require("../../../components/button/helpButton");
var helpUrls_1 = require("../../helpUrls");
var EventConfigurationPage = /** @class */ (function (_super) {
    __extends(EventConfigurationPage, _super);
    function EventConfigurationPage(api, navigator, currentUserManager, eventId) {
        var _this = _super.call(this, __1.PageTypes.EventConfiguration, 'Event Tools') || this;
        _this.api = api;
        _this.navigator = navigator;
        _this.currentUserManager = currentUserManager;
        _this.eventId = eventId;
        _this.billableBusinessAreaAlerts = [];
        _this.billableBusinessAreaReferences = [];
        _this.hideEventAttendeeAccessLevels = function () {
            if (!_this.eventAttendeeAccessLevelDataList.areAllValid()) {
                _this.eventAttendeeAccessLevelAlert.show({
                    alertType: __1.AlertType.Error,
                    heading: 'Access Levels Invalid',
                    message: 'One and only one attendee access level must be set as the default.'
                });
                return;
            }
            _this._eventAttendeeAccessLevelsVisible = false;
            _this.observationProvider.notifyObservers(__1.EventConfigurationPageActions.EventAttendeeAccessLevelsHidden);
        };
        _this.showAttendeeAccessLevels = function () {
            _this.eventAttendeeAccessLevelAlert.hide();
            _this._eventAttendeeAccessLevelsVisible = true;
            _this.observationProvider.notifyObservers(__1.EventConfigurationPageActions.EventAttendeeAccessLevelsVisible);
        };
        _this.copyVenuePreferences = function () {
            var _loop_1 = function (venuePreference) {
                var venuePreferenceName = venuePreference.form.getFieldByKey('name').value;
                if (_this.venueDataList.findIndex(function (venueItem) { return venueItem.name === venuePreferenceName; }) < 0) {
                    _this.venueDataList.addModel({
                        name: venuePreferenceName
                    });
                }
            };
            for (var _i = 0, _a = _this.venuePreferenceDataList.items; _i < _a.length; _i++) {
                var venuePreference = _a[_i];
                _loop_1(venuePreference);
            }
        };
        _this.showMeetingSettingsPane = function () {
            _this._activeMeetingPane = __1.EventConfigurationPageMeetingPanes.Settings;
            _this.observationProvider.notifyObservers(__1.EventConfigurationPageActions.ActiveMeetingPaneChanged);
        };
        _this.showMeetingVenuesPane = function () {
            _this._activeMeetingPane = __1.EventConfigurationPageMeetingPanes.Venues;
            _this.observationProvider.notifyObservers(__1.EventConfigurationPageActions.ActiveMeetingPaneChanged);
        };
        _this.showMeetingRequestPane = function () {
            _this._activeMeetingPane = __1.EventConfigurationPageMeetingPanes.MeetingRequests;
            _this.observationProvider.notifyObservers(__1.EventConfigurationPageActions.ActiveMeetingPaneChanged);
        };
        _this.buildFileManagementFields = function () {
            _this._fileManagementEnabledCheckbox = new __1.CheckBoxField('fileManagementEnabled', 'Enabled');
            _this._fileManagementFields = [
                _this._fileManagementEnabledCheckbox
            ];
        };
        _this.buildEventAlertFields = function () {
            _this._eventAlertEnabledCheckbox = new __1.CheckBoxField('alertEnabled', 'Enabled');
            _this._eventAlertEnabledCheckbox.observationProvider.observe(_this.alertEnabledChanged, __1.FieldActions.ValueChanged);
            _this._eventAlertTitleField = new __1.TextField('alertTitle', 'Heading', true, 500, 'required');
            _this._eventAlertMessageField = new __1.RichTextField('alertMessage', 'Message', true);
            _this._alertMessageFields = [
                _this._eventAlertEnabledCheckbox,
                _this._eventAlertTitleField,
                _this._eventAlertMessageField
            ];
            for (var i = 1; i < _this._alertMessageFields.length; i++) {
                _this._alertMessageFields[i].setVisibility(false);
            }
        };
        _this.buildInventoryFields = function () {
            _this._inventoryEnabledCheckbox = new __1.CheckBoxField('inventoryManagementEnabled', 'Enabled');
            _this._inventoryEnabledCheckbox.observationProvider.observe(_this.inventoryEnabledChanged, __1.FieldActions.ValueChanged);
            _this._inventoryModificationCuttoffField = new __1.DateField('inventoryModificationCutOffDate', 'Inventory modification cutoff date', true);
            _this._preEventInventoryReservationDateField = new __1.DateField('preEventInventoryReservationDate', 'Pre-event inventory reservation date', true, 'Date is required');
            _this._postEventInventoryReleaseDateField = new __1.DateField('postEventInventoryReleaseDate', 'Post-event inventory release date', true, 'Date is required');
            _this._inventoryFields = [
                _this._inventoryEnabledCheckbox,
                _this._inventoryModificationCuttoffField,
                _this._preEventInventoryReservationDateField,
                _this._postEventInventoryReleaseDateField
            ];
            for (var i = 1; i < _this._inventoryFields.length; i++) {
                _this._inventoryFields[i].setVisibility(false);
            }
        };
        _this.buildCostManagementFields = function () { return __awaiter(_this, void 0, void 0, function () {
            var businessAreas, _loop_2, this_1, i;
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this._costManagementEnabledCheckbox = new __1.CheckBoxField('costManagementEnabled', 'Enabled');
                        this._costManagementEnabledCheckbox.observationProvider.observe(this.costManagementEnabledChanged, __1.FieldActions.ValueChanged);
                        this._costManagementCutoffDateField = new __1.DateField('costManagementCutoffDate', 'Cut-off Date', true, 'required');
                        this._costManagementFields = [
                            this._costManagementEnabledCheckbox,
                            this._costManagementCutoffDateField
                        ];
                        this._billableBusinessAreaCheckboxes = [];
                        this._billableBusinessAreaEstimateFields = [];
                        return [4 /*yield*/, this.api.getBusinessAreasWithDepartments()];
                    case 1:
                        businessAreas = _a.sent();
                        _loop_2 = function () {
                            var checkbox = new __1.CheckBoxField(businessAreas[i].id.toString(), businessAreas[i].name);
                            this_1._billableBusinessAreaCheckboxes.push(checkbox);
                            this_1._billableBusinessAreaEstimateFields.push(new components_1.MoneyField(businessAreas[i].id.toString(), 'Budgeted Cost', false));
                            checkbox.observationProvider.observe(function () {
                                var index = _this._billableBusinessAreaCheckboxes.findIndex(function (existingField) { return existingField.key === checkbox.key; });
                                _this._billableBusinessAreaEstimateFields[index].setRequiredStatus(checkbox.value);
                                _this._billableBusinessAreaEstimateFields[index].isDisabled = !checkbox.value;
                                // mark as dirty to ensure validation is shown on ui
                                if (checkbox.value && !_this._billableBusinessAreaEstimateFields[index].isDirty) {
                                    _this._billableBusinessAreaEstimateFields[index].markAsDirty();
                                }
                            }, __1.FieldActions.ValueChanged);
                        };
                        this_1 = this;
                        for (i = 0; i < businessAreas.length; i++) {
                            _loop_2();
                        }
                        return [2 /*return*/];
                }
            });
        }); };
        _this.buildMeetingsFields = function () {
            _this._meetingsEnabledCheckbox = new __1.CheckBoxField('meetingsEnabled', 'Enabled');
            _this._meetingsEnabledCheckbox.observationProvider.observe(_this.meetingsEnabledChanged, __1.FieldActions.ValueChanged);
            _this._meetingsStartDateField = new __1.DateField('meetingsStartDate', 'Meetings Start Date', true, 'Date is required');
            _this._meetingsEndDateField = new __1.DateField('meetingsEndDate', 'Meetings End Date', true, 'Date is required');
            _this._meetingsPrescheduleEndDate = new __1.DateField('meetingsPrescheduleEndDate', 'Preschedule Cut-Off Date', true, 'Date is required');
            _this._meetingsDefaultStatus = new __1.MeetingStatusField('meetingsDefaultStatus', 'Default Meeting Status', true, __1.MeetingStatuses.Working);
            _this._meetingsDefaultStatus.isRequestDisabled = true;
            _this._meetingsDiningEnabled = new __1.CheckBoxField('meetingsDiningEnabled', 'Enabled');
            _this._meetingsDiningEnabled.setVisibility(_this.diningFeatureEnabled);
            _this._golfCartsEnabled = new __1.CheckBoxField('golfCartsEnabled', 'Onsite Golf Carts Available');
            _this._meetingRequestsEnabledCheckbox = new __1.CheckBoxField('meetingRequestsEnabled', 'Meeting Requests Enabled');
            _this._meetingRequestsEnabledCheckbox.observationProvider.observe(_this.meetingRequestEnabledChanged, __1.FieldActions.ValueChanged);
            _this._meetingRequestCutoffField = new __1.DateField('meetingRequestCutOffDate', 'Meeting Request Cutoff', true, 'required');
            _this._meetingsFields = [
                _this._meetingsEnabledCheckbox,
                _this._meetingsStartDateField,
                _this._meetingsEndDateField,
                _this._meetingsPrescheduleEndDate,
                _this._meetingsDefaultStatus,
                _this._meetingsDiningEnabled,
                _this._golfCartsEnabled,
                _this._meetingRequestsEnabledCheckbox
            ];
            if (_this.currentUserManager.hasFeatureEnabled(__1.FeatureType.MeetingInvites)) {
                _this._meetingInvitesEnabledCheckbox = new __1.CheckBoxField('meetingInvitesEnabled', 'Enable Meeting Calendar Invites (Outlook, Gmail, etc.)');
                _this._meetingsFields.push(_this._meetingInvitesEnabledCheckbox);
            }
            for (var i = 1; i < _this._meetingsFields.length; i++) {
                _this._meetingsFields[i].setVisibility(false);
            }
            _this._meetingsDiningEnabled.observationProvider.observe(_this.diningEnabledChanged, __1.FieldActions.ValueChanged);
        };
        _this.meetingRequestEnabledChanged = function (meetingRequestsEnabled) {
            _this.meetingRequestRepresentativeDataList.setVisibility(meetingRequestsEnabled);
            _this._meetingRequestCutoffField.setVisibility(meetingRequestsEnabled);
        };
        _this.diningEnabledChanged = function (diningEnabled) {
            for (var _i = 0, _a = _this.venueDataList.items; _i < _a.length; _i++) {
                var venueItems = _a[_i];
                var venueDiningField = venueItems.form.fields.filter(function (field) { return field.key === 'isDiningVenue'; })[0];
                venueDiningField.setVisibility(diningEnabled);
            }
            _this.observationProvider.notifyObservers(__1.EventConfigurationPageActions.DiningEnabledChanged);
        };
        _this.costManagementEnabledChanged = function () {
            for (var i = 1; i < _this._costManagementFields.length; i++) {
                _this._costManagementFields[i].setVisibility(_this._costManagementEnabledCheckbox.value);
            }
            _this.costManagementAccordian.isOpen = _this._costManagementEnabledCheckbox.value;
            _this.costManagementAccordian.toggleButton.setDisabled(!_this._costManagementEnabledCheckbox.value);
            _this.observationProvider.notifyObservers(__1.EventConfigurationPageActions.CostManagementEnabledChanged);
        };
        _this.meetingsEnabledChanged = function () {
            for (var i = 1; i < _this._meetingsFields.length; i++) {
                _this._meetingsFields[i].setVisibility(_this._meetingsEnabledCheckbox.value);
            }
            _this.meetingsAccordian.isOpen = _this._meetingsEnabledCheckbox.value;
            _this.meetingsAccordian.toggleButton.setDisabled(!_this._meetingsEnabledCheckbox.value);
            _this.observationProvider.notifyObservers(__1.EventConfigurationPageActions.MeetingsEnabledChanged);
        };
        _this.inventoryEnabledChanged = function () {
            for (var i = 1; i < _this._inventoryFields.length; i++) {
                _this._inventoryFields[i].setVisibility(_this._inventoryEnabledCheckbox.value);
            }
            _this.inventoryAccordian.isOpen = _this._inventoryEnabledCheckbox.value;
            _this.inventoryAccordian.toggleButton.setDisabled(!_this._inventoryEnabledCheckbox.value);
            _this.observationProvider.notifyObservers(__1.EventConfigurationPageActions.InventoryEnabledChanged);
        };
        _this.alertEnabledChanged = function () {
            for (var i = 1; i < _this._alertMessageFields.length; i++) {
                _this._alertMessageFields[i].setVisibility(_this._eventAlertEnabledCheckbox.value);
            }
            _this.alertAccordian.isOpen = _this._eventAlertEnabledCheckbox.value;
            _this.alertAccordian.toggleButton.setDisabled(!_this._eventAlertEnabledCheckbox.value);
            _this.observationProvider.notifyObservers(__1.EventConfigurationPageActions.AlertEnabledChanged);
        };
        _this.goToEventLanding = function () {
            _this.navigator.goToEventLanding(_this.eventId);
        };
        _this.save = function () { return __awaiter(_this, void 0, void 0, function () {
            var _i, _a, venue, err_1;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        if (!this.isPageValid()) return [3 /*break*/, 5];
                        this.saveButton.setProcessing(true);
                        this.showConfirmationPromptButton.setDisabled(true);
                        this.form.model.venues = this.venueDataList.getModels();
                        this.form.model.venuePreferences = this.venuePreferenceDataList.getModels();
                        this.form.model.meetingRequestRepresentatives = this.meetingRequestRepresentativeDataList.getModels();
                        this.form.model.eventAttendeeAccessLevels = this.eventAttendeeAccessLevelDataList.getModels();
                        this.form.model.eventTransportationOptions = this._eventTransportationConfigurationForm.getTransportationOptions();
                        this.form.model.eventTransportationVendorCompanies = this._eventTransportationConfigurationForm.getTransportationVendorCompanies();
                        this.form.model.transportationTermsAndConditions = this._eventTransportationConfigurationForm.transportTermsAndConditionsField.value;
                        this.form.model.isTransportationEnabled = this._eventTransportationConfigurationForm.transportationEnabledCheckbox.value;
                        this.form.model.eventHotelVendorCompanies = this._eventHotelConfigurationForm.getEventHotelVendorCompnies();
                        this.form.model.eventHotels = this._eventHotelConfigurationForm.getHotelModels();
                        this.form.model.hotelTermsAndConditions = this._eventHotelConfigurationForm.hotelTermsAndConditionsField.value;
                        this.form.model.isHotelsEnabled = this._eventHotelConfigurationForm.hotelsEnabledCheckbox.value;
                        this.setBillableBusinessAreasModel();
                        for (_i = 0, _a = this._form.model.venues; _i < _a.length; _i++) {
                            venue = _a[_i];
                            venue.isDiningVenue = venue.isDiningVenue && this._form.model.meetingsDiningEnabled;
                        }
                        _b.label = 1;
                    case 1:
                        _b.trys.push([1, 3, , 4]);
                        return [4 /*yield*/, this.api.put(__1.EndpointUrls.EventConfiguration, this.eventId, this.form.model)];
                    case 2:
                        _b.sent();
                        this.navigator.goToEventLanding(this.eventId);
                        return [3 /*break*/, 4];
                    case 3:
                        err_1 = _b.sent();
                        this.saveButton.setProcessing(false);
                        this.showConfirmationPromptButton.setDisabled(false);
                        return [3 /*break*/, 4];
                    case 4: return [3 /*break*/, 6];
                    case 5:
                        this.saveButton.setProcessing(false);
                        _b.label = 6;
                    case 6: return [2 /*return*/];
                }
            });
        }); };
        _this.setBillableBusinessAreasModel = function () {
            // remove unchecked business areas
            for (var _i = 0, _a = _this._form.model.billableBusinessAreas; _i < _a.length; _i++) {
                var billableBusinessArea = _a[_i];
                for (var _b = 0, _c = _this._billableBusinessAreaCheckboxes; _b < _c.length; _b++) {
                    var checkbox = _c[_b];
                    if (checkbox.key === billableBusinessArea.businessAreaId.toString() && !checkbox.value) {
                        billableBusinessArea.isRemoved = true;
                    }
                }
            }
            var _loop_3 = function (i) {
                var checkbox = _this._billableBusinessAreaCheckboxes[i];
                var estimateField = _this._billableBusinessAreaEstimateFields[i];
                var existingIndex = _this.form.model.billableBusinessAreas.findIndex(function (existingBusinessArea) {
                    return existingBusinessArea.businessAreaId.toString() === checkbox.key;
                });
                // add business areas
                if (existingIndex < 0 && checkbox.value) {
                    _this.form.model.billableBusinessAreas.push({
                        eventId: _this.eventId,
                        businessAreaId: parseInt(checkbox.key, 10),
                        estimatedCost: estimateField.value
                    });
                }
                // update business areas
                if (existingIndex >= 0) {
                    _this.form.model.billableBusinessAreas[existingIndex].estimatedCost = estimateField.value;
                }
            };
            // add or update business areas
            for (var i = 0; i < _this._billableBusinessAreaCheckboxes.length; i++) {
                _loop_3(i);
            }
        };
        _this.isPageValid = function () {
            var isValid = _this._form.isValid();
            isValid = isValid && _this.venueDataList.areAllValid();
            isValid = isValid && _this.venuePreferenceDataList.areAllValid();
            isValid = isValid && _this.eventAttendeeAccessLevelDataList.areAllValid();
            isValid = isValid && _this.meetingRequestRepresentativeDataList.areAllValid();
            if (isValid && _this.form.model.meetingsEnabled) {
                if (__1.DateUtils.isFirstDateGreaterOrEqual(_this.form.model.meetingsStartDate, _this.form.model.meetingsEndDate)) {
                    isValid = false;
                    _this.alert.show({
                        alertType: __1.AlertType.Error,
                        heading: 'Invalid Meeting Dates',
                        message: 'Meeting start date must be prior to the meeting end date.'
                    });
                }
                else if (__1.DateUtils.isFirstDateGreaterOrEqual(_this.form.model.meetingsPrescheduleEndDate, _this.form.model.meetingsStartDate)) {
                    isValid = false;
                    _this.alert.show({
                        alertType: __1.AlertType.Error,
                        heading: 'Invalid Meeting Dates',
                        message: 'Meeting preschedule end date must be prior to meeting start date.'
                    });
                }
            }
            var defaultAttendeeAccessLevelCount = _this.eventAttendeeAccessLevelDataList.items.filter(function (accessLevelItem) { return !accessLevelItem.form.model.isRemoved && accessLevelItem.form.model.isDefault; }).length;
            if (defaultAttendeeAccessLevelCount !== 1) {
                isValid = false;
                var message = '';
                if (_this.eventAttendeeAccessLevelDataList.items.filter(function (accessLevel) { return !accessLevel.form.model.isRemoved; }).length === 0) {
                    message = 'At least one event attendee user right is required.';
                }
                else if (defaultAttendeeAccessLevelCount === 0) {
                    message = 'You must set a default attendee user right.';
                }
                else if (defaultAttendeeAccessLevelCount > 1) {
                    message = 'You can only set one default attendee user right.';
                }
                _this.alert.show({
                    alertType: __1.AlertType.Error,
                    heading: 'Invalid Access Level',
                    message: message
                });
            }
            for (var _i = 0, _a = _this._billableBusinessAreaEstimateFields; _i < _a.length; _i++) {
                var estimatedCostField = _a[_i];
                isValid = isValid && estimatedCostField.isValid();
            }
            return isValid;
        };
        _this.showConfirmationPrompt = function () {
            _this.confirmationPrompt.show();
            _this.observationProvider.notifyObservers(__1.EventConfigurationPageActions.ConfirmationPromptShown);
        };
        _this.confirmationPromptCancelled = function () {
            _this.observationProvider.notifyObservers(__1.EventConfigurationPageActions.ConfirmationPromptCancelled);
        };
        _this.deleteEvent = function () { return __awaiter(_this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.confirmationPrompt.deleteButton.setProcessing(true);
                        this.confirmationPrompt.cancelButton.setDisabled(true);
                        this.observationProvider.notifyObservers(__1.EventConfigurationPageActions.EventDeleting);
                        return [4 /*yield*/, this.api.put(__1.EndpointUrls.Events + '/Delete', this.eventId, null)];
                    case 1:
                        _a.sent();
                        this.confirmationPrompt.deleteButton.setProcessing(false);
                        this.confirmationPrompt.cancelButton.setDisabled(false);
                        this.observationProvider.notifyObservers(__1.EventConfigurationPageActions.EventDeleted);
                        this.navigator.goToEventLanding(this.eventId);
                        return [2 /*return*/];
                }
            });
        }); };
        _this.buildBillableBusinessAreaReferenceButton = function (index) {
            return new button_1.default({
                onClick: function () {
                    _this.billableBusinessAreaAlerts[index].show({
                        alertType: __1.AlertType.Error,
                        heading: 'Unable to Remove',
                        message: 'This business area has the following cost management associations that must be removed before deleting.',
                        button: new button_1.default({
                            text: 'Close',
                            onClick: _this.hideReferenceDeleteAlert(index)
                        })
                    });
                },
                leftIcon: __1.Icons.Alert,
                isVisible: _this.billableBusinessAreaCheckboxes[index].value && _this._billableBusinessAreaCheckboxes[index].isDisabled === true
            });
        };
        _this.hideReferenceDeleteAlert = function (index) { return function () {
            _this.billableBusinessAreaAlerts[index].hide();
        }; };
        _this.alertAccordian = new components_1.Accordian(false);
        _this.meetingsAccordian = new components_1.Accordian(false);
        _this.hotelAndTransportationCutoffAccordian = new components_1.Accordian(false);
        _this.inventoryAccordian = new components_1.Accordian(false);
        _this.costManagementAccordian = new components_1.Accordian(false);
        _this.fileManagementHelpButton = new helpButton_1.default(helpUrls_1.HelpUrlType.EventConfigurationFileManagement, null, navigator, api);
        _this.alertMessageHelpButton = new helpButton_1.default(helpUrls_1.HelpUrlType.EventConfigurationAlertMessage, null, navigator, api);
        _this.meetingsHelpButton = new helpButton_1.default(helpUrls_1.HelpUrlType.EventConfigurationMeetings, null, navigator, api);
        _this.hotelHelpButton = new helpButton_1.default(helpUrls_1.HelpUrlType.EventConfigurationHotel, null, navigator, api);
        _this.transportationHelpButton = new helpButton_1.default(helpUrls_1.HelpUrlType.EventConfigurationTrasnportation, null, navigator, api);
        _this.inventoryHelpButton = new helpButton_1.default(helpUrls_1.HelpUrlType.EventConfigurationInventory, null, navigator, api);
        _this.costManagementHelpButton = new helpButton_1.default(helpUrls_1.HelpUrlType.EventConfigurationCostManagement, null, navigator, api);
        _this.showConfirmationPromptButton = new button_1.default({
            leftIcon: __1.Icons.Trash,
            onClick: _this.showConfirmationPrompt
        });
        _this.cancelButton = new button_1.default({
            text: 'Cancel',
            onClick: _this.goToEventLanding
        });
        _this.saveButton = new button_1.default({
            text: 'Save',
            onClick: _this.save,
            processingText: 'Saving...'
        });
        _this.observationProvider = new __1.ObservationProvider();
        _this.buildForm().then(function () {
            _this.loadConfigurationData();
        });
        _this._activeMeetingPane = __1.EventConfigurationPageMeetingPanes.Settings;
        _this.confirmationPrompt = new __1.ConfirmationPrompt(true, 'Delete Event', 'Once an event is deleted it can only be activated prior to the event end date. After this date, the event will be placed into Archived status and will no longer be editable. All unsaved changes to the event configuration will be lost.', 'Delete', 'Cancel');
        _this.confirmationPrompt.observationProvider.observe(_this.deleteEvent, __1.ConfirmationPromptActions.Confirmed);
        _this.confirmationPrompt.observationProvider.observe(_this.confirmationPromptCancelled, __1.ConfirmationPromptActions.Cancelled);
        _this.showMeetingSettingsPaneButton = new button_1.default({
            text: 'Settings',
            onClick: _this.showMeetingSettingsPane
        });
        _this.showMeetingVenuesPaneButton = new button_1.default({
            text: 'Venues',
            onClick: _this.showMeetingVenuesPane
        });
        _this.venueDataList = new __1.DataList(function () {
            var diningVenueField = new __1.CheckBoxField('isDiningVenue', 'Dining venue');
            diningVenueField.setVisibility(_this._meetingsDiningEnabled.value);
            var venueFields = [
                new __1.TextField('name', 'Name', true, 50),
                diningVenueField
            ];
            return venueFields;
        }, true, 'Add Venue', 'No venues assigned', '', true);
        _this.addVenueButton = new button_1.default({
            text: 'Add Venue',
            leftIcon: __1.Icons.Plus,
            onClick: _this.venueDataList.addItem
        });
        _this.venuePreferenceDataList = new __1.DataList(function () {
            return [
                new __1.TextField('name', 'Name', true, 50)
            ];
        }, true, 'Add Venue', 'No venue preferences assigned', '', true);
        _this.addVenuePreferenceButton = new button_1.default({
            text: 'Add Venue Preference',
            leftIcon: __1.Icons.Plus,
            onClick: _this.venuePreferenceDataList.addItem
        });
        _this.meetingRequestRepresentativeDataList = new __1.DataList(function () {
            return [
                new __1.BusinessAreaDropdown(_this.api, 'businessAreaId', 'Business Area', true, 'required'),
                new __1.UserSearchField(_this.api, 'User', true, 'required', false, 'userId', 'fullName')
            ];
        }, true, 'Add Representative', 'No business area representatives');
        _this.meetingRequestRepresentativeDataList.setVisibility(false);
        _this.eventAttendeeAccessLevelDataList = new __1.EventAttendeeAccessLevelDataList(_this.currentUserManager);
        _this.copyVenuePreferencesButton = new button_1.default({
            leftIcon: __1.Icons.ChevronRight,
            text: 'Copy',
            onClick: _this.copyVenuePreferences
        });
        _this.showMeetingRequestPaneButton = new button_1.default({
            text: 'Meeting Requests',
            onClick: _this.showMeetingRequestPane
        });
        _this.showEventAttendeeAccessLevelButton = new button_1.default({
            text: 'Attendee Access Levels',
            onClick: _this.showAttendeeAccessLevels
        });
        _this.eventAttendeeAccessLevelAlert = new __1.Alert();
        return _this;
    }
    Object.defineProperty(EventConfigurationPage.prototype, "isLoaded", {
        get: function () {
            return this._isLoaded;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(EventConfigurationPage.prototype, "form", {
        get: function () {
            return this._form;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(EventConfigurationPage.prototype, "venueForms", {
        get: function () {
            return this._venueForms;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(EventConfigurationPage.prototype, "venuePreferenceForms", {
        get: function () {
            return this._venuePreferenceForms;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(EventConfigurationPage.prototype, "fileManagementFields", {
        get: function () {
            return this._fileManagementFields;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(EventConfigurationPage.prototype, "alertMessageFields", {
        get: function () {
            return this._alertMessageFields;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(EventConfigurationPage.prototype, "inventoryFields", {
        get: function () {
            return this._inventoryFields;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(EventConfigurationPage.prototype, "meetingsFields", {
        get: function () {
            return this._meetingsFields;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(EventConfigurationPage.prototype, "costManagementFields", {
        get: function () {
            return this._costManagementFields;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(EventConfigurationPage.prototype, "billableBusinessAreaEstimateFields", {
        get: function () {
            return this._billableBusinessAreaEstimateFields;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(EventConfigurationPage.prototype, "billableBusinessAreaCheckboxes", {
        get: function () {
            return this._billableBusinessAreaCheckboxes;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(EventConfigurationPage.prototype, "activeMeetingPane", {
        get: function () {
            return this._activeMeetingPane;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(EventConfigurationPage.prototype, "meetingRequestEnabledCheckbox", {
        get: function () {
            return this._meetingRequestsEnabledCheckbox;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(EventConfigurationPage.prototype, "meetingRequestCutoffField", {
        get: function () {
            return this._meetingRequestCutoffField;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(EventConfigurationPage.prototype, "meetingInvitesEnabledCheckbox", {
        get: function () {
            return this._meetingInvitesEnabledCheckbox;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(EventConfigurationPage.prototype, "eventAttendeeAccessLevelsVisible", {
        get: function () {
            return this._eventAttendeeAccessLevelsVisible;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(EventConfigurationPage.prototype, "meetingRequestUrl", {
        get: function () {
            return "".concat(this.navigator.webBaseUrl, "#/events/").concat(this.eventId, "/0");
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(EventConfigurationPage.prototype, "eventHotelConfigurationForm", {
        get: function () {
            return this._eventHotelConfigurationForm;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(EventConfigurationPage.prototype, "eventTransportationConfigurationForm", {
        get: function () {
            return this._eventTransportationConfigurationForm;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(EventConfigurationPage.prototype, "accommodationCutoffDateField", {
        get: function () {
            return this._accommodationCutoffDateField;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(EventConfigurationPage.prototype, "requireArrivalDepartureInformationField", {
        get: function () {
            return this._requireArrivalDepartureInformationField;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(EventConfigurationPage.prototype, "badgingEnabledCheckbox", {
        get: function () {
            return this._badgingEnabledCheckbox;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(EventConfigurationPage.prototype, "enableAttendeeHeadCount", {
        get: function () {
            return this._enableAttendeeHeadCount;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(EventConfigurationPage.prototype, "isApp", {
        get: function () {
            return this.currentUserManager.clientChecker.isMobileApp();
        },
        enumerable: false,
        configurable: true
    });
    EventConfigurationPage.prototype.buildForm = function () {
        return __awaiter(this, void 0, void 0, function () {
            var fields, _i, _a, field, _b, _c, field, _d, _e, field, _f, _g, field, _h, _j, field;
            return __generator(this, function (_k) {
                switch (_k.label) {
                    case 0:
                        this._accommodationCutoffDateField = new __1.DateField('accommodationCutoffDate', 'Hotel & Transportation Cut-off Date', false);
                        this._requireArrivalDepartureInformationField = new __1.CheckBoxField('requireArrivalDepartureInformation', 'Require Arrival/Departure Information');
                        this._badgingEnabledCheckbox = new __1.CheckBoxField('badgingEnabled', 'Enabled');
                        this._enableAttendeeHeadCount = new __1.CheckBoxField('enableAttendeeHeadCount', 'Enable Attendee Head Count');
                        this._enableAttendeeHeadCount.setVisibility(this.attendeeHeadCountFeatureIsEnabled);
                        this.buildInventoryFields();
                        this.buildMeetingsFields();
                        return [4 /*yield*/, this.buildCostManagementFields()];
                    case 1:
                        _k.sent();
                        this.buildFileManagementFields();
                        this.buildEventAlertFields();
                        fields = [];
                        for (_i = 0, _a = this._inventoryFields; _i < _a.length; _i++) {
                            field = _a[_i];
                            fields.push(field);
                        }
                        for (_b = 0, _c = this._meetingsFields; _b < _c.length; _b++) {
                            field = _c[_b];
                            fields.push(field);
                        }
                        for (_d = 0, _e = this._costManagementFields; _d < _e.length; _d++) {
                            field = _e[_d];
                            fields.push(field);
                        }
                        for (_f = 0, _g = this._fileManagementFields; _f < _g.length; _f++) {
                            field = _g[_f];
                            fields.push(field);
                        }
                        for (_h = 0, _j = this._alertMessageFields; _h < _j.length; _h++) {
                            field = _j[_h];
                            fields.push(field);
                        }
                        fields.push(this._meetingRequestCutoffField);
                        fields.push(this._accommodationCutoffDateField);
                        fields.push(this._requireArrivalDepartureInformationField);
                        fields.push(this._badgingEnabledCheckbox);
                        fields.push(this._enableAttendeeHeadCount);
                        this._form = new __1.Form(fields);
                        this._venueForms = [];
                        this._venuePreferenceForms = [];
                        return [2 /*return*/];
                }
            });
        });
    };
    Object.defineProperty(EventConfigurationPage.prototype, "diningFeatureEnabled", {
        get: function () {
            return this.currentUserManager.hasFeatureEnabled(__1.FeatureType.Dining);
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(EventConfigurationPage.prototype, "transportationFeatureIsEnabled", {
        get: function () {
            return this.currentUserManager.hasFeatureEnabled(__1.FeatureType.TravelAndLodging);
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(EventConfigurationPage.prototype, "lodgingFeatureIsEnabled", {
        get: function () {
            return this.currentUserManager.hasFeatureEnabled(__1.FeatureType.TravelAndLodging);
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(EventConfigurationPage.prototype, "inventoryFeatureIsEnabled", {
        get: function () {
            return this.currentUserManager.hasFeatureEnabled(__1.FeatureType.Inventory);
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(EventConfigurationPage.prototype, "costManagementFeatureIsEnabled", {
        get: function () {
            return this.currentUserManager.hasFeatureEnabled(__1.FeatureType.CostManagement);
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(EventConfigurationPage.prototype, "attendeeHeadCountFeatureIsEnabled", {
        get: function () {
            return this.currentUserManager.hasFeatureEnabled(__1.FeatureType.AttendeeHeadCount);
        },
        enumerable: false,
        configurable: true
    });
    EventConfigurationPage.prototype.loadConfigurationData = function () {
        return __awaiter(this, void 0, void 0, function () {
            var _a, _b, i, businessAreaIsSelected, j;
            var _this = this;
            return __generator(this, function (_c) {
                switch (_c.label) {
                    case 0:
                        _b = (_a = this._form).setModel;
                        return [4 /*yield*/, this.api.get(__1.EndpointUrls.Events + '/GetConfiguration/' + this.eventId)];
                    case 1:
                        _b.apply(_a, [_c.sent()]);
                        for (i = 0; i < this._billableBusinessAreaCheckboxes.length; i++) {
                            businessAreaIsSelected = false;
                            for (j = 0; j < this._form.model.billableBusinessAreas.length; j++) {
                                if (this._form.model.billableBusinessAreas[j].businessAreaId.toString() === this._billableBusinessAreaCheckboxes[i].key) {
                                    this._billableBusinessAreaCheckboxes[i].setValue(true);
                                    this._billableBusinessAreaEstimateFields[i].setValue(this._form.model.billableBusinessAreas[j].estimatedCost);
                                    if (!this._form.model.billableBusinessAreas[j].isDeletable) {
                                        this._billableBusinessAreaCheckboxes[i].isDisabled = true;
                                    }
                                    this.billableBusinessAreaAlerts.push(new __1.Alert());
                                    this.billableBusinessAreaReferences.push(this._form.model.billableBusinessAreas[j].references);
                                    businessAreaIsSelected = true;
                                }
                            }
                            if (!businessAreaIsSelected) {
                                this.billableBusinessAreaAlerts.push(new __1.Alert());
                                this.billableBusinessAreaReferences.push([]);
                            }
                        }
                        this._eventHotelConfigurationForm = new __1.EventHotelConfigurationForm(this.api, this._form.model.eventStartDate);
                        this._eventTransportationConfigurationForm = new __1.EventTransportationConfigurationForm(this.api);
                        this._isLoaded = true;
                        if (!this._meetingsStartDateField.value) {
                            this._meetingsStartDateField.setValue(this._form.model.eventStartDate);
                        }
                        if (!this._meetingsEndDateField.value) {
                            this._meetingsEndDateField.setValue(this._form.model.eventEndDate);
                        }
                        if (!this.form.model.venues.length) {
                            this.venueDataList.setModels([
                                { name: 'None', sequence: 0, isDeletable: true },
                                { name: 'Other (See Comments)', sequence: 1, isDeletable: true },
                            ]);
                        }
                        else {
                            this.venueDataList.setModels(this._form.model.venues);
                        }
                        if (!this.form.model.venuePreferences.length) {
                            this.venuePreferenceDataList.setModels([
                                { name: 'None', sequence: 0, isDeletable: true },
                                { name: 'Other (See Comments)', sequence: 1, isDeletable: true }
                            ]);
                        }
                        else {
                            this.venuePreferenceDataList.setModels(this._form.model.venuePreferences);
                        }
                        this.diningEnabledChanged(this._form.model.meetingsDiningEnabled);
                        this.meetingRequestRepresentativeDataList.setModels(this.form.model.meetingRequestRepresentatives);
                        this.eventAttendeeAccessLevelDataList.setModels(this.form.model.eventAttendeeAccessLevels);
                        this._eventTransportationConfigurationForm.setTransportationVendorCompanies(this.form.model.eventTransportationVendorCompanies);
                        this._eventTransportationConfigurationForm.setTransportationOptions(this.form.model.eventTransportationOptions);
                        this._eventTransportationConfigurationForm.transportTermsAndConditionsField.setValue(this.form.model.transportationTermsAndConditions);
                        this._eventTransportationConfigurationForm.transportationEnabledCheckbox.setValue(this.form.model.isTransportationEnabled);
                        this._eventTransportationConfigurationForm.observationProvider.observe(function () { _this.observationProvider.notifyObservers(__1.EventConfigurationPageActions.TransportationEnabledChanged); }, __1.EventTransportationConfigurationFormActions.TransportationEnabledChanged);
                        this._eventHotelConfigurationForm.setEventHotelVendorCompanies(this.form.model.eventHotelVendorCompanies);
                        this._eventHotelConfigurationForm.setHotelModels(this.form.model.eventHotels);
                        this._eventHotelConfigurationForm.setTermsAndConditions(this.form.model.hotelTermsAndConditions);
                        this._eventHotelConfigurationForm.observationProvider.observe(function () { _this.observationProvider.notifyObservers(__1.EventConfigurationPageActions.HotelsEnabledChanged); }, __1.EventHotelConfigurationFormActions.HotelsEnabledChanged);
                        this._eventHotelConfigurationForm.hotelsEnabledCheckbox.setValue(this.form.model.isHotelsEnabled);
                        this.observationProvider.notifyObservers(__1.EventConfigurationPageActions.ConfigurationLoaded);
                        return [2 /*return*/];
                }
            });
        });
    };
    return EventConfigurationPage;
}(__1.BasePage));
exports.default = EventConfigurationPage;
