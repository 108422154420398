"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
var _1 = require(".");
var baseField_1 = require("./baseField");
var button_1 = require("../button/button");
var icons_1 = require("../icons");
var FileInputField = /** @class */ (function (_super) {
    __extends(FileInputField, _super);
    function FileInputField(key, label, isRequired, acceptedFileTypes, maxFileBytes, requiredValidationMessage) {
        if (acceptedFileTypes === void 0) { acceptedFileTypes = _1.FileInputFileTypes.Images; }
        if (maxFileBytes === void 0) { maxFileBytes = 307200; }
        var _this = _super.call(this, _1.FieldType.File, key, label, isRequired, null, requiredValidationMessage) || this;
        _this.key = key;
        _this.acceptedFileTypes = acceptedFileTypes;
        _this.maxFileBytes = maxFileBytes;
        _this.removeButton = new button_1.default({
            onClick: function () { return _this.clearValue(); },
            leftIcon: icons_1.Icons.Minus,
            isVisible: false
        });
        return _this;
    }
    Object.defineProperty(FileInputField.prototype, "placeHolderText", {
        get: function () {
            return this._placeHolderText;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(FileInputField.prototype, "value", {
        get: function () {
            return this.stringValue;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(FileInputField.prototype, "errorMessage", {
        get: function () {
            var baseErrorMessage = _super.prototype.getErrorMessage.call(this);
            if (baseErrorMessage) {
                return baseErrorMessage;
            }
            if (this.value && !this.isFileSizeValid() && this.isDirty) {
                return 'Files cannot exceed 3Mb';
            }
            return baseErrorMessage;
        },
        enumerable: false,
        configurable: true
    });
    FileInputField.prototype.isValid = function () {
        var isValid = this.checkRequiredStatus();
        this.setIsValid(isValid);
        return isValid;
    };
    FileInputField.prototype.isFileSizeValid = function () {
        if (!this.value) {
            return true;
        }
        // base64 has 4/3 overhead due to encoding
        var fileSize = Math.round((this.value.length) * 3 / 4);
        return fileSize <= this.maxFileBytes;
    };
    FileInputField.prototype.setValue = function (newValue) {
        if (newValue) {
            this.removeButton.setVisibility(true);
        }
        else {
            this.removeButton.setVisibility(false);
        }
        this.setStringValue(newValue);
        if (this.isFileSizeValid() === false) {
            this.setIsValid(false);
        }
    };
    return FileInputField;
}(baseField_1.default));
exports.default = FileInputField;
