"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var EmailAndServicesTileActions;
(function (EmailAndServicesTileActions) {
    EmailAndServicesTileActions["Toggled"] = "Toggled";
    EmailAndServicesTileActions["DataLoading"] = "DataLoading";
    EmailAndServicesTileActions["DataLoaded"] = "DataLoaded";
    EmailAndServicesTileActions["EditModeActivated"] = "EditModeEnabled";
    EmailAndServicesTileActions["EditModeDeactivated"] = "EditModeDeactivated";
    EmailAndServicesTileActions["SendingEmails"] = "SendingEmails";
    EmailAndServicesTileActions["EmailsSent"] = "EmailsSent";
    EmailAndServicesTileActions["VendorTypeChanged"] = "VendorTypeChanged";
})(EmailAndServicesTileActions || (EmailAndServicesTileActions = {}));
exports.default = EmailAndServicesTileActions;
