"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
var __1 = require("../..");
var button_1 = require("../../components/button/button");
var helpButton_1 = require("../../components/button/helpButton");
var helpUrls_1 = require("../helpUrls");
var VendorCompaniesPage = /** @class */ (function (_super) {
    __extends(VendorCompaniesPage, _super);
    function VendorCompaniesPage(api, navigator, currentUserManager) {
        var _this = _super.call(this, __1.PageTypes.VendorCompaniesPage, 'Vendor Companies') || this;
        _this.api = api;
        _this.navigator = navigator;
        _this.currentUserManager = currentUserManager;
        _this.loadData = function () {
            return _this.api.getTablePage(__1.EndpointUrls.VendorCompanies, _this.table.recordsPerPage, _this.table.currentPage, _this.table.sortExpression, _this.table.searchPhrase, __assign({}, _this.listOptionSlideContainer.appliedFiltersModel));
        };
        _this.goToVendorCompanyDetailPage = function (vendorCompany) { return function () {
            if (vendorCompany) {
                _this.navigator.goToVendorCompanyDetailPage(vendorCompany.id);
                var detailPage = _this.navigator.currentPage;
                detailPage.observationProvider.observeOnce(function () { return _this.table.load(); }, __1.VendorCompanyDetailPageActions.RecordUpdated);
            }
            else {
                _this.navigator.goToVendorCompanyDetailPage();
                var detailPage = _this.navigator.currentPage;
                detailPage.observationProvider.observeOnce(function () { return _this.table.load(); }, __1.VendorCompanyDetailPageActions.RecordAdded);
            }
        }; };
        _this.buildEditButton = function (vendorCompany) {
            return new button_1.default({
                onClick: _this.goToVendorCompanyDetailPage(vendorCompany),
                text: vendorCompany.name
            });
        };
        _this.helpButton = new helpButton_1.default(helpUrls_1.HelpUrlType.VendorCompanyListPage, null, navigator, api);
        _this.table = new __1.Table(_this.loadData, api);
        _this.table.searchBoxPlaceholder = 'By company name';
        var filters = [
            new __1.BooleanDropdownField('isActive', 'Is Active', false),
            new __1.TextField('name', 'Company Name', false, 200),
            new __1.BooleanDropdownField('offersHotels', 'Offers Hotels', false),
            new __1.BooleanDropdownField('offersAuto', 'Offers Transportation', false)
        ];
        var defaultFilters = { isActive: true };
        _this.listOptionSlideContainer = new __1.ListOptionSlideContainer(filters, defaultFilters);
        _this.listOptionSlideContainer.observationProvider.observe(function () { return _this.table.load(); }, __1.ListOptionSlideContainerActions.OptionsApplied);
        _this.table.load();
        _this.newCompanyButton = new button_1.default({
            onClick: _this.goToVendorCompanyDetailPage(),
            text: 'New Company',
            leftIcon: __1.Icons.Plus
        });
        return _this;
    }
    Object.defineProperty(VendorCompaniesPage.prototype, "isApp", {
        get: function () {
            return this.currentUserManager.clientChecker.isMobileApp();
        },
        enumerable: false,
        configurable: true
    });
    return VendorCompaniesPage;
}(__1.BasePage));
exports.default = VendorCompaniesPage;
