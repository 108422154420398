"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var InputUtils = /** @class */ (function () {
    function InputUtils() {
    }
    InputUtils.formatAsDate = function (input) {
        input.addEventListener('keypress', function (e) {
            // e.keyCode 47 === '/'
            // e.keyCode 48 - 57  === '0-9'
            if (e.keyCode < 47 || e.keyCode > 57) {
                e.preventDefault();
            }
            var len = input.value.length;
            if (e.keyCode === 47) {
                e.preventDefault();
                if (len === 1) {
                    input.value = '0' + input.value;
                    len++;
                }
                if (len === 4) {
                    var lastNumber = input.value.substring(input.value.length - 1);
                    input.value = input.value.substring(0, input.value.length - 1);
                    input.value = input.value + '0' + lastNumber;
                    len++;
                }
            }
            if (len === 2) {
                input.value += '/';
            }
            if (len === 5) {
                input.value += '/';
            }
        });
    };
    InputUtils.formatAsPhone = function (input) {
        input.addEventListener('keypress', function (e) {
            // e.keyCode 40 === (
            // e.keyCode 41 === )
            // e.keyCode 32 === ' '
            // e.keyCode 45 === -
            if (e.keyCode < 48 || e.keyCode > 57) {
                e.preventDefault();
            }
            var len = input.value.length;
            if (len === 0) {
                input.value += '(';
            }
            if (len === 4) {
                input.value += ') ';
            }
            if (len === 5) {
                input.value += ' ';
            }
            if (len === 9) {
                input.value += '-';
            }
        });
    };
    return InputUtils;
}());
exports.default = InputUtils;
