"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var EventInfoTileActions;
(function (EventInfoTileActions) {
    EventInfoTileActions["Toggled"] = "Toggled";
    EventInfoTileActions["DataLoading"] = "DataLoading";
    EventInfoTileActions["DataLoaded"] = "DataLoaded";
    EventInfoTileActions["EditModeActivated"] = "EditModeEnabled";
    EventInfoTileActions["EditModeDeactivated"] = "EditModeDeactivated";
})(EventInfoTileActions || (EventInfoTileActions = {}));
exports.default = EventInfoTileActions;
