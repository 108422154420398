"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g = Object.create((typeof Iterator === "function" ? Iterator : Object).prototype);
    return g.next = verb(0), g["throw"] = verb(1), g["return"] = verb(2), typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
var __1 = require("..");
var observationProvider_1 = require("../utils/observationProvider");
var currentUserManagerActions_1 = require("./currentUserManagerActions");
var authorization_1 = require("../api/authorization");
var api_1 = require("../api");
var CurrentUserManager = /** @class */ (function () {
    function CurrentUserManager(localStoragePlugin, clientChecker) {
        var _this = this;
        this.localStoragePlugin = localStoragePlugin;
        this.clientChecker = clientChecker;
        this.loadCurrentUserToken = function () {
            _this.authenticatedUserDto = _this.localStoragePlugin.getCurrentUser();
            if (_this.authenticatedUserDto && _this.isTokenExpired()) {
                _this.logout();
            }
        };
        this.isTokenExpired = function () {
            return (!_this.authenticatedUserDto.expirationDateTime) || __1.DateUtils.hasDatePast(_this.authenticatedUserDto.expirationDateTime);
        };
        this.login = function (authenticatedUserDto) {
            if (authenticatedUserDto.passwordResetKey) {
                _this.observationProvider.notifyObservers(currentUserManagerActions_1.default.RequiringPasswordChange, authenticatedUserDto.passwordResetKey);
            }
            else {
                _this.authenticatedUserDto = authenticatedUserDto;
                _this.localStoragePlugin.setCurrentUser(authenticatedUserDto, false);
                _this.observationProvider.notifyObservers(currentUserManagerActions_1.default.LoggedIn, authenticatedUserDto.upcomingEventId);
            }
        };
        this.logout = function () {
            _this.authenticatedUserDto = null;
            _this.localStoragePlugin.setCurrentUser(null, false);
            _this.observationProvider.notifyObservers(currentUserManagerActions_1.default.LoggedOut);
        };
        this.updateUserPreferences = function (preferences) {
            if (_this.authenticatedUserDto) {
                _this.authenticatedUserDto.defaultMyMeetingsFilterEnabled = preferences.defaultMyMeetingsFilterEnabled;
                _this.authenticatedUserDto.defaultMeetingScheduleEnabled = preferences.defaultMeetingScheduleEnabled;
                _this.localStoragePlugin.setCurrentUser(_this.authenticatedUserDto, false);
            }
        };
        this.observationProvider = new observationProvider_1.default();
        this.loadCurrentUserToken();
    }
    Object.defineProperty(CurrentUserManager.prototype, "isLoggedIn", {
        get: function () {
            return this.authenticatedUserDto !== null;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(CurrentUserManager.prototype, "authenticationToken", {
        get: function () {
            if (!this.authenticatedUserDto) {
                return null;
            }
            return this.authenticatedUserDto.authenticationToken;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(CurrentUserManager.prototype, "userType", {
        get: function () {
            if (!this.authenticatedUserDto) {
                return api_1.UserTypes.Anonymous;
            }
            return this.authenticatedUserDto.userType;
        },
        enumerable: false,
        configurable: true
    });
    CurrentUserManager.prototype.updateProfileImageUrl = function (profileImageUrl) {
        if (!this.authenticatedUserDto) {
            return;
        }
        this.authenticatedUserDto.profileImageUrl = profileImageUrl;
        this.localStoragePlugin.setCurrentUser(this.authenticatedUserDto, false);
        this.observationProvider.notifyObservers(currentUserManagerActions_1.default.ProfileImageUrlUpdated);
    };
    CurrentUserManager.prototype.updateHeaderLogoImageUrl = function (imageUrl) {
        if (!this.authenticatedUserDto) {
            return;
        }
        this.authenticatedUserDto.headerLogoImageUrl = imageUrl;
        this.localStoragePlugin.setCurrentUser(this.authenticatedUserDto, false);
        this.observationProvider.notifyObservers(currentUserManagerActions_1.default.HeaderLogoImageUrlUpdated);
    };
    Object.defineProperty(CurrentUserManager.prototype, "swapReplacementEmail", {
        get: function () {
            if (!this.authenticatedUserDto) {
                return null;
            }
            return this.authenticatedUserDto.swapReplacementEmail;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(CurrentUserManager.prototype, "email", {
        get: function () {
            if (!this.authenticatedUserDto) {
                return null;
            }
            return this.authenticatedUserDto.email;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(CurrentUserManager.prototype, "isViewOnly", {
        get: function () {
            return !this.hasAnyPermission() &&
                this.userType != api_1.UserTypes.VendorUser;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(CurrentUserManager.prototype, "vendorCompanyId", {
        get: function () {
            if (!this.authenticatedUserDto) {
                return null;
            }
            return this.authenticatedUserDto.vendorCompanyId;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(CurrentUserManager.prototype, "userId", {
        get: function () {
            if (!this.authenticatedUserDto) {
                return null;
            }
            return this.authenticatedUserDto.userId;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(CurrentUserManager.prototype, "businessAreaId", {
        get: function () {
            if (!this.authenticatedUserDto) {
                return null;
            }
            return this.authenticatedUserDto.businessAreaId;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(CurrentUserManager.prototype, "tenantId", {
        get: function () {
            if (!this.authenticatedUserDto) {
                return null;
            }
            return this.authenticatedUserDto.tenantId;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(CurrentUserManager.prototype, "userFullName", {
        get: function () {
            if (!this.authenticatedUserDto) {
                return null;
            }
            return "".concat(this.authenticatedUserDto.firstName, " ").concat(this.authenticatedUserDto.lastName);
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(CurrentUserManager.prototype, "isSuperUser", {
        get: function () {
            if (!this.authenticatedUserDto) {
                return null;
            }
            return this.authenticatedUserDto.isSuperUser;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(CurrentUserManager.prototype, "permissions", {
        get: function () {
            if (!this.authenticatedUserDto) {
                return null;
            }
            return this.authenticatedUserDto;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(CurrentUserManager.prototype, "productKnowledgeRequired", {
        get: function () {
            if (!this.authenticatedUserDto) {
                return null;
            }
            return this.authenticatedUserDto.productKnowledgeRequired;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(CurrentUserManager.prototype, "titleRequired", {
        get: function () {
            if (!this.authenticatedUserDto) {
                return null;
            }
            return this.authenticatedUserDto.titleRequired;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(CurrentUserManager.prototype, "userPreferences", {
        get: function () {
            if (!this.authenticatedUserDto) {
                return null;
            }
            return this.authenticatedUserDto;
        },
        enumerable: false,
        configurable: true
    });
    CurrentUserManager.prototype.hasPermission = function (permissionType) {
        if (!this.authenticatedUserDto) {
            return false;
        }
        if (this.authenticatedUserDto.isSuperUser) {
            return true;
        }
        return this.authenticatedUserDto[permissionType];
    };
    CurrentUserManager.prototype.hasFeatureEnabled = function (featureType) {
        if (!this.authenticatedUserDto) {
            return false;
        }
        return this.authenticatedUserDto[featureType];
    };
    Object.defineProperty(CurrentUserManager.prototype, "profileImageUrl", {
        get: function () {
            if (!this.authenticatedUserDto) {
                return null;
            }
            return this.authenticatedUserDto.profileImageUrl;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(CurrentUserManager.prototype, "headerLogoImageUrl", {
        get: function () {
            if (!this.authenticatedUserDto) {
                return null;
            }
            return this.authenticatedUserDto.headerLogoImageUrl;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(CurrentUserManager.prototype, "isSso", {
        get: function () {
            if (!this.authenticatedUserDto) {
                return null;
            }
            return this.authenticatedUserDto.isSso;
        },
        enumerable: false,
        configurable: true
    });
    CurrentUserManager.prototype.hasAnyPermission = function () {
        for (var permissionType in authorization_1.PermissionType) {
            if (authorization_1.PermissionType.hasOwnProperty(permissionType)) {
                if (this.hasPermission(authorization_1.PermissionType[permissionType])) {
                    return true;
                }
            }
        }
        return false;
    };
    CurrentUserManager.prototype.setActiveTenant = function (setActiveTenantResponse) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                if (!this.authenticatedUserDto) {
                    return [2 /*return*/];
                }
                this.authenticatedUserDto.authenticationToken = setActiveTenantResponse.token;
                this.authenticatedUserDto.headerLogoImageUrl = setActiveTenantResponse.headerLogoImageUrl;
                this.authenticatedUserDto.inventoryEnabled = setActiveTenantResponse.inventoryEnabled;
                this.authenticatedUserDto.meetingInvitesEnabled = setActiveTenantResponse.meetingInvitesEnabled;
                this.authenticatedUserDto.meetingNotesEnabled = setActiveTenantResponse.meetingNotesEnabled;
                this.authenticatedUserDto.costManagementEnabled = setActiveTenantResponse.costManagementEnabled;
                this.authenticatedUserDto.travelAndLodgingEnabled = setActiveTenantResponse.travelAndLodgingEnabled;
                this.authenticatedUserDto.diningEnabled = setActiveTenantResponse.diningEnabled;
                this.authenticatedUserDto.productKnowledgeRequired = setActiveTenantResponse.productKnowledgeRequired;
                this.authenticatedUserDto.titleRequired = setActiveTenantResponse.titleRequired;
                this.authenticatedUserDto.tenantId = setActiveTenantResponse.tenantId;
                this.localStoragePlugin.setCurrentUser(this.authenticatedUserDto, true);
                this.observationProvider.notifyObservers(currentUserManagerActions_1.default.TenantChanged);
                return [2 /*return*/];
            });
        });
    };
    CurrentUserManager.prototype.getBadgePrintSettings = function () {
        return this.localStoragePlugin.getBadgePrintSettings();
    };
    CurrentUserManager.prototype.setBadgePrintSettings = function (settings) {
        this.localStoragePlugin.setBadgePrintSettings(settings);
    };
    return CurrentUserManager;
}());
exports.default = CurrentUserManager;
