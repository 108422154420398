"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var MeetingNotificationModalActions;
(function (MeetingNotificationModalActions) {
    MeetingNotificationModalActions["DataLoaded"] = "DataLoaded";
    MeetingNotificationModalActions["Sending"] = "Sending";
    MeetingNotificationModalActions["Sent"] = "Sent";
    MeetingNotificationModalActions["VisibilityChanged"] = "VisibilityChanged";
})(MeetingNotificationModalActions || (MeetingNotificationModalActions = {}));
exports.default = MeetingNotificationModalActions;
