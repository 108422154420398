"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g = Object.create((typeof Iterator === "function" ? Iterator : Object).prototype);
    return g.next = verb(0), g["throw"] = verb(1), g["return"] = verb(2), typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
var __1 = require("../..");
var button_1 = require("../../components/button/button");
var helpButton_1 = require("../../components/button/helpButton");
var helpUrls_1 = require("../helpUrls");
var InventorySchedulerPage = /** @class */ (function (_super) {
    __extends(InventorySchedulerPage, _super);
    function InventorySchedulerPage(api, currentUserManager, navigator) {
        var _this = _super.call(this, __1.PageTypes.InventorySchedulerPage, 'Inventory Scheduler') || this;
        _this.api = api;
        _this.currentUserManager = currentUserManager;
        _this.navigator = navigator;
        _this.selectInventoryItem = function (inventoryItem) { return function () {
            if (_this._selectedInventoryItem &&
                inventoryItem.inventoryItemId === _this._selectedInventoryItem.inventoryItemId) {
                _this._selectedInventoryItem = null;
                _this.observationProvider.notifyObservers(__1.InventorySchedulerPageActions.InventoryItemSelectionChanged);
                return;
            }
            _this._selectedInventoryItem = __assign({}, inventoryItem);
            _this._selectedInventoryItem.eventAssignments = [];
            for (var _i = 0, _a = inventoryItem.eventAssignments; _i < _a.length; _i++) {
                var assignment = _a[_i];
                _this._selectedInventoryItem.eventAssignments.push(assignment);
            }
            _this._selectedInventoryItem.eventAssignments.push({
                eventName: _this._selectedRequest.eventName,
                preEventInventoryReservationDate: _this._selectedRequest.preEventInventoryReservationDate,
                eventStartDate: _this._selectedRequest.eventStartDate,
                eventEndDate: _this._selectedRequest.eventEndDate,
                postEventInventoryReleaseDate: _this._selectedRequest.postEventInventoryReleaseDate
            });
            _this._errorMessage = '';
            _this.observationProvider.notifyObservers(__1.InventorySchedulerPageActions.InventoryItemSelectionChanged);
        }; };
        _this.getEventGroupSubHeading = function (index) {
            return "".concat(__1.DateUtils.formatDate(_this._inventoryRequests[index].data[0].eventStartDate), " - ").concat(__1.DateUtils.formatDate(_this._inventoryRequests[index].data[0].eventEndDate), " | ").concat(_this._inventoryRequests[index].data[0].eventCity, " ").concat(_this._inventoryRequests[index].data[0].eventCountry);
        };
        _this.selectInventoryRequest = function (request) { return function () {
            _this._selectedRequest = request;
            _this._schedulerMonths = __1.DateUtils.getWholeMonths(_this._selectedRequest.preEventInventoryReservationDate, _this._selectedRequest.postEventInventoryReleaseDate);
            _this._schedulerDates = __1.DateUtils.getDateRangeArray(__1.DateUtils.getFirstDateOfMonth(_this._selectedRequest.preEventInventoryReservationDate), __1.DateUtils.getLastDateOfMonth(_this._selectedRequest.postEventInventoryReleaseDate));
            _this.loadSchedulerData();
        }; };
        _this.loadSchedulerData = function () { return __awaiter(_this, void 0, void 0, function () {
            var requestParams, schedulerData;
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!this._selectedRequest) {
                            return [2 /*return*/];
                        }
                        requestParams = {
                            startDate: this._schedulerDates[0],
                            endDate: this._schedulerDates[this._schedulerDates.length - 1],
                            inventoryItemGroupId: this._selectedRequest.inventoryItemGroupId
                        };
                        return [4 /*yield*/, this.api.get(__1.EndpointUrls.Inventory + '/SchedulerEventInventory', requestParams)];
                    case 1:
                        schedulerData = _a.sent();
                        this._inventoryItems = schedulerData.inventoryItems;
                        this._otherRequests = schedulerData.requests.filter(function (request) { return request.id !== _this._selectedRequest.id; });
                        this.observationProvider.notifyObservers(__1.InventorySchedulerPageActions.SchedulerDataLoaded);
                        return [2 /*return*/];
                }
            });
        }); };
        _this.isInRequestDateRange = function (lookupDate, request) {
            return _this.isInDateRange(lookupDate, request);
        };
        _this.isInventoryItemInRequestDateRange = function (lookupDate, inventoryItem) {
            var status = __1.InventorySchedulerDateStatus.None;
            for (var _i = 0, _a = inventoryItem.eventAssignments; _i < _a.length; _i++) {
                var eventAssignment = _a[_i];
                var eventAssignmentStatus = _this.isInDateRange(lookupDate, eventAssignment);
                if (eventAssignmentStatus && status) {
                    return __1.InventorySchedulerDateStatus.Conflict;
                }
                if (status === __1.InventorySchedulerDateStatus.None) {
                    status = eventAssignmentStatus;
                }
            }
            return status;
        };
        _this.isInDateRange = function (lookupDate, item) {
            var preEventDate = __1.DateUtils.getDateFromString(item.preEventInventoryReservationDate);
            var eventStartDate = __1.DateUtils.getDateFromString(item.eventStartDate);
            var eventEndDate = __1.DateUtils.getDateFromString(item.eventEndDate);
            var postEventDate = __1.DateUtils.getDateFromString(item.postEventInventoryReleaseDate);
            if (preEventDate <= lookupDate && lookupDate < eventStartDate) {
                return __1.InventorySchedulerDateStatus.PreEvent;
            }
            if (eventStartDate <= lookupDate && lookupDate <= eventEndDate) {
                return __1.InventorySchedulerDateStatus.Event;
            }
            if (eventEndDate < lookupDate && lookupDate <= postEventDate) {
                return __1.InventorySchedulerDateStatus.PostEvent;
            }
            return __1.InventorySchedulerDateStatus.None;
        };
        _this.findEventInInventoryItemByDate = function (date, inventoryItem) {
            return inventoryItem.eventAssignments.find(function (event) { return date.toString() === __1.DateUtils.getDateFromString(event.eventStartDate).toString(); });
        };
        _this.findEventInRequestByDate = function (date, request) {
            return date.toString() === __1.DateUtils.getDateFromString(request.eventStartDate).toString();
        };
        _this.getSchedulerDates = function (month) {
            var dates = [];
            for (var _i = 0, _a = _this._schedulerDates; _i < _a.length; _i++) {
                var date = _a[_i];
                if (__1.DateUtils.getMonth(date).monthNumber === month.monthNumber) {
                    dates.push(date);
                }
            }
            return dates;
        };
        _this.saveRequestFulfillment = function () { return __awaiter(_this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!this._selectedInventoryItem) {
                            this._errorMessage = 'You must select an inventory item to fulfill the request with.';
                            this.observationProvider.notifyObservers(__1.InventorySchedulerPageActions.ErrorMessageChanged);
                            return [2 /*return*/];
                        }
                        return [4 /*yield*/, this.api.post(__1.EndpointUrls.Events + '/InventoryRequest/' + this._selectedRequest.id + '/Fulfill/' + this._selectedInventoryItem.inventoryItemId, {})];
                    case 1:
                        _a.sent();
                        this.loadRequests();
                        this.clearSelectedInventoryRequest();
                        return [2 /*return*/];
                }
            });
        }); };
        _this.clearSelectedInventoryRequest = function () {
            _this._selectedRequest = null;
            _this._schedulerMonths = [];
            _this._schedulerDates = [];
            _this._selectedInventoryItem = null;
            _this.observationProvider.notifyObservers(__1.InventorySchedulerPageActions.InventoryRequestCleared);
        };
        _this.buildInventoryRequestButton = function (request) {
            return new button_1.default({
                text: request.inventoryItemGroupName,
                onClick: _this.selectInventoryRequest(request)
            });
        };
        _this.buildEventOverviewButton = function (request) {
            return new button_1.default({
                onClick: _this.showEventOverview(request.eventId),
                text: request.eventName
            });
        };
        _this.showEventOverview = function (eventId) { return function () {
            _this._eventOverview.loadData(eventId);
        }; };
        _this.buildInventoryItemButton = function (inventoryItem) {
            return new button_1.default({
                onClick: _this.selectInventoryItem(inventoryItem)
            });
        };
        _this.helpButton = new helpButton_1.default(helpUrls_1.HelpUrlType.InventorySchedulerPage, null, navigator, api);
        _this.observationProvider = new __1.ObservationProvider();
        _this._eventOverview = new __1.EventOverview(_this.api, _this.currentUserManager);
        _this.loadRequests();
        _this.backButton = new button_1.default({
            text: 'Back',
            onClick: _this.clearSelectedInventoryRequest
        });
        _this.saveButton = new button_1.default({
            text: 'Save',
            onClick: _this.saveRequestFulfillment
        });
        return _this;
    }
    Object.defineProperty(InventorySchedulerPage.prototype, "isLoadingRequests", {
        get: function () {
            return this._isLoadingRequests;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(InventorySchedulerPage.prototype, "inventoryRequests", {
        get: function () {
            return this._inventoryRequests;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(InventorySchedulerPage.prototype, "selectedRequest", {
        get: function () {
            return this._selectedRequest;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(InventorySchedulerPage.prototype, "schedulerMonths", {
        get: function () {
            return this._schedulerMonths;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(InventorySchedulerPage.prototype, "inventoryItems", {
        get: function () {
            return this._inventoryItems;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(InventorySchedulerPage.prototype, "selectedInventoryItem", {
        get: function () {
            return this._selectedInventoryItem;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(InventorySchedulerPage.prototype, "errorMessage", {
        get: function () {
            return this._errorMessage;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(InventorySchedulerPage.prototype, "eventOverview", {
        get: function () {
            return this._eventOverview;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(InventorySchedulerPage.prototype, "otherRequests", {
        get: function () {
            return this._otherRequests;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(InventorySchedulerPage.prototype, "isApp", {
        get: function () {
            return this.currentUserManager.clientChecker.isMobileApp();
        },
        enumerable: false,
        configurable: true
    });
    InventorySchedulerPage.prototype.loadRequests = function () {
        return __awaiter(this, void 0, void 0, function () {
            var groupByOptions, requests;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this._isLoadingRequests = true;
                        groupByOptions = [
                            { key: 'eventName', label: 'Event', enabled: true },
                        ];
                        return [4 /*yield*/, this.api.get(__1.EndpointUrls.Inventory + '/EventRequests')];
                    case 1:
                        requests = _a.sent();
                        this._inventoryRequests = __1.ArrayUtils.groupBy(groupByOptions, requests, 'inventoryItemGroupName', 'eventStartDate');
                        this._isLoadingRequests = false;
                        this.observationProvider.notifyObservers(__1.InventorySchedulerPageActions.InventoryRequestedLoaded);
                        return [2 /*return*/];
                }
            });
        });
    };
    return InventorySchedulerPage;
}(__1.BasePage));
exports.default = InventorySchedulerPage;
