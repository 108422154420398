"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var _1 = require(".");
var __1 = require("../..");
var imageCarousel_1 = require("../imageCarousel");
var DownloadButton = /** @class */ (function () {
    function DownloadButton(params) {
        var _this = this;
        this.loadCarousel = function () {
            if (_this._url.indexOf('handlingMethod=Carousel') < 0) {
                if (_this._url.indexOf('?') >= 0) {
                    _this._url += '&handlingMethod=Carousel';
                }
                else {
                    _this._url += '?handlingMethod=Carousel';
                }
            }
            var url = _this._url.replace(_this.api.baseUrl, '');
            _this._isProcessing = true;
            _this.api.get(url)
                .then(function (response) {
                _this._imageCarousel = new imageCarousel_1.ImageCarousel([response.imageUrl], response.fileName);
                _this._isProcessing = false;
                _this.imageCarousel.observationProvider.observe(function () {
                    _this._imageCarousel = null;
                    _this.observationProvider.notifyObservers(_1.DownloadButtonActions.CarouselClosed);
                }, imageCarousel_1.ImageCarouselActions.Closed);
                _this.observationProvider.notifyObservers(_1.DownloadButtonActions.CarouselLoaded);
            })
                .catch(function () {
                _this._isProcessing = false;
            });
        };
        this.click = function () {
            if (!_this._url) {
                return;
            }
            if (!_this._isProcessing && !_this._isDisabled && typeof _this.onClick === 'function') {
                if (_this.processingText) {
                    _this._isProcessing = true;
                }
                _this.onClick(_this);
                _this.observationProvider.notifyObservers(_1.DownloadButtonActions.Clicked);
            }
        };
        this.setRightIcon = function (icon) {
            _this._rightIcon = icon;
        };
        this.setLeftIcon = function (icon) {
            _this._leftIcon = icon;
        };
        this.setProcessing = function (isProcessing) {
            _this._isProcessing = isProcessing;
            _this.observationProvider.notifyObservers(_1.DownloadButtonActions.ProcessingChanged);
        };
        this.setUrl = function (url) {
            _this._url = url;
            _this.observationProvider.notifyObservers(_1.DownloadButtonActions.UrlChanged);
        };
        this.setDisabled = function (isDisabled) {
            _this._isDisabled = isDisabled;
            _this.observationProvider.notifyObservers(_1.DownloadButtonActions.DisabledChanged);
        };
        this.setVisibility = function (isVisible) {
            _this._isVisible = isVisible;
            _this.observationProvider.notifyObservers(_1.DownloadButtonActions.VisibilityChanged);
        };
        this.setText = function (text) {
            _this._text = text;
            _this.observationProvider.notifyObservers(_1.DownloadButtonActions.TextChanged);
        };
        this._api = params.api;
        this._url = params.url;
        this._text = params.text;
        this._leftIcon = params.leftIcon;
        this._rightIcon = params.rightIcon;
        this._isVisible = params.isVisible === false ? false : true;
        this._isDisabled = params.isDisabled;
        this.onClick = params.onClick;
        this.observationProvider = new __1.ObservationProvider();
    }
    Object.defineProperty(DownloadButton.prototype, "isVisible", {
        get: function () {
            return this._isVisible;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(DownloadButton.prototype, "isDisabled", {
        get: function () {
            return this._isDisabled;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(DownloadButton.prototype, "isLoading", {
        get: function () {
            return this._isLoading;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(DownloadButton.prototype, "text", {
        get: function () {
            return this._text;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(DownloadButton.prototype, "leftIcon", {
        get: function () {
            return this._leftIcon;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(DownloadButton.prototype, "rightIcon", {
        get: function () {
            return this._rightIcon;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(DownloadButton.prototype, "url", {
        get: function () {
            return this._url;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(DownloadButton.prototype, "isProcessing", {
        get: function () {
            return this._isProcessing;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(DownloadButton.prototype, "api", {
        get: function () {
            return this._api;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(DownloadButton.prototype, "imageCarousel", {
        get: function () {
            return this._imageCarousel;
        },
        enumerable: false,
        configurable: true
    });
    return DownloadButton;
}());
exports.default = DownloadButton;
