import { h, JSX } from 'preact';
import MeetingsTileProps from './meetingsTileProps';
import EventInfoTile from './eventInfoTile';
import ObservingComponent from '../componentBases/observingComponent';
import SmartTable from '../smartTable/smartTable';
import SmartTableColumn from '../smartTable/smartTableColumn';
import FormattedDate from '../text/formattedDate';
import { DateFormats, MeetingTableItem, EventMeetingsTileActions, TableActions, Icons, DateUtils, MeetingTilePanes } from 'core.frontend';
import MeetingDetailForm from '../meetings/meetingDetailForm';
import MeetingStatus from '../meetings/meetingStatus';
import MeetingCreatedByLabel from '../meetings/meetingCreatedByLabel';
import MeetingAttendeesLabel from '../meetings/meetingAttendeesLabel';
import InteractiveVenueAssigner from '../meetings/interactiveVenueAssigner';
import MeetingSchedule from '../meetings/meetingSchedule';
import Button from '../buttons/button';
import Field from '../fields/field';
import ButtonColors from '../buttons/buttonColors';
import ButtonSizes from '../buttons/buttonSizes';
import TableRecordCount from '../table/tableRecordCount';
import Alert from '../alerts/alert';
import Icon from '../icons/icon';

class MeetingsTile extends ObservingComponent<MeetingsTileProps> {

    public componentWillMount(): void {
        this.registerUpdateObserver(this.props.tile.observationProvider, [EventMeetingsTileActions.EditVenuesCancelled, EventMeetingsTileActions.EditVenueModeChanged, EventMeetingsTileActions.VenuesSaved, EventMeetingsTileActions.GuestArrivedChanged, EventMeetingsTileActions.ActivePaneChanged]);
        this.registerUpdateObserver(this.props.tile.table.observationProvider, [TableActions.Loaded, TableActions.ExpandedContentLoaded]);
    }

    private meetingDetailsTemplate = (item: MeetingTableItem) => {
        if (item.expandedContent) {
            return (
                <div class="d-block text-sm">
                    <div class="d-block">
                        <b>Name: </b>{item.name}
                    </div>

                    <div class="d-block">
                        <b>Venue: </b>{item.expandedContent.assignedVenue}
                    </div>

                    <div class="flex">
                        <MeetingCreatedByLabel meeting={item.expandedContent} />
                    </div>

                    {item.expandedContent.subject &&
                        <div class="d-block">
                            <b>Subject: </b>{item.expandedContent.subject}
                        </div>
                    }

                    <div class="d-block">
                        <b>Meeting Category: </b>{item.expandedContent.defaultMeetingCategory}
                        {item.expandedContent.scheduledBy &&
                            <span> / {item.expandedContent.scheduledBy}</span>
                        }
                    </div>

                    <div class="d-block">
                        <b>Food Needed: </b>{item.expandedContent.foodNeeded ? 'Yes' : 'No' }
                    </div>

                    <div class="d-block">
                        <b>Table(s): </b> {item.expandedContent.tableAssignments.map((table, index) => <span>{table.name + (index + 1 < item.expandedContent.tableAssignments.length ? ', ' : '')}</span>)}
                    </div>

                    <div class="d-block">
                        <b>Attendees: </b><MeetingAttendeesLabel meeting={item.expandedContent} />
                    </div>

                    <div class="d-block">
                        <b>Customers: </b>

                        <div>
                            {(!item.expandedContent.guests || !item.expandedContent.guests.length) &&
                                <span>None</span>
                            }

                            {!!item.expandedContent.guests && !!item.expandedContent.guests.length &&
                                item.expandedContent.guests.map((guest, index) => (
                                    <div>

                                        {!!guest.rank &&
                                            <span>{guest.rank} </span>
                                        }

                                        {!!guest.title &&
                                            <span> ({guest.title}) </span>
                                        }

                                        {guest.fullName}

                                        <span> - </span>
                                        {guest.companyName}

                                        {!!guest.isLead &&
                                            <span> (L)</span>
                                        }

                                        {index + 1 < item.expandedContent.guests.length &&
                                            <span>&nbsp; | &nbsp;</span>
                                        }
                                    </div>
                                ))
                            }
                        </div>
                    </div>

                    <div class="d-block">
                        <b>Comments: </b>{item.expandedContent.printedComments}
                    </div>
                </div>
            );
        } else {
            return (
                <div class="d-block">
                    <div className="loading-placeholder shimmer d-block"></div>
                    <div className="loading-placeholder shimmer d-block"></div>
                    <div className="loading-placeholder shimmer d-block"></div>
                    <div className="loading-placeholder shimmer d-block"></div>
                    <div className="loading-placeholder shimmer d-block"></div>
                    <div className="loading-placeholder shimmer d-block"></div>
                </div>
            );
        }
    }

    private buildColumns = (): SmartTableColumn<MeetingTableItem>[] => {
        const { tile } = this.props;

        return [
            {
                heading: '#',
                sortExpression: 'meetingNumber',
                cellTemplate: (item) => (
                    <Button
                        className="p-0"
                        button={tile.buildMeetingNumberDetailButton(item)} />
                ),
                headingClassName: 'p-p5',
                className: 'd-none d-md-table-cell fit-content text-center p-0'
            },
            {
                heading: 'Name',
                sortExpression: 'name',
                cellTemplate: (item, index) => (
                    <span className="text-increase-phone">
                        <span>
                            <span class="d-md-none">
                                <Button
                                    className="btn-link"
                                    button={tile.buildMeetingDetailButton(item, true)} />
                            </span>

                            <Button
                                className="btn-link d-none d-md-inline"
                                button={tile.buildMeetingDetailButton(item, false)} />

                            <span class="d-md-none">
                                <br/>

                                <Button
                                    className="btn-link"
                                    button={tile.buildMeetingNumberDetailButton(item)} />
                            </span>

                            {!item.isActive &&
                                <span class="text-sm text-red">
                                   <span> - </span>
                                    Inactive
                                </span>
                            }
                        </span>

                        <span class="d-md-none">
                            <br/>

                            <FormattedDate
                                date={item.startDateTime}
                                format={DateFormats.MMMd} />

                            <span> | </span>

                            <FormattedDate
                                date={item.startDateTime}
                                format={DateFormats.hhmm} />

                            <span> - </span>

                            <FormattedDate
                                date={item.endDateTime}
                                format={DateFormats.hhmm} />
                        </span>

                        <span class="d-block">
                            {tile.isVenueEditMode &&
                                <div class="d-block">
                                    <span>Venue Preference: </span>
                                    <b>{item.venuePreference}</b>

                                    <span> | </span>

                                    <span>Total Attendees: </span>
                                    <b>{item.totalAttendees}</b>
                                </div>
                            }

                            <div class="d-block">
                                <span>Venue: </span>

                                {!tile.isVenueEditMode &&
                                    <span>
                                        <b>{item.venue}</b>

                                        {item.assignedTables &&
                                            <span class='text-sm'> / {item.assignedTables}</span>
                                        }
                                    </span>
                                }

                                {!tile.isVenueEditMode && !item.venue &&
                                    <i>Unassigned</i>
                                }

                                {tile.isVenueEditMode &&
                                    <Field
                                        className="no-label input-xs width-10"
                                        field={tile.editVenueForms[index].fields[0]} />
                                }
                            </div>

                            <span class="d-md-none fill text-right">
                                Status: <MeetingStatus status={item.status} />
                            </span>
                        </span>
                    </span>
                )
            },
            {
                className: "d-none text-center d-md-table-cell text-sm fit-content",
                headingClassName: "fit-content",
                heading: 'Status',
                sortExpression: 'status',
                cellTemplate: (item, index) =>
                    <MeetingStatus status={item.status} />
            },
            {
                className: "d-none d-md-table-cell fit-content text-center text-sm",
                heading: 'Date',
                sortExpression: 'StartDateTime',
                cellTemplate: (item) =>
                    <FormattedDate
                        date={item.startDateTime}
                        format={DateFormats.MMMd} />
            },
            {
                className: "d-none d-md-table-cell fit-content text-center text-sm",
                heading: 'Time',
                sortExpression: 'StartDateTime',
                cellTemplate: (item) =>
                    <span>
                        <FormattedDate
                            date={item.startDateTime}
                            format={DateFormats.hhmm} />

                        <span> - </span>

                        <FormattedDate
                            date={item.endDateTime}
                            format={DateFormats.hhmm} />
                    </span>
            },
            {
                heading: 'Notified',
                className: 'text-center fit-content d-none d-md-table-cell p-0',
                cellTemplate: (item, index) =>
                <span>
                    {item.attendeesNotified &&
                        <Icon
                            title={"Notified by " + item.attendeesNotifiedBy + " - " + DateUtils.formatDate(item.attendeesNotifiedDateTime, DateFormats.MMMd, true) + ' ' + DateUtils.formatDate(item.attendeesNotifiedDateTime, DateFormats.hhmm, true)}
                            icon={Icons.Alert} />
                    }
                </span>
            },
            {
                heading: <span>Guests<br/>Arrived</span>,
                className: 'text-center fit-content pt-p25 pb-p25 ' + (tile.isVenueEditMode ? 'd-hidden' : ''),
                cellTemplate: (item, index) =>
                <span>
                    <Button
                        className="text-sm btn-icon btn-icon-stacked"
                        button={tile.buildGuestArrivedButton(item)} />

                    {item.guestsArrived &&
                        <span
                            onClick={() => {tile.getGuestsArrivedInfo(item)}}
                            onPointerOver={() => {tile.getGuestsArrivedInfo(item)}}>
                            <Icon
                                title={
                                    (!!item.guestsArrivedDateTime) ?
                                        "Checked by " + item.guestsArrivedCheckedBy + " - " + DateUtils.formatDate(item.guestsArrivedDateTime, DateFormats.MMMd, true) + ' ' + DateUtils.formatDate(item.guestsArrivedDateTime, DateFormats.hhmm, true) :
                                        "Loading..."}
                                icon={Icons.Alert} />
                        </span>
                    }
                </span>
            }
        ];
    }

    public render({ tile }: MeetingsTileProps): JSX.Element {
        return (
            <div>
                { tile.isVisible &&
                    <EventInfoTile
                        color="teal"
                        tile={tile}
                        icon="ti ti-meeting">

                        <div class="btn-group mt-1 flex">
                            <Button
                                className={'btn-sm btn-primary fill btn ' + (tile.activePane == MeetingTilePanes.Table ? 'active ' : '' )}
                                button={tile.meetingTableButton} />

                            <Button
                                className={'btn-sm btn-primary fill btn ' + (tile.activePane === MeetingTilePanes.MeetingSchedule ? 'active ' : '' )}
                                button={tile.meetingScheduleButton} />
                        </div>

                        {tile.activePane === MeetingTilePanes.Table &&
                            <div>
                                <Alert
                                    className="mt-1"
                                    alert={tile.alert} />

                                {!!tile.table.isVisible &&
                                    <div class="flex mt-1">
                                        <div class="text-sm d-flex v-center">
                                            {!tile.isEditMode &&
                                                <div>
                                                    <div>
                                                        <Field
                                                            className="label-right"
                                                            field={tile.autoRefrehsCheckbox} />
                                                    </div>
                                                    <i>
                                                        <span>Last Refreshed: </span>
                                                        <FormattedDate
                                                            format={DateFormats.hhmm}
                                                            date={tile.loadedDateTime} />
                                                    </i>
                                                </div>
                                            }

                                            {tile.table.showPager &&
                                                <div class="d-md-none">
                                                    <TableRecordCount
                                                        className="mt-0"
                                                        isLoading={tile.table.isLoading}
                                                        currentPageNumber={tile.table.currentPage}
                                                        totalPageCount={tile.table.pageCount}
                                                        totalRecords={tile.table.totalRecordCount} />
                                                </div>
                                            }

                                            {tile.isEditMode &&
                                                <div>
                                                    <Field
                                                        className="label-right"
                                                        field={tile.sendMeetingInvitesCheckbox} />
                                                </div>
                                            }
                                        </div>

                                        <Button
                                            className="ml-auto"
                                            color={ButtonColors.Primary}
                                            size={ButtonSizes.Small}
                                            button={tile.newMeetingButton} />
                                    </div>
                                }

                                {tile.venueAssignmentModeButton.isVisible &&
                                    <div class="mt-1">
                                        {tile.isEditMode &&
                                            <div class="flex">
                                                <div class="ml-auto">
                                                    <Field
                                                        className="label-right"
                                                        field={tile.sendMeetingInvitesCheckbox} />
                                                </div>
                                            </div>
                                        }
                                        <div class="flex">

                                            <Field
                                                className="inline-label text-sm input-sm v-center mr-auto"
                                                field={tile.venueAssignmentDropdown} />

                                            <Button
                                                className="ml-1 btn-link"
                                                button={tile.refreshVenueAssignmentButton} />

                                            <Button
                                                className="ml-auto btn-link"
                                                button={tile.venueAssignmentModeButton} />
                                        </div>
                                    </div>
                                }

                                <div class="mt-1">
                                    <SmartTable
                                        tableClassName="table-small"
                                        headerClassName="d-none d-md-table-header-group text-sm"
                                        table={tile.table}
                                        columns={this.buildColumns()}
                                        listOptionSlideContainer={tile.listOptionSlideContainer}
                                        expandedContentTemplate={this.meetingDetailsTemplate} />
                                </div>

                                <InteractiveVenueAssigner interactiveVenueAssigner={tile.interactiveVenueAssigner} />
                            </div>
                        }

                        {tile.activePane === MeetingTilePanes.MeetingSchedule &&
                            <div>
                                <MeetingSchedule meetingSchedule={tile.meetingSchedule} />
                            </div>
                        }
                    </EventInfoTile>
                }

                <MeetingDetailForm meetingDetailForm={tile.meetingDetailForm} />
            </div>
        );
    }
}

export default MeetingsTile;
