"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var _1 = require(".");
var ObservationProvider = /** @class */ (function () {
    function ObservationProvider() {
        var _this = this;
        this.observeOnce = function (fn, action) {
            var observerId = _this.observe(fn, action);
            _this.oneTimeObserverIds.push(observerId);
            return observerId;
        };
        this.observeMany = function (fn, actions) {
            var observerIds = [];
            for (var _i = 0, _a = _1.ArrayUtils.arrayify(actions); _i < _a.length; _i++) {
                var action = _a[_i];
                observerIds.push(_this.observe(fn, action));
            }
            return observerIds;
        };
        this.observe = function (fn, action) {
            var observer = { id: _this.nextObserverId, fn: fn, action: action };
            if (_this.doesSameObserverExist(fn, action)) {
                return;
            }
            _this.observers.push(observer);
            _this.nextObserverId++;
            return observer.id;
        };
        this.stopObserving = function (id) {
            _this.observers = _this.observers.filter(function (observer) { return observer.id !== id; });
        };
        this.stopObservingAll = function () {
            for (var _i = 0, _a = _this.observers; _i < _a.length; _i++) {
                var observer = _a[_i];
                _this.stopObserving(observer.id);
            }
        };
        this.notifyObservers = function (action, param) {
            for (var i = 0; i < _this.observers.length; i++) {
                if (_this.isObservingAction(_this.observers[i], action)) {
                    _this.observers[i].fn(param);
                    _this.checkAndHandleOneTimeObserver(_this.observers[i].id);
                }
            }
        };
        this.nextObserverId = 1;
        this.oneTimeObserverIds = [];
        this.observers = [];
    }
    ObservationProvider.prototype.doesSameObserverExist = function (fn, action) {
        return this.observers.findIndex(function (existingObserver) { return existingObserver.fn === fn && existingObserver.action === action; }) >= 0;
    };
    ObservationProvider.prototype.checkAndHandleOneTimeObserver = function (observerId) {
        var oneTimeObserverIdIndex = this.oneTimeObserverIds.indexOf(observerId);
        if (oneTimeObserverIdIndex !== -1) {
            this.stopObserving(this.oneTimeObserverIds[oneTimeObserverIdIndex]);
            this.oneTimeObserverIds.splice(oneTimeObserverIdIndex, 1);
        }
    };
    ObservationProvider.prototype.isObservingAction = function (observer, action) {
        return !action || !observer.action || observer.action === action;
    };
    return ObservationProvider;
}());
exports.default = ObservationProvider;
