"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g = Object.create((typeof Iterator === "function" ? Iterator : Object).prototype);
    return g.next = verb(0), g["throw"] = verb(1), g["return"] = verb(2), typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
var __1 = require("../..");
var button_1 = require("../../components/button/button");
var api_1 = require("../../api");
var components_1 = require("../../components");
var UrlAssignerPage = /** @class */ (function (_super) {
    __extends(UrlAssignerPage, _super);
    function UrlAssignerPage(api, navigator, eventId) {
        var _this = _super.call(this, __1.PageTypes.UrlAssignerPage, 'Meeting Urls') || this;
        _this.api = api;
        _this.navigator = navigator;
        _this.eventId = eventId;
        _this.loadData = function () { return __awaiter(_this, void 0, void 0, function () {
            var _a;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        this._isLoading = true;
                        _a = this;
                        return [4 /*yield*/, this.api.get(api_1.EndpointUrls.Events + '/' + this.eventId + '/UrlAssignerPageData')];
                    case 1:
                        _a._data = _b.sent();
                        this._meetingUrlFields = this._data.meetings.map(function (meeting) { return new components_1.TextField(meeting.id.toString(), 'Url', false, 1000, null, meeting.url); });
                        this._isLoading = false;
                        this.observationProvider.notifyObservers(__1.UrlAssignerPageActions.DataLoaded);
                        return [2 /*return*/];
                }
            });
        }); };
        _this.save = function () { return __awaiter(_this, void 0, void 0, function () {
            var i, err_1;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        for (i = 0; i < this.data.meetings.length; i++) {
                            this.data.meetings[i].url = this.meetingUrlFields[i].value;
                        }
                        return [4 /*yield*/, this.api.put(api_1.EndpointUrls.Events + '/UrlAssignerPageData', this.eventId, this.data.meetings)];
                    case 1:
                        _a.sent();
                        this.saveButton.setProcessing(false);
                        this.alert.show({
                            alertType: components_1.AlertType.Success,
                            heading: 'Meetings Saved',
                            message: 'Meeting urls have been saved'
                        });
                        return [3 /*break*/, 3];
                    case 2:
                        err_1 = _a.sent();
                        this.alert.show({
                            alertType: components_1.AlertType.Error,
                            heading: 'Unable to Save',
                            message: 'An error occurred trying to save meeting urls'
                        });
                        this.saveButton.setProcessing(false);
                        return [3 /*break*/, 3];
                    case 3: return [2 /*return*/];
                }
            });
        }); };
        _this.observationProvider = new __1.ObservationProvider();
        _this.saveButton = new button_1.default({
            onClick: _this.save,
            text: 'Save',
            processingText: 'Saving',
            buttonType: components_1.ButtonTypes.Submit
        });
        _this.backButton = new button_1.default({
            onClick: function () { return _this.navigator.goToEventLanding(_this.eventId); },
            text: 'Back'
        });
        _this.loadData();
        return _this;
    }
    Object.defineProperty(UrlAssignerPage.prototype, "isLoading", {
        get: function () {
            return this._isLoading;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(UrlAssignerPage.prototype, "data", {
        get: function () {
            return this._data;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(UrlAssignerPage.prototype, "meetingUrlFields", {
        get: function () {
            return this._meetingUrlFields;
        },
        enumerable: false,
        configurable: true
    });
    return UrlAssignerPage;
}(__1.BasePage));
exports.default = UrlAssignerPage;
