"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var PermissionType;
(function (PermissionType) {
    PermissionType["EditUsers"] = "editUsers";
    PermissionType["EditUsersAdvanced"] = "editUsersAdvanced";
    PermissionType["EditCustomers"] = "editCustomers";
    PermissionType["AddEvents"] = "addEvents";
    PermissionType["EditAllEvents"] = "editAllEvents";
    PermissionType["AccessAllEvents"] = "accessAllEvents";
    PermissionType["EventProducer"] = "eventProducer";
    PermissionType["EditUserGroups"] = "editUserGroups";
    PermissionType["EditInventory"] = "editInventory";
    PermissionType["ScheduleInventory"] = "scheduleInventory";
    PermissionType["ViewInventoryForecast"] = "viewInventoryForecast";
    PermissionType["EditSystemSettings"] = "editSystemSettings";
    PermissionType["EditCustomerCompanies"] = "editCustomerCompanies";
    PermissionType["ViewAllMeetingNotes"] = "viewAllMeetingNotes";
    PermissionType["EditVendors"] = "editVendors";
    PermissionType["ViewAdditionalHeadCount"] = "viewAdditionalHeadCount";
})(PermissionType || (PermissionType = {}));
exports.default = PermissionType;
