"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var CacheType;
(function (CacheType) {
    CacheType[CacheType["UserGroups"] = 0] = "UserGroups";
    CacheType[CacheType["BusinessAreas"] = 1] = "BusinessAreas";
    CacheType[CacheType["CustomerCompanies"] = 2] = "CustomerCompanies";
    CacheType[CacheType["LinesOfBusiness"] = 3] = "LinesOfBusiness";
    CacheType[CacheType["Hotels"] = 4] = "Hotels";
    CacheType[CacheType["VendorCompanies"] = 5] = "VendorCompanies";
    CacheType[CacheType["Users"] = 6] = "Users";
    CacheType[CacheType["InventoryItemTypes"] = 7] = "InventoryItemTypes";
    CacheType[CacheType["Venues"] = 8] = "Venues";
    CacheType[CacheType["VenuePreferences"] = 9] = "VenuePreferences";
    CacheType[CacheType["MeetingCategories"] = 10] = "MeetingCategories";
    CacheType[CacheType["MeetingDates"] = 11] = "MeetingDates";
    CacheType[CacheType["DefaultMeetingStatus"] = 12] = "DefaultMeetingStatus";
    CacheType[CacheType["EventTransportVehicleOptions"] = 13] = "EventTransportVehicleOptions";
    CacheType[CacheType["UserTitles"] = 14] = "UserTitles";
    CacheType[CacheType["EventLeadResponsibilities"] = 15] = "EventLeadResponsibilities";
    CacheType[CacheType["DefaultMeetingCategories"] = 16] = "DefaultMeetingCategories";
    CacheType[CacheType["InventoryItemGroups"] = 17] = "InventoryItemGroups";
    CacheType[CacheType["InventoryItemCategories"] = 18] = "InventoryItemCategories";
    CacheType[CacheType["MeetingCreators"] = 19] = "MeetingCreators";
    CacheType[CacheType["MeetingConfigurations"] = 20] = "MeetingConfigurations";
    CacheType[CacheType["EventAttendeeAccessLevels"] = 21] = "EventAttendeeAccessLevels";
    CacheType[CacheType["ProfileImages"] = 22] = "ProfileImages";
    CacheType[CacheType["HeaderLogoImages"] = 23] = "HeaderLogoImages";
    CacheType[CacheType["EventAttendees"] = 24] = "EventAttendees";
    CacheType[CacheType["TravelAndLodgingConfigurations"] = 25] = "TravelAndLodgingConfigurations";
    CacheType[CacheType["ProductKnowledgeOptions"] = 26] = "ProductKnowledgeOptions";
    CacheType[CacheType["EmailTemplateOptions"] = 27] = "EmailTemplateOptions";
    CacheType[CacheType["BillableBusinessAreas"] = 28] = "BillableBusinessAreas";
    CacheType[CacheType["EventLandingPageData"] = 29] = "EventLandingPageData";
    CacheType[CacheType["HelpUrls"] = 30] = "HelpUrls";
    CacheType[CacheType["SystemAdminContactInfo"] = 31] = "SystemAdminContactInfo";
    CacheType[CacheType["MeetingNotesDisclaimer"] = 32] = "MeetingNotesDisclaimer";
    CacheType[CacheType["MeetingScheduledByOptions"] = 33] = "MeetingScheduledByOptions";
})(CacheType || (CacheType = {}));
exports.default = CacheType;
