"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var AccommodationArrivalType;
(function (AccommodationArrivalType) {
    AccommodationArrivalType[AccommodationArrivalType["Air"] = 0] = "Air";
    AccommodationArrivalType[AccommodationArrivalType["Rail"] = 1] = "Rail";
    AccommodationArrivalType[AccommodationArrivalType["Bus"] = 2] = "Bus";
    AccommodationArrivalType[AccommodationArrivalType["Other"] = 3] = "Other";
})(AccommodationArrivalType || (AccommodationArrivalType = {}));
exports.default = AccommodationArrivalType;
