"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var NavigatorActions;
(function (NavigatorActions) {
    NavigatorActions["PageChanged"] = "PageChanged";
    NavigatorActions["UserDropdownOpened"] = "UserDropdownOpened";
    NavigatorActions["UserDropdownClosed"] = "UserDropdownClosed";
    NavigatorActions["NavigateToSamePageCanceled"] = "NavigateToSamePageCanceled";
    NavigatorActions["PageChanging"] = "PageChanging";
    NavigatorActions["NavigatingToUrl"] = "NavigatingToUrl";
})(NavigatorActions || (NavigatorActions = {}));
exports.default = NavigatorActions;
