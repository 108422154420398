"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var __1 = require("..");
var events_1 = require("./events");
var helpUrls_1 = require("./helpUrls");
var ssoLoginPage_1 = require("./login/ssoLoginPage");
var roomStatusDisplay_1 = require("./roomStatusDisplay");
var vendorPortal_1 = require("./vendorPortal");
var PageFactory = /** @class */ (function () {
    function PageFactory(api, currentUserManager) {
        var _this = this;
        this.api = api;
        this.currentUserManager = currentUserManager;
        this.clearCachedPageInstances = function () {
            _this.customerListPage = null;
            _this.userListPage = null;
            _this.vendorUserListPage = null;
            _this.inventoryListPage = null;
            _this.userGroupListPage = null;
            _this.eventListPage = null;
        };
        this.currentUserManager.observationProvider.observe(this.clearCachedPageInstances, __1.CurrentUserManagerActions.LoggedOut);
    }
    PageFactory.prototype.createPage = function (pageType, navigator, param) {
        switch (pageType) {
            case __1.PageTypes.Login: {
                return new __1.LoginPage(this.api, navigator, this.currentUserManager);
            }
            case __1.PageTypes.EventList: {
                if (!this.eventListPage) {
                    this.eventListPage = new __1.EventListPage(this.api, navigator, this.currentUserManager);
                }
                else {
                    this.eventListPage.table.load();
                }
                return this.eventListPage;
            }
            case __1.PageTypes.UserList: {
                if (!this.userListPage) {
                    this.userListPage = new __1.UserListPage(this.api, navigator, this.currentUserManager);
                }
                return this.userListPage;
            }
            case __1.PageTypes.UserDetail: {
                return new __1.UserDetailPage(this.api, navigator, this.currentUserManager, param);
            }
            case __1.PageTypes.CustomerList: {
                if (!this.customerListPage) {
                    this.customerListPage = new __1.CustomerListPage(this.api, navigator, this.currentUserManager);
                }
                return this.customerListPage;
            }
            case __1.PageTypes.CustomerDetail: {
                return new __1.CustomerDetailPage(this.api, navigator, this.currentUserManager, param);
            }
            case __1.PageTypes.RequestPasswordReset: {
                return new __1.RequestPasswordResetPage(this.api, navigator, this.currentUserManager, param);
            }
            case __1.PageTypes.ResetPassword: {
                return new __1.ResetPasswordPage(this.api, navigator, this.currentUserManager, param);
            }
            case __1.PageTypes.RequestAccess: {
                return new __1.RequestAccessPage(this.api, navigator, this.currentUserManager);
            }
            case __1.PageTypes.CompleteAccessRequest: {
                return new __1.CompleteAccessRequestPage(this.api, navigator, this.currentUserManager, param);
            }
            case __1.PageTypes.EventLanding: {
                var eventId = param.eventId;
                var meetingId = param.meetingId;
                return new __1.EventLandingPage(this.api, navigator, this.currentUserManager, eventId, meetingId);
            }
            case __1.PageTypes.History: {
                return new __1.HistoryPage(this.api, navigator, param);
            }
            case __1.PageTypes.VendorUserList: {
                if (!this.vendorUserListPage) {
                    this.vendorUserListPage = new __1.VendorUserListPage(this.api, navigator, this.currentUserManager);
                }
                return this.vendorUserListPage;
            }
            case __1.PageTypes.VendorUserDetail: {
                return new __1.VendorUserDetailPage(this.api, navigator, this.currentUserManager, param);
            }
            case __1.PageTypes.EventConfiguration: {
                return new __1.EventConfigurationPage(this.api, navigator, this.currentUserManager, param);
            }
            case __1.PageTypes.UserProfile: {
                return new __1.UserProfilePage(this.api, navigator, this.currentUserManager);
            }
            case __1.PageTypes.NewEvent: {
                return new __1.NewEventPage(this.api, navigator, this.currentUserManager);
            }
            case __1.PageTypes.InventoryList: {
                if (!this.inventoryListPage) {
                    this.inventoryListPage = new __1.InventoryListPage(this.api, navigator, this.currentUserManager);
                }
                return this.inventoryListPage;
            }
            case __1.PageTypes.InventoryDetailPage: {
                return new __1.InventoryDetailPage(this.api, navigator, this.currentUserManager, param);
            }
            case __1.PageTypes.UserGroupList: {
                if (!this.userGroupListPage) {
                    this.userGroupListPage = new __1.UserGroupListPage(this.api, navigator, this.currentUserManager);
                }
                return this.userGroupListPage;
            }
            case __1.PageTypes.UserGroupDetail: {
                return new __1.UserGroupDetailPage(this.api, navigator, this.currentUserManager, param);
            }
            case __1.PageTypes.InventorySchedulerPage: {
                return new __1.InventorySchedulerPage(this.api, this.currentUserManager, navigator);
            }
            case __1.PageTypes.EventOverviewReport: {
                return new __1.EventOverviewReportPage(this.api, this.currentUserManager, param);
            }
            case __1.PageTypes.InventoryForecast: {
                return new __1.InventoryForecastPage(this.api, this.currentUserManager, navigator);
            }
            case __1.PageTypes.SystemSettings: {
                return new __1.SystemSettingsPage(this.api, this.currentUserManager, navigator);
            }
            case __1.PageTypes.EventHistory: {
                return new __1.EventHistoryPage(this.api, navigator, this.currentUserManager, param);
            }
            case __1.PageTypes.CustomerCompanyList: {
                return new __1.CustomerCompaniesListPage(this.api, navigator, this.currentUserManager);
            }
            case __1.PageTypes.CustomerCompanyDetail: {
                return new __1.CustomerCompanyDetailPage(this.api, navigator, this.currentUserManager, param);
            }
            case __1.PageTypes.VendorCompaniesPage: {
                if (!this.vendorCompanyListPage) {
                    this.vendorCompanyListPage = new __1.VendorCompaniesPage(this.api, navigator, this.currentUserManager);
                }
                return this.vendorCompanyListPage;
            }
            case __1.PageTypes.VendorCompanyDetailPage: {
                return new __1.VendorCompanyDetailPage(this.api, navigator, this.currentUserManager, param);
            }
            case __1.PageTypes.AttendeeReport: {
                return new __1.AttendeeReportPage(this.api, navigator, this.currentUserManager, param);
            }
            case __1.PageTypes.MeetingsReportPage: {
                return new __1.MeetingsReportPage(this.api, navigator, this.currentUserManager, param);
            }
            case __1.PageTypes.TenantList: {
                return new __1.TenantList(this.api, navigator, this.currentUserManager);
            }
            case __1.PageTypes.TenantDetailPage: {
                return new __1.TenantDetailPage(this.api, navigator, param);
            }
            case __1.PageTypes.HotelReportPage: {
                return new __1.HotelReportPage(this.api, navigator, this.currentUserManager, param);
            }
            case __1.PageTypes.TransportationReportPage: {
                return new events_1.TransportationReportPage(this.api, navigator, this.currentUserManager, param);
            }
            case __1.PageTypes.VendorUserProfile: {
                return new __1.VendorUserProfile(this.api, navigator, this.currentUserManager);
            }
            case __1.PageTypes.VendorEventList: {
                return new vendorPortal_1.VendorEventListPage(this.api, navigator, this.currentUserManager);
            }
            case __1.PageTypes.VendorEmailTemplates: {
                return new vendorPortal_1.VendorEmailTemplatesPage(this.api, navigator);
            }
            case __1.PageTypes.VendorEmailTemplate: {
                return new vendorPortal_1.VendorEmailTemplatePage(this.api, navigator, param);
            }
            case __1.PageTypes.EmailTemplates: {
                return new __1.EmailTemplatesPage(this.api, navigator, this.currentUserManager);
            }
            case __1.PageTypes.EmailTemplate: {
                return new __1.EmailTemplatePage(this.api, navigator, this.currentUserManager, param);
            }
            case __1.PageTypes.VendorEventLandingPage: {
                return new __1.VendorEventLandingPage(this.api, navigator, this.currentUserManager, param);
            }
            case __1.PageTypes.UrlAssignerPage: {
                return new __1.UrlAssignerPage(this.api, navigator, param);
            }
            case __1.PageTypes.MeetingPlacard: {
                return new __1.MeetingPlacardPage(this.api, param.eventId, param.passKey);
            }
            case __1.PageTypes.RoomDisplayPage: {
                return new roomStatusDisplay_1.RoomStatusDisplayPage(this.api, param.eventId, param.passKey);
            }
            case __1.PageTypes.AttendeeDataGridReport: {
                return new events_1.AttendeeDataGridPage(this.api, navigator, this.currentUserManager, param);
            }
            case __1.PageTypes.VenueDataGridReport: {
                return new events_1.VenueDataGridPage(this.api, navigator, this.currentUserManager, param);
            }
            case __1.PageTypes.VendorCompanyInfo: {
                return new vendorPortal_1.VendorCompanyInfoPage(this.api, navigator, this.currentUserManager);
            }
            case __1.PageTypes.GolfCartReport: {
                return new events_1.GolfCartReportPage(this.api, navigator, this.currentUserManager, param);
            }
            case __1.PageTypes.DiningReport: {
                return new events_1.DiningReportPage(this.api, navigator, this.currentUserManager, param);
            }
            case __1.PageTypes.HelpUrls: {
                return new helpUrls_1.HelpUrlsPage(this.api);
            }
            case __1.PageTypes.PasswordResetRequestText: {
                return new __1.RequestPasswordResetTextPage(this.api, navigator, this.currentUserManager, param);
            }
            case __1.PageTypes.DeviceRegistration: {
                return new __1.DeviceRegistrationPage(this.api, navigator, this.currentUserManager, param);
            }
            case __1.PageTypes.SsoLogin: {
                return new ssoLoginPage_1.default(navigator, this.currentUserManager);
            }
            //<NEXT>
            default: {
                throw new Error("Unable to create page of pageType:".concat(pageType));
            }
        }
    };
    return PageFactory;
}());
exports.default = PageFactory;
