"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var observationProvider_1 = require("../../utils/observationProvider");
var _1 = require("./");
var formActions_1 = require("./formActions");
var SubmittableForm = /** @class */ (function () {
    function SubmittableForm(endpointUrl, api, internalFields, internalModel) {
        if (internalFields === void 0) { internalFields = []; }
        if (internalModel === void 0) { internalModel = {}; }
        var _this = this;
        this.endpointUrl = endpointUrl;
        this.api = api;
        this.notifyOfSubmissionFailure = function (submissionFailure) {
            _this.observationProvider.notifyObservers(_1.SubmittableFormActions.SubmissionFailure, submissionFailure);
        };
        this.submit = function () {
            _this.form.submit();
        };
        this.isValid = function () {
            return _this.form.isValid();
        };
        this.submitToApi = function () {
            if ('id' in _this.model) {
                _this.submitPut();
            }
            else {
                _this.submitPost();
            }
        };
        this.setModel = function (model) {
            _this.form.setModel(model);
        };
        this.submitPut = function () {
            _this.observationProvider.notifyObservers(_1.SubmittableFormActions.RecordEditing, _this.model);
            _this.api.put(_this.endpointUrl, _this.model.id, _this.model)
                .then(function () {
                _this.observationProvider.notifyObservers(_1.SubmittableFormActions.RecordEdited, _this.model);
            })
                .catch(function (err) {
                var errorMessage = '';
                if (err && err.message) {
                    errorMessage = err.message;
                }
                else {
                    errorMessage = 'An error occurred trying to save the record';
                }
                _this.notifyOfSubmissionFailure(new _1.FormSubmissionFailure(_1.FormSubmissionFailureTypes.PutError, errorMessage));
            });
        };
        this.submitPost = function () {
            _this.observationProvider.notifyObservers(_1.SubmittableFormActions.RecordAdding, _this.model);
            _this.api.post(_this.endpointUrl, _this.model)
                .then(function (newId) {
                _this.model.id = newId;
                _this.observationProvider.notifyObservers(_1.SubmittableFormActions.RecordAdded, _this.model);
            })
                .catch(function (err) {
                var errorMessage = '';
                if (err && err.message) {
                    errorMessage = err.message;
                }
                else {
                    errorMessage = 'An error occurred trying to save the record';
                }
                _this.notifyOfSubmissionFailure(new _1.FormSubmissionFailure(_1.FormSubmissionFailureTypes.PostError, errorMessage));
            });
        };
        this.form = new _1.Form(internalFields, internalModel);
        this.observationProvider = new observationProvider_1.default();
        this.form.observationProvider.observe(this.submitToApi, formActions_1.default.FormSubmitted);
        this.form.observationProvider.observe(this.notifyOfSubmissionFailure, formActions_1.default.FormSubmissionFailure);
    }
    Object.defineProperty(SubmittableForm.prototype, "model", {
        get: function () {
            return this.form.model;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(SubmittableForm.prototype, "fields", {
        get: function () {
            return this.form.fields;
        },
        enumerable: false,
        configurable: true
    });
    return SubmittableForm;
}());
exports.default = SubmittableForm;
