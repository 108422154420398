"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var NavActions;
(function (NavActions) {
    NavActions["NavVisibilityUpdated"] = "NavVisibilityUpdated";
    NavActions["MenuOpened"] = "MenuOpened";
    NavActions["MenuClosed"] = "MenuClosed";
    NavActions["InventoryExpanded"] = "InventoryExpanded";
    NavActions["SettingsExpanded"] = "SettingsExpanded";
})(NavActions || (NavActions = {}));
exports.default = NavActions;
