"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g = Object.create((typeof Iterator === "function" ? Iterator : Object).prototype);
    return g.next = verb(0), g["throw"] = verb(1), g["return"] = verb(2), typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
var __1 = require("../..");
var button_1 = require("../button/button");
var fields_1 = require("../fields");
var meetingCalendarNotificationModalActions_1 = require("./meetingCalendarNotificationModalActions");
var MeetingCalendarNotificationModal = /** @class */ (function () {
    function MeetingCalendarNotificationModal(api, currentUserManager) {
        var _this = this;
        this.api = api;
        this.currentUserManager = currentUserManager;
        this.buildForm = function () {
            _this._subjectField = new fields_1.TextField('subject', 'Subject', true, 500, 'Required');
            _this._emailTemplateField = new fields_1.RichTextField('message', 'Message', true, 'required');
            _this._ccEmailField = new fields_1.MultipleEmailField('CcAddresses', false, 'CC Address');
            _this._form = new __1.Form([
                _this._subjectField,
                _this._emailTemplateField,
                _this._ccEmailField
            ], {
                meetingId: _this._meetingId,
                subject: _this._defaultSubject
            });
            _this._emailTemplateField.setValue(_this._defaultEmailTemplate);
            for (var i = 0; i < _this._data.attendees.length; i++) {
                _this._attendeeCheckBoxes.push(new fields_1.CheckBoxField(i.toString(), _this._data.attendees[i].fullName, false, null, true));
                _this._attendeeEmailFields.push(new fields_1.EmailField(i.toString(), false, 'Email', _this.data.attendees[i].email));
            }
            for (var i = 0; i < _this._data.guests.length; i++) {
                _this._guestCheckBoxes.push(new fields_1.CheckBoxField(i.toString(), _this._data.guests[i].fullName, false, null, true));
                _this._guestEmailFields.push(new fields_1.EmailField(i.toString(), false, 'Email', _this.data.guests[i].email));
            }
            _this._replyToNameField = new fields_1.TextField('replyToName', 'Reply To Name', true, 100, 'Reply To Name', _this.currentUserManager.userFullName);
            _this._replyToEmailField = new fields_1.EmailField('replyToEmail', true, 'Reply To', _this.currentUserManager.email);
        };
        this.show = function (meetingNumber) { return __awaiter(_this, void 0, void 0, function () {
            var _a, emailTemplate;
            var _b, _c;
            return __generator(this, function (_d) {
                switch (_d.label) {
                    case 0:
                        this._meetingId = meetingNumber;
                        this._isLoading = true;
                        this._isOpen = true;
                        this.observationProvider.notifyObservers(meetingCalendarNotificationModalActions_1.default.Opened);
                        this._attendeeCheckBoxes = [];
                        this._guestCheckBoxes = [];
                        this._attendeeEmailFields = [];
                        this._guestEmailFields = [];
                        _a = this;
                        return [4 /*yield*/, this.api.get("".concat(__1.EndpointUrls.Meetings, "/").concat(this._meetingId, "/MeetingNotificationModalData"))];
                    case 1:
                        _a._data = _d.sent();
                        return [4 /*yield*/, this.api.get("".concat(__1.EndpointUrls.EmailTemplates, "/MeetingInviteTemplate"))];
                    case 2:
                        emailTemplate = _d.sent();
                        this._defaultSubject = (_b = emailTemplate === null || emailTemplate === void 0 ? void 0 : emailTemplate.subject) !== null && _b !== void 0 ? _b : '';
                        this._defaultEmailTemplate = (_c = emailTemplate === null || emailTemplate === void 0 ? void 0 : emailTemplate.templateContent) !== null && _c !== void 0 ? _c : '';
                        this.buildForm();
                        this._isLoading = false;
                        this.observationProvider.notifyObservers(meetingCalendarNotificationModalActions_1.default.DataLoaded);
                        return [2 /*return*/];
                }
            });
        }); };
        this.hide = function () {
            _this._isOpen = false;
            _this.alert.hide();
            _this.sendButton.setProcessing(false);
            _this._data = null;
            _this.observationProvider.notifyObservers(meetingCalendarNotificationModalActions_1.default.Closing);
        };
        this.send = function () { return __awaiter(_this, void 0, void 0, function () {
            var request, e_1;
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this._form.submit();
                        if (!this._form.isValid()) {
                            this.sendButton.setProcessing(false);
                            return [2 /*return*/];
                        }
                        if (!this.getEmailFieldsAreValid()) {
                            this.alert.show({
                                alertType: __1.AlertType.Error,
                                heading: 'Invalid Email',
                                message: 'One or more email addresses are invalid'
                            });
                            this.sendButton.setProcessing(false);
                            return [2 /*return*/];
                        }
                        request = this._form.model;
                        request.attendees = this._attendeeCheckBoxes
                            .filter(function (checkbox) { return checkbox.value; })
                            .map(function (checkbox) {
                            return {
                                id: _this._data.attendees[checkbox.key].id,
                                fullName: _this._data.attendees[checkbox.key].fullName,
                                email: _this._attendeeEmailFields[checkbox.key].value
                            };
                        });
                        request.guests = this._guestCheckBoxes
                            .filter(function (checkbox) { return checkbox.value; })
                            .map(function (checkbox) {
                            return {
                                id: _this._data.guests[checkbox.key].id,
                                fullName: _this._data.guests[checkbox.key].fullName,
                                email: _this._guestEmailFields[checkbox.key].value
                            };
                        });
                        if (!request.attendees.length && !request.guests.length) {
                            this.alert.show({
                                alertType: __1.AlertType.Error,
                                heading: 'Unable to send',
                                message: 'You must select at least one recipient to send a notification'
                            });
                            this.sendButton.setProcessing(false);
                            return [2 /*return*/];
                        }
                        this.observationProvider.notifyObservers(meetingCalendarNotificationModalActions_1.default.Sending);
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 3, , 4]);
                        return [4 /*yield*/, this.api.post("".concat(__1.EndpointUrls.Meetings, "/").concat(this._meetingId, "/SendCalendarNotifications"), request)];
                    case 2:
                        _a.sent();
                        this.observationProvider.notifyObservers(meetingCalendarNotificationModalActions_1.default.Sent);
                        this.hide();
                        return [3 /*break*/, 4];
                    case 3:
                        e_1 = _a.sent();
                        this.sendButton.setProcessing(false);
                        this.alert.show({
                            alertType: __1.AlertType.Error,
                            heading: 'Unable to send',
                            message: 'An error occurred trying to send the notification'
                        });
                        return [3 /*break*/, 4];
                    case 4: return [2 /*return*/];
                }
            });
        }); };
        this.observationProvider = new __1.ObservationProvider();
        this._title = "Meeting Calendar Notifications";
        this.closeButton = new button_1.default({
            onClick: this.hide,
            text: 'Close'
        });
        this.sendButton = new button_1.default({
            onClick: this.send,
            text: 'Send',
            processingText: 'Sending...'
        });
        this.alert = new __1.Alert();
        this._defaultSubject = '';
        this._defaultEmailTemplate = '';
    }
    Object.defineProperty(MeetingCalendarNotificationModal.prototype, "title", {
        get: function () {
            return this._title;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(MeetingCalendarNotificationModal.prototype, "isLoading", {
        get: function () {
            return this._isLoading;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(MeetingCalendarNotificationModal.prototype, "isOpen", {
        get: function () {
            return this._isOpen;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(MeetingCalendarNotificationModal.prototype, "subjectField", {
        get: function () {
            return this._subjectField;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(MeetingCalendarNotificationModal.prototype, "ccEmailField", {
        get: function () {
            return this._ccEmailField;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(MeetingCalendarNotificationModal.prototype, "emailTemplateField", {
        get: function () {
            return this._emailTemplateField;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(MeetingCalendarNotificationModal.prototype, "form", {
        get: function () {
            return this._form;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(MeetingCalendarNotificationModal.prototype, "data", {
        get: function () {
            return this._data;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(MeetingCalendarNotificationModal.prototype, "attendeeCheckBoxes", {
        get: function () {
            return this._attendeeCheckBoxes;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(MeetingCalendarNotificationModal.prototype, "guestCheckBoxes", {
        get: function () {
            return this._guestCheckBoxes;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(MeetingCalendarNotificationModal.prototype, "attendeeEmailFields", {
        get: function () {
            return this._attendeeEmailFields;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(MeetingCalendarNotificationModal.prototype, "guestEmailFields", {
        get: function () {
            return this._guestEmailFields;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(MeetingCalendarNotificationModal.prototype, "replyToEmailField", {
        get: function () {
            return this._replyToEmailField;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(MeetingCalendarNotificationModal.prototype, "replyToNameField", {
        get: function () {
            return this._replyToNameField;
        },
        enumerable: false,
        configurable: true
    });
    MeetingCalendarNotificationModal.prototype.getEmailFieldsAreValid = function () {
        for (var i = 0; i < this._attendeeEmailFields.length; i++) {
            if (this._attendeeCheckBoxes[i].value && !this._attendeeEmailFields[i].isValid()) {
                return false;
            }
        }
        for (var i = 0; i < this._guestEmailFields.length; i++) {
            if (this._guestCheckBoxes[i].value && !this._guestEmailFields[i].isValid()) {
                return false;
            }
        }
        return true;
    };
    return MeetingCalendarNotificationModal;
}());
exports.default = MeetingCalendarNotificationModal;
