"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
var __1 = require("../..");
var button_1 = require("../../components/button/button");
var HistoryPage = /** @class */ (function (_super) {
    __extends(HistoryPage, _super);
    function HistoryPage(api, navigator, params) {
        var _this = _super.call(this, __1.PageTypes.History, params.historyType === __1.HistoryTypes.UserHistory ? 'User History' :
            params.historyType === __1.HistoryTypes.EventHistory ? 'Event History' :
                params.historyType === __1.HistoryTypes.InventoryHistory ? 'Inventory History' :
                    params.historyType === __1.HistoryTypes.AccommodationHistory ? 'Travel & Hotel History' :
                        'Record History') || this;
        _this.api = api;
        _this.navigator = navigator;
        _this.params = params;
        _this.goBack = function () {
            _this.navigator.goBack();
        };
        _this._table = new __1.HistoryTable(params.historyType, params.recordId, api);
        _this._table.searchBoxPlaceholder = 'By note';
        _this._table.load();
        _this.backButton = new button_1.default({
            leftIcon: __1.Icons.ChevronLeft,
            onClick: _this.goBack
        });
        return _this;
    }
    Object.defineProperty(HistoryPage.prototype, "table", {
        get: function () {
            return this._table;
        },
        enumerable: false,
        configurable: true
    });
    return HistoryPage;
}(__1.BasePage));
exports.default = HistoryPage;
