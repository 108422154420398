"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var __1 = require("..");
var BasePage = /** @class */ (function () {
    function BasePage(pageType, title) {
        this.pageType = pageType;
        this.title = title;
        this.alert = new __1.Alert();
    }
    return BasePage;
}());
exports.default = BasePage;
