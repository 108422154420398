"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g = Object.create((typeof Iterator === "function" ? Iterator : Object).prototype);
    return g.next = verb(0), g["throw"] = verb(1), g["return"] = verb(2), typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
var _1 = require(".");
var numberDropdownField_1 = require("./numberDropdownField");
var UsersDropdown = /** @class */ (function (_super) {
    __extends(UsersDropdown, _super);
    function UsersDropdown(api, key, label, isRequired, requiredMessage, initialValue) {
        var _this = _super.call(this, key, label, isRequired, [], requiredMessage, initialValue, _1.FieldType.DropDown) || this;
        _this.api = api;
        _this.key = key;
        _this.requiredMessage = requiredMessage;
        _this.loadOptions = function () { return __awaiter(_this, void 0, void 0, function () {
            var users, options, _i, users_1, user;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.api.getAllUsersForDropdown()];
                    case 1:
                        users = _a.sent();
                        options = [];
                        for (_i = 0, users_1 = users; _i < users_1.length; _i++) {
                            user = users_1[_i];
                            options.push({ label: "".concat(user.firstName, " ").concat(user.lastName), value: user.id });
                        }
                        this.setOptions(options);
                        this._optionsLoaded = true;
                        this.observationProvider.notifyObservers(_1.FieldActions.OptionsLoaded);
                        return [2 /*return*/];
                }
            });
        }); };
        _this._loadingPromise = _this.loadOptions().then(function () {
            if (initialValue) {
                _this.setValue(initialValue);
            }
        });
        return _this;
    }
    UsersDropdown.prototype.setValue = function (value) {
        var _this = this;
        if (this._optionsLoaded) {
            _super.prototype.setValue.call(this, value);
        }
        else {
            this.loadingPromise.then(function () {
                _super.prototype.setValue.call(_this, value);
            });
        }
    };
    Object.defineProperty(UsersDropdown.prototype, "loadingPromise", {
        get: function () {
            return this._loadingPromise;
        },
        enumerable: false,
        configurable: true
    });
    return UsersDropdown;
}(numberDropdownField_1.default));
exports.default = UsersDropdown;
