"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var FormSubmissionFailure = /** @class */ (function () {
    function FormSubmissionFailure(failureType, message) {
        this.failureType = failureType;
        this.message = message;
    }
    return FormSubmissionFailure;
}());
exports.default = FormSubmissionFailure;
