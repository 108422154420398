"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var HelpListModalActions;
(function (HelpListModalActions) {
    HelpListModalActions["Loaded"] = "Loaded";
    HelpListModalActions["Opened"] = "Opened";
    HelpListModalActions["Closed"] = "Closed";
    HelpListModalActions["HelpModalOpened"] = "HelpModalOpened";
    HelpListModalActions["HelpModalClosed"] = "HelpModalClosed";
})(HelpListModalActions || (HelpListModalActions = {}));
exports.default = HelpListModalActions;
