"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var MeetingDetailFormActions;
(function (MeetingDetailFormActions) {
    MeetingDetailFormActions["Loaded"] = "Loaded";
    MeetingDetailFormActions["Submitted"] = "Submitted";
    MeetingDetailFormActions["Opened"] = "Opened";
    MeetingDetailFormActions["Closed"] = "Closed";
    MeetingDetailFormActions["RecordUpdated"] = "RecordUpdated";
    MeetingDetailFormActions["RecordAdding"] = "RecordUpdated";
    MeetingDetailFormActions["RecordAdded"] = "RecordAdded";
    MeetingDetailFormActions["RecordDeactivated"] = "RecordDeactivated";
    MeetingDetailFormActions["RecordReactivated"] = "RecordReactivated";
    MeetingDetailFormActions["AttendeeRemoved"] = "AttendeeRemoved";
    MeetingDetailFormActions["AttendeeRemovalCancelled"] = "AttendeeRemovalCancelled";
    MeetingDetailFormActions["AttendeeAdded"] = "AttendeeAdded";
    MeetingDetailFormActions["AttendeeTypeChanged"] = "AttendeeTypeChanged";
    MeetingDetailFormActions["RecordDuplicated"] = "RecordDuplicated";
    MeetingDetailFormActions["HistoryTableHidden"] = "HistoryTableHidden";
    MeetingDetailFormActions["HistoryTableShown"] = "HistoryTableShown";
    MeetingDetailFormActions["HistoryTableActionOccurred"] = "HistoryTableActionOccurred";
    MeetingDetailFormActions["Loading"] = "Loading";
    MeetingDetailFormActions["PromptForConflicts"] = "PromptForConflicts";
    MeetingDetailFormActions["ConflictModalClosed"] = "ConflictModalClosed";
    MeetingDetailFormActions["CustomerEditVisibiltyChanged"] = "CustomerEditVisibiltyChanged";
    MeetingDetailFormActions["FileAdded"] = "FileAdded";
    MeetingDetailFormActions["EditModeToggled"] = "EditModeToggled";
    MeetingDetailFormActions["ActivePaneChanged"] = "ActivePaneChanged";
    MeetingDetailFormActions["GuestArrivedChanged"] = "GuestArrivedChanged";
    MeetingDetailFormActions["MeetingCategoryChanged"] = "MeetingCategoryChanged";
})(MeetingDetailFormActions || (MeetingDetailFormActions = {}));
exports.default = MeetingDetailFormActions;
