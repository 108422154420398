"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g = Object.create((typeof Iterator === "function" ? Iterator : Object).prototype);
    return g.next = verb(0), g["throw"] = verb(1), g["return"] = verb(2), typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
var _1 = require(".");
var __1 = require("../../../../..");
var button_1 = require("../../../../../components/button/button");
var helpButton_1 = require("../../../../../components/button/helpButton");
var helpUrls_1 = require("../../../../helpUrls");
var ExtrasTile = /** @class */ (function (_super) {
    __extends(ExtrasTile, _super);
    function ExtrasTile(eventId, api, navigator, currentUserManager) {
        var _this = _super.call(this, 'Extras', api, currentUserManager.clientChecker.isMobileApp(), true, false) || this;
        _this.eventId = eventId;
        _this.navigator = navigator;
        _this.currentUserManager = currentUserManager;
        _this.loadData = function () { return __awaiter(_this, void 0, void 0, function () {
            var _a;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _a = this;
                        return [4 /*yield*/, this.api.getEventManager(this.eventId)];
                    case 1:
                        _a._eventManager = _b.sent();
                        this.observationProvider.notifyObservers(_1.ExtrasTileActions.DataLoaded);
                        return [2 /*return*/];
                }
            });
        }); };
        _this.update = function () { };
        _this._helpButton = new helpButton_1.default(helpUrls_1.HelpUrlType.ExtrasTile, null, navigator, api);
        _this.refreshButton.setVisibility(false);
        _this.processLoadData();
        _this.urlAssignerButton = new button_1.default({
            text: 'Virtual Event URL Grid',
            onClick: function () { return _this.navigator.goToUrlAssignerPage(_this.eventId); }
        });
        return _this;
    }
    Object.defineProperty(ExtrasTile.prototype, "meetingsEnabled", {
        get: function () {
            if (!this._eventManager) {
                return false;
            }
            return this._eventManager.isMeetingsEnabled;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(ExtrasTile.prototype, "meetingPlacardUrl", {
        get: function () {
            if (!this.meetingsEnabled) {
                return '';
            }
            return "".concat(this.navigator.webBaseUrl, "#/displays/meetingPlacard?eventId=").concat(this.eventId, "&passKey=").concat(this._eventManager.eventLandingData.passKey);
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(ExtrasTile.prototype, "roomDisplayUrl", {
        get: function () {
            if (!this.meetingsEnabled) {
                return '';
            }
            return "".concat(this.navigator.webBaseUrl, "#/displays/roomDisplay?eventId=").concat(this.eventId, "&passKey=").concat(this._eventManager.eventLandingData.passKey);
        },
        enumerable: false,
        configurable: true
    });
    return ExtrasTile;
}(__1.EventLandingTile));
exports.default = ExtrasTile;
