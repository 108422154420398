"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var Config = /** @class */ (function () {
    function Config() {
    }
    Object.defineProperty(Config.prototype, "apiBaseUrl", {
        get: function () {
            return globalConfig.apiBaseUrl;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(Config.prototype, "webBaseUrl", {
        get: function () {
            return globalConfig.webBaseUrl;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(Config.prototype, "forceSso", {
        get: function () {
            return globalConfig.forceSso;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(Config.prototype, "loginRedirectHandler", {
        get: function () {
            return globalConfig.loginRedirectHandler;
        },
        enumerable: false,
        configurable: true
    });
    return Config;
}());
exports.default = Config;
