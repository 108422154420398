"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
var _1 = require(".");
var __1 = require("../..");
var DateDropdownField = /** @class */ (function (_super) {
    __extends(DateDropdownField, _super);
    function DateDropdownField(key, label, isRequired, options, requiredValidationMessage, initialValue, fieldType, noSelectionLabel) {
        if (fieldType === void 0) { fieldType = _1.FieldType.DropDown; }
        var _this = _super.call(this, key, label, isRequired, options, requiredValidationMessage, initialValue, fieldType, noSelectionLabel) || this;
        if (initialValue) {
            _this.setValue(initialValue);
        }
        return _this;
    }
    DateDropdownField.prototype.isValidDate = function () {
        return __1.DateUtils.isValid(this.stringValue.toString(), __1.DateFormats.MMDDYYYY);
    };
    DateDropdownField.prototype.isValid = function () {
        var isValid = true;
        if (!this.checkRequiredStatus()) {
            isValid = false;
        }
        if (this.stringValue && !this.isValidDate()) {
            isValid = false;
        }
        this.setIsValid(isValid);
        return isValid;
    };
    Object.defineProperty(DateDropdownField.prototype, "value", {
        get: function () {
            if (!this.stringValue === null || !this.isValidDate()) {
                return null;
            }
            return this.stringValue;
        },
        enumerable: false,
        configurable: true
    });
    return DateDropdownField;
}(_1.BaseDropdownField));
exports.default = DateDropdownField;
