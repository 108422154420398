"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g = Object.create((typeof Iterator === "function" ? Iterator : Object).prototype);
    return g.next = verb(0), g["throw"] = verb(1), g["return"] = verb(2), typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
Object.defineProperty(exports, "__esModule", { value: true });
var __1 = require("../..");
var button_1 = require("../../components/button/button");
var api_1 = require("../../api");
var components_1 = require("../../components");
var helpButton_1 = require("../../components/button/helpButton");
var utils_1 = require("../../utils");
var helpUrls_1 = require("../helpUrls");
var expenseType_1 = require("./expenseType");
var SystemSettingsPage = /** @class */ (function (_super) {
    __extends(SystemSettingsPage, _super);
    function SystemSettingsPage(api, currentUserManager, navigator) {
        var _this = _super.call(this, __1.PageTypes.SystemSettings, '') || this;
        _this.api = api;
        _this.currentUserManager = currentUserManager;
        _this.navigator = navigator;
        _this.setVisibleMeetingCategories = function () {
            var showOnlyActive = _this.showOnlyActiveMeetingCategoryCheckbox.value;
            for (var i = 0; i < _this.defaultMeetingCategoriesDataList.items.length; i++) {
                _this.defaultMeetingCategoriesDataList.setItemVisibility(i, !showOnlyActive || _this.defaultMeetingCategoriesDataList.items[i].form.model.isActive);
            }
        };
        _this.recreateApiAuthKey = function () {
            _this._apiKey = utils_1.StringUtils.createRandomIdentifier();
            _this._apiKeyHasChanged = true;
            _this.observationProvider.notifyObservers(__1.SystemSettingsPageActions.ApiAuthTokenReCreated);
        };
        _this.showEventAttendeeAccessLevels = function () {
            if (_this._activePane === __1.SystemSettingsPanes.EventAttendeeAccessLevels) {
                return;
            }
            _this._activePane = __1.SystemSettingsPanes.EventAttendeeAccessLevels;
            _this.observationProvider.notifyObservers(__1.SystemSettingsPageActions.PaneChanged);
            _this.loadEventAttendeeAccessLevelData();
        };
        _this.showInventoryItemTypePane = function () {
            if (_this._activePane === __1.SystemSettingsPanes.InventoryItemTypePane) {
                return;
            }
            _this._activePane = __1.SystemSettingsPanes.InventoryItemTypePane;
            _this.observationProvider.notifyObservers(__1.SystemSettingsPageActions.PaneChanged);
            _this.loadInventoryItemTypeData();
        };
        _this.showInventoryItemCategoryPane = function () {
            if (_this._activePane === __1.SystemSettingsPanes.InventoryItemCategoryPane) {
                return;
            }
            _this._activePane = __1.SystemSettingsPanes.InventoryItemCategoryPane;
            _this.observationProvider.notifyObservers(__1.SystemSettingsPageActions.PaneChanged);
            _this.loadInventoryItemCategoryData();
        };
        _this.showInventoryItemGroupPane = function () {
            if (_this._activePane === __1.SystemSettingsPanes.InventoryItemGroupPane) {
                return;
            }
            _this._activePane = __1.SystemSettingsPanes.InventoryItemGroupPane;
            _this.observationProvider.notifyObservers(__1.SystemSettingsPageActions.PaneChanged);
            _this.loadInventoryItemGroupData();
        };
        _this.showDefaultMeetingCategoriesPane = function () {
            if (_this._activePane === __1.SystemSettingsPanes.DefaultMeetingCategories) {
                return;
            }
            _this._activePane = __1.SystemSettingsPanes.DefaultMeetingCategories;
            _this.observationProvider.notifyObservers(__1.SystemSettingsPageActions.PaneChanged);
            _this.loadDefaultMeetingCategoryData();
        };
        _this.showMeetingNotesDisclaimerPane = function () {
            if (_this._activePane === __1.SystemSettingsPanes.MeetingNotesDisclaimer) {
                return;
            }
            _this._activePane = __1.SystemSettingsPanes.MeetingNotesDisclaimer;
            _this.observationProvider.notifyObservers(__1.SystemSettingsPageActions.PaneChanged);
            _this.loadMeetingNotesDisclaimerData();
        };
        _this.showEventLeadResponsibilitiesPane = function () {
            if (_this._activePane === __1.SystemSettingsPanes.EventLeadResponsibilities) {
                return;
            }
            _this._activePane = __1.SystemSettingsPanes.EventLeadResponsibilities;
            _this.observationProvider.notifyObservers(__1.SystemSettingsPageActions.PaneChanged);
            _this.loadEventLeadResponsibilityData();
        };
        _this.showApprovedAccessDomainPane = function () {
            if (_this._activePane === __1.SystemSettingsPanes.ApprovedAccessDomains) {
                return;
            }
            _this._activePane = __1.SystemSettingsPanes.ApprovedAccessDomains;
            _this.observationProvider.notifyObservers(__1.SystemSettingsPageActions.PaneChanged);
            _this.loadAccessDomainData();
        };
        _this.showBusinessAreasPane = function () {
            if (_this._activePane === __1.SystemSettingsPanes.BusinessAreas) {
                return;
            }
            _this._activePane = __1.SystemSettingsPanes.BusinessAreas;
            _this.observationProvider.notifyObservers(__1.SystemSettingsPageActions.PaneChanged);
            _this.loadBusinessAreaData();
        };
        _this.showCostManagementServicesPane = function () {
            if (_this._activePane === __1.SystemSettingsPanes.CostManagementServices) {
                return;
            }
            _this._activePane = __1.SystemSettingsPanes.CostManagementServices;
            _this.observationProvider.notifyObservers(__1.SystemSettingsPageActions.PaneChanged);
            _this.loadCostManagementServicesData();
        };
        _this.showDepartmentsPane = function () {
            if (_this._activePane === __1.SystemSettingsPanes.Departments) {
                return;
            }
            _this._activePane = __1.SystemSettingsPanes.Departments;
            _this.observationProvider.notifyObservers(__1.SystemSettingsPageActions.PaneChanged);
            _this.loadDepartmentData();
        };
        _this.showLinesOfBusinessPane = function () {
            if (_this._activePane === __1.SystemSettingsPanes.LinesOfBusiness) {
                return;
            }
            _this._activePane = __1.SystemSettingsPanes.LinesOfBusiness;
            _this.observationProvider.notifyObservers(__1.SystemSettingsPageActions.PaneChanged);
            _this.loadLinesOfBusinessData();
        };
        _this.showUserTitlesPane = function () {
            if (_this._activePane === __1.SystemSettingsPanes.UserTitles) {
                return;
            }
            _this._activePane = __1.SystemSettingsPanes.UserTitles;
            _this.observationProvider.notifyObservers(__1.SystemSettingsPageActions.PaneChanged);
            _this.loadUserTitleData();
        };
        _this.showProductKnowledgeOptionsPane = function () {
            if (_this._activePane === __1.SystemSettingsPanes.ProductKnowledgeOptions) {
                return;
            }
            _this._activePane = __1.SystemSettingsPanes.ProductKnowledgeOptions;
            _this.observationProvider.notifyObservers(__1.SystemSettingsPageActions.PaneChanged);
            _this.loadProductKnowledgeData();
        };
        _this.showApiAuthKeyPane = function () {
            if (_this._activePane === __1.SystemSettingsPanes.ApiAuthKeyPane) {
                return;
            }
            _this._activePane = __1.SystemSettingsPanes.ApiAuthKeyPane;
            _this.observationProvider.notifyObservers(__1.SystemSettingsPageActions.PaneChanged);
            _this.loadApiKeyData();
        };
        _this.showHotelsPane = function () {
            if (_this._activePane === __1.SystemSettingsPanes.Hotels) {
                return;
            }
            _this._activePane = __1.SystemSettingsPanes.Hotels;
            _this.observationProvider.notifyObservers(__1.SystemSettingsPageActions.PaneChanged);
            _this.loadHotelData();
        };
        _this.showTransportTermsPane = function () {
            if (_this._activePane === __1.SystemSettingsPanes.TransportTermsPane) {
                return;
            }
            _this._activePane = __1.SystemSettingsPanes.TransportTermsPane;
            _this.observationProvider.notifyObservers(__1.SystemSettingsPageActions.PaneChanged);
            _this.loadTransportTermData();
        };
        _this.showLodgingTermsPane = function () {
            if (_this._activePane === __1.SystemSettingsPanes.LodgingTermsPane) {
                return;
            }
            _this._activePane = __1.SystemSettingsPanes.LodgingTermsPane;
            _this.observationProvider.notifyObservers(__1.SystemSettingsPageActions.PaneChanged);
            _this.loadLodgingTermData();
        };
        _this.showSystemSettingsSystemNotificationPane = function () {
            if (_this._activePane === __1.SystemSettingsPanes.SystemSettingsSystemNotification) {
                return;
            }
            _this._activePane = __1.SystemSettingsPanes.SystemSettingsSystemNotification;
            _this.observationProvider.notifyObservers(__1.SystemSettingsPageActions.PaneChanged);
            _this.loadSystemSettingsSystemNotification();
        };
        _this.setCostManagementServiceSecondaryFactorFields = function () {
            var _loop_1 = function (i) {
                var primaryFactorField = _this.costManagementServicesDataList.items[i].form.fields[1];
                var secondaryFactorField = _this.costManagementServicesDataList.items[i].form.fields[2];
                primaryFactorField.observationProvider.stopObservingAll();
                primaryFactorField.observationProvider.observe(function (value) {
                    if (value != __1.CostManagementCostFactor.Area) {
                        secondaryFactorField.isDisabled = true;
                        secondaryFactorField.setValue(null);
                    }
                    else {
                        secondaryFactorField.isDisabled = false;
                    }
                }, components_1.FieldActions.ValueChanged);
                if (primaryFactorField.value != __1.CostManagementCostFactor.Area) {
                    secondaryFactorField.isDisabled = true;
                    secondaryFactorField.setValue(null);
                }
                else {
                    secondaryFactorField.isDisabled = false;
                }
            };
            for (var i = 0; i < _this.costManagementServicesDataList.items.length; i++) {
                _loop_1(i);
            }
        };
        _this.saveClicked = function () { return __awaiter(_this, void 0, void 0, function () {
            var proceedToSave;
            return __generator(this, function (_a) {
                this.alert.hide();
                if (!this.areAllFormsValid()) {
                    this.saveButton.setProcessing(false);
                    return [2 /*return*/];
                }
                this._updateEventsAccessLevels = false;
                this._updateEventsInventoryPoints = false;
                this._updateEventServices = false;
                proceedToSave = true;
                if (this.hasEventAcessLevelsChanged()) {
                    proceedToSave = false;
                    this.eventAccessLevelUpdateEventsConfirmationPrompt.show();
                }
                if (this.hasInventoryItemPointsChanged()) {
                    proceedToSave = false;
                    this.inventoryItemTypeUpdateCurrentEventsConfirmationPrompt.show();
                }
                if (this.hasCostManagementServicesChanged()) {
                    proceedToSave = false;
                    this.costManagementServicesUpdateCurrentEventsConfirmationPrompt.show();
                }
                if (proceedToSave) {
                    this.save();
                }
                return [2 /*return*/];
            });
        }); };
        _this.hasInventoryItemPointsChanged = function () {
            if (_this.inventoryItemTypeDataList && _this.inventoryItemTypeDataList.items && _this._originalInventoryItemTypes) {
                for (var i = 0; i < _this._originalInventoryItemTypes.length; i++) {
                    if (_this.inventoryItemTypeDataList.items[i].form.getFieldByKey('domesticPoints').value != _this._originalInventoryItemTypes[i].domesticPoints ||
                        _this.inventoryItemTypeDataList.items[i].form.getFieldByKey('internationalPoints').value != _this._originalInventoryItemTypes[i].internationalPoints) {
                        return true;
                    }
                }
            }
            return false;
        };
        _this.hasCostManagementServicesChanged = function () {
            if (_this.costManagementServicesDataList && _this.costManagementServicesDataList.items && _this._originalCostManagementServices) {
                for (var i = 0; i < _this._originalCostManagementServices.length; i++) {
                    _this.costManagementServicesDataList.items[i].form.getFieldByKey('primaryCostFactor').value != _this._originalCostManagementServices[i].primaryCostFactor ||
                        _this.costManagementServicesDataList.items[i].form.getFieldByKey('secondaryCostFactor').value != _this._originalCostManagementServices[i].secondaryCostFactor ||
                        _this.costManagementServicesDataList.items[i].form.getFieldByKey('expenseType').value != _this._originalCostManagementServices[i].expenseType;
                }
                {
                    return true;
                }
            }
            return false;
        };
        _this.hasEventAcessLevelsChanged = function () {
            if (_this.eventAttendeeAccessLevelDataList && _this.eventAttendeeAccessLevelDataList.items && _this._originalEventAccessLevels) {
                var propertyNames = void 0;
                for (var i = 0; i < _this._originalEventAccessLevels.length; i++) {
                    if (!propertyNames) {
                        propertyNames = Object.getOwnPropertyNames(_this._originalEventAccessLevels[i]);
                    }
                    for (var j = 0; j < propertyNames.length; j++) {
                        if (['id', 'references', 'isDeletable', 'isRemoved', 'permissionsVisible'].indexOf(propertyNames[j]) >= 0) {
                            continue;
                        }
                        if (!_this.eventAttendeeAccessLevelDataList.items[i].form.model.id ||
                            _this.eventAttendeeAccessLevelDataList.items[i].form.model.isRemoved ||
                            _this._originalEventAccessLevels[i][propertyNames[j]] !== _this.eventAttendeeAccessLevelDataList.items[i].form.model[propertyNames[j]]) {
                            return true;
                        }
                    }
                }
            }
            return false;
        };
        _this.save = function () { return __awaiter(_this, void 0, void 0, function () {
            var systemSettings, _a;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        this.inventoryItemTypeUpdateCurrentEventsConfirmationPrompt.hide();
                        this.eventAccessLevelUpdateEventsConfirmationPrompt.hide();
                        this.costManagementServicesUpdateCurrentEventsConfirmationPrompt.hide();
                        systemSettings = {
                            approvedAccessDomains: [],
                            departments: [],
                            businessAreas: [],
                            linesOfBusiness: [],
                            userTitles: [],
                            hotels: [],
                            eventLeadResponsibilities: [],
                            defaultMeetingCategories: [],
                            meetingScheduledByOptions: [],
                            inventoryItemTypes: [],
                            inventoryItemCategories: [],
                            inventoryItemGroups: [],
                            productKnowledgeOptions: [],
                            costManagementServices: [],
                            defaultEventAttendeeAccessLevels: [],
                            updateEventsInventoryItemPoints: this._updateEventsInventoryPoints,
                            updateEventsAttendeeAccessLevels: this._updateEventsAccessLevels,
                            updateEventServices: this._updateEventServices,
                            meetingNotesDisclaimer: null,
                        };
                        if (this._isAllowedAccessDomainsLoaded) {
                            systemSettings.approvedAccessDomains = this.approvedAccessDomainDataList.getModels();
                        }
                        if (this._isDepartmentsLoaded) {
                            systemSettings.departments = this.departmentDataList.getModels();
                        }
                        if (this._isBusinessAreasLoaded) {
                            systemSettings.businessAreas = this.businessAreaDataList.getModels();
                        }
                        if (this._isLinesOfBusinessLoaded) {
                            systemSettings.linesOfBusiness = this.linesOfBusinessDataList.getModels();
                        }
                        if (this._isUserTitlesLoaded) {
                            systemSettings.userTitles = this.userTitleDataList.getModels();
                        }
                        if (this._isHotelsLoaded) {
                            systemSettings.hotels = this.hotelsDataList.getModels();
                        }
                        if (this._isEventLeadResponsibilitiesLoaded) {
                            systemSettings.eventLeadResponsibilities = this.eventLeadResponsibilitiesDataList.getModels();
                        }
                        if (this._isDefaultMeetingCategoriesLoaded) {
                            systemSettings.defaultMeetingCategories = this.defaultMeetingCategoriesDataList.getModels();
                            systemSettings.meetingScheduledByOptions = this.meetingScheduledByDataList.getModels();
                        }
                        if (this._isInventoryItemTypesLoaded) {
                            systemSettings.inventoryItemTypes = this.inventoryItemTypeDataList.getModels();
                        }
                        if (this._isInventoryItemCapabilitiesLoaded) {
                            systemSettings.inventoryItemCategories = this.inventoryItemCategoryDataList.getModels();
                        }
                        if (this._isInventoryItemGroupsLoaded) {
                            systemSettings.inventoryItemGroups = this.inventoryItemGroupDataList.getModels();
                        }
                        if (this._isProductKnowledgeOptionsLoaded) {
                            systemSettings.productKnowledgeOptions = this.productKnowledgeDataList.getModels();
                        }
                        if (this._isCostManagementServicesLoaded) {
                            systemSettings.costManagementServices = this.costManagementServicesDataList.getModels();
                        }
                        if (this._isEventAttendeeAccessLevelsLoaded) {
                            systemSettings.defaultEventAttendeeAccessLevels = this.eventAttendeeAccessLevelDataList.getModels();
                        }
                        if (this._isSystemSettingsSystemNotificationLoaded) {
                            systemSettings.systemNotification = this.systemSettingsSystemNotificationForm.model;
                        }
                        if (this._isTransportTermsLoaded) {
                            if (!systemSettings.travelAndLodgingTerms) {
                                systemSettings.travelAndLodgingTerms = {};
                            }
                            systemSettings.travelAndLodgingTerms.transportTermsAndConditions = this.transportTermsField.value;
                        }
                        if (this._isLodgingTermsLoaded) {
                            if (!systemSettings.travelAndLodgingTerms) {
                                systemSettings.travelAndLodgingTerms = {};
                            }
                            systemSettings.travelAndLodgingTerms.lodgingTermsAndConditions = this.lodgingTermsField.value;
                        }
                        if (this._isMeetingNotesDisclaimerLoaded) {
                            systemSettings.meetingNotesDisclaimer = this.meetingNotesDisclaimerField.value;
                        }
                        if (this._apiKeyHasChanged) {
                            systemSettings.apiAuthToken = this._apiKey;
                        }
                        this.observationProvider.notifyObservers(__1.SystemSettingsPageActions.Saving);
                        _b.label = 1;
                    case 1:
                        _b.trys.push([1, 3, 4, 5]);
                        return [4 /*yield*/, this.api.post(__1.EndpointUrls.Settings + '/Save', systemSettings)];
                    case 2:
                        _b.sent();
                        this._isBusinessAreasLoaded = false;
                        this._isDefaultMeetingCategoriesLoaded = false;
                        this._isAllowedAccessDomainsLoaded = false;
                        this._isEventLeadResponsibilitiesLoaded = false;
                        this._isHotelsLoaded = false;
                        this._isInventoryItemCapabilitiesLoaded = false;
                        this._isInventoryItemGroupsLoaded = false;
                        this._isInventoryItemTypesLoaded = false;
                        this._isLinesOfBusinessLoaded = false;
                        this._isLodgingTermsLoaded = false;
                        this._isMeetingNotesDisclaimerLoaded = false;
                        this._isTransportTermsLoaded = false;
                        this._isApiKeyLoaded = false;
                        this._isDepartmentsLoaded = false;
                        this._isUserTitlesLoaded = false;
                        this._isProductKnowledgeOptionsLoaded = false;
                        this._isCostManagementServicesLoaded = false;
                        this._isEventAttendeeAccessLevelsLoaded = false;
                        this._isSystemSettingsSystemNotificationLoaded = false;
                        this.reloadCurrentPane();
                        this.alert.show({
                            heading: 'Settings Saved',
                            message: 'System settings were saved successfully',
                            alertType: components_1.AlertType.Success
                        });
                        return [3 /*break*/, 5];
                    case 3:
                        _a = _b.sent();
                        this.alert.show({
                            heading: 'Unable to Save',
                            alertType: components_1.AlertType.Error,
                            message: 'An error occurred while trying to save the system settings'
                        });
                        return [3 /*break*/, 5];
                    case 4:
                        this.saveButton.setDisabled(false);
                        this.saveButton.setProcessing(false);
                        this.observationProvider.notifyObservers(__1.SystemSettingsPageActions.Saved);
                        return [7 /*endfinally*/];
                    case 5: return [2 /*return*/];
                }
            });
        }); };
        _this.areAllFormsValid = function () {
            var isValid = true;
            var userTitleDataListValid = _this.userTitleDataList.areAllValid();
            if (!userTitleDataListValid) {
                _this.showUserTitlesPane();
            }
            isValid = isValid && userTitleDataListValid;
            var departmentDataListValid = _this.departmentDataList.areAllValid();
            if (!departmentDataListValid) {
                _this.showDepartmentsPane();
            }
            isValid = isValid && departmentDataListValid;
            var linesOfBusinessDataListValid = _this.linesOfBusinessDataList.areAllValid();
            if (!linesOfBusinessDataListValid) {
                _this.showLinesOfBusinessPane();
            }
            isValid = isValid && linesOfBusinessDataListValid;
            var inventoryItemTypeDataListValid = _this.inventoryItemTypeDataList.areAllValid();
            if (!inventoryItemTypeDataListValid) {
                _this.showInventoryItemTypePane();
            }
            isValid = isValid && inventoryItemTypeDataListValid;
            var inventoryItemGroupDataListValid = _this.inventoryItemGroupDataList.areAllValid();
            if (!inventoryItemGroupDataListValid) {
                _this.showInventoryItemGroupPane();
            }
            isValid = isValid && inventoryItemGroupDataListValid;
            var inventoryItemCategoryDataListValid = _this.inventoryItemCategoryDataList.areAllValid();
            if (!inventoryItemCategoryDataListValid) {
                _this.showInventoryItemCategoryPane();
            }
            isValid = isValid && inventoryItemCategoryDataListValid;
            var hotelsDataListValid = _this.hotelsDataList.areAllValid();
            if (!hotelsDataListValid) {
                _this.showHotelsPane();
            }
            isValid = isValid && hotelsDataListValid;
            var eventLeadResponsibilitiesDataListValid = _this.eventLeadResponsibilitiesDataList.areAllValid();
            if (!eventLeadResponsibilitiesDataListValid) {
                _this.showEventLeadResponsibilitiesPane();
            }
            isValid = isValid && eventLeadResponsibilitiesDataListValid;
            var approvedAccessDomainDataListValid = _this.approvedAccessDomainDataList.areAllValid();
            if (!approvedAccessDomainDataListValid) {
                _this.showApprovedAccessDomainPane();
            }
            isValid = isValid && approvedAccessDomainDataListValid;
            var defaultMeetingCategoriesDataListValid = _this.defaultMeetingCategoriesDataList.areAllValid();
            if (!defaultMeetingCategoriesDataListValid) {
                _this.showDefaultMeetingCategoriesPane();
            }
            isValid = isValid && defaultMeetingCategoriesDataListValid;
            var meetingScheduledByListValid = _this.meetingScheduledByDataList.areAllValid();
            if (!meetingScheduledByListValid) {
                _this.showDefaultMeetingCategoriesPane();
            }
            isValid = isValid && meetingScheduledByListValid;
            var eventAttendeeAccessLevelDataListValid = !_this._isEventAttendeeAccessLevelsLoaded || _this.eventAttendeeAccessLevelDataList.areAllValid();
            if (!eventAttendeeAccessLevelDataListValid) {
                _this.showEventAttendeeAccessLevels();
            }
            isValid = isValid && eventAttendeeAccessLevelDataListValid;
            var businessAreasValid = _this.businessAreaDataList.areAllValid();
            if (!businessAreasValid) {
                _this.showBusinessAreasPane();
            }
            isValid = isValid && businessAreasValid;
            var costManagementServicesValid = _this.costManagementServicesDataList.areAllValid();
            if (!costManagementServicesValid) {
                _this.showCostManagementServicesPane();
            }
            isValid = isValid && costManagementServicesValid;
            if (_this._isSystemSettingsSystemNotificationLoaded) {
                _this.systemSettingsSystemNotificationForm.submit();
                var systemSettingsSystemNotificationValid = _this.systemSettingsSystemNotificationForm.isValid();
                if (!systemSettingsSystemNotificationValid) {
                    _this.showSystemSettingsSystemNotificationPane();
                }
                isValid = isValid && systemSettingsSystemNotificationValid;
            }
            return isValid;
        };
        _this.reloadCurrentPane = function () {
            switch (_this.activePane) {
                case __1.SystemSettingsPanes.ApiAuthKeyPane:
                    return;
                case __1.SystemSettingsPanes.ApprovedAccessDomains:
                    _this.loadAccessDomainData();
                    return;
                case __1.SystemSettingsPanes.BusinessAreas:
                    _this.loadBusinessAreaData();
                    return;
                case __1.SystemSettingsPanes.CostManagementServices:
                    _this.loadCostManagementServicesData();
                    return;
                case __1.SystemSettingsPanes.DefaultMeetingCategories:
                    _this.loadDefaultMeetingCategoryData();
                    return;
                case __1.SystemSettingsPanes.Departments:
                    _this.loadDepartmentData();
                    return;
                case __1.SystemSettingsPanes.EventAttendeeAccessLevels:
                    _this.loadEventAttendeeAccessLevelData();
                    return;
                case __1.SystemSettingsPanes.EventLeadResponsibilities:
                    _this.loadEventLeadResponsibilityData();
                    return;
                case __1.SystemSettingsPanes.Hotels:
                    _this.loadHotelData();
                    return;
                case __1.SystemSettingsPanes.InventoryItemCategoryPane:
                    _this.loadInventoryItemCategoryData();
                    return;
                case __1.SystemSettingsPanes.InventoryItemGroupPane:
                    _this.loadInventoryItemGroupData();
                    return;
                case __1.SystemSettingsPanes.InventoryItemTypePane:
                    _this.loadInventoryItemTypeData();
                    return;
                case __1.SystemSettingsPanes.LinesOfBusiness:
                    _this.loadLinesOfBusinessData();
                    return;
                case __1.SystemSettingsPanes.LodgingTermsPane:
                    return;
                case __1.SystemSettingsPanes.ProductKnowledgeOptions:
                    _this.loadProductKnowledgeData();
                    return;
                case __1.SystemSettingsPanes.TransportTermsPane:
                    return;
                case __1.SystemSettingsPanes.UserTitles:
                    _this.loadUserTitleData();
                    return;
                case __1.SystemSettingsPanes.SystemSettingsSystemNotification:
                    _this.loadSystemSettingsSystemNotification();
                    return;
                case __1.SystemSettingsPanes.MeetingNotesDisclaimer:
                    _this.loadMeetingNotesDisclaimerData();
                    return;
            }
        };
        _this.helpButton = new helpButton_1.default(helpUrls_1.HelpUrlType.SystemSettingsPage, null, navigator, api);
        _this.observationProvider = new __1.ObservationProvider();
        _this.title = 'System Settings';
        var hotelsButton = new button_1.default({ text: 'Hotel Reward Chains', onClick: _this.showHotelsPane });
        var transportTermsButton = new button_1.default({ text: 'Default Transportation Terms & Conditions', onClick: _this.showTransportTermsPane });
        var lodgingTermsButton = new button_1.default({ text: 'Default Hotel Terms & Conditions', onClick: _this.showLodgingTermsPane });
        if (!_this.currentUserManager.hasFeatureEnabled(api_1.FeatureType.TravelAndLodging)) {
            hotelsButton.setVisibility(false);
            transportTermsButton.setVisibility(false);
            lodgingTermsButton.setVisibility(false);
        }
        var costManagementServicesButton = new button_1.default({ text: 'Cost Management Services', onClick: _this.showCostManagementServicesPane });
        if (!_this.currentUserManager.hasFeatureEnabled(api_1.FeatureType.CostManagement)) {
            costManagementServicesButton.setVisibility(false);
        }
        var inventoryItemTypesButton = new button_1.default({ text: 'Inventory Item Types', onClick: _this.showInventoryItemTypePane });
        var inventoryItemGroupsButton = new button_1.default({ text: 'Inventory Item Groups', onClick: _this.showInventoryItemGroupPane });
        var inventoryItemCapabilitiesButton = new button_1.default({ text: 'Inventory Item Capabilities', onClick: _this.showInventoryItemCategoryPane });
        if (!_this.currentUserManager.hasFeatureEnabled(api_1.FeatureType.Inventory)) {
            inventoryItemTypesButton.setVisibility(false);
            inventoryItemGroupsButton.setVisibility(false);
            inventoryItemCapabilitiesButton.setVisibility(false);
        }
        var meetingNotesDisclaimerButton = new button_1.default({ text: 'Meeting Notes Disclaimer', onClick: _this.showMeetingNotesDisclaimerPane });
        if (!_this.currentUserManager.hasFeatureEnabled(api_1.FeatureType.MeetingNotes)) {
            meetingNotesDisclaimerButton.setVisibility(false);
        }
        _this.dropdownItems = [
            { button: new button_1.default({ text: 'Business Areas', onClick: _this.showBusinessAreasPane }) },
            { button: costManagementServicesButton },
            { button: new button_1.default({ text: 'Default Event Access Levels', onClick: _this.showEventAttendeeAccessLevels }) },
            { button: new button_1.default({ text: 'Meeting Categories', onClick: _this.showDefaultMeetingCategoriesPane }) },
            { button: new button_1.default({ text: 'Domain Whitelist', onClick: _this.showApprovedAccessDomainPane }) },
            { button: new button_1.default({ text: 'Event Lead Responsibilities', onClick: _this.showEventLeadResponsibilitiesPane }) },
            { button: hotelsButton },
            { button: inventoryItemCapabilitiesButton },
            { button: inventoryItemGroupsButton },
            { button: inventoryItemTypesButton },
            { button: new button_1.default({ text: 'Lines of Business', onClick: _this.showLinesOfBusinessPane }) },
            { button: lodgingTermsButton },
            { button: meetingNotesDisclaimerButton },
            { button: new button_1.default({ text: 'Open Api Authentication Key', onClick: _this.showApiAuthKeyPane }) },
            { button: new button_1.default({ text: 'Operating Companies', onClick: _this.showDepartmentsPane }) },
            { button: new button_1.default({ text: 'Area of Expertise', onClick: _this.showProductKnowledgeOptionsPane }) },
            { button: transportTermsButton },
            { button: new button_1.default({ text: 'User Titles', onClick: _this.showUserTitlesPane }) },
            { button: new button_1.default({ text: 'Login Alerts', onClick: _this.showSystemSettingsSystemNotificationPane }) }
        ];
        _this.dropdown = new __1.Dropdown(_this.dropdownItems);
        _this.approvedAccessDomainDataList = new __1.DataList(function () { return [new __1.TextField('domain', 'Domain', true, 100, 'Required')]; }, true, 'Add Domain');
        _this.departmentDataList = new __1.DataList(function () { return [
            new __1.TextField('name', 'Name', true, 100, 'Required'),
            new __1.BusinessAreaDropdown(_this.api, 'businessAreaId', 'Business Area', true, 'required')
        ]; }, true, 'Add Operating Company');
        _this.businessAreaDataList = new __1.DataList(function () { return [
            new __1.TextField('name', 'Name', true, 100, 'required'),
            new components_1.UserSearchField(_this.api, 'Domestic Admin', false, null, false, 'domesticAdminUserId', 'domesticAdminUser'),
            new components_1.UserSearchField(_this.api, 'International Admin', false, null, false, 'internationalAdminUserId', 'internationalAdminUser')
        ]; }, true, 'Add Business Area');
        _this.linesOfBusinessDataList = new __1.DataList(function () { return [new __1.TextField('name', 'Line of business', true, 100, 'required')]; }, true, 'Add Line of Business');
        _this.userTitleDataList = new __1.DataList(function () { return [new __1.TextField('title', 'Title', true, 100, 'required')]; }, true, 'Add Title');
        _this.hotelsDataList = new __1.DataList(function () { return [new __1.TextField('name', 'Name', true, 200)]; }, true, 'Add Hotel');
        _this.eventLeadResponsibilitiesDataList = new __1.DataList(function () { return [new __1.TextField('name', 'Name', true, 200)]; }, true, 'Add Responsibility');
        _this.defaultMeetingCategoriesDataList = new __1.DataList(function () { return [
            new __1.TextField('name', 'Category Name', true, 200),
            new components_1.CheckBoxField('requireScheduledBy', 'Scheduled By Required'),
            new components_1.CheckBoxField('isActive', 'Is Active'),
        ]; }, true, 'Add Category');
        _this.meetingScheduledByDataList = new __1.DataList(function () { return [new __1.TextField('name', 'Scheduled By Name', true, 200)]; }, true, 'Add Option');
        _this.inventoryItemTypeDataList = new __1.DataList(function () {
            var fields = [
                new __1.TextField('name', 'Type Name', true, 200)
            ];
            if (_this.currentUserManager.hasFeatureEnabled(api_1.FeatureType.CostManagement)) {
                fields.push(new components_1.IntField('domesticPoints', 'Dom. Points', true, 'required'));
                fields.push(new components_1.IntField('internationalPoints', 'Intl. Points', true, 'required'));
            }
            fields.push(new __1.TextField('description', 'Description', true, 500));
            fields.push(new components_1.CheckBoxField('requireBusinessArea', 'Business Area'));
            fields.push(new components_1.CheckBoxField('requireLineOfBusiness', 'Line of Business'));
            fields.push(new components_1.CheckBoxField('requireCrateInformation', 'Crate Info'));
            fields.push(new components_1.CheckBoxField('requireDimensions', 'Dimensions'));
            fields.push(new components_1.CheckBoxField('requireWeight', 'Weight'));
            return fields;
        }, true, 'Add Type');
        _this.inventoryItemCategoryDataList = new __1.DataList(function () { return [new __1.TextField('name', 'Capability', true, 200)]; }, true, 'Add Capability');
        _this.inventoryItemGroupDataList = new __1.DataList(function () { return [new __1.TextField('name', 'Group Name', true, 200)]; }, true, 'Add Group');
        _this.productKnowledgeDataList = new __1.DataList(function () { return [new __1.TextField('name', 'Option', true, 250)]; }, true, 'Add Option');
        _this.costManagementServicesDataList = new __1.DataList(function () { return [
            new __1.TextField('name', 'Service Name', true, 250),
            new components_1.CostFactorDropdownField('primaryCostFactor', 'Primary Factor', true),
            new components_1.NumberDropdownField('secondaryCostFactor', 'Secondary Factory', false, [{ label: 'Point Value', value: __1.CostManagementCostFactor.PointValue }, { label: 'Attendees', value: __1.CostManagementCostFactor.Attendee }], null, null, components_1.FieldType.DropDown, 'None'),
            new components_1.NumberDropdownField('expenseType', 'Type', true, [{ label: 'Expense', value: expenseType_1.default.Expense }, { label: 'Credit', value: expenseType_1.default.Credit }])
        ]; }, true, 'Add Service');
        _this.costManagementServicesDataList.observationProvider.observe(_this.setCostManagementServiceSecondaryFactorFields, components_1.DataListActions.RecordAdded);
        _this.costManagementServicesUpdateCurrentEventsConfirmationPrompt = new components_1.ConfirmationPrompt(true, 'Update Active Events', 'Would you like to update event cost management services for active events? Only upcomming or active events will can be updated.', 'Update Active Events', 'Do Not Update Events');
        _this.costManagementServicesUpdateCurrentEventsConfirmationPrompt.observationProvider.observe(function () {
            _this._updateEventServices = true;
            _this.save();
        }, components_1.ConfirmationPromptActions.Confirmed);
        _this.costManagementServicesUpdateCurrentEventsConfirmationPrompt.observationProvider.observe(function () {
            _this._updateEventServices = false;
            _this.save();
        }, components_1.ConfirmationPromptActions.Cancelled);
        _this.createNewApiAuthKeyButton = new button_1.default({
            text: 'Re-Create Key',
            onClick: _this.recreateApiAuthKey
        });
        _this.eventAttendeeAccessLevelDataList = new __1.EventAttendeeAccessLevelDataList(_this.currentUserManager);
        _this.transportTermsField = new components_1.RichTextField('transportTerms', 'Default Transport Terms & Conditions', false);
        _this.lodgingTermsField = new components_1.RichTextField('lodgingTerms', 'Hotel Terms & Conditions', false);
        _this.meetingNotesDisclaimerField = new components_1.RichTextField('meetingNotesDisclaimer', 'Meeting Notes Disclaimer Text', false);
        _this.inventoryItemTypeUpdateCurrentEventsConfirmationPrompt = new components_1.ConfirmationPrompt(true, 'Update Active Events', 'You have made changes to inventory item type point values. Would you like to update the point values for inventory items that are currently assigned to active events? Only upcomming or active events will can be updated.', 'Update Active Events', 'Do Not Update Events');
        _this.inventoryItemTypeUpdateCurrentEventsConfirmationPrompt.observationProvider.observe(function () {
            _this._updateEventsInventoryPoints = true;
            _this.save();
        }, components_1.ConfirmationPromptActions.Confirmed);
        _this.inventoryItemTypeUpdateCurrentEventsConfirmationPrompt.observationProvider.observe(function () {
            _this._updateEventsInventoryPoints = false;
            _this.save();
        }, components_1.ConfirmationPromptActions.Cancelled);
        _this.eventAccessLevelUpdateEventsConfirmationPrompt = new components_1.ConfirmationPrompt(true, 'Update Active Events', 'You have made changes to event access level permissions. Would you like to event access levels for active events already created in TEMS?', 'Update Active Events', 'Do Not Update Events');
        _this.eventAccessLevelUpdateEventsConfirmationPrompt.observationProvider.observe(function () {
            _this._updateEventsAccessLevels = true;
            _this.save();
        }, components_1.ConfirmationPromptActions.Confirmed);
        _this.eventAccessLevelUpdateEventsConfirmationPrompt.observationProvider.observe(function () {
            _this._updateEventsAccessLevels = false;
            _this.save();
        }, components_1.ConfirmationPromptActions.Cancelled);
        var notificationEnabledField = new components_1.CheckBoxField('tenantNotificationEnabled', 'Alert Enabled');
        var notificationMessageField = new __1.TextField('tenantNotificationMessage', 'Message', true, 2000, 'required', null, components_1.FieldType.TextArea);
        var notificationCountField = new components_1.IntField('tenantNotificationDisplayCount', 'Number of times to display alert', true, 'required');
        _this.systemSettingsSystemNotificationForm = new components_1.Form([
            notificationEnabledField,
            notificationMessageField,
            notificationCountField
        ]);
        notificationEnabledField.observationProvider.observe(function () {
            notificationMessageField.setVisibility(notificationEnabledField.value === true);
            notificationCountField.setVisibility(notificationEnabledField.value === true);
        }, components_1.FieldActions.ValueChanged);
        _this.saveButton = new button_1.default({
            text: 'Save All',
            processingText: 'Saving...',
            onClick: _this.saveClicked
        });
        _this.showApprovedAccessDomainPane();
        _this.showOnlyActiveMeetingCategoryCheckbox = new components_1.CheckBoxField('showOnlyActiveMeetingCategory', 'Show Only Active', false, null, true);
        _this.showOnlyActiveMeetingCategoryCheckbox.observationProvider.observe(function () {
            _this.setVisibleMeetingCategories();
        }, components_1.FieldActions.ValueChanged);
        return _this;
    }
    Object.defineProperty(SystemSettingsPage.prototype, "isLoaded", {
        get: function () {
            return this._isLoaded;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(SystemSettingsPage.prototype, "activePane", {
        get: function () {
            return this._activePane;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(SystemSettingsPage.prototype, "tenantId", {
        get: function () {
            return this.currentUserManager.tenantId;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(SystemSettingsPage.prototype, "openApiBaseUrl", {
        get: function () {
            return globalConfig.webBaseUrl;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(SystemSettingsPage.prototype, "apiKey", {
        get: function () {
            return this._apiKey;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(SystemSettingsPage.prototype, "apiKeyHasChanged", {
        get: function () {
            return this._apiKeyHasChanged;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(SystemSettingsPage.prototype, "isApp", {
        get: function () {
            return this.currentUserManager.clientChecker.isMobileApp();
        },
        enumerable: false,
        configurable: true
    });
    SystemSettingsPage.prototype.loadAccessDomainData = function () {
        return __awaiter(this, void 0, void 0, function () {
            var _a, _b;
            return __generator(this, function (_c) {
                switch (_c.label) {
                    case 0:
                        if (this._isAllowedAccessDomainsLoaded) {
                            return [2 /*return*/];
                        }
                        this.saveButton.setDisabled(true);
                        this._isLoaded = false;
                        this.observationProvider.notifyObservers(__1.SystemSettingsPageActions.RecordLoading);
                        _b = (_a = this.approvedAccessDomainDataList).setModels;
                        return [4 /*yield*/, this.api.get(__1.EndpointUrls.Settings + '/ApprovedAccessDomains')];
                    case 1:
                        _b.apply(_a, [_c.sent()]);
                        this._isLoaded = true;
                        this.saveButton.setProcessing(false);
                        this.saveButton.setDisabled(false);
                        this._isAllowedAccessDomainsLoaded = true;
                        this.observationProvider.notifyObservers(__1.SystemSettingsPageActions.RecordLoaded);
                        return [2 /*return*/];
                }
            });
        });
    };
    SystemSettingsPage.prototype.loadDepartmentData = function () {
        return __awaiter(this, void 0, void 0, function () {
            var _a, _b;
            return __generator(this, function (_c) {
                switch (_c.label) {
                    case 0:
                        if (this._isDepartmentsLoaded) {
                            return [2 /*return*/];
                        }
                        this.saveButton.setDisabled(true);
                        this._isLoaded = false;
                        this.observationProvider.notifyObservers(__1.SystemSettingsPageActions.RecordLoading);
                        _b = (_a = this.departmentDataList).setModels;
                        return [4 /*yield*/, this.api.get(__1.EndpointUrls.Settings + '/Departments')];
                    case 1:
                        _b.apply(_a, [_c.sent()]);
                        this._isLoaded = true;
                        this.saveButton.setProcessing(false);
                        this.saveButton.setDisabled(false);
                        this._isDepartmentsLoaded = true;
                        this.observationProvider.notifyObservers(__1.SystemSettingsPageActions.RecordLoaded);
                        return [2 /*return*/];
                }
            });
        });
    };
    SystemSettingsPage.prototype.loadBusinessAreaData = function () {
        return __awaiter(this, void 0, void 0, function () {
            var _a, _b;
            return __generator(this, function (_c) {
                switch (_c.label) {
                    case 0:
                        if (this._isBusinessAreasLoaded) {
                            return [2 /*return*/];
                        }
                        this.saveButton.setDisabled(true);
                        this._isLoaded = false;
                        this.observationProvider.notifyObservers(__1.SystemSettingsPageActions.RecordLoading);
                        _b = (_a = this.businessAreaDataList).setModels;
                        return [4 /*yield*/, this.api.get(__1.EndpointUrls.Settings + '/BusinessAreas')];
                    case 1:
                        _b.apply(_a, [_c.sent()]);
                        this._isLoaded = true;
                        this.saveButton.setProcessing(false);
                        this.saveButton.setDisabled(false);
                        this._isBusinessAreasLoaded = true;
                        this.observationProvider.notifyObservers(__1.SystemSettingsPageActions.RecordLoaded);
                        return [2 /*return*/];
                }
            });
        });
    };
    SystemSettingsPage.prototype.loadLinesOfBusinessData = function () {
        return __awaiter(this, void 0, void 0, function () {
            var _a, _b;
            return __generator(this, function (_c) {
                switch (_c.label) {
                    case 0:
                        if (this._isLinesOfBusinessLoaded) {
                            return [2 /*return*/];
                        }
                        this.saveButton.setDisabled(true);
                        this._isLoaded = false;
                        this.observationProvider.notifyObservers(__1.SystemSettingsPageActions.RecordLoading);
                        _b = (_a = this.linesOfBusinessDataList).setModels;
                        return [4 /*yield*/, this.api.get(__1.EndpointUrls.Settings + '/LinesOfBusiness')];
                    case 1:
                        _b.apply(_a, [_c.sent()]);
                        this._isLoaded = true;
                        this.saveButton.setProcessing(false);
                        this.saveButton.setDisabled(false);
                        this._isLinesOfBusinessLoaded = true;
                        this.observationProvider.notifyObservers(__1.SystemSettingsPageActions.RecordLoaded);
                        return [2 /*return*/];
                }
            });
        });
    };
    SystemSettingsPage.prototype.loadUserTitleData = function () {
        return __awaiter(this, void 0, void 0, function () {
            var _a, _b;
            return __generator(this, function (_c) {
                switch (_c.label) {
                    case 0:
                        if (this._isUserTitlesLoaded) {
                            return [2 /*return*/];
                        }
                        this.saveButton.setDisabled(true);
                        this._isLoaded = false;
                        this.observationProvider.notifyObservers(__1.SystemSettingsPageActions.RecordLoading);
                        _b = (_a = this.userTitleDataList).setModels;
                        return [4 /*yield*/, this.api.get(__1.EndpointUrls.Settings + '/UserTitles')];
                    case 1:
                        _b.apply(_a, [_c.sent()]);
                        this._isLoaded = true;
                        this.saveButton.setProcessing(false);
                        this.saveButton.setDisabled(false);
                        this._isUserTitlesLoaded = true;
                        this.observationProvider.notifyObservers(__1.SystemSettingsPageActions.RecordLoaded);
                        return [2 /*return*/];
                }
            });
        });
    };
    SystemSettingsPage.prototype.loadHotelData = function () {
        return __awaiter(this, void 0, void 0, function () {
            var _a, _b;
            return __generator(this, function (_c) {
                switch (_c.label) {
                    case 0:
                        if (this._isHotelsLoaded) {
                            return [2 /*return*/];
                        }
                        this.saveButton.setDisabled(true);
                        this._isLoaded = false;
                        this.observationProvider.notifyObservers(__1.SystemSettingsPageActions.RecordLoading);
                        _b = (_a = this.hotelsDataList).setModels;
                        return [4 /*yield*/, this.api.get(__1.EndpointUrls.Settings + '/Hotels')];
                    case 1:
                        _b.apply(_a, [_c.sent()]);
                        this._isLoaded = true;
                        this.saveButton.setProcessing(false);
                        this.saveButton.setDisabled(false);
                        this._isHotelsLoaded = true;
                        this.observationProvider.notifyObservers(__1.SystemSettingsPageActions.RecordLoaded);
                        return [2 /*return*/];
                }
            });
        });
    };
    SystemSettingsPage.prototype.loadEventLeadResponsibilityData = function () {
        return __awaiter(this, void 0, void 0, function () {
            var _a, _b;
            return __generator(this, function (_c) {
                switch (_c.label) {
                    case 0:
                        if (this._isEventLeadResponsibilitiesLoaded) {
                            return [2 /*return*/];
                        }
                        this.saveButton.setDisabled(true);
                        this._isLoaded = false;
                        this.observationProvider.notifyObservers(__1.SystemSettingsPageActions.RecordLoading);
                        _b = (_a = this.eventLeadResponsibilitiesDataList).setModels;
                        return [4 /*yield*/, this.api.get(__1.EndpointUrls.Settings + '/EventLeadResponsibilities')];
                    case 1:
                        _b.apply(_a, [_c.sent()]);
                        this._isLoaded = true;
                        this.saveButton.setProcessing(false);
                        this.saveButton.setDisabled(false);
                        this._isEventLeadResponsibilitiesLoaded = true;
                        this.observationProvider.notifyObservers(__1.SystemSettingsPageActions.RecordLoaded);
                        return [2 /*return*/];
                }
            });
        });
    };
    SystemSettingsPage.prototype.loadDefaultMeetingCategoryData = function () {
        return __awaiter(this, void 0, void 0, function () {
            var _a, _b, _c, _d;
            return __generator(this, function (_e) {
                switch (_e.label) {
                    case 0:
                        if (this._isDefaultMeetingCategoriesLoaded) {
                            return [2 /*return*/];
                        }
                        this.saveButton.setDisabled(true);
                        this._isLoaded = false;
                        this.observationProvider.notifyObservers(__1.SystemSettingsPageActions.RecordLoading);
                        _b = (_a = this.defaultMeetingCategoriesDataList).setModels;
                        return [4 /*yield*/, this.api.get(__1.EndpointUrls.Settings + '/DefaultMeetingCategories/true')];
                    case 1:
                        _b.apply(_a, [_e.sent()]);
                        _d = (_c = this.meetingScheduledByDataList).setModels;
                        return [4 /*yield*/, this.api.getMeetingScheduledByOptions()];
                    case 2:
                        _d.apply(_c, [_e.sent()]);
                        this._isLoaded = true;
                        this.setVisibleMeetingCategories();
                        this.saveButton.setProcessing(false);
                        this.saveButton.setDisabled(false);
                        this._isDefaultMeetingCategoriesLoaded = true;
                        this.observationProvider.notifyObservers(__1.SystemSettingsPageActions.RecordLoaded);
                        return [2 /*return*/];
                }
            });
        });
    };
    SystemSettingsPage.prototype.loadInventoryItemTypeData = function () {
        return __awaiter(this, void 0, void 0, function () {
            var inventoryItemTypes;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (this._isInventoryItemTypesLoaded) {
                            return [2 /*return*/];
                        }
                        this.saveButton.setDisabled(true);
                        this._isLoaded = false;
                        this.observationProvider.notifyObservers(__1.SystemSettingsPageActions.RecordLoading);
                        return [4 /*yield*/, this.api.get(__1.EndpointUrls.Settings + '/InventoryItemTypes')];
                    case 1:
                        inventoryItemTypes = _a.sent();
                        this._originalInventoryItemTypes = __spreadArray([], inventoryItemTypes, true);
                        this.inventoryItemTypeDataList.setModels(inventoryItemTypes);
                        this._isLoaded = true;
                        this.saveButton.setProcessing(false);
                        this.saveButton.setDisabled(false);
                        this._isInventoryItemTypesLoaded = true;
                        this.observationProvider.notifyObservers(__1.SystemSettingsPageActions.RecordLoaded);
                        return [2 /*return*/];
                }
            });
        });
    };
    SystemSettingsPage.prototype.loadInventoryItemGroupData = function () {
        return __awaiter(this, void 0, void 0, function () {
            var _a, _b;
            return __generator(this, function (_c) {
                switch (_c.label) {
                    case 0:
                        if (this._isInventoryItemGroupsLoaded) {
                            return [2 /*return*/];
                        }
                        this.saveButton.setDisabled(true);
                        this._isLoaded = false;
                        this.observationProvider.notifyObservers(__1.SystemSettingsPageActions.RecordLoading);
                        _b = (_a = this.inventoryItemGroupDataList).setModels;
                        return [4 /*yield*/, this.api.get(__1.EndpointUrls.Settings + '/InventoryItemGroups')];
                    case 1:
                        _b.apply(_a, [_c.sent()]);
                        this._isLoaded = true;
                        this.saveButton.setProcessing(false);
                        this.saveButton.setDisabled(false);
                        this._isInventoryItemGroupsLoaded = true;
                        this.observationProvider.notifyObservers(__1.SystemSettingsPageActions.RecordLoaded);
                        return [2 /*return*/];
                }
            });
        });
    };
    SystemSettingsPage.prototype.loadProductKnowledgeData = function () {
        return __awaiter(this, void 0, void 0, function () {
            var _a, _b;
            return __generator(this, function (_c) {
                switch (_c.label) {
                    case 0:
                        if (this._isProductKnowledgeOptionsLoaded) {
                            return [2 /*return*/];
                        }
                        this.saveButton.setDisabled(true);
                        this._isLoaded = false;
                        this.observationProvider.notifyObservers(__1.SystemSettingsPageActions.RecordLoading);
                        _b = (_a = this.productKnowledgeDataList).setModels;
                        return [4 /*yield*/, this.api.get(__1.EndpointUrls.Settings + '/ProductKnowledge')];
                    case 1:
                        _b.apply(_a, [_c.sent()]);
                        this._isLoaded = true;
                        this.saveButton.setProcessing(false);
                        this.saveButton.setDisabled(false);
                        this._isProductKnowledgeOptionsLoaded = true;
                        this.observationProvider.notifyObservers(__1.SystemSettingsPageActions.RecordLoaded);
                        return [2 /*return*/];
                }
            });
        });
    };
    SystemSettingsPage.prototype.loadCostManagementServicesData = function () {
        return __awaiter(this, void 0, void 0, function () {
            var costManagementSerivces;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (this._isCostManagementServicesLoaded) {
                            return [2 /*return*/];
                        }
                        this.saveButton.setDisabled(true);
                        this._isLoaded = false;
                        this.observationProvider.notifyObservers(__1.SystemSettingsPageActions.RecordLoading);
                        return [4 /*yield*/, this.api.get(__1.EndpointUrls.Settings + '/CostManagementServices')];
                    case 1:
                        costManagementSerivces = _a.sent();
                        this._originalCostManagementServices = __spreadArray([], costManagementSerivces, true);
                        this.costManagementServicesDataList.setModels(costManagementSerivces);
                        this.setCostManagementServiceSecondaryFactorFields();
                        this._isLoaded = true;
                        this.saveButton.setProcessing(false);
                        this.saveButton.setDisabled(false);
                        this._isCostManagementServicesLoaded = true;
                        this.observationProvider.notifyObservers(__1.SystemSettingsPageActions.RecordLoaded);
                        return [2 /*return*/];
                }
            });
        });
    };
    SystemSettingsPage.prototype.loadInventoryItemCategoryData = function () {
        return __awaiter(this, void 0, void 0, function () {
            var _a, _b;
            return __generator(this, function (_c) {
                switch (_c.label) {
                    case 0:
                        if (this._isInventoryItemCapabilitiesLoaded) {
                            return [2 /*return*/];
                        }
                        this.saveButton.setDisabled(true);
                        this._isLoaded = false;
                        this.observationProvider.notifyObservers(__1.SystemSettingsPageActions.RecordLoading);
                        _b = (_a = this.inventoryItemCategoryDataList).setModels;
                        return [4 /*yield*/, this.api.get(__1.EndpointUrls.Settings + '/InventoryItemCategories')];
                    case 1:
                        _b.apply(_a, [_c.sent()]);
                        this._isLoaded = true;
                        this.saveButton.setProcessing(false);
                        this.saveButton.setDisabled(false);
                        this._isInventoryItemCapabilitiesLoaded = true;
                        this.observationProvider.notifyObservers(__1.SystemSettingsPageActions.RecordLoaded);
                        return [2 /*return*/];
                }
            });
        });
    };
    SystemSettingsPage.prototype.loadEventAttendeeAccessLevelData = function () {
        return __awaiter(this, void 0, void 0, function () {
            var eventAccessLevels;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (this._isEventAttendeeAccessLevelsLoaded) {
                            return [2 /*return*/];
                        }
                        this.saveButton.setDisabled(true);
                        this._isLoaded = false;
                        this.observationProvider.notifyObservers(__1.SystemSettingsPageActions.RecordLoading);
                        return [4 /*yield*/, this.api.get(__1.EndpointUrls.Settings + '/EventAttendeeAccessLevels')];
                    case 1:
                        eventAccessLevels = _a.sent();
                        this._originalEventAccessLevels = __spreadArray([], eventAccessLevels, true);
                        this.eventAttendeeAccessLevelDataList.setModels(eventAccessLevels);
                        this._isLoaded = true;
                        this.saveButton.setProcessing(false);
                        this.saveButton.setDisabled(false);
                        this._isEventAttendeeAccessLevelsLoaded = true;
                        this.observationProvider.notifyObservers(__1.SystemSettingsPageActions.RecordLoaded);
                        return [2 /*return*/];
                }
            });
        });
    };
    SystemSettingsPage.prototype.loadSystemSettingsSystemNotification = function () {
        return __awaiter(this, void 0, void 0, function () {
            var systemSettingsSystemNotification;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (this._isSystemSettingsSystemNotificationLoaded) {
                            return [2 /*return*/];
                        }
                        this.saveButton.setDisabled(true);
                        this._isLoaded = false;
                        this.observationProvider.notifyObservers(__1.SystemSettingsPageActions.RecordLoading);
                        return [4 /*yield*/, this.api.get(__1.EndpointUrls.Settings + '/SystemNotifications')];
                    case 1:
                        systemSettingsSystemNotification = _a.sent();
                        this.systemSettingsSystemNotificationForm.setModel(systemSettingsSystemNotification);
                        this._isLoaded = true;
                        this.saveButton.setProcessing(false);
                        this.saveButton.setDisabled(false);
                        this._isSystemSettingsSystemNotificationLoaded = true;
                        this.observationProvider.notifyObservers(__1.SystemSettingsPageActions.RecordLoaded);
                        return [2 /*return*/];
                }
            });
        });
    };
    SystemSettingsPage.prototype.loadTransportTermData = function () {
        return __awaiter(this, void 0, void 0, function () {
            var _a, _b;
            return __generator(this, function (_c) {
                switch (_c.label) {
                    case 0:
                        if (this._isTransportTermsLoaded) {
                            return [2 /*return*/];
                        }
                        this.saveButton.setDisabled(true);
                        this._isLoaded = false;
                        this.observationProvider.notifyObservers(__1.SystemSettingsPageActions.RecordLoading);
                        _b = (_a = this.transportTermsField).setValue;
                        return [4 /*yield*/, this.api.get(__1.EndpointUrls.Settings + '/TransportTerms')];
                    case 1:
                        _b.apply(_a, [_c.sent()]);
                        this._isLoaded = true;
                        this.saveButton.setProcessing(false);
                        this.saveButton.setDisabled(false);
                        this._isTransportTermsLoaded = true;
                        this.observationProvider.notifyObservers(__1.SystemSettingsPageActions.RecordLoaded);
                        return [2 /*return*/];
                }
            });
        });
    };
    SystemSettingsPage.prototype.loadApiKeyData = function () {
        return __awaiter(this, void 0, void 0, function () {
            var _a;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        if (this._isApiKeyLoaded) {
                            return [2 /*return*/];
                        }
                        this.saveButton.setDisabled(true);
                        this._isLoaded = false;
                        this.observationProvider.notifyObservers(__1.SystemSettingsPageActions.RecordLoading);
                        _a = this;
                        return [4 /*yield*/, this.api.get(__1.EndpointUrls.Settings + '/ApiKey')];
                    case 1:
                        _a._apiKey = _b.sent();
                        this._isLoaded = true;
                        this.saveButton.setProcessing(false);
                        this.saveButton.setDisabled(false);
                        this._isApiKeyLoaded = true;
                        this.observationProvider.notifyObservers(__1.SystemSettingsPageActions.RecordLoaded);
                        return [2 /*return*/];
                }
            });
        });
    };
    SystemSettingsPage.prototype.loadLodgingTermData = function () {
        return __awaiter(this, void 0, void 0, function () {
            var _a, _b;
            return __generator(this, function (_c) {
                switch (_c.label) {
                    case 0:
                        if (this._isLodgingTermsLoaded) {
                            return [2 /*return*/];
                        }
                        this.saveButton.setDisabled(true);
                        this._isLoaded = false;
                        this.observationProvider.notifyObservers(__1.SystemSettingsPageActions.RecordLoading);
                        _b = (_a = this.lodgingTermsField).setValue;
                        return [4 /*yield*/, this.api.get(__1.EndpointUrls.Settings + '/LodgingTerms')];
                    case 1:
                        _b.apply(_a, [_c.sent()]);
                        this._isLoaded = true;
                        this.saveButton.setProcessing(false);
                        this.saveButton.setDisabled(false);
                        this._isLodgingTermsLoaded = true;
                        this.observationProvider.notifyObservers(__1.SystemSettingsPageActions.RecordLoaded);
                        return [2 /*return*/];
                }
            });
        });
    };
    SystemSettingsPage.prototype.loadMeetingNotesDisclaimerData = function () {
        return __awaiter(this, void 0, void 0, function () {
            var response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (this._isMeetingNotesDisclaimerLoaded) {
                            return [2 /*return*/];
                        }
                        this.saveButton.setDisabled(true);
                        this._isLoaded = false;
                        this.observationProvider.notifyObservers(__1.SystemSettingsPageActions.RecordLoading);
                        return [4 /*yield*/, this.api.get(__1.EndpointUrls.Settings + '/MeetingNotesDisclaimer')];
                    case 1:
                        response = _a.sent();
                        this.meetingNotesDisclaimerField.setValue(response.disclaimerText);
                        this._isLoaded = true;
                        this.saveButton.setProcessing(false);
                        this.saveButton.setDisabled(false);
                        this._isMeetingNotesDisclaimerLoaded = true;
                        this.observationProvider.notifyObservers(__1.SystemSettingsPageActions.RecordLoaded);
                        return [2 /*return*/];
                }
            });
        });
    };
    return SystemSettingsPage;
}(__1.BasePage));
exports.default = SystemSettingsPage;
