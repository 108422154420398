"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
var _1 = require(".");
var baseField_1 = require("./baseField");
var IntField = /** @class */ (function (_super) {
    __extends(IntField, _super);
    function IntField(key, label, isRequired, requiredValidationMessage, initialValue, maxValue, minValue) {
        var _this = _super.call(this, _1.FieldType.Int, key, label, isRequired, 10, requiredValidationMessage) || this;
        _this.key = key;
        _this.maxValue = maxValue;
        _this.minValue = minValue;
        if (initialValue) {
            _this.setValue(initialValue);
        }
        return _this;
    }
    Object.defineProperty(IntField.prototype, "errorMessage", {
        get: function () {
            var baseErrorMessage = _super.prototype.getErrorMessage.call(this);
            if (baseErrorMessage) {
                return baseErrorMessage;
            }
            if (this.value && !this.isValidNumber() && this.isDirty) {
                return 'Number is invalid';
            }
            if (!this.isWithinRange()) {
                return "Must be between ".concat(this.minValue, " - ").concat(this.maxValue);
            }
            if (!this.isGreaterThan()) {
                return "Must be greater than ".concat(this.minValue);
            }
            if (!this.isLessThan()) {
                return "Must be less than ".concat(this.maxValue);
            }
            return baseErrorMessage;
        },
        enumerable: false,
        configurable: true
    });
    IntField.prototype.isValidNumber = function () {
        var regex = /^[-+]?\d+$/;
        return regex.test(this.value.toString());
    };
    IntField.prototype.isGreaterThan = function () {
        var isValid = true;
        if (!this.notANumber(this.minValue) && !this.notANumber(this.value)) {
            isValid = this.value >= this.minValue;
        }
        return isValid;
    };
    IntField.prototype.isLessThan = function () {
        var isValid = true;
        if (!this.notANumber(this.maxValue) && !this.notANumber(this.value)) {
            isValid = this.value <= this.maxValue;
        }
        return isValid;
    };
    IntField.prototype.isWithinRange = function () {
        var isValid = true;
        if (!this.notANumber(this.maxValue) && !this.notANumber(this.minValue) && !this.notANumber(this.value)) {
            isValid = this.value <= this.maxValue && this.value >= this.minValue;
        }
        return isValid;
    };
    IntField.prototype.notANumber = function (value) {
        return value === null || isNaN(value);
    };
    IntField.prototype.isValid = function () {
        var isValid = true;
        if (!this.checkRequiredStatus()) {
            isValid = false;
        }
        if (this.stringValue && !this.isValidNumber()) {
            isValid = false;
        }
        if (!this.isWithinRange()) {
            isValid = false;
        }
        if (!this.isGreaterThan()) {
            isValid = false;
        }
        if (!this.isLessThan()) {
            isValid = false;
        }
        this.setIsValid(isValid);
        return isValid;
    };
    IntField.prototype.setValue = function (newValue) {
        if (newValue === undefined || newValue === null) {
            this.setStringValue('');
        }
        else {
            this.setStringValue(newValue.toString());
        }
    };
    Object.defineProperty(IntField.prototype, "value", {
        get: function () {
            if (!this.stringValue && this.stringValue !== '0') {
                return null;
            }
            return parseInt(this.stringValue);
        },
        enumerable: false,
        configurable: true
    });
    return IntField;
}(baseField_1.default));
exports.default = IntField;
