"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var __1 = require("../..");
var utils_1 = require("../../utils");
var button_1 = require("../button/button");
var Calendar = /** @class */ (function () {
    function Calendar(focusDate) {
        var _this = this;
        this.show = function (focusDate) {
            if (focusDate) {
                _this._focusDate = focusDate;
            }
            _this.buildCalendarByFocusDate();
            _this._visible = true;
            _this.observationProvider.notifyObservers(__1.CalendarActions.Shown);
        };
        this.focus = function () {
            _this._isFocused = true;
        };
        this.blur = function () {
            _this._isFocused = false;
        };
        this.hide = function () {
            _this._visible = false;
            _this._isFocused = false;
            _this.observationProvider.notifyObservers(__1.CalendarActions.Hidden);
        };
        this.buildCalendarByFocusDate = function () {
            var dateParts = __1.DateUtils.getDateParts(_this._focusDate);
            _this._calendarGrid = [];
            _this._calendarMonth = __1.DateUtils.getMonth(_this._focusDate).name;
            _this._calendarYear = dateParts.yearString;
            var lastDayOfMonth = __1.DateUtils.getDateParts(__1.DateUtils.getLastDateOfMonth(_this._focusDate)).day;
            var firstDayOfMonth = __1.DateUtils.getDateParts(__1.DateUtils.getFirstDateOfMonth(_this._focusDate)).dayOfWeek;
            var weekIndex = 0;
            var currentDayOfMonth = 1;
            var calendarCountingStarted = false;
            while (lastDayOfMonth >= currentDayOfMonth) {
                _this._calendarGrid[weekIndex] = [];
                for (var dayOfWeekIndex = 0; dayOfWeekIndex < 7; dayOfWeekIndex++) {
                    calendarCountingStarted = calendarCountingStarted || firstDayOfMonth === dayOfWeekIndex;
                    if (calendarCountingStarted && currentDayOfMonth <= lastDayOfMonth) {
                        _this._calendarGrid[weekIndex][dayOfWeekIndex] = _this.buildCalendarDateButton(dateParts.yearString, dateParts.monthNumberString, currentDayOfMonth);
                        currentDayOfMonth++;
                    }
                    else {
                        _this._calendarGrid[weekIndex][dayOfWeekIndex] = null;
                    }
                }
                weekIndex++;
            }
        };
        this.buildCalendarDateButton = function (year, month, dayOfMonth) {
            return new button_1.default({
                text: dayOfMonth.toString(),
                onClick: function () { return _this.dateButtonClicked(year, month, dayOfMonth); }
            });
        };
        this.goToNextCalendarMonth = function () {
            var firstOfFocusMonth = __1.DateUtils.getFirstDateOfMonth(_this._focusDate);
            _this._focusDate = __1.DateUtils.addMonths(firstOfFocusMonth, 1);
            _this.buildCalendarByFocusDate();
            _this.observationProvider.notifyObservers(__1.CalendarActions.FocusDateChanged);
        };
        this.goToPreviousCalendarMonth = function () {
            var firstOfFocusMonth = __1.DateUtils.getFirstDateOfMonth(_this._focusDate);
            _this._focusDate = __1.DateUtils.addMonths(firstOfFocusMonth, -1);
            _this.buildCalendarByFocusDate();
            _this.observationProvider.notifyObservers(__1.CalendarActions.FocusDateChanged);
        };
        this.dateButtonClicked = function (year, month, dayOfMonth) {
            var date = __1.DateUtils.getDateFromString("".concat(month, "/").concat(dayOfMonth, "/").concat(year), utils_1.DateFormats.MMDDYYYY);
            _this.setDate(date);
        };
        this.setDate = function (date) {
            _this._selectedDate = date;
            _this.observationProvider.notifyObservers(__1.CalendarActions.DateSelected, date);
        };
        this.observationProvider = new __1.ObservationProvider();
        if (focusDate) {
            this._focusDate = focusDate;
        }
        else {
            this._focusDate = new Date();
        }
        this.calendarWeekHeader = ['Sun', 'Mon', 'Tue', 'Wed', 'Thur', 'Fri', 'Sat'];
        this.calendarNextMonthButton = new button_1.default({
            leftIcon: __1.Icons.ChevronRight,
            onClick: this.goToNextCalendarMonth
        });
        this.calendarPreviousMonthButton = new button_1.default({
            leftIcon: __1.Icons.ChevronLeft,
            onClick: this.goToPreviousCalendarMonth
        });
        this.todayButton = new button_1.default({
            text: 'Today',
            onClick: function () { return _this.setDate(new Date()); }
        });
    }
    Object.defineProperty(Calendar.prototype, "isFocused", {
        get: function () {
            return this._isFocused;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(Calendar.prototype, "selectedDate", {
        get: function () {
            return this._selectedDate;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(Calendar.prototype, "calendarMonth", {
        get: function () {
            return this._calendarMonth;
        },
        enumerable: false,
        configurable: true
    });
    Calendar.prototype.get_calendarYear = function () {
        return this._calendarYear;
    };
    Object.defineProperty(Calendar.prototype, "calendarHeader", {
        get: function () {
            return this._calendarMonth + ', ' + this._calendarYear;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(Calendar.prototype, "calendarGrid", {
        get: function () {
            return this._calendarGrid;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(Calendar.prototype, "visible", {
        get: function () {
            return this._visible;
        },
        enumerable: false,
        configurable: true
    });
    return Calendar;
}());
exports.default = Calendar;
