"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var EventLandingPageActions;
(function (EventLandingPageActions) {
    EventLandingPageActions["EventDataLoaded"] = "EventDataLoaded";
    EventLandingPageActions["EventActivated"] = "EventActivated";
    EventLandingPageActions["EventAlertExpanded"] = "EventAlertExpanded";
    EventLandingPageActions["EventAlertCollapsed"] = "EventAlertCollapsed";
    EventLandingPageActions["EventArchived"] = "EventArchived";
})(EventLandingPageActions || (EventLandingPageActions = {}));
exports.default = EventLandingPageActions;
