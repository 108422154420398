"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var InventorySchedulerDateStatus;
(function (InventorySchedulerDateStatus) {
    InventorySchedulerDateStatus[InventorySchedulerDateStatus["None"] = 0] = "None";
    InventorySchedulerDateStatus[InventorySchedulerDateStatus["Event"] = 1] = "Event";
    InventorySchedulerDateStatus[InventorySchedulerDateStatus["PreEvent"] = 2] = "PreEvent";
    InventorySchedulerDateStatus[InventorySchedulerDateStatus["PostEvent"] = 3] = "PostEvent";
    InventorySchedulerDateStatus[InventorySchedulerDateStatus["Conflict"] = 4] = "Conflict";
})(InventorySchedulerDateStatus || (InventorySchedulerDateStatus = {}));
exports.default = InventorySchedulerDateStatus;
