"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var FieldOption = /** @class */ (function () {
    function FieldOption(label, value, disabled) {
        this.label = label;
        this.value = value;
        this.disabled = disabled;
    }
    return FieldOption;
}());
exports.default = FieldOption;
