"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var FeatureType;
(function (FeatureType) {
    FeatureType["TravelAndLodging"] = "travelAndLodgingEnabled";
    FeatureType["Inventory"] = "inventoryEnabled";
    FeatureType["MeetingNotes"] = "meetingNotesEnabled";
    FeatureType["CostManagement"] = "costManagementEnabled";
    FeatureType["Dining"] = "diningEnabled";
    FeatureType["AttendeeHeadCount"] = "enableAttendeeHeadCount";
    FeatureType["MeetingInvites"] = "meetingInvitesEnabled";
})(FeatureType || (FeatureType = {}));
exports.default = FeatureType;
