"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var RequestAccessPageActions;
(function (RequestAccessPageActions) {
    RequestAccessPageActions["FormInvalid"] = "FormInvalid";
    RequestAccessPageActions["FormSubmitted"] = "FormSubmitted";
    RequestAccessPageActions["ErrorSubmitting"] = "ErrorSubmitting";
    RequestAccessPageActions["SubmittedSuccessfully"] = "SubmittedSuccessfully";
})(RequestAccessPageActions || (RequestAccessPageActions = {}));
exports.default = RequestAccessPageActions;
