"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var FunctionUtils = /** @class */ (function () {
    function FunctionUtils() {
    }
    /*
        if parameter is not a function it returns a function that returns the parameter.

        Scenario:
            allow for a function to receive param of type (param: string) => string | string
            this function can be used to ensure that parameters are processed consitently regardless
            of which optional type the parameter is.

            like this...
            foo = (fn: (param: string) => string | string) => {
                return Functionify(fn);
            }
    */
    FunctionUtils.functionify = function (fn) {
        if (typeof fn !== 'function') {
            return function () { return fn; };
        }
        return fn;
    };
    return FunctionUtils;
}());
exports.default = FunctionUtils;
