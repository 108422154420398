"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var FieldActions;
(function (FieldActions) {
    FieldActions["Blured"] = "Blured";
    FieldActions["Focus"] = "Focus";
    FieldActions["Disabled"] = "Disabled";
    FieldActions["Enabled"] = "Enabled";
    FieldActions["ValueChanged"] = "ValueChanged";
    FieldActions["Shown"] = "Shown";
    FieldActions["RequiredStatusChanged"] = "RequiredStatusChanged";
    FieldActions["Hidden"] = "Hidden";
    FieldActions["Valid"] = "Valid";
    FieldActions["Invalid"] = "Invalid";
    FieldActions["LabelButtonClicked"] = "LabelButtonClicked";
    FieldActions["SearchResultsCleared"] = "SearchResultsCleared";
    FieldActions["Searching"] = "Searching";
    FieldActions["SearchValueChanged"] = "SearchValueChanged";
    FieldActions["SearchCompleted"] = "SearchCompleted";
    FieldActions["OptionsLoaded"] = "OptionsLoaded";
    FieldActions["DropdownOpened"] = "DropdownOpened";
    FieldActions["DropdownClosed"] = "DropdownClosed";
    FieldActions["SearchDisabledIdsChanged"] = "SearchDisabledIdsChanged";
    FieldActions["PasswordVisibilityToggled"] = "PasswordVisibilityToggled";
})(FieldActions || (FieldActions = {}));
exports.default = FieldActions;
