"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g = Object.create((typeof Iterator === "function" ? Iterator : Object).prototype);
    return g.next = verb(0), g["throw"] = verb(1), g["return"] = verb(2), typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
var __1 = require("..");
var utils_1 = require("../../utils");
var HotelRewardsForm = /** @class */ (function () {
    function HotelRewardsForm(api) {
        this.api = api;
        this.hotelRewardFieldPrefix = 'UserHotelReward_';
        this._isLoading = true;
        this._hotelRewardNumbersFields = [];
        this.observationProvider = new utils_1.ObservationProvider();
        this.buildHotelRewardFields();
    }
    Object.defineProperty(HotelRewardsForm.prototype, "isLoading", {
        get: function () {
            return this._isLoading;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(HotelRewardsForm.prototype, "fields", {
        get: function () {
            return this._hotelRewardNumbersFields;
        },
        enumerable: false,
        configurable: true
    });
    HotelRewardsForm.prototype.buildHotelRewardFields = function () {
        return __awaiter(this, void 0, void 0, function () {
            var _a, _i, _b, hotel;
            return __generator(this, function (_c) {
                switch (_c.label) {
                    case 0:
                        _a = this;
                        return [4 /*yield*/, this.api.getHotels()];
                    case 1:
                        _a._hotels = _c.sent();
                        for (_i = 0, _b = this._hotels; _i < _b.length; _i++) {
                            hotel = _b[_i];
                            this._hotelRewardNumbersFields.push(new __1.TextField(this.hotelRewardFieldPrefix + hotel.id, hotel.name, false, 250));
                        }
                        this._isLoading = false;
                        this.observationProvider.notifyObservers(__1.HotelRewardsFormActions.HotelsLoaded);
                        return [2 /*return*/];
                }
            });
        });
    };
    HotelRewardsForm.prototype.getHotelRewardNumbers = function () {
        var _this = this;
        return this._hotelRewardNumbersFields.map(function (hotelRewardField) {
            return {
                hotelId: _this.parseHotelIdFromHotelRewardField(hotelRewardField),
                rewardNumber: hotelRewardField.value,
                hotelName: hotelRewardField.label
            };
        });
    };
    HotelRewardsForm.prototype.parseHotelIdFromHotelRewardField = function (field) {
        return parseInt(field.key.replace(this.hotelRewardFieldPrefix, ''), 10);
    };
    HotelRewardsForm.prototype.setHotelRewardNumbers = function (hotelRewardNumbers) {
        var _loop_1 = function (hotelRewardNumberField) {
            var hotelId = this_1.parseHotelIdFromHotelRewardField(hotelRewardNumberField);
            var userHotelRewardNumber = hotelRewardNumbers.find(function (rewardNumber) { return rewardNumber.hotelId === hotelId; });
            if (userHotelRewardNumber && userHotelRewardNumber.rewardNumber) {
                hotelRewardNumberField.setValue(userHotelRewardNumber.rewardNumber);
            }
            else {
                hotelRewardNumberField.setValue('');
            }
        };
        var this_1 = this;
        for (var _i = 0, _a = this._hotelRewardNumbersFields; _i < _a.length; _i++) {
            var hotelRewardNumberField = _a[_i];
            _loop_1(hotelRewardNumberField);
        }
    };
    return HotelRewardsForm;
}());
exports.default = HotelRewardsForm;
