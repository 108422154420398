"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var LodgingStatus;
(function (LodgingStatus) {
    LodgingStatus[LodgingStatus["NotSubmitted"] = 0] = "NotSubmitted";
    LodgingStatus[LodgingStatus["Pending"] = 1] = "Pending";
    LodgingStatus[LodgingStatus["Confirmed"] = 2] = "Confirmed";
    LodgingStatus[LodgingStatus["ConfirmedWithChanges"] = 3] = "ConfirmedWithChanges";
    LodgingStatus[LodgingStatus["Cancelled"] = 4] = "Cancelled";
})(LodgingStatus || (LodgingStatus = {}));
exports.default = LodgingStatus;
