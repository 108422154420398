"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
var dataList_1 = require("../dataList");
var __1 = require("../..");
var icons_1 = require("../icons");
var alert_1 = require("../alert");
var button_1 = require("../button/button");
var EventAttendeeAccessLevelDataList = /** @class */ (function (_super) {
    __extends(EventAttendeeAccessLevelDataList, _super);
    function EventAttendeeAccessLevelDataList(currentUserManager) {
        var _this = _super.call(this, function () { return [
            new __1.TextField('name', 'Name', true, 250, 'required'),
            _this.buildIsDefaultCheckbox(),
            new __1.CheckBoxField('editEvent', 'Edit Event Info'),
            new __1.CheckBoxField('editAttendees', 'Edit Attendees'),
            new __1.CheckBoxField('createMeetings', 'Create/Edit Meetings'),
            new __1.CheckBoxField('deleteMeetings', 'Delete Meetings'),
            new __1.CheckBoxField('assignVenues', 'Assign Venues'),
            new __1.CheckBoxField('prescheduleMeetings', 'Preschedule Meetings'),
            _this.buildAccessVendorPortalCheckbox(),
            new __1.CheckBoxField('accessRoomScheduler', 'Access Room Scheduler'),
            _this.buildOverrideAccommodationCutOffCheckbox(),
            new __1.CheckBoxField('overrideBusinessAreaRestriction', 'Override Business Area Restriction'),
            _this.buildEditInventoryCheckbox(),
            new __1.CheckBoxField('editEventConfiguration', 'Edit Event Configuration'),
            new __1.CheckBoxField('markMeetingGuestsArrived', 'Mark Meeting Guests Arrived'),
            new __1.CheckBoxField('sendMeetingNotifications', 'Send Meeting Notifications'),
            new __1.CheckBoxField('receiveMeetingRequestNotifications', 'Receive Meeting Request Notifications'),
            new __1.CheckBoxField('submitMeetingRequests', 'Submit Meeting Requests'),
            new __1.CheckBoxField('editFiles', 'Edit Files'),
            new __1.CheckBoxField('viewPrivateAttendees', 'View Private Attendees'),
            _this.buildEditTravelAndLodgingCheckbox(),
            _this.buildViewAllMeetingNotesCheckbox(),
            _this.buildViewCostManagementCheckbox(),
            _this.buildEditCostManagementCheckbox(),
            _this.buildEditDiningCheckbox(),
            _this.buildViewAdditionalHeadCountCheckbox(),
        ]; }, true, 'Add Item') || this;
        _this.currentUserManager = currentUserManager;
        _this.buildViewAdditionalHeadCountCheckbox = function () {
            var field = new __1.CheckBoxField('viewAdditionalHeadCount', 'View Additional Head Count');
            field.setVisibility(_this.currentUserManager.hasFeatureEnabled(__1.FeatureType.AttendeeHeadCount));
            return field;
        };
        _this.buildViewCostManagementCheckbox = function () {
            var field = new __1.CheckBoxField('viewCostManagement', 'View Cost Management');
            field.setVisibility(_this.currentUserManager.hasFeatureEnabled(__1.FeatureType.CostManagement));
            return field;
        };
        _this.buildEditCostManagementCheckbox = function () {
            var field = new __1.CheckBoxField('editCostManagement', 'Edit Cost Management');
            field.setVisibility(_this.currentUserManager.hasFeatureEnabled(__1.FeatureType.CostManagement));
            return field;
        };
        _this.buildViewAllMeetingNotesCheckbox = function () {
            var field = new __1.CheckBoxField('viewAllMeetingNotes', 'View / Add Meeting notes to all meetings');
            field.setVisibility(_this.currentUserManager.hasFeatureEnabled(__1.FeatureType.MeetingNotes));
            return field;
        };
        _this.buildEditDiningCheckbox = function () {
            var field = new __1.CheckBoxField('editDining', 'Schedule/Edit Dining Area');
            field.setVisibility(_this.currentUserManager.hasFeatureEnabled(__1.FeatureType.Dining));
            return field;
        };
        _this.buildEditTravelAndLodgingCheckbox = function () {
            var field = new __1.CheckBoxField('editTravelAndLodging', 'Edit Travel & Hotel');
            field.setVisibility(_this.currentUserManager.hasFeatureEnabled(__1.FeatureType.TravelAndLodging));
            return field;
        };
        _this.buildEditInventoryCheckbox = function () {
            var field = new __1.CheckBoxField('editInventory', 'Edit Inventory');
            field.setVisibility(_this.currentUserManager.hasFeatureEnabled(__1.FeatureType.Inventory));
            return field;
        };
        _this.buildOverrideAccommodationCutOffCheckbox = function () {
            var field = new __1.CheckBoxField('overrideAccommodationCutOff', 'Override Accommodation Cut-Off');
            field.setVisibility(_this.currentUserManager.hasFeatureEnabled(__1.FeatureType.TravelAndLodging));
            return field;
        };
        _this.buildAccessVendorPortalCheckbox = function () {
            var field = new __1.CheckBoxField('accessVendorPortal', 'Access Vendor Portal');
            field.setVisibility(_this.currentUserManager.hasFeatureEnabled(__1.FeatureType.TravelAndLodging));
            return field;
        };
        _this.areAllValid = function () {
            var isValid = true;
            var defaultCount = 0;
            for (var _i = 0, _a = _this.items; _i < _a.length; _i++) {
                var item = _a[_i];
                item.form.submit();
                isValid = isValid && item.form.isValid();
                if (!item.form.model.isRemoved && item.form.model.isDefault) {
                    defaultCount++;
                }
            }
            isValid = isValid && (defaultCount === 1);
            _this.alert.hide();
            if (defaultCount !== 1) {
                _this.alert.show({
                    alertType: alert_1.AlertType.Error,
                    heading: 'Invalid',
                    message: 'You must have one and only one default attendee access level'
                });
            }
            else if (!isValid) {
                _this.alert.show({
                    alertType: alert_1.AlertType.Error,
                    heading: 'Invalid',
                    message: 'One or more access levels are not valid'
                });
            }
            return isValid;
        };
        _this.buildPermissionVisibilityButton = function (item) {
            return new button_1.default({
                leftIcon: icons_1.Icons.ChevronRight,
                onClick: function () { return _this.togglePermissionVisibility(item); },
                text: 'Event Permissions'
            });
        };
        _this.togglePermissionVisibility = function (item) {
            item.permissionsVisible = !!!item.permissionsVisible;
            _this.observationProvider.notifyObservers(dataList_1.DataListActions.VisibilityChanged);
        };
        _this.buildIsDefaultCheckbox = function () {
            var field = new __1.CheckBoxField('isDefault', 'Is Default');
            field.observationProvider.observe(function () { return _this.defaultFieldChanged(field); }, __1.FieldActions.ValueChanged);
            return field;
        };
        _this.defaultFieldChanged = function (field) {
            if (_this._isDefaultChanging || _this.isLoading) {
                return;
            }
            _this._isDefaultChanging = true;
            for (var _i = 0, _a = _this.items; _i < _a.length; _i++) {
                var accessLevelItem = _a[_i];
                if (accessLevelItem.form.getFieldByKey('isDefault') !== field) {
                    accessLevelItem.form.setModel(__assign(__assign({}, accessLevelItem.form.model), { isDefault: false }));
                }
            }
            field.setValue(true);
            _this._isDefaultChanging = false;
        };
        _this.alert = new __1.Alert();
        return _this;
    }
    return EventAttendeeAccessLevelDataList;
}(dataList_1.DataList));
exports.default = EventAttendeeAccessLevelDataList;
