"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
var __1 = require("../..");
var button_1 = require("../../components/button/button");
var helpButton_1 = require("../../components/button/helpButton");
var helpUrls_1 = require("../helpUrls");
var CustomerCompaniesListPage = /** @class */ (function (_super) {
    __extends(CustomerCompaniesListPage, _super);
    function CustomerCompaniesListPage(api, navigator, currentUserManager) {
        var _this = _super.call(this, __1.PageTypes.CustomerCompanyList, 'Customer Companies') || this;
        _this.api = api;
        _this.navigator = navigator;
        _this.currentUserManager = currentUserManager;
        _this.goToNewCustomerCompanyPage = function () {
            _this.navigator.goToCustomerCompanyDetailPage();
        };
        _this.loadData = function () {
            return _this.api.getTablePage(__1.EndpointUrls.CustomerCompanies, _this._table.recordsPerPage, _this._table.currentPage, _this._table.sortExpression, _this._table.searchPhrase, __assign({}, _this.listOptionSlideContainer.appliedFiltersModel));
        };
        _this.buildCustomerCompanyEditButton = function (customerCompany) {
            return new button_1.default({
                onClick: function () { return _this.navigator.goToCustomerCompanyDetailPage(customerCompany.id); },
                text: customerCompany.name
            });
        };
        _this.helpButton = new helpButton_1.default(helpUrls_1.HelpUrlType.CustomerCompanyListPage, null, navigator, api);
        _this._table = new __1.Table(_this.loadData, api);
        _this._table.searchBoxPlaceholder = 'By company name';
        var filters = [
            new __1.BooleanDropdownField('isActive', 'Is Active', false)
        ];
        var defaultFilters = { isActive: true };
        _this.listOptionSlideContainer = new __1.ListOptionSlideContainer(filters, defaultFilters);
        _this.listOptionSlideContainer.observationProvider.observe(function () { return _this._table.load(); }, __1.ListOptionSlideContainerActions.OptionsApplied);
        _this._table.load();
        _this.newCustomerCompanyButton = new button_1.default({
            onClick: _this.goToNewCustomerCompanyPage,
            text: 'New Company',
            leftIcon: __1.Icons.Plus
        });
        return _this;
    }
    Object.defineProperty(CustomerCompaniesListPage.prototype, "table", {
        get: function () {
            return this._table;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(CustomerCompaniesListPage.prototype, "isApp", {
        get: function () {
            return this.currentUserManager.clientChecker.isMobileApp();
        },
        enumerable: false,
        configurable: true
    });
    return CustomerCompaniesListPage;
}(__1.BasePage));
exports.default = CustomerCompaniesListPage;
