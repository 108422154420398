"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var InventoryDetailPageActions;
(function (InventoryDetailPageActions) {
    InventoryDetailPageActions["Loaded"] = "Loaded";
    InventoryDetailPageActions["RecordUpdating"] = "RecordUpdating";
    InventoryDetailPageActions["RecordUpdated"] = "RecordUpdated";
    InventoryDetailPageActions["RecordActivated"] = "RecordActivated";
    InventoryDetailPageActions["RecordDeactivated"] = "RecordDeactivated";
    InventoryDetailPageActions["RecordAdding"] = "RecordAdding";
    InventoryDetailPageActions["RecordAdded"] = "RecordAdded";
    InventoryDetailPageActions["RecordDeleting"] = "RecordDeleting";
    InventoryDetailPageActions["CrateAdded"] = "CrateAdded";
    InventoryDetailPageActions["CrateRemoved"] = "CrateRemoved";
    InventoryDetailPageActions["CrateRemoveCancelled"] = "CrateRemoveCancelled";
    InventoryDetailPageActions["ImageAdded"] = "ImageAdded";
    InventoryDetailPageActions["ImageRemoved"] = "ImageRemoved";
    InventoryDetailPageActions["ImageRemoveCancelled"] = "ImageRemoveCancelled";
    InventoryDetailPageActions["InventoryItemTypeChanged"] = "InventoryItemTypeChanged";
    InventoryDetailPageActions["RecordDuplicated"] = "RecordDuplicated";
    InventoryDetailPageActions["FileAdded"] = "FileAdded";
})(InventoryDetailPageActions || (InventoryDetailPageActions = {}));
exports.default = InventoryDetailPageActions;
