"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g = Object.create((typeof Iterator === "function" ? Iterator : Object).prototype);
    return g.next = verb(0), g["throw"] = verb(1), g["return"] = verb(2), typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
var __1 = require("../../..");
var _1 = require(".");
var components_1 = require("../../../components");
var events_1 = require("../../events");
var button_1 = require("../../../components/button/button");
var FulfillmentForm = /** @class */ (function () {
    function FulfillmentForm(api, eventId, vendorCompanyId) {
        var _this = this;
        this.api = api;
        this.eventId = eventId;
        this.vendorCompanyId = vendorCompanyId;
        this.setPane = function (pane) {
            _this._vendorFulfillmentFormPanes = pane;
            _this.observationProvider.notifyObservers(_1.VendorFulfillmentFormActions.ActivePaneChanged);
        };
        this.getHotelRoomOptions = function () { return __awaiter(_this, void 0, void 0, function () {
            var _a;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _a = this;
                        return [4 /*yield*/, this.api.getHotelRoomOptions(this.eventId, this.vendorCompanyId)];
                    case 1:
                        _a._hotelRoomOptions = _b.sent();
                        this._configurationDataLoaded = true;
                        this.observationProvider.notifyObservers(_1.VendorFulfillmentFormActions.DataLoaded);
                        return [2 /*return*/];
                }
            });
        }); };
        this.buildForm = function () {
            var statusOptions = [
                { label: 'Not Yet Submitted', value: events_1.DailyTransportStatus.NotSubmitted },
                { label: 'Pending', value: events_1.DailyTransportStatus.Pending },
                { label: 'Confirmed', value: events_1.DailyTransportStatus.Confirmed },
                { label: 'Confirmed with Changes', value: events_1.DailyTransportStatus.ConfirmedWithChanges },
                { label: 'Cancelled', value: events_1.DailyTransportStatus.Cancelled },
            ];
            _this._transportationVendorCommentsField = new components_1.TextField('transportationVendorComments', '', false, 1000, '', null, components_1.FieldType.TextArea);
            _this._transportationStatusField = new components_1.NumberDropdownField('dailyTransportStatus', '', true, statusOptions, 'required');
            _this._hotelStatusField = new components_1.NumberDropdownField('lodgingStatus', '', true, statusOptions, 'required');
            _this._roomTypeField = new components_1.NumberDropdownField('eventHotelRoomId', '', false, _this._hotelRoomOptions, 'required', null, components_1.FieldType.DropDown, 'Not Set');
            _this._hotelConfirmationNumberField = new components_1.TextField('lodgingConfirmationNumber', '', false, 250, 'required');
            _this._HotelVendorCommentsField = new components_1.TextField('hotelVendorComments', '', false, 1000, '', null, components_1.FieldType.TextArea);
            _this._transportationFields = [
                _this._transportationVendorCommentsField,
                _this._transportationStatusField
            ];
            _this._hotelFields = [
                _this._hotelStatusField,
                _this._roomTypeField,
                _this._hotelConfirmationNumberField,
                _this._HotelVendorCommentsField
            ];
            _this._form = new components_1.Form([
                _this._transportationVendorCommentsField,
                _this._transportationStatusField,
                _this._hotelStatusField,
                _this._roomTypeField,
                _this._hotelConfirmationNumberField,
                _this._HotelVendorCommentsField
            ], {
                eventAttendeeId: _this._eventAttendeeId
            });
        };
        this.showHistoryTable = function () {
            _this._historyTable.load();
            _this._historyTableIsVisible = true;
            _this.observationProvider.notifyObservers(_1.VendorFulfillmentFormActions.HistoryTableShown);
        };
        this.hideHistoryTable = function () {
            _this._historyTableIsVisible = false;
            _this.observationProvider.notifyObservers(_1.VendorFulfillmentFormActions.HistoryTableHidden);
        };
        this.show = function (eventAttendeeId) { return __awaiter(_this, void 0, void 0, function () {
            var _a;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        this._eventAttendeeId = eventAttendeeId;
                        this._isLoading = true;
                        this._isOpen = true;
                        this.observationProvider.notifyObservers(_1.VendorFulfillmentFormActions.VisibilityChanged);
                        _a = this;
                        return [4 /*yield*/, this.api.get("".concat(__1.EndpointUrls.Vendors, "/VendorPortal/GetVendorFulfillmentData/").concat(eventAttendeeId))];
                    case 1:
                        _a._fulfillmentData = _b.sent();
                        if (this._isHotelVendor) {
                            this._vendorFulfillmentFormPanes = _1.VendorFulfillmentFormPanes.Hotel;
                        }
                        else if (this._isTransportationVendor) {
                            this._vendorFulfillmentFormPanes = _1.VendorFulfillmentFormPanes.Transportation;
                        }
                        this._form.setModel({
                            eventAttendeeId: this._fulfillmentData.eventAttendeeId,
                            transportationVendorComments: this._fulfillmentData.transportationVendorComments,
                            dailyTransportStatus: this._fulfillmentData.dailyTransportStatus,
                            lodgingStatus: this._fulfillmentData.lodgingStatus,
                            eventHotelRoomId: this._fulfillmentData.eventHotelRoomId,
                            hotelVendorComments: this._fulfillmentData.hotelVendorComments,
                            lodgingConfirmationNumber: this._fulfillmentData.lodgingConfirmationNumber
                        });
                        this._historyTable = new __1.HistoryTable(__1.HistoryTypes.AccommodationHistory, this._fulfillmentData.eventAttendeeId, this.api);
                        this._historyTable.searchBoxPlaceholder = 'By note';
                        this._isLoading = false;
                        this.observationProvider.notifyObservers(_1.VendorFulfillmentFormActions.DataLoaded);
                        return [2 /*return*/];
                }
            });
        }); };
        this.hide = function () {
            _this._isOpen = false;
            _this.alert.hide();
            _this.saveButton.setProcessing(false);
            _this._fulfillmentData = null;
            _this._transportationStatusField.clearValue();
            _this._hotelStatusField.clearValue();
            _this._transportationVendorCommentsField.clearValue();
            _this.observationProvider.notifyObservers(_1.VendorFulfillmentFormActions.VisibilityChanged);
        };
        this.save = function () { return __awaiter(_this, void 0, void 0, function () {
            var e_1;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this._form.submit();
                        if (!this._form.isValid()) {
                            this.saveButton.setProcessing(false);
                            return [2 /*return*/];
                        }
                        this.observationProvider.notifyObservers(_1.VendorFulfillmentFormActions.Saving);
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 3, , 4]);
                        return [4 /*yield*/, this.api.post("".concat(__1.EndpointUrls.Vendors, "/VendorPortal/SaveVendorFulfillment"), this.form.model)];
                    case 2:
                        _a.sent();
                        this.observationProvider.notifyObservers(_1.VendorFulfillmentFormActions.Saved);
                        this.hide();
                        return [3 /*break*/, 4];
                    case 3:
                        e_1 = _a.sent();
                        this.saveButton.setProcessing(false);
                        this.alert.show({
                            alertType: components_1.AlertType.Error,
                            heading: 'Unable to save',
                            message: 'An error occurred trying to save this record'
                        });
                        return [3 /*break*/, 4];
                    case 4: return [2 /*return*/];
                }
            });
        }); };
        this.observationProvider = new __1.ObservationProvider();
        this.closeButton = new button_1.default({
            onClick: this.hide,
            text: 'Close'
        });
        this.saveButton = new button_1.default({
            onClick: this.save,
            text: 'Save',
            processingText: 'Saving...'
        });
        this.historyButton = new button_1.default({
            leftIcon: components_1.Icons.Clock,
            onClick: function () { return _this.showHistoryTable(); },
            text: 'History'
        });
        this.alert = new components_1.Alert();
        this.getHotelRoomOptions()
            .then(function () {
            _this.buildForm();
        });
        this.showTransportationPaneButton = new button_1.default({
            text: 'Transportation',
            onClick: function () { return _this.setPane(_1.VendorFulfillmentFormPanes.Transportation); }
        });
        this.showHotelPaneButton = new button_1.default({
            text: 'Hotel',
            onClick: function () { return _this.setPane(_1.VendorFulfillmentFormPanes.Hotel); }
        });
    }
    Object.defineProperty(FulfillmentForm.prototype, "isLoading", {
        get: function () {
            return this._isLoading && this._configurationDataLoaded;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(FulfillmentForm.prototype, "isOpen", {
        get: function () {
            return this._isOpen;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(FulfillmentForm.prototype, "historyTableIsVisible", {
        get: function () {
            return this._historyTableIsVisible;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(FulfillmentForm.prototype, "fulfillmentData", {
        get: function () {
            return this._fulfillmentData;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(FulfillmentForm.prototype, "transportationStatusField", {
        get: function () {
            return this._transportationStatusField;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(FulfillmentForm.prototype, "hotelStatusField", {
        get: function () {
            return this._hotelStatusField;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(FulfillmentForm.prototype, "roomTypeField", {
        get: function () {
            return this._roomTypeField;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(FulfillmentForm.prototype, "hotelConfirmationNumberField", {
        get: function () {
            return this._hotelConfirmationNumberField;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(FulfillmentForm.prototype, "hotelVendorCommentsField", {
        get: function () {
            return this._HotelVendorCommentsField;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(FulfillmentForm.prototype, "form", {
        get: function () {
            return this._form;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(FulfillmentForm.prototype, "transportationVendorCommentsField", {
        get: function () {
            return this._transportationVendorCommentsField;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(FulfillmentForm.prototype, "historyTable", {
        get: function () {
            return this._historyTable;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(FulfillmentForm.prototype, "vendorFulfillmentFormPanes", {
        get: function () {
            return this._vendorFulfillmentFormPanes;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(FulfillmentForm.prototype, "contactPhone", {
        get: function () {
            if (!this._fulfillmentData) {
                return '';
            }
            if (this._fulfillmentData.contactPhone) {
                return this._fulfillmentData.contactPhone;
            }
            return this._fulfillmentData.usersContactPhone;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(FulfillmentForm.prototype, "contactEmail", {
        get: function () {
            if (!this._fulfillmentData) {
                return '';
            }
            if (this._fulfillmentData.contactEmail) {
                return this._fulfillmentData.contactEmail;
            }
            return this._fulfillmentData.usersContactEmail;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(FulfillmentForm.prototype, "isTransportationVendor", {
        get: function () {
            return this._isTransportationVendor;
        },
        enumerable: false,
        configurable: true
    });
    FulfillmentForm.prototype.setIsTransportationVendor = function (isTransportationVendor) {
        this._isTransportationVendor = isTransportationVendor;
        if (!this._transportationFields) {
            return;
        }
        for (var i = 0; i < this._transportationFields.length; i++) {
            this._transportationFields[i].isDisabled = !this._isTransportationVendor;
        }
    };
    Object.defineProperty(FulfillmentForm.prototype, "isHotelVendor", {
        get: function () {
            return this._isHotelVendor;
        },
        enumerable: false,
        configurable: true
    });
    FulfillmentForm.prototype.setIsHotelVendor = function (isHotelVendor) {
        this._isHotelVendor = isHotelVendor;
        if (!this._hotelFields) {
            return;
        }
        for (var i = 0; i < this._hotelFields.length; i++) {
            this._hotelFields[i].isDisabled = !this._isHotelVendor;
        }
    };
    return FulfillmentForm;
}());
exports.default = FulfillmentForm;
