"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var HotelConfigurationsTileActions;
(function (HotelConfigurationsTileActions) {
    HotelConfigurationsTileActions["Toggled"] = "Toggled";
    HotelConfigurationsTileActions["DataLoading"] = "DataLoading";
    HotelConfigurationsTileActions["DataLoaded"] = "DataLoaded";
    HotelConfigurationsTileActions["EditModeActivated"] = "EditModeEnabled";
    HotelConfigurationsTileActions["EditModeDeactivated"] = "EditModeDeactivated";
    HotelConfigurationsTileActions["VendorTypeChanged"] = "VendorTypeChanged";
})(HotelConfigurationsTileActions || (HotelConfigurationsTileActions = {}));
exports.default = HotelConfigurationsTileActions;
