"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var utils_1 = require("../../utils");
var icons_1 = require("../icons");
var buttonActions_1 = require("./buttonActions");
var buttonTypes_1 = require("./buttonTypes");
var HelpButton = /** @class */ (function () {
    function HelpButton(helpUrlType, text, navigator, api) {
        var _this = this;
        this.helpUrlType = helpUrlType;
        this.navigator = navigator;
        this.api = api;
        this.click = function () {
            if (!_this._isProcessing && !_this._isDisabled) {
                if (_this.processingText) {
                    _this._isProcessing = true;
                }
                _this.onClick(_this);
                _this.observationProvider.notifyObservers(buttonActions_1.default.Clicked);
            }
        };
        this.setRightIcon = function (icon) {
            _this._rightIcon = icon;
        };
        this.setLeftIcon = function (icon) {
            _this._leftIcon = icon;
        };
        this.setIsActiveState = function (isActiveState) {
            _this._isActiveState = isActiveState;
        };
        this.setDisabled = function (isDisabled) {
            _this._isDisabled = isDisabled;
            _this.observationProvider.notifyObservers(buttonActions_1.default.DisabledChanged);
        };
        this.setProcessing = function (isProcessing) {
            _this._isProcessing = isProcessing;
            _this.observationProvider.notifyObservers(buttonActions_1.default.ProcessingChanged);
        };
        this.setIsLoading = function (isLoading) {
            _this._isLoading = isLoading;
            _this.observationProvider.notifyObservers(buttonActions_1.default.LoadingChanged);
        };
        this.setVisibility = function (isVisible) {
            _this._isVisible = isVisible;
            _this.observationProvider.notifyObservers(buttonActions_1.default.VisibilityChanged);
        };
        this.setHoverText = function (text) {
            _this._hoverText = text;
            _this.observationProvider.notifyObservers(buttonActions_1.default.HoverTextChanged);
        };
        this.setText = function (text) {
            _this._text = text;
            _this.observationProvider.notifyObservers(buttonActions_1.default.TextChanged);
        };
        this.setProcessingText = function (text) {
            _this._processingText = text;
            _this.observationProvider.notifyObservers(buttonActions_1.default.ProcessingTextChanged);
        };
        this.setSubText = function (text) {
            _this._subText = text;
            _this.observationProvider.notifyObservers(buttonActions_1.default.TextChanged);
        };
        this.setBadge = function (badge) {
            _this._badge = badge;
            _this.observationProvider.notifyObservers(buttonActions_1.default.BadgeUpdated);
        };
        this.observationProvider = new utils_1.ObservationProvider();
        this._leftIcon = icons_1.Icons.Question;
        this._text = text;
        this.buttonType = buttonTypes_1.default.Anchor;
        this.onClick = function (sendingButton) { return _this.navigator.helpListModal.open(helpUrlType); };
        this.api.getHelpUrls()
            .then(function (helpUrls) {
            if (!helpUrls) {
                return;
            }
            var matchingUrl = helpUrls.find(function (url) { return !!url && url.helpUrlType === helpUrlType && url.name; });
            if (matchingUrl && matchingUrl.pdfUrl) {
                _this.setVisibility(true);
                _this.setHoverText(matchingUrl.hoverText);
            }
            else {
                _this.setVisibility(false);
            }
        });
    }
    Object.defineProperty(HelpButton.prototype, "isVisible", {
        get: function () {
            return this._isVisible;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(HelpButton.prototype, "isProcessing", {
        get: function () {
            return this._isProcessing;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(HelpButton.prototype, "isDisabled", {
        get: function () {
            return this._isDisabled;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(HelpButton.prototype, "isLoading", {
        get: function () {
            return this._isLoading;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(HelpButton.prototype, "text", {
        get: function () {
            return this._text;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(HelpButton.prototype, "isActiveState", {
        get: function () {
            return this._isActiveState;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(HelpButton.prototype, "leftIcon", {
        get: function () {
            return this._leftIcon;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(HelpButton.prototype, "rightIcon", {
        get: function () {
            return this._rightIcon;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(HelpButton.prototype, "subText", {
        get: function () {
            return this._subText;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(HelpButton.prototype, "processingText", {
        get: function () {
            return this._processingText;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(HelpButton.prototype, "badge", {
        get: function () {
            return this._badge;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(HelpButton.prototype, "hoverText", {
        get: function () {
            return this._hoverText;
        },
        enumerable: false,
        configurable: true
    });
    return HelpButton;
}());
exports.default = HelpButton;
