"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g = Object.create((typeof Iterator === "function" ? Iterator : Object).prototype);
    return g.next = verb(0), g["throw"] = verb(1), g["return"] = verb(2), typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
var __1 = require("../../../../..");
var button_1 = require("../../../../../components/button/button");
var components_1 = require("../../../../../components");
var EventInfoForm = /** @class */ (function () {
    function EventInfoForm(api) {
        var _this = this;
        this.api = api;
        this.exhibitSpaceAdded = function () {
            if (_this.eventExhibitSpacesDataList.items.length === 1 && !_this.eventExhibitSpacesDataList.items[0].form.model.id) {
                _this.eventExhibitSpacesDataList.items[0].form.getFieldByKey('isPrimary').setValue(true);
            }
        };
        this.pirmaryExhibitFieldChanged = function (field) {
            if (_this._isPrimaryExhibitSpaceChanging) {
                return;
            }
            _this._isPrimaryExhibitSpaceChanging = true;
            for (var _i = 0, _a = _this.eventExhibitSpacesDataList.items; _i < _a.length; _i++) {
                var exhibitSpaceItem = _a[_i];
                exhibitSpaceItem.form.submit();
                if (exhibitSpaceItem.form.getFieldByKey('isPrimary') !== field) {
                    exhibitSpaceItem.form.setModel(__assign(__assign({}, exhibitSpaceItem.form.model), { isPrimary: false }));
                }
            }
            field.setValue(true);
            _this._isPrimaryExhibitSpaceChanging = false;
        };
        this.exhibitSpaceRemoved = function (item) {
        };
        this.addExhibitSpace = function () {
            _this.eventExhibitSpacesDataList.addModel({ isDeletable: true });
        };
        this.setModel = function (eventInfo) {
            _this._editForm.setModel(__assign({}, eventInfo));
            _this.webLinksDataList.setModels(eventInfo.webLinks);
            _this.eventLeadDataList.setModels(eventInfo.eventLeads);
            _this.eventExhibitSpacesDataList.setModels(eventInfo.exhibitSpaces);
            _this.observationProvider.notifyObservers(__1.EventInfoFormActions.ModelSet);
        };
        this.addEventLead = function () {
            var _loop_1 = function (selectedUser) {
                var matchingLeadIndex = _this.eventLeadDataList.findIndex(function (eventLead) { return eventLead.userId === selectedUser.id; });
                if (matchingLeadIndex >= 0) {
                    __1.ArrayUtils.move(_this.eventLeadDataList.items, matchingLeadIndex, 0);
                    return "continue";
                }
                _this.eventLeadDataList.addModel({
                    fullName: selectedUser.fullName,
                    userId: selectedUser.id
                });
            };
            for (var _i = 0, _a = _this.addEventLeadSearchField.selectedResults; _i < _a.length; _i++) {
                var selectedUser = _a[_i];
                _loop_1(selectedUser);
            }
            _this.addEventLeadSearchField.clearSearchResults();
            _this.addEventLeadSearchField.clearSelected();
            _this.observationProvider.notifyObservers(__1.EventInfoFormActions.EventLeadAdded);
        };
        this.buildForm = function () {
            var eventTypeField = new __1.EventTypeDropdown('eventType', true);
            eventTypeField.setVisibility(false);
            var fields = [
                new __1.TextField('name', 'Event Name', true, 250),
                eventTypeField,
                new __1.CheckBoxField('isInternational', 'International Event'),
                new __1.TimezoneField('timezone', true),
                new __1.TextField('address', 'Address', false, 250),
                new __1.TextField('city', 'City', true, 250, 'City is required'),
                new __1.TextField('state', 'State', false, 250),
                new __1.TextField('zipCode', 'Zip Code', false, 10),
                new __1.TextField('country', 'Country', true, 250, 'Country is required'),
                new __1.DateField('startDate', 'Start Date', true),
                new __1.DateField('endDate', 'End Date', true),
                new __1.TextField('exhibitStartTime', 'Exhibit Start Time', false, 100),
                new __1.TextField('exhibitEndTime', 'Exhibit End Time', false, 100),
                new __1.EventProducerSearchField(_this.api, 'Event Producer', true, 'Event producer is required', false, 'eventProducerId', 'eventProducerName'),
                new __1.BusinessAreaDropdown(_this.api, 'leadBusinessAreaId', 'Lead BA', true, 'Lead business area is required'),
                new __1.TextField('notesComments', 'Strategy / Objective', false, 1000, null, '', __1.FieldType.TextArea),
                // new TextField('strategyComments', 'Strategy', false, 1000, null, '', FieldType.TextArea),
                // new TextField('objectiveComments', 'Objective', false, 1000, null, '', FieldType.TextArea),
            ];
            var defaultModel = {
                eventType: __1.EventTypes.Tradeshow
            };
            _this._editForm = new __1.Form(fields, defaultModel);
        };
        this.submit = function () { return __awaiter(_this, void 0, void 0, function () {
            var eventId;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!this.formsAreValid()) {
                            return [2 /*return*/];
                        }
                        this._editForm.submit();
                        this._editForm.model.webLinks = this.webLinksDataList.getModels();
                        this._editForm.model.eventLeads = this.eventLeadDataList.getModels();
                        this._editForm.model.exhibitSpaces = this.eventExhibitSpacesDataList.getModels();
                        if (!this._editForm.model.id) return [3 /*break*/, 2];
                        return [4 /*yield*/, this.api.post("".concat(__1.EndpointUrls.Events, "/SaveEventInfoTileData"), this._editForm.model)];
                    case 1:
                        _a.sent();
                        this.observationProvider.notifyObservers(__1.EventInfoFormActions.FormSaved);
                        return [3 /*break*/, 4];
                    case 2: return [4 /*yield*/, this.api.post("".concat(__1.EndpointUrls.Events, "/CreateNewEvent"), this._editForm.model)];
                    case 3:
                        eventId = _a.sent();
                        this.observationProvider.notifyObservers(__1.EventInfoFormActions.FormSaved, eventId);
                        _a.label = 4;
                    case 4:
                        this.observationProvider.notifyObservers(__1.EventInfoFormActions.Submitted);
                        return [2 /*return*/];
                }
            });
        }); };
        this.formsAreValid = function () {
            var isValid = true;
            isValid = isValid && _this.webLinksDataList.areAllValid();
            isValid = isValid && _this.eventLeadDataList.areAllValid();
            isValid = isValid && _this._editForm.isValid();
            isValid = isValid && _this.eventExhibitSpacesDataList.areAllValid();
            return isValid;
        };
        this.observationProvider = new __1.ObservationProvider();
        this.addEventLeadSearchField = new __1.UserSearchField(api, 'Event Lead', false, null, true);
        this.webLinksDataList = new __1.DataList(function () { return [
            new __1.TextField('text', 'Text', true, 250, 'Web Link text required'),
            new __1.TextField('url', 'URL', true, 2000, 'Web Link URL is required')
        ]; });
        this.eventLeadDataList = new __1.DataList(function () { return [
            new __1.EventLeadResponsibilityField(api, 'eventLeadResponsibilityId', 'Responsibility', true)
        ]; }, true, 'Add Event Lead', 'No Event Leads');
        this.eventExhibitSpacesDataList = new __1.DataList(function () { return [
            new __1.TextField('name', 'Full Name', true, 250, 'required'),
            new __1.TextField('shortName', 'Short Name', true, 100, 'required'),
            new __1.TextField('standNumber', 'Stand#', false, 100, 'required'),
            new components_1.IntField('totalFloorSpace', 'Total Floor Space Sq. Ft.', true, 'required', null, null, 1),
            new __1.TextField('description', 'Description', false, 500),
            _this.buildExhibitSpaceIsPrimaryCheckbox(),
        ]; }, true, 'Add Exhibit Space', 'No exhibit spaces assigned');
        this.eventExhibitSpacesDataList.observationProvider.observe(this.exhibitSpaceAdded, components_1.DataListActions.RecordAdded);
        this.eventExhibitSpacesDataList.observationProvider.observe(this.exhibitSpaceRemoved, components_1.DataListActions.RecordRemoved);
        this.addWebLinkButton = new button_1.default({
            leftIcon: __1.Icons.Plus,
            text: 'Add Link',
            onClick: this.webLinksDataList.addItem
        });
        this.addEventLeadButton = new button_1.default({
            leftIcon: __1.Icons.Plus,
            text: 'Add Lead',
            onClick: this.addEventLead
        });
        this.addExhibitSpaceButton = new button_1.default({
            leftIcon: __1.Icons.Plus,
            text: 'Add Exhibit Space',
            onClick: this.addExhibitSpace
        });
        this.buildForm();
    }
    Object.defineProperty(EventInfoForm.prototype, "editForm", {
        get: function () {
            return this._editForm;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(EventInfoForm.prototype, "currentEventDateTime", {
        get: function () {
            return this._currentEventDateTime;
        },
        enumerable: false,
        configurable: true
    });
    EventInfoForm.prototype.buildExhibitSpaceIsPrimaryCheckbox = function () {
        var _this = this;
        var isPrimaryCheckbox = new __1.CheckBoxField('isPrimary', 'Primary Space');
        isPrimaryCheckbox.observationProvider.observe(function () { return _this.pirmaryExhibitFieldChanged(isPrimaryCheckbox); }, components_1.FieldActions.ValueChanged);
        return isPrimaryCheckbox;
    };
    return EventInfoForm;
}());
exports.default = EventInfoForm;
