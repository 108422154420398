import { h, JSX } from 'preact';
import ObservingComponent from '../componentBases/observingComponent';
import VendorUserDetailPageProps from './vendorUserDetailPageProps';
import Button from '../buttons/button';
import Field from '../fields/field';
import { VendorUserDetailPageActions } from 'core.frontend';
import ButtonColors from '../buttons/buttonColors';
import Alert from '../alerts/alert';
import FormButtonContainer from '../containers/formButtonContainer';
import ConfirmationPrompt from '../containers/confirmationPrompt';
import InternationalPhoneField from '../fields/internationalPhoneField';

class VendorUserDetailPage extends ObservingComponent<VendorUserDetailPageProps> {

    public componentWillMount(): void {
        const { page } = this.props;

        this.registerUpdateObserver(page.observationProvider, [
            VendorUserDetailPageActions.RecordLoaded,
            VendorUserDetailPageActions.Unlocking,
            VendorUserDetailPageActions.Unlocked
        ]);
    }

    public render({ page }: VendorUserDetailPageProps): JSX.Element {
        return (
            <div>
                <ConfirmationPrompt confirmationPrompt={page.confirmationPrompt} />
                <Alert alert={page.alert} />

                <div>
                    <div class="page-heading">
                        <h3>
                            {page.title}
                        </h3>
                        <div class="btn-container flex">

                            <Button
                                className="btn-icon btn-lg"
                                button={page.helpButton} />

                            <Button
                                className="btn-icon"
                                color={ButtonColors.Secondary}
                                button={page.resetPasswordButton} />

                            <Button
                                color={ButtonColors.Secondary}
                                className="ml-1 btn-icon"
                                button={page.historyButton} />
                        </div>
                    </div>

                    <div class="form-container">
                        {page.form.fields.map((field) => {

                            if (field.key == 'phone') {
                                <InternationalPhoneField
                                    field={field}
                                    className='stacked-label'
                                    isLoading={page.isLoading} />
                            } else {
                                <Field
                                    className="stacked-label"
                                    field={field}
                                    isLoading={page.isLoading} />
                            }
                            }
                        )}
                    </div>

                    <FormButtonContainer
                        leftButtons={[
                            <Button
                                color={ButtonColors.Secondary}
                                className="btn-icon v-center"
                                button={page.deactivateButton} />
                        ]}
                        rightButtons={[
                            <Button
                                color={ButtonColors.Secondary}
                                button={page.cancelButton} />,
                            <Button
                                color={ButtonColors.Primary}
                                button={page.saveButton} />
                        ]} />
                </div>
            </div>
        );
    }
}

export default VendorUserDetailPage;
