"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var TravelAndAccommodationTilePanes;
(function (TravelAndAccommodationTilePanes) {
    TravelAndAccommodationTilePanes[TravelAndAccommodationTilePanes["AttendeeInfo"] = 0] = "AttendeeInfo";
    TravelAndAccommodationTilePanes[TravelAndAccommodationTilePanes["Arrival"] = 1] = "Arrival";
    TravelAndAccommodationTilePanes[TravelAndAccommodationTilePanes["Departure"] = 2] = "Departure";
    TravelAndAccommodationTilePanes[TravelAndAccommodationTilePanes["DailyTransport"] = 3] = "DailyTransport";
    TravelAndAccommodationTilePanes[TravelAndAccommodationTilePanes["HotelInfo"] = 4] = "HotelInfo";
    TravelAndAccommodationTilePanes[TravelAndAccommodationTilePanes["Completed"] = 5] = "Completed";
})(TravelAndAccommodationTilePanes || (TravelAndAccommodationTilePanes = {}));
exports.default = TravelAndAccommodationTilePanes;
