"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var TrasportationReportTransportType;
(function (TrasportationReportTransportType) {
    TrasportationReportTransportType[TrasportationReportTransportType["All"] = 1] = "All";
    TrasportationReportTransportType[TrasportationReportTransportType["CompanyProvided"] = 2] = "CompanyProvided";
    TrasportationReportTransportType[TrasportationReportTransportType["Individual"] = 3] = "Individual";
    TrasportationReportTransportType[TrasportationReportTransportType["Other"] = 4] = "Other";
    TrasportationReportTransportType[TrasportationReportTransportType["AirportToHotel"] = 5] = "AirportToHotel";
    TrasportationReportTransportType[TrasportationReportTransportType["HotelToAirport"] = 6] = "HotelToAirport";
})(TrasportationReportTransportType || (TrasportationReportTransportType = {}));
exports.default = TrasportationReportTransportType;
