"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var CompleteAccessRequestPageActions;
(function (CompleteAccessRequestPageActions) {
    CompleteAccessRequestPageActions["FormInvalid"] = "FormInvalid";
    CompleteAccessRequestPageActions["FormSubmitted"] = "FormSubmitted";
    CompleteAccessRequestPageActions["ErrorSubmitting"] = "ErrorSubmitting";
    CompleteAccessRequestPageActions["SubmittedSuccessfully"] = "SubmittedSuccessfully";
    CompleteAccessRequestPageActions["FormLoaded"] = "FormLoaded";
    CompleteAccessRequestPageActions["InvalidAccessRequest"] = "InvalidAccessRequest";
})(CompleteAccessRequestPageActions || (CompleteAccessRequestPageActions = {}));
exports.default = CompleteAccessRequestPageActions;
