"use strict";
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
Object.defineProperty(exports, "__esModule", { value: true });
var ArrayUtils = /** @class */ (function () {
    function ArrayUtils() {
    }
    ArrayUtils.arrayify = function (obj) {
        if (!Array.isArray(obj)) {
            return [obj];
        }
        return obj;
    };
    ArrayUtils.groupBy = function (groupOptions, array, sortKey, headingSortKey) {
        var groupOptionArray = __spreadArray([], ArrayUtils.arrayify(groupOptions), true);
        // if no group keys
        if (!groupOptionArray || groupOptionArray.length === 0) {
            if (sortKey) {
                array = ArrayUtils.sortBy(array, sortKey);
            }
            return [{ groupKeyValue: '', groupHeading: '', data: array }];
        }
        var groupKey = groupOptionArray[0].key;
        var defaultHeading = groupOptionArray[0].defaultHeading;
        if (headingSortKey) {
            array = ArrayUtils.sortBy(array, headingSortKey);
        }
        else {
            array = ArrayUtils.sortBy(array, groupKey);
        }
        var arrayGroups = [];
        array.map(function (item, index) {
            if (index === 0 ||
                (arrayGroups[arrayGroups.length - 1].groupKeyValue !== item[groupKey] &&
                    !(arrayGroups[arrayGroups.length - 1].groupHeading === defaultHeading && !item[groupKey]))) {
                var heading = item[groupKey];
                if (!heading) {
                    heading = defaultHeading;
                }
                if (groupOptionArray[0].headingBuilder) {
                    heading = groupOptionArray[0].headingBuilder(item, array.filter(function (x) { return x[groupKey] === item[groupKey]; }));
                }
                arrayGroups.push({
                    groupKeyValue: item[groupKey],
                    groupHeading: heading,
                    data: [item]
                });
            }
            else {
                arrayGroups[arrayGroups.length - 1].data.push(item);
            }
        });
        if (groupOptionArray.length > 1) {
            // remove key that was just grouped
            groupOptionArray.splice(0, 1);
            arrayGroups.map(function (group) {
                group.subGroup = ArrayUtils.groupBy(groupOptionArray, group.data, sortKey);
            });
        }
        else if (sortKey) {
            // sort last group
            for (var i = 0; i < arrayGroups.length; i++) {
                arrayGroups[i].data = ArrayUtils.sortBy(arrayGroups[i].data, sortKey);
            }
        }
        return arrayGroups;
    };
    ArrayUtils.sortBy = function (array, sortKey) {
        return __spreadArray([], array.sort(function (a, b) {
            if (a[sortKey] < b[sortKey]) {
                return -1;
            }
            if (a[sortKey] > b[sortKey]) {
                return 1;
            }
            return 0;
        }), true);
    };
    ArrayUtils.move = function (array, oldIndex, newIndex) {
        while (oldIndex < 0) {
            oldIndex += array.length;
        }
        while (newIndex < 0) {
            newIndex += array.length;
        }
        if (newIndex >= array.length) {
            var k = newIndex - array.length + 1;
            while (k--) {
                array.push(undefined);
            }
        }
        array.splice(newIndex, 0, array.splice(oldIndex, 1)[0]);
    };
    return ArrayUtils;
}());
exports.default = ArrayUtils;
