"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var EventLandingTileActions;
(function (EventLandingTileActions) {
    EventLandingTileActions["Toggled"] = "Toggled";
    EventLandingTileActions["DataLoading"] = "DataLoading";
    EventLandingTileActions["DataLoaded"] = "DataLoaded";
    EventLandingTileActions["EditModeActivated"] = "EditModeEnabled";
    EventLandingTileActions["EditModeDeactivated"] = "EditModeDeactivated";
    EventLandingTileActions["VisibilityToggled"] = "VisibilityToggled";
    EventLandingTileActions["IsEditableChanged"] = "IsEditableChanged";
    EventLandingTileActions["FullScreenChanged"] = "FullScreenChanged";
    EventLandingTileActions["EditFormSaving"] = "EditFormSaving";
    EventLandingTileActions["TitleChanged"] = "TitleChanged";
})(EventLandingTileActions || (EventLandingTileActions = {}));
exports.default = EventLandingTileActions;
