"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
var _1 = require(".");
var utils_1 = require("../../utils");
var baseField_1 = require("./baseField");
var fieldActions_1 = require("./fieldActions");
var __1 = require("../..");
var calendar_1 = require("../calendar");
var DateField = /** @class */ (function (_super) {
    __extends(DateField, _super);
    function DateField(key, label, isRequired, requiredValidationMessage, initialValue, defaultFocusDate) {
        var _this = _super.call(this, _1.FieldType.Date, key, label, isRequired, 10, requiredValidationMessage) || this;
        _this.key = key;
        _this.defaultFocusDate = defaultFocusDate;
        _this.calendarDateSelected = function (date) {
            _this.setValue(date);
        };
        _this.focused = function () {
            _this.calendar.show(_this.getCalendarViewDate());
        };
        _this.blurred = function () {
            if (!_this.calendar.isFocused) {
                _this.calendar.hide();
            }
        };
        _this.getCalendarViewDate = function () {
            if (_this.value) {
                return _this.value;
            }
            else if (_this.defaultFocusDate) {
                return _this.defaultFocusDate;
            }
            return new Date();
        };
        _this.calendar = new __1.Calendar();
        _this.calendar.observationProvider.observe(_this.calendarDateSelected, calendar_1.CalendarActions.DateSelected);
        if (initialValue) {
            _this.setValue(initialValue);
        }
        _this.setPlaceholderLabel('mm/dd/yyyy');
        _this._invalidMessage = "Date is invalid ".concat(_this.placeholderLabel);
        _this.observationProvider.observe(_this.focused, fieldActions_1.default.Focus);
        _this.observationProvider.observe(_this.blurred, fieldActions_1.default.Blured);
        return _this;
    }
    Object.defineProperty(DateField.prototype, "errorMessage", {
        get: function () {
            var baseErrorMessage = _super.prototype.getErrorMessage.call(this);
            if (baseErrorMessage) {
                return baseErrorMessage;
            }
            if (this.stringValue && !this.isValidDate() && this.isDirty) {
                return this._invalidMessage;
            }
            return baseErrorMessage;
        },
        enumerable: false,
        configurable: true
    });
    DateField.prototype.isValidDate = function () {
        return utils_1.DateUtils.isValid(this.stringValue.toString(), utils_1.DateFormats.MMDDYYYY);
    };
    DateField.prototype.isValid = function () {
        var isValid = true;
        if (!this.checkRequiredStatus()) {
            isValid = false;
        }
        if (this.stringValue && !this.isValidDate()) {
            isValid = false;
        }
        this.setIsValid(isValid);
        return isValid;
    };
    DateField.prototype.setValue = function (newValue) {
        if (!newValue) {
            this.setStringValue('');
        }
        else if (newValue.toString().length <= 10) {
            this.setStringValue(this.formatDatePartial(newValue.toString()));
        }
        else {
            this.setStringValue(utils_1.DateUtils.formatDate(newValue));
        }
        if (this.calendar.visible) {
            this.calendar.hide();
        }
    };
    DateField.prototype.formatDatePartial = function (dateString) {
        var formattedStr = '';
        for (var i = 0; i < dateString.length; i++) {
            var char = dateString.charAt(i);
            if ('0123456789/'.indexOf(char) !== -1) {
                if (char === '/') {
                    if (formattedStr.length === 1) {
                        formattedStr = '0' + formattedStr + char;
                    }
                    if (formattedStr.length === 4) {
                        var lastNumber = formattedStr.substring(formattedStr.length - 1);
                        formattedStr = formattedStr.substring(0, formattedStr.length - 1);
                        formattedStr = formattedStr + '0' + lastNumber + char;
                    }
                }
                else {
                    if (formattedStr.length === 2 || formattedStr.length === 5) {
                        formattedStr += '/';
                    }
                    formattedStr += char;
                }
            }
        }
        return formattedStr;
    };
    Object.defineProperty(DateField.prototype, "value", {
        get: function () {
            if (!this.stringValue === null || !this.isValidDate()) {
                return null;
            }
            //return new Date(this.stringValue);
            return this.stringValue;
        },
        enumerable: false,
        configurable: true
    });
    return DateField;
}(baseField_1.default));
exports.default = DateField;
