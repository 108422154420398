"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var ReportForType;
(function (ReportForType) {
    ReportForType[ReportForType["All"] = 1] = "All";
    ReportForType[ReportForType["SpecificAttendees"] = 2] = "SpecificAttendees";
    ReportForType[ReportForType["BusienssArea"] = 3] = "BusienssArea";
    ReportForType[ReportForType["Department"] = 4] = "Department";
    ReportForType[ReportForType["MeetingCategory"] = 5] = "MeetingCategory";
    ReportForType[ReportForType["Venue"] = 6] = "Venue";
    ReportForType[ReportForType["ScheduledBy"] = 7] = "ScheduledBy";
})(ReportForType || (ReportForType = {}));
exports.default = ReportForType;
