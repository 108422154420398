"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g = Object.create((typeof Iterator === "function" ? Iterator : Object).prototype);
    return g.next = verb(0), g["throw"] = verb(1), g["return"] = verb(2), typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
var __1 = require("../..");
var button_1 = require("../../components/button/button");
var eventHistory_1 = require("../eventHistory");
var components_1 = require("../../components");
var helpUrls_1 = require("../helpUrls");
var helpButton_1 = require("../../components/button/helpButton");
var CustomerDetailPage = /** @class */ (function (_super) {
    __extends(CustomerDetailPage, _super);
    function CustomerDetailPage(api, navigator, currentUserManager, customerId) {
        var _this = _super.call(this, __1.PageTypes.CustomerDetail, 'Customer Detail') || this;
        _this.api = api;
        _this.navigator = navigator;
        _this.currentUserManager = currentUserManager;
        _this.customerId = customerId;
        _this.loadCustomer = function (customerId) { return __awaiter(_this, void 0, void 0, function () {
            var customer, _a;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        this._isLoading = true;
                        this.observationProvider.notifyObservers(__1.CustomerDetailPageActions.RecordLoading);
                        _b.label = 1;
                    case 1:
                        _b.trys.push([1, 3, , 4]);
                        return [4 /*yield*/, this.api.getById(__1.EndpointUrls.Customers, customerId)];
                    case 2:
                        customer = _b.sent();
                        this.form.setModel(customer);
                        this._isLoading = false;
                        if (!this.form.model.isActive) {
                            this.showInactiveAlert();
                        }
                        this.deactivateButton.setVisibility(this.isDeactivatable);
                        this.observationProvider.notifyObservers(__1.CustomerDetailPageActions.RecordLoaded);
                        return [3 /*break*/, 4];
                    case 3:
                        _a = _b.sent();
                        this._isLoading = false;
                        this.observationProvider.notifyObservers(__1.CustomerDetailPageActions.RecordLoadFailure, 'Unable to load record for edit');
                        return [3 /*break*/, 4];
                    case 4: return [2 /*return*/];
                }
            });
        }); };
        _this.formSubmitted = function () { return __awaiter(_this, void 0, void 0, function () {
            var err_1, _a, err_2;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        this.saveButton.setProcessing(true);
                        if (this._customerCompanySearchField.newCompanyNameField.value) {
                            this._form.model.newCustomerCompanyName = this._customerCompanySearchField.newCompanyNameField.value;
                            this._form.model.customerCompanyId = null;
                        }
                        else {
                            this._form.model.customerCompanyId = this._customerCompanySearchField.selectedResults[0].id;
                            delete this._form.model.newCustomerCompanyName;
                        }
                        if (!this.customerId) return [3 /*break*/, 5];
                        _b.label = 1;
                    case 1:
                        _b.trys.push([1, 3, , 4]);
                        return [4 /*yield*/, this.api.put(__1.EndpointUrls.Customers, this._form.model.id, this._form.model)];
                    case 2:
                        _b.sent();
                        this.observationProvider.notifyObservers(__1.CustomerDetailPageActions.RecordEditing, this.form.model);
                        this.observationProvider.notifyObservers(__1.CustomerDetailPageActions.RecordEdited, this.form.model);
                        return [3 /*break*/, 4];
                    case 3:
                        err_1 = _b.sent();
                        this.handleError(err_1);
                        return [2 /*return*/];
                    case 4: return [3 /*break*/, 8];
                    case 5:
                        _b.trys.push([5, 7, , 8]);
                        _a = this.form.model;
                        return [4 /*yield*/, this.api.post(__1.EndpointUrls.Customers, this._form.model)];
                    case 6:
                        _a.id = _b.sent();
                        this.observationProvider.notifyObservers(__1.CustomerDetailPageActions.RecordAdding, this.form.model);
                        this.observationProvider.notifyObservers(__1.CustomerDetailPageActions.RecordAdded, this._form.model);
                        return [3 /*break*/, 8];
                    case 7:
                        err_2 = _b.sent();
                        this.handleError(err_2);
                        return [2 /*return*/];
                    case 8:
                        this.navigator.goToCustomerList();
                        return [2 /*return*/];
                }
            });
        }); };
        _this.handleError = function (err) {
            _this.saveButton.setProcessing(false);
            _this.observationProvider.notifyObservers(__1.CustomerDetailPageActions.SubmissionError);
        };
        _this.cancel = function () {
            _this.observationProvider.notifyObservers(__1.CustomerDetailPageActions.Cancel);
            _this.navigator.goToCustomerList();
        };
        _this.deactivateCustomer = function () { return __awaiter(_this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!this.isDeactivatable) {
                            throw new Error('Cannot deactivate because the customerId is not specified');
                        }
                        this._form.model.isActive = false;
                        return [4 /*yield*/, this.api.put(__1.EndpointUrls.Customers + '/deactivate', this.customerId, null)];
                    case 1:
                        _a.sent();
                        this.observationProvider.notifyObservers(__1.CustomerDetailPageActions.RecordDeactivated, this._form.model);
                        this.navigator.goToCustomerList();
                        return [2 /*return*/];
                }
            });
        }); };
        _this.activateCustomer = function () { return __awaiter(_this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.saveButton.setProcessing(true);
                        this.alert.button.setProcessing(true);
                        this._form.model.isActive = true;
                        return [4 /*yield*/, this.api.put(__1.EndpointUrls.Customers + '/activate', this.customerId, null)];
                    case 1:
                        _a.sent();
                        this.observationProvider.notifyObservers(__1.CustomerDetailPageActions.RecordActivated, this._form.model);
                        this.navigator.goToCustomerList();
                        return [2 /*return*/];
                }
            });
        }); };
        _this.showInactiveAlert = function () {
            _this.alert.show({
                alertType: __1.AlertType.Error,
                heading: 'Customer Inactive',
                message: 'this customer record is not active. Activating this record will also activate the associated customer company.',
                button: new button_1.default({
                    onClick: _this.activateCustomer,
                    processingText: 'Activating...',
                    text: 'Activate'
                })
            });
        };
        _this.helpButton = new helpButton_1.default(helpUrls_1.HelpUrlType.CustomerDetailPage, null, navigator, api);
        _this.buildForm();
        _this.observationProvider = new __1.ObservationProvider();
        _this.confirmationPrompt = new __1.ConfirmationPrompt(!!customerId, 'Deactivate Customer', 'Deactivating customers will exclude the customer from dropdown options throughout the system. Are you sure you want to deactivate this user account?', 'Deactivate', 'Cancel');
        _this.confirmationPrompt.observationProvider.observe(_this.deactivateCustomer, components_1.ConfirmationPromptActions.Confirmed);
        _this.deactivateButton = new button_1.default({
            isVisible: false,
            onClick: _this.confirmationPrompt.show,
            leftIcon: __1.Icons.Trash
        });
        _this.cancelButton = new button_1.default({
            onClick: _this.cancel,
            text: 'Cancel',
            isLoading: _this._isLoading
        });
        _this.saveButton = new button_1.default({
            buttonType: __1.ButtonTypes.Submit,
            onClick: _this.form.submit,
            text: 'Save',
            processingText: 'Saving...',
            isLoading: _this._isLoading,
        });
        _this.eventHistoryButton = new button_1.default({
            isVisible: false,
            onClick: function () { navigator.goToEventHistoryPage({ eventHistoryType: eventHistory_1.EventHistoryType.Customers, recordId: _this.customerId }); },
            leftIcon: __1.Icons.Calendar
        });
        if (customerId) {
            _this.loadCustomer(customerId);
            _this.eventHistoryButton.setVisibility(true);
            _this.title = 'Edit Customer';
        }
        return _this;
    }
    Object.defineProperty(CustomerDetailPage.prototype, "form", {
        get: function () {
            return this._form;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(CustomerDetailPage.prototype, "isLoading", {
        get: function () {
            return this._isLoading;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(CustomerDetailPage.prototype, "isApp", {
        get: function () {
            return this.currentUserManager.clientChecker.isMobileApp();
        },
        enumerable: false,
        configurable: true
    });
    CustomerDetailPage.prototype.buildForm = function () {
        this._customerCompanySearchField = new components_1.CustomerCompanySearchField(this.api, 'Company', true, 'customerCompanyId', 'customerCompanyName', 'required', false);
        var fields = [
            new components_1.CheckBoxField('isActive', 'Is Active', false, null, true),
            new __1.TextField('rank', 'Rank/Position', false, 250),
            new __1.TextField('title', 'Title', false, 250),
            new __1.TextField('firstName', 'First Name', true, 100, 'First name is required'),
            new __1.TextField('lastName', 'Last Name', true, 100, 'Last name is required'),
            this._customerCompanySearchField,
            new __1.EmailField('email', false),
            new __1.TextField('phone', 'Mobile Phone', false, 100),
            new __1.TextField('comments', 'Comments', false, 500, null, null, __1.FieldType.TextArea)
        ];
        this._form = new __1.Form(fields);
        this._form.setModel({ isActive: true });
        this._form.observationProvider.observe(this.formSubmitted, __1.FormActions.FormSubmitted);
    };
    Object.defineProperty(CustomerDetailPage.prototype, "isDeactivatable", {
        get: function () {
            return !!this.customerId && this._form && this._form.model.isActive;
        },
        enumerable: false,
        configurable: true
    });
    return CustomerDetailPage;
}(__1.BasePage));
exports.default = CustomerDetailPage;
