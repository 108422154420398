"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
var utils_1 = require("../../utils");
var _1 = require(".");
var __1 = require("..");
var button_1 = require("../button/button");
var alert_1 = require("../alert");
var icons_1 = require("../icons");
var DataList = /** @class */ (function () {
    function DataList(_fieldBuilder, _isEditable, _addButtonText, emptyStateMessage, emptyStateHeading, isSortable) {
        if (_isEditable === void 0) { _isEditable = true; }
        if (_addButtonText === void 0) { _addButtonText = 'Add Item'; }
        if (emptyStateHeading === void 0) { emptyStateHeading = ''; }
        var _this = this;
        this._fieldBuilder = _fieldBuilder;
        this._isEditable = _isEditable;
        this.emptyStateMessage = emptyStateMessage;
        this.emptyStateHeading = emptyStateHeading;
        this.isSortable = isSortable;
        this.getEmptyStateIsVisible = function () {
            return !_this._isLoading && _this._dataListItems.filter(function (item) { return item.isVislble; }).length === 0;
        };
        this.addItem = function () {
            _this.addModel({ isDeletable: true });
        };
        this.addModel = function (record, forceAdd) {
            if (forceAdd === void 0) { forceAdd = false; }
            if (!forceAdd &&
                _this._dataListItems[0] &&
                _this._dataListItems[0].form.fields.length > 0 &&
                !_this._dataListItems[0].form.fields[0].value) {
                return;
            }
            var newForm = new __1.Form(_this._fieldBuilder());
            newForm.setModel(record);
            if (!_this._isEditable) {
                newForm.isDisabled = true;
            }
            var newItem = {
                form: newForm,
                alert: new __1.Alert(),
                isVislble: true
            };
            _this._dataListItems.unshift(newItem);
            _this.observationProvider.notifyObservers(_1.DataListActions.RecordAdded);
        };
        this.setItemVisibility = function (index, isVisible) {
            _this._dataListItems[index].isVislble = isVisible;
            _this.observationProvider.notifyObservers(_1.DataListActions.ItemVisibilityChanged, _this._dataListItems[index]);
        };
        this.removeItem = function (item, forceRemove) {
            if (forceRemove === void 0) { forceRemove = false; }
            return function () {
                if (forceRemove || !item.form.model.id) {
                    _this._dataListItems.splice(_this.getItemIndex(item), 1);
                }
                else {
                    item.form.model.isRemoved = true;
                }
                _this.observationProvider.notifyObservers(_1.DataListActions.RecordRemoved, item);
            };
        };
        this.cancelRemoveItem = function (item) { return function () {
            item.form.model.isRemoved = false;
            _this.observationProvider.notifyObservers(_1.DataListActions.CancelRecordRemove);
        }; };
        this.areAllValid = function () {
            var isValid = true;
            for (var _i = 0, _a = _this._dataListItems; _i < _a.length; _i++) {
                var item = _a[_i];
                if (!item.isVislble) {
                    continue;
                }
                item.form.submit();
                isValid = isValid && item.form.isValid();
            }
            return isValid;
        };
        this.getModels = function () {
            return _this._dataListItems.filter(function (item) { return item.isVislble; }).map(function (item) {
                item.form.submit();
                return item.form.model;
            });
        };
        this.setModels = function (models) {
            _this._isLoading = true;
            _this._dataListItems = [];
            // addModel adds the next item to the top.
            // Reverse order maintains the original order.
            for (var i = models.length - 1; i >= 0; i--) {
                var model = models[i];
                _this.addModel(__assign({}, model), true);
            }
            _this._isLoading = false;
        };
        this.exists = function (predicate) {
            return _this._dataListItems.findIndex(function (item) { return predicate(item.form.model); }) >= 0;
        };
        this.findIndex = function (predicate) {
            return _this._dataListItems.findIndex(function (item) {
                item.form.submit();
                return predicate(item.form.model);
            });
        };
        this.getItemIndex = function (item) {
            return _this._dataListItems.findIndex(function (listItem) { return listItem.form.model.id == item.form.model.id; });
        };
        this.showReferenceDeleteAlert = function (item) { return function () {
            item.alert.show({
                alertType: alert_1.AlertType.Error,
                heading: 'Unable to Delete',
                message: 'This record has the following references which must be removed before deleting.',
                button: new button_1.default({
                    text: 'Close',
                    onClick: _this.hideReferenceDeleteAlert(item)
                })
            });
        }; };
        this.hideReferenceDeleteAlert = function (item) { return function () {
            item.alert.hide();
        }; };
        this.buildDeleteButton = function (item) {
            var btn;
            if (!_this._isEditable || _this._deleteButtonIsHidden) {
                return null;
            }
            if (item.form.model.isDeletable === false) {
                btn = new button_1.default({
                    onClick: _this.showReferenceDeleteAlert(item),
                    leftIcon: icons_1.Icons.Alert,
                });
            }
            else if (!item.form.model.isRemoved) {
                btn = new button_1.default({
                    onClick: _this.removeItem(item),
                    leftIcon: icons_1.Icons.Minus
                });
            }
            else {
                btn = new button_1.default({
                    onClick: _this.cancelRemoveItem(item),
                    leftIcon: icons_1.Icons.Plus
                });
            }
            if (item.alert.isVisible) {
                btn.setVisibility(false);
            }
            item.alert.observationProvider.observe(function () { btn.setVisibility(false); }, alert_1.AlertActions.Shown);
            item.alert.observationProvider.observe(function () { btn.setVisibility(true); }, alert_1.AlertActions.Hidden);
            return btn;
        };
        this.setIsEditable = function (isEditable) {
            _this._isEditable = isEditable;
            for (var _i = 0, _a = _this._dataListItems; _i < _a.length; _i++) {
                var item = _a[_i];
                item.form.isDisabled = !isEditable;
                if (!isEditable) {
                    item.form.model.isRemoved = false;
                }
            }
            _this.observationProvider.notifyObservers(_1.DataListActions.EditableChanged, _this._isEditable);
        };
        this.buildMoveUpButton = function (currentIndex) {
            return new button_1.default({
                leftIcon: icons_1.Icons.ChevronUp,
                onClick: function () { return _this.moveItemUp(currentIndex); },
                isDisabled: currentIndex === 0
            });
        };
        this.buildMoveDownButton = function (currentIndex) {
            return new button_1.default({
                leftIcon: icons_1.Icons.ChevronDown,
                onClick: function () { return _this.moveItemDown(currentIndex); },
                isDisabled: currentIndex === _this._dataListItems.length - 1
            });
        };
        this.moveItemUp = function (currentIndex) {
            if (!_this.isSortable || currentIndex === 0) {
                return;
            }
            utils_1.ArrayUtils.move(_this._dataListItems, currentIndex, currentIndex - 1);
            _this.observationProvider.notifyObservers(_1.DataListActions.ItemsReordered);
        };
        this.moveItemDown = function (currentIndex) {
            if (!_this.isSortable || currentIndex === _this._dataListItems.length - 1) {
                return;
            }
            utils_1.ArrayUtils.move(_this._dataListItems, currentIndex, currentIndex + 1);
            _this.observationProvider.notifyObservers(_1.DataListActions.ItemsReordered);
        };
        this.setVisibility = function (isVisible) {
            if (isVisible != _this._isVisible) {
                _this._isVisible = isVisible;
                _this.addItemButton.setVisibility(_this._isVisible);
                _this.observationProvider.notifyObservers(_1.DataListActions.VisibilityChanged);
            }
        };
        this.setDeleteButtonVisibility = function (deleteButtonVisible) {
            _this._deleteButtonIsHidden = deleteButtonVisible !== true;
            _this.observationProvider.notifyObservers(_1.DataListActions.DeleteButtonVisibilityChanged);
        };
        this._dataListItems = [];
        this._isLoading = true;
        this._isVisible = true;
        this.addItemButton = new button_1.default({
            text: _addButtonText,
            leftIcon: icons_1.Icons.Plus,
            onClick: this.addItem
        });
        this.observationProvider = new utils_1.ObservationProvider();
    }
    Object.defineProperty(DataList.prototype, "isVisible", {
        get: function () {
            return this._isVisible;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(DataList.prototype, "items", {
        get: function () {
            return this._dataListItems;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(DataList.prototype, "isLoading", {
        get: function () {
            return this._isLoading;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(DataList.prototype, "isEditable", {
        get: function () {
            return this._isEditable;
        },
        enumerable: false,
        configurable: true
    });
    return DataList;
}());
exports.default = DataList;
