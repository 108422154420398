"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var __1 = require("../..");
var api_1 = require("../../api");
var pages_1 = require("../../pages");
var authentication_1 = require("../authentication");
var EventManager = /** @class */ (function () {
    function EventManager(eventLandingData, eventPermissions, currentUserManager, meetingConfigurations, travelAndLodingConfigurations) {
        var _this = this;
        this.eventLandingData = eventLandingData;
        this.eventPermissions = eventPermissions;
        this.currentUserManager = currentUserManager;
        this.meetingConfigurations = meetingConfigurations;
        this.travelAndLodingConfigurations = travelAndLodingConfigurations;
        this.canViewTravelAndLodgingHistory = function (userId) {
            if (_this.currentUserManager.userId === userId) {
                return true;
            }
            if (_this.currentUserManager.isSuperUser) {
                return true;
            }
            if (_this.eventPermissions.editTravelAndLodging) {
                return true;
            }
            return false;
        };
        this.canEditTravelAndLodging = function (userId) {
            if (_this.currentUserManager.isSuperUser) {
                return true;
            }
            if (_this.travelAndLodingConfigurations.accommodationCutoffDate &&
                __1.DateUtils.hasDatePast(_this.travelAndLodingConfigurations.accommodationCutoffDate) &&
                !_this.eventPermissions.overrideAccommodationCutOff) {
                return false;
            }
            if (_this.currentUserManager.userId === userId) {
                return true;
            }
            if (_this.eventPermissions.editTravelAndLodging) {
                return true;
            }
            return false;
        };
        this.getCanDeactivateMeetings = function (meetingDetail) {
            if (_this.eventLandingData.status !== api_1.EventStatus.Active) {
                return false;
            }
            if (!meetingDetail.isActive || !meetingDetail.id) {
                return false;
            }
            if (_this.currentUserManager.isSuperUser || _this.eventPermissions.deleteMeetings) {
                return true;
            }
            return false;
        };
        this._arrivalDepartureComments = '';
        this._dailyShuttleComments = '';
    }
    Object.defineProperty(EventManager.prototype, "userType", {
        get: function () {
            return this.currentUserManager.userType;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(EventManager.prototype, "canQuickAddCustomers", {
        get: function () {
            return true; // This should be allowed regardless of customer/customer edit permissions
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(EventManager.prototype, "isCurrentUserEventProducer", {
        get: function () {
            return this.currentUserManager.userId === this.eventLandingData.eventProducerId;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(EventManager.prototype, "isDiningRoomTileEditable", {
        get: function () {
            return this.eventLandingData.status === api_1.EventStatus.Active && this.canEditDining;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(EventManager.prototype, "isBadgingTileEditable", {
        get: function () {
            return this.eventLandingData.status === api_1.EventStatus.Active;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(EventManager.prototype, "isReportsTileEditable", {
        get: function () {
            return false;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(EventManager.prototype, "isMeetingTileEditable", {
        get: function () {
            return this.canAssignVenues;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(EventManager.prototype, "isSelfManageEnabled", {
        get: function () {
            return this.isSelfManageHotelEnabled || this.isSelfManageTransportationEnabled;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(EventManager.prototype, "isBadgingEnabled", {
        get: function () {
            return this.eventLandingData.badgingEnabled;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(EventManager.prototype, "isSelfManageHotelEnabled", {
        get: function () {
            if (!this.travelAndLodingConfigurations ||
                !this.travelAndLodingConfigurations.eventHotels ||
                !this.travelAndLodingConfigurations.eventHotels.length) {
                return false;
            }
            return this.travelAndLodingConfigurations.eventHotels.some(function (hotel) { return !hotel.vendorCompanyId; });
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(EventManager.prototype, "isSelfManageTransportationEnabled", {
        get: function () {
            if (!this.travelAndLodingConfigurations ||
                !this.travelAndLodingConfigurations.eventTransportationOptions ||
                !this.travelAndLodingConfigurations.eventTransportationOptions.length) {
                return false;
            }
            return this.travelAndLodingConfigurations.eventTransportationOptions.some(function (option) { return !option.vendorCompanyId; });
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(EventManager.prototype, "isCostManagementTileEditable", {
        get: function () {
            if (this.eventLandingData.status !== api_1.EventStatus.Active) {
                return false;
            }
            if (this.currentUserManager.isSuperUser) {
                return true;
            }
            return this.eventPermissions.editCostManagement &&
                !this.eventLandingData.isCostManagementTileLocked;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(EventManager.prototype, "isDailyHotelEventTransportEnabled", {
        get: function () {
            if (!this.travelAndLodingConfigurations || !this.travelAndLodingConfigurations.eventTransportationOptions) {
                return false;
            }
            if (!this.travelAndLodingConfigurations.isTransportationEnabled) {
                return false;
            }
            return this.travelAndLodingConfigurations.eventTransportationOptions.some(function (option) { return option.dailyHotelEventTransportEnabled; });
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(EventManager.prototype, "isAirportToHotelTransportEnabled", {
        get: function () {
            if (!this.travelAndLodingConfigurations || !this.travelAndLodingConfigurations.eventTransportationOptions) {
                return false;
            }
            if (!this.travelAndLodingConfigurations.isTransportationEnabled) {
                return false;
            }
            return this.travelAndLodingConfigurations.eventTransportationOptions.some(function (option) { return option.airportToHotelTransportEnabled; });
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(EventManager.prototype, "isHotelToAirportTransportEnabled", {
        get: function () {
            if (!this.travelAndLodingConfigurations || !this.travelAndLodingConfigurations.eventTransportationOptions) {
                return false;
            }
            if (!this.travelAndLodingConfigurations.isTransportationEnabled) {
                return false;
            }
            return this.travelAndLodingConfigurations.eventTransportationOptions.some(function (option) { return option.hotelToAirportTransportEnabled; });
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(EventManager.prototype, "isDailyIndividualTransportAvailable", {
        get: function () {
            if (!this.travelAndLodingConfigurations || !this.travelAndLodingConfigurations.eventTransportationOptions) {
                return false;
            }
            if (!this.travelAndLodingConfigurations.isTransportationEnabled) {
                return false;
            }
            return this.travelAndLodingConfigurations.eventTransportationOptions.some(function (option) { return option.eventTransportVehicleOptions.length > 0; });
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(EventManager.prototype, "requireArrivalDepartureInformation", {
        get: function () {
            if (!this.travelAndLodingConfigurations || !this.travelAndLodingConfigurations.requireArrivalDepartureInformation) {
                return false;
            }
            return this.travelAndLodingConfigurations.requireArrivalDepartureInformation;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(EventManager.prototype, "showAccommodationTileTransportPane", {
        get: function () {
            return this.isDailyIndividualTransportAvailable || this.isDailyHotelEventTransportEnabled;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(EventManager.prototype, "isAccommodationCutoffPast", {
        get: function () {
            if (this.travelAndLodingConfigurations.accommodationCutoffDate &&
                __1.DateUtils.hasDatePast(this.travelAndLodingConfigurations.accommodationCutoffDate)) {
                return true;
            }
            return false;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(EventManager.prototype, "isEventInfoTileEditable", {
        get: function () {
            if (this.eventLandingData.status !== api_1.EventStatus.Active) {
                return false;
            }
            if (this.currentUserManager.isSuperUser ||
                this.eventPermissions.editEvent ||
                this.currentUserManager.hasPermission(api_1.PermissionType.EditAllEvents)) {
                return true;
            }
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(EventManager.prototype, "isCostManagementEnabled", {
        get: function () {
            return this.currentUserManager.hasFeatureEnabled(api_1.FeatureType.CostManagement) &&
                this.eventLandingData.costManagementEnabled &&
                this.eventPermissions.viewCostManagement;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(EventManager.prototype, "isEventInventoryTileEditable", {
        get: function () {
            if (this.eventLandingData.status !== api_1.EventStatus.Active) {
                return false;
            }
            if (this.currentUserManager.isSuperUser) {
                return true;
            }
            return this.eventPermissions.editInventory &&
                !this.eventLandingData.isInventoryTileLocked;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(EventManager.prototype, "canSendMeetingNotifications", {
        get: function () {
            return this.currentUserManager.isSuperUser ||
                this.eventPermissions.sendMeetingNotifications;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(EventManager.prototype, "canViewPrivateAttendees", {
        get: function () {
            return this.currentUserManager.isSuperUser ||
                this.eventPermissions.viewPrivateAttendees;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(EventManager.prototype, "isFileMagagementTileEditable", {
        get: function () {
            if (this.eventLandingData.status !== api_1.EventStatus.Active) {
                return false;
            }
            if (this.currentUserManager.isSuperUser) {
                return true;
            }
            return this.eventPermissions.editFiles;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(EventManager.prototype, "canViewEditMeetingNotes", {
        get: function () {
            return this.currentUserManager.hasFeatureEnabled(api_1.FeatureType.MeetingNotes) && (this.currentUserManager.isSuperUser || this.eventPermissions.viewAllMeetingNotes);
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(EventManager.prototype, "canAssignVenues", {
        get: function () {
            if (this.eventLandingData.status !== api_1.EventStatus.Active) {
                return false;
            }
            if (this.currentUserManager.isSuperUser ||
                this.eventPermissions.assignVenues) {
                return true;
            }
            return false;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(EventManager.prototype, "canEditDining", {
        get: function () {
            if (this.eventLandingData.status !== api_1.EventStatus.Active) {
                return false;
            }
            if (this.currentUserManager.isSuperUser ||
                this.eventPermissions.editDining) {
                return true;
            }
            return false;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(EventManager.prototype, "canAddEvents", {
        get: function () {
            return this.currentUserManager.isSuperUser || this.currentUserManager.hasPermission(api_1.PermissionType.AddEvents);
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(EventManager.prototype, "isEventAttendeeTileEditable", {
        get: function () {
            if (this.eventLandingData.status !== api_1.EventStatus.Active) {
                return false;
            }
            if (this.currentUserManager.isSuperUser || this.eventPermissions.editAttendees) {
                return true;
            }
            if (this.eventLandingData.meetingsEnabled &&
                this.eventPermissions.prescheduleMeetings &&
                !__1.DateUtils.hasDatePast(this.meetingConfigurations.meetingsPrescheduleEndDate)) {
                return true;
            }
            return false;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(EventManager.prototype, "eventConfigurationEditable", {
        get: function () {
            return this.currentUserManager.isSuperUser ||
                this.eventPermissions.editEventConfiguration;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(EventManager.prototype, "canCreateMeetings", {
        get: function () {
            if (this.eventLandingData.status !== api_1.EventStatus.Active) {
                return false;
            }
            if (this.currentUserManager.isSuperUser || this.eventPermissions.createMeetings) {
                return true;
            }
            if (this.eventPermissions.prescheduleMeetings &&
                !__1.DateUtils.hasDatePast(this.meetingConfigurations.meetingsPrescheduleEndDate)) {
                return true;
            }
            if (this.canSubmitMeetingRequests) {
                return true;
            }
            return false;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(EventManager.prototype, "costManagementFeatureEnabled", {
        get: function () {
            return this.currentUserManager.hasFeatureEnabled(api_1.FeatureType.CostManagement);
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(EventManager.prototype, "isMeetingNotesEnabled", {
        get: function () {
            return this.currentUserManager.hasFeatureEnabled(api_1.FeatureType.MeetingNotes);
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(EventManager.prototype, "isInventoryEnabled", {
        get: function () {
            return this.currentUserManager.hasFeatureEnabled(api_1.FeatureType.Inventory) &&
                this.eventLandingData.inventoryManagementEnabled;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(EventManager.prototype, "isMeetingInvitesEnabled", {
        get: function () {
            return this.eventLandingData.meetingInvitesEnabled;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(EventManager.prototype, "isFileManagementEnabled", {
        get: function () {
            return this.eventLandingData.fileManagementEnabled;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(EventManager.prototype, "isMeetingsEnabled", {
        get: function () {
            return this.eventLandingData.meetingsEnabled;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(EventManager.prototype, "isGolfCartsEnabled", {
        get: function () {
            return this.eventLandingData.golfCartsEnabled;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(EventManager.prototype, "isDiningEnabled", {
        get: function () {
            return this.eventLandingData.diningEnabled;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(EventManager.prototype, "isMeetingsReportVisible", {
        get: function () {
            return this.currentUserManager.userType === authentication_1.UserTypes.User && this.eventLandingData.meetingsEnabled;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(EventManager.prototype, "isHotelReportVisible", {
        get: function () {
            return this.travelAndLodingConfigurations.isHotelsEnabled;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(EventManager.prototype, "isTransportationReportVisible", {
        get: function () {
            return this.travelAndLodingConfigurations.isTransportationEnabled;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(EventManager.prototype, "isAccommodationsEnabled", {
        get: function () {
            return this.currentUserManager.hasFeatureEnabled(api_1.FeatureType.TravelAndLodging) &&
                (this.eventLandingData.isHotelsEnabled ||
                    this.eventLandingData.isTransportationEnabled);
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(EventManager.prototype, "canDuplicateMeetings", {
        get: function () {
            return this.canEditMeetings;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(EventManager.prototype, "canEditMeetings", {
        get: function () {
            if (this.eventLandingData.status !== api_1.EventStatus.Active) {
                return false;
            }
            if (this.currentUserManager.isSuperUser || this.eventPermissions.createMeetings) {
                return true;
            }
            if (this.eventPermissions.prescheduleMeetings &&
                !__1.DateUtils.hasDatePast(this.meetingConfigurations.meetingsPrescheduleEndDate)) {
                return true;
            }
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(EventManager.prototype, "canViewAdditionalHeadCount", {
        get: function () {
            return this.currentUserManager.hasFeatureEnabled(api_1.FeatureType.AttendeeHeadCount) &&
                this.eventLandingData.enableAttendeeHeadCount && (this.currentUserManager.isSuperUser ||
                this.isCurrentUserEventProducer ||
                this.currentUserManager.hasPermission(api_1.PermissionType.ViewAdditionalHeadCount));
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(EventManager.prototype, "canActivateEvent", {
        get: function () {
            return this.currentUserManager.isSuperUser ||
                this.isCurrentUserEventProducer ||
                this.currentUserManager.hasPermission(api_1.PermissionType.EditAllEvents);
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(EventManager.prototype, "canSubmitMeetingRequests", {
        get: function () {
            return this.meetingConfigurations.meetingRequestsEnabled &&
                (this.currentUserManager.isSuperUser ||
                    this.eventPermissions.submitMeetingRequests) &&
                !__1.DateUtils.hasDatePast(this.meetingConfigurations.meetingRequestCutOffDate);
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(EventManager.prototype, "isMeetingFinalizedStatusEnabled", {
        get: function () {
            return this.canEditMeetings;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(EventManager.prototype, "isMeetingWorkingStatusEnabled", {
        get: function () {
            return this.canEditMeetings;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(EventManager.prototype, "canChangeMeetingGuestArrived", {
        get: function () {
            if (this.eventLandingData.status !== api_1.EventStatus.Active) {
                return false;
            }
            if (this.currentUserManager.isSuperUser ||
                this.eventPermissions.markMeetingGuestsArrived ||
                this.currentUserManager.permissions.editAllEvents) {
                return true;
            }
            return false;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(EventManager.prototype, "defaultEventAttendeeAccessLevelId", {
        get: function () {
            return this.eventLandingData.defaultEventAttendeeAccessLevelId;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(EventManager.prototype, "defaultMeetingStatus", {
        get: function () {
            var defaultStatus = this.meetingConfigurations.meetingsDefaultStatus;
            if (defaultStatus === pages_1.MeetingStatuses.Working &&
                this.isMeetingWorkingStatusEnabled) {
                return pages_1.MeetingStatuses.Working;
            }
            else if (defaultStatus === pages_1.MeetingStatuses.Finalized &&
                this.isMeetingFinalizedStatusEnabled) {
                return pages_1.MeetingStatuses.Finalized;
            }
            else if (this.canSubmitMeetingRequests) {
                return pages_1.MeetingStatuses.Requested;
            }
            return null;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(EventManager.prototype, "arrivalDepartureComments", {
        get: function () {
            if (this._arrivalDepartureComments) {
                return this._arrivalDepartureComments;
            }
            if (this.travelAndLodingConfigurations &&
                this.travelAndLodingConfigurations.eventTransportationOptions) {
                for (var i = 0; i < this.travelAndLodingConfigurations.eventTransportationOptions.length; i++) {
                    var transportOption = this.travelAndLodingConfigurations.eventTransportationOptions[i];
                    if (!transportOption.arrivalDepartureTransportComments) {
                        continue;
                    }
                    if (this._arrivalDepartureComments) {
                        this._arrivalDepartureComments += '; ';
                    }
                    this._arrivalDepartureComments += transportOption.arrivalDepartureTransportComments;
                }
                return this._arrivalDepartureComments;
            }
            return '';
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(EventManager.prototype, "dailySuttleComments", {
        get: function () {
            if (this._dailyShuttleComments) {
                return this._dailyShuttleComments;
            }
            if (this.travelAndLodingConfigurations &&
                this.travelAndLodingConfigurations.eventTransportationOptions) {
                for (var i = 0; i < this.travelAndLodingConfigurations.eventTransportationOptions.length; i++) {
                    var transportOption = this.travelAndLodingConfigurations.eventTransportationOptions[i];
                    if (!transportOption.dailyHotelEventTransportComments) {
                        continue;
                    }
                    if (this._dailyShuttleComments) {
                        this._dailyShuttleComments += '; ';
                    }
                    this._dailyShuttleComments += transportOption.dailyHotelEventTransportComments;
                }
                return this._dailyShuttleComments;
            }
            return '';
        },
        enumerable: false,
        configurable: true
    });
    return EventManager;
}());
exports.default = EventManager;
