"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var RequestPasswordResetPageActions;
(function (RequestPasswordResetPageActions) {
    RequestPasswordResetPageActions["FormInvalid"] = "FormInvalid";
    RequestPasswordResetPageActions["FormSubmitted"] = "FormSubmitted";
    RequestPasswordResetPageActions["ErrorSubmitting"] = "ErrorSubmitting";
    RequestPasswordResetPageActions["SubmittedSuccessfully"] = "SubmittedSuccessfully";
})(RequestPasswordResetPageActions || (RequestPasswordResetPageActions = {}));
exports.default = RequestPasswordResetPageActions;
