"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var QuickAddCustomerFormActions;
(function (QuickAddCustomerFormActions) {
    QuickAddCustomerFormActions["CustomerCreated"] = "CustomerCreated";
    QuickAddCustomerFormActions["Opened"] = "Shown";
    QuickAddCustomerFormActions["Closed"] = "Hidden";
    QuickAddCustomerFormActions["FormInvalid"] = "FormInvalid";
    QuickAddCustomerFormActions["CustomerUpdated"] = "CustomerUpdated";
})(QuickAddCustomerFormActions || (QuickAddCustomerFormActions = {}));
exports.default = QuickAddCustomerFormActions;
