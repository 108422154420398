// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.login-page {
    flex: 1;
    display: flex;
    flex-direction: column;
    overflow-y: auto;
}

.login-page header {
    margin: 0;
    background-color: #3A4856;
    display: none;
    color: #fff;
    width: 100%;
    background-image: none;
}

.login-page header > div {
    background-color: #3A4856;
    width: 100%;
    height: 4rem;
    color: #fff;
    display: flex;
}

.login-page header > div > a {
    margin: auto 1rem auto auto;
}

.login-page main {
    flex:1;
    width: 100%;
    margin: 0;
    padding: 0;
}

.login-page main .card {
    height: 100%;
}

.login-page + footer {
    background-color: #3A4856;
    color: #fff;
    text-align: center;
    padding: .75rem;
    width: 100%;
    font-size: .8rem;
    margin: 0;
}

@media (min-width: 768px) {
    .login-page header {
        display: flex;
    }

    .login-page main {
        margin: auto;
        width: 75%;
        padding: 0 0 2rem 0;
        flex: unset;
        min-width: 25rem;
        max-width: 50rem;
    }
}`, "",{"version":3,"sources":["webpack://./src/styles/login.css"],"names":[],"mappings":"AAAA;IACI,OAAO;IACP,aAAa;IACb,sBAAsB;IACtB,gBAAgB;AACpB;;AAEA;IACI,SAAS;IACT,yBAAyB;IACzB,aAAa;IACb,WAAW;IACX,WAAW;IACX,sBAAsB;AAC1B;;AAEA;IACI,yBAAyB;IACzB,WAAW;IACX,YAAY;IACZ,WAAW;IACX,aAAa;AACjB;;AAEA;IACI,2BAA2B;AAC/B;;AAEA;IACI,MAAM;IACN,WAAW;IACX,SAAS;IACT,UAAU;AACd;;AAEA;IACI,YAAY;AAChB;;AAEA;IACI,yBAAyB;IACzB,WAAW;IACX,kBAAkB;IAClB,eAAe;IACf,WAAW;IACX,gBAAgB;IAChB,SAAS;AACb;;AAEA;IACI;QACI,aAAa;IACjB;;IAEA;QACI,YAAY;QACZ,UAAU;QACV,mBAAmB;QACnB,WAAW;QACX,gBAAgB;QAChB,gBAAgB;IACpB;AACJ","sourcesContent":[".login-page {\r\n    flex: 1;\r\n    display: flex;\r\n    flex-direction: column;\r\n    overflow-y: auto;\r\n}\r\n\r\n.login-page header {\r\n    margin: 0;\r\n    background-color: #3A4856;\r\n    display: none;\r\n    color: #fff;\r\n    width: 100%;\r\n    background-image: none;\r\n}\r\n\r\n.login-page header > div {\r\n    background-color: #3A4856;\r\n    width: 100%;\r\n    height: 4rem;\r\n    color: #fff;\r\n    display: flex;\r\n}\r\n\r\n.login-page header > div > a {\r\n    margin: auto 1rem auto auto;\r\n}\r\n\r\n.login-page main {\r\n    flex:1;\r\n    width: 100%;\r\n    margin: 0;\r\n    padding: 0;\r\n}\r\n\r\n.login-page main .card {\r\n    height: 100%;\r\n}\r\n\r\n.login-page + footer {\r\n    background-color: #3A4856;\r\n    color: #fff;\r\n    text-align: center;\r\n    padding: .75rem;\r\n    width: 100%;\r\n    font-size: .8rem;\r\n    margin: 0;\r\n}\r\n\r\n@media (min-width: 768px) {\r\n    .login-page header {\r\n        display: flex;\r\n    }\r\n\r\n    .login-page main {\r\n        margin: auto;\r\n        width: 75%;\r\n        padding: 0 0 2rem 0;\r\n        flex: unset;\r\n        min-width: 25rem;\r\n        max-width: 50rem;\r\n    }\r\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
