"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var DataListActions;
(function (DataListActions) {
    DataListActions["RecordAdded"] = "RecordAdded";
    DataListActions["RecordRemoved"] = "RecordRemoved";
    DataListActions["CancelRecordRemove"] = "CancelRecordRemove";
    DataListActions["EditableChanged"] = "EditableChanged";
    DataListActions["ItemsReordered"] = "ItemsReordered";
    DataListActions["VisibilityChanged"] = "VisibilityChanged";
    DataListActions["DeleteButtonVisibilityChanged"] = "DeleteButtonVisibilityChanged";
    DataListActions["ItemVisibilityChanged"] = "ItemVisibilityChanged";
})(DataListActions || (DataListActions = {}));
exports.default = DataListActions;
