"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g = Object.create((typeof Iterator === "function" ? Iterator : Object).prototype);
    return g.next = verb(0), g["throw"] = verb(1), g["return"] = verb(2), typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
Object.defineProperty(exports, "__esModule", { value: true });
var _1 = require(".");
var __1 = require("../../../../..");
var button_1 = require("../../../../../components/button/button");
var components_1 = require("../../../../../components");
var helpButton_1 = require("../../../../../components/button/helpButton");
var exhibitSpaceField_1 = require("../../../../../components/fields/exhibitSpaceField");
var helpUrls_1 = require("../../../../helpUrls");
var settings_1 = require("../../../../settings");
var costManagementItemType_1 = require("./costManagementItemType");
var CostManagementTile = /** @class */ (function (_super) {
    __extends(CostManagementTile, _super);
    function CostManagementTile(eventId, api, currentUserManager, _isVisible, _canEdit, navigator) {
        var _this = _super.call(this, 'Cost Management', api, currentUserManager.clientChecker.isMobileApp(), _isVisible, _canEdit) || this;
        _this.eventId = eventId;
        _this.currentUserManager = currentUserManager;
        _this.navigator = navigator;
        _this.showContentItemsPane = function () {
            _this._currentPane = _1.CostManagementTilePane.InventoryItemsPane;
            _this.observationProvider.notifyObservers(_1.CostManagementTileActions.CurrentPaneChanged);
        };
        _this.showServiceItemsPane = function () {
            _this._currentPane = _1.CostManagementTilePane.ServiceItemsPane;
            _this.observationProvider.notifyObservers(_1.CostManagementTileActions.CurrentPaneChanged);
        };
        _this.showCostDistributionPane = function () {
            _this._currentPane = _1.CostManagementTilePane.CostDistribution;
            _this.observationProvider.notifyObservers(_1.CostManagementTileActions.CurrentPaneChanged);
        };
        _this.loadServiceData = function () { return __awaiter(_this, void 0, void 0, function () {
            var _a, services, i, _b, _c;
            return __generator(this, function (_d) {
                switch (_d.label) {
                    case 0:
                        this._serviceForms = [];
                        _a = this;
                        return [4 /*yield*/, this.api.getCostManagementServices()];
                    case 1:
                        _a._costManagmentServices = _d.sent();
                        return [4 /*yield*/, this.api.get("".concat(__1.EndpointUrls.Events, "/").concat(this.eventId, "/EventServices"))];
                    case 2:
                        services = _d.sent();
                        i = 0;
                        _d.label = 3;
                    case 3:
                        if (!(i < services.length)) return [3 /*break*/, 6];
                        services[i].costDifference = services[i].actualCost - services[i].estimatedCost;
                        _c = (_b = this._serviceForms).push;
                        return [4 /*yield*/, this.buildServiceForm(services[i])];
                    case 4:
                        _c.apply(_b, [_d.sent()]);
                        _d.label = 5;
                    case 5:
                        i++;
                        return [3 /*break*/, 3];
                    case 6:
                        this.calculateServiceTotals();
                        return [2 /*return*/];
                }
            });
        }); };
        _this.buildServiceForm = function (service) { return __awaiter(_this, void 0, void 0, function () {
            var serviceOptions, nameField, businessAreaDropdown, providerField, descriptionField, estimatedCostField, actualCostField, allowedPercentField, exhibitSpaceField, form;
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.api.getCostManagementServiceOptions()];
                    case 1:
                        serviceOptions = _a.sent();
                        nameField = new components_1.NumberDropdownField('costManagementServiceId', 'Service', true, serviceOptions, '');
                        businessAreaDropdown = new components_1.BusinessAreaMultiselectField(this.api, 'businessAreas', 'Billed Business Areas', null, true, 'required', this.eventId);
                        if (!service.billedBusinessAreas || service.primaryCostFactor !== __1.CostManagementCostFactor.BusinessArea) {
                            service.billedBusinessAreas = [],
                                businessAreaDropdown.setValue('-2');
                        }
                        else {
                            businessAreaDropdown.setValue(service.billedBusinessAreas.map(function (billedBusinessArea) { return billedBusinessArea.businessAreaId; }));
                        }
                        providerField = new components_1.TextField('provider', 'Provider', true, 100, '');
                        descriptionField = new components_1.TextField('description', 'Description', false, 250);
                        estimatedCostField = new components_1.MoneyField('estimatedCost', 'Estimated Cost', true, '');
                        actualCostField = new components_1.MoneyField('actualCost', 'Actual Cost', false, '');
                        allowedPercentField = new components_1.IntField('allowablePercent', 'Allowable Percent', true, '');
                        exhibitSpaceField = new exhibitSpaceField_1.default(this.api, this.eventId, 'eventExhibitSpaceId', 'Exhibit Space', true);
                        form = new __1.Form([
                            nameField,
                            businessAreaDropdown,
                            exhibitSpaceField,
                            providerField,
                            descriptionField,
                            estimatedCostField,
                            actualCostField,
                            allowedPercentField
                        ]);
                        form.setModel(service);
                        if (!service.id || this.isServiceACredit(service)) {
                            allowedPercentField.setVisibility(false);
                        }
                        else {
                            allowedPercentField.setVisibility(true);
                        }
                        actualCostField.observationProvider.observe(function (newValue) {
                            var estimatedCost = estimatedCostField.value || 0;
                            form.model.costDifference = newValue - estimatedCost;
                            _this.calculateServiceTotals();
                            _this.observationProvider.notifyObservers(_1.CostManagementTileActions.ServiceCostValuesChanged);
                        }, components_1.FieldActions.ValueChanged);
                        estimatedCostField.observationProvider.observe(function (newValue) {
                            var actualCost = actualCostField.value || 0;
                            form.model.costDifference = actualCost - newValue;
                            _this.calculateServiceTotals();
                            _this.observationProvider.notifyObservers(_1.CostManagementTileActions.ServiceCostValuesChanged);
                        }, components_1.FieldActions.ValueChanged);
                        nameField.observationProvider.observe(function (newValue) {
                            form.model.costManagementServiceId = newValue;
                            if (_this.isServiceACredit(form.model)) {
                                form.model.expenseType = settings_1.ExpenseType.Credit;
                                allowedPercentField.setVisibility(false);
                            }
                            else {
                                form.model.expenseType = settings_1.ExpenseType.Expense;
                                allowedPercentField.setVisibility(true);
                            }
                            form.model.primaryCostFactor = _this.getServicePrimaryFactor(form.model);
                            _this.observationProvider.notifyObservers(_1.CostManagementTileActions.ServiceChanged);
                        }, components_1.FieldActions.ValueChanged);
                        if (form.model.primaryCostFactor) {
                            form.model.primaryCostFactor = this.getServicePrimaryFactor(form.model);
                        }
                        return [2 /*return*/, form];
                }
            });
        }); };
        _this.loadBillableBusinessAreas = function () { return __awaiter(_this, void 0, void 0, function () {
            var _a;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _a = this;
                        return [4 /*yield*/, this.api.getEventBillableBusinessAreas(this.eventId)];
                    case 1:
                        _a._billableBusinessAreas = _b.sent();
                        return [2 /*return*/];
                }
            });
        }); };
        _this.loadExhibitSpaceOptions = function () { return __awaiter(_this, void 0, void 0, function () {
            var _a, i;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _a = this;
                        return [4 /*yield*/, this.api.getExhibitSpaces(this.eventId)];
                    case 1:
                        _a._exhibitSpaceOptions = _b.sent();
                        for (i = 0; i < this._exhibitSpaceOptions.length; i++) {
                            if (this._exhibitSpaceOptions[i].isPrimary) {
                                this._primaryExhibitSpaceId = this._exhibitSpaceOptions[i].id;
                                break;
                            }
                        }
                        return [2 /*return*/];
                }
            });
        }); };
        _this.addNewServiceItem = function () { return __awaiter(_this, void 0, void 0, function () {
            var _a, _b;
            return __generator(this, function (_c) {
                switch (_c.label) {
                    case 0:
                        _b = (_a = this._newServiceItemForms).unshift;
                        return [4 /*yield*/, this.buildServiceForm({ allowablePercent: 100 })];
                    case 1:
                        _b.apply(_a, [_c.sent()]);
                        this.observationProvider.notifyObservers(_1.CostManagementTileActions.ServiceItemAdded);
                        return [2 /*return*/];
                }
            });
        }); };
        _this.addItem = function () {
            if (_this.forms.length > 0 && !_this.forms[0].model.itemType) {
                return;
            }
            _this._forms.unshift(_this.buildForm({}));
            _this.observationProvider.notifyObservers(_1.CostManagementTileActions.ItemAdded);
        };
        _this.buildForm = function (item) {
            var itemTypeDropdown = new components_1.NumberDropdownField('itemType', 'Type', true, [{ label: 'I', value: costManagementItemType_1.default.Inventory }, { label: 'R', value: costManagementItemType_1.default.Rented }], 'required');
            var businessAreaDropdown = new components_1.BusinessAreaMultiselectField(_this.api, 'businessAreas', 'Billed Business Areas', null, true, 'required', _this.eventId);
            if (!item.billedBusinessAreas) {
                item.billedBusinessAreas = [];
                businessAreaDropdown.setValue('-2');
            }
            else {
                businessAreaDropdown.setValue(item.billedBusinessAreas.map(function (billedBusinessArea) { return billedBusinessArea.businessAreaId; }));
            }
            // inventory fields
            var inventorySearchField = new components_1.InventorySearchField(_this.api, 'Inventory Item', item.itemType === costManagementItemType_1.default.Inventory, 'required', false, 'inventoryItemId', 'text');
            inventorySearchField.setDisabledOptionIds(_this._forms.map(function (form) { return form.model.inventoryItemId; }));
            // rental fields
            var descriptionField = new components_1.TextField('description', 'Discription', false, 250, 'required');
            var nameField = new components_1.TextField('name', 'Name', item.itemType === costManagementItemType_1.default.Rented, 250, 'required');
            var areaField = new components_1.IntField('areaSquareFeet', 'Area Sq Ft', false);
            var weightField = new components_1.IntField('weight', 'Weight', false);
            var quantityField = new components_1.IntField('quantity', 'Quantity', item.itemType === costManagementItemType_1.default.Rented, 'required');
            var pointsField = new components_1.IntField('points', 'Points', true, 'required');
            var ignoreDimensionsField = new components_1.CheckBoxField('ignoreDimensions', 'Ignore Dimensions');
            var exhibitSpaceField = new exhibitSpaceField_1.default(_this.api, _this.eventId, 'exhibitSpaceId', 'Exhibit Space', true);
            var pointOverrideComments = new components_1.TextField('pointsAdjustmentComments', 'Comments', false, 1000, null, null, components_1.FieldType.TextArea);
            var form = new __1.Form([
                itemTypeDropdown,
                inventorySearchField,
                descriptionField,
                nameField,
                areaField,
                weightField,
                quantityField,
                pointsField,
                pointOverrideComments,
                ignoreDimensionsField,
                exhibitSpaceField,
                businessAreaDropdown
            ], item);
            itemTypeDropdown.observationProvider.observe(function () {
                form.model.itemType = itemTypeDropdown.value;
                if (form.model.itemType == costManagementItemType_1.default.Inventory) {
                    nameField.setRequiredStatus(false);
                    areaField.setRequiredStatus(false);
                    weightField.setRequiredStatus(false);
                    quantityField.setRequiredStatus(false);
                }
                else {
                    inventorySearchField.setRequiredStatus(false);
                }
                _this.observationProvider.notifyObservers(_1.CostManagementTileActions.ItemTypeChanged);
            }, components_1.FieldActions.ValueChanged);
            // observe once so that setModel can be called without triggering ValueChanged in an endless loop
            inventorySearchField.observationProvider.observeOnce(function () {
                _this.loadNewInventoryItem(inventorySearchField, businessAreaDropdown, exhibitSpaceField, form);
            }, components_1.FieldActions.ValueChanged);
            if (!item.eventRentedItemId && !item.eventInventoryId && !item.exhibitSpaceId) {
                exhibitSpaceField.setValue(_this._primaryExhibitSpaceId);
            }
            pointsField.observationProvider.observe(function () {
                if (form.model.originalPoints) {
                    _this.showPointOverideModal(form);
                }
            }, components_1.FieldActions.ValueChanged);
            return form;
        };
        _this.showPointOverideModal = function (form) {
            if (_this.isEditMode) {
                var currentPointsValue = form.getFieldByKey('points').value;
                if (currentPointsValue == form.model.originalPoints ||
                    currentPointsValue == form.model.mostRecentPoints) {
                    return;
                }
            }
            _this._pointOverrideModalIsVisible = true;
            _this._pointOverrideForm = form;
            _this.applyPointOverrideModalButton.setVisibility(_this.isEditMode);
            _this.cancelPointOverrideModalButton.setVisibility(_this.isEditMode);
            _this._pointOverrideCommentField = form.getFieldByKey('pointsAdjustmentComments');
            _this._pointOverrideCommentField.isDisabled = !_this.isEditMode;
            _this.observationProvider.notifyObservers(_1.CostManagementTileActions.ShowPointOverrideModal);
        };
        _this.showInventoryItemWeightChangeModal = function (form) {
            _this._weightChangeModalIsVisible = true;
            _this._weightChangeModalTableItem = form.model;
            _this.observationProvider.notifyObservers(_1.CostManagementTileActions.ShowWeightChangeModal);
        };
        _this.showInventoryItemAreaShareFootChangeModal = function (form) {
            _this._areaSquareFootChangeModalIsVisible = true;
            _this._areaSquareFootChangeModalTableItem = form.model;
            _this.observationProvider.notifyObservers(_1.CostManagementTileActions.ShowWeightChangeModal);
        };
        _this.hidePointOverrideModal = function () {
            _this._pointOverrideModalIsVisible = false;
            if (_this.isEditMode) {
                _this._pointOverrideForm.getFieldByKey('points').setValue(_this._pointOverrideForm.model.mostRecentPoints);
            }
            _this.observationProvider.notifyObservers(_1.CostManagementTileActions.HidePointOverrideModal);
        };
        _this.hideAreaSquareFootChangeModal = function () {
            _this._areaSquareFootChangeModalIsVisible = false;
            _this.observationProvider.notifyObservers(_1.CostManagementTileActions.HideAreaSquareFeetChangeModal);
        };
        _this.hideWeightChangeModal = function () {
            _this._weightChangeModalIsVisible = false;
            _this.observationProvider.notifyObservers(_1.CostManagementTileActions.HideWeightChangeModal);
        };
        _this.applyPointOverrideComment = function () {
            if (!_this._pointOverrideCommentField.value) {
                _this.pointsOverrideAlert.show({
                    alertType: components_1.AlertType.Error,
                    heading: 'Comment Required',
                    message: 'You must provide a comment explaining the default point value override.'
                });
                return;
            }
            _this._pointOverrideForm.model.pointsModified = true;
            _this._pointOverrideModalIsVisible = false;
            _this._pointOverrideForm.model.pointsAdjustmentDateTime = new Date();
            _this.observationProvider.notifyObservers(_1.CostManagementTileActions.HidePointOverrideModal);
        };
        _this.loadNewInventoryItem = function (inventorySearchField, businessAreaDropdown, exhibitSpaceField, form) { return __awaiter(_this, void 0, void 0, function () {
            var inventoryItemId, newItem;
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        // Note: field obersvationProvider is reset to observe once to ensure that form.setModel doesn't trigger ValueChanged
                        //       on the field in an endless loop
                        if (!inventorySearchField.selectedResults[0]) {
                            inventorySearchField.observationProvider.observeOnce(function () {
                                _this.loadNewInventoryItem(inventorySearchField, businessAreaDropdown, exhibitSpaceField, form);
                            }, components_1.FieldActions.ValueChanged);
                            return [2 /*return*/];
                        }
                        inventoryItemId = inventorySearchField.selectedResults[0].id;
                        this.observationProvider.notifyObservers(_1.CostManagementTileActions.InventoryItemLoading);
                        return [4 /*yield*/, this.api.get("".concat(__1.EndpointUrls.Events, "/").concat(this.eventId, "/GetNewCostManagementTableItemByInventoryId/").concat(inventoryItemId))];
                    case 1:
                        newItem = _a.sent();
                        form.setModel(newItem);
                        exhibitSpaceField.setValue(this._primaryExhibitSpaceId);
                        businessAreaDropdown.setValue(newItem.billedBusinessAreas.map(function (billedBusinessAreas) { return billedBusinessAreas.businessAreaId; }));
                        inventorySearchField.observationProvider.observeOnce(function () {
                            _this.loadNewInventoryItem(inventorySearchField, businessAreaDropdown, exhibitSpaceField, form);
                        }, components_1.FieldActions.ValueChanged);
                        this.observationProvider.notifyObservers(_1.CostManagementTileActions.InventoryItemLoaded);
                        return [2 /*return*/];
                }
            });
        }); };
        _this.update = function () { return __awaiter(_this, void 0, void 0, function () {
            var isContentFormValid, isServicesFormValid, isNewServicesFormValid, i, i, i;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        isContentFormValid = true;
                        isServicesFormValid = true;
                        isNewServicesFormValid = true;
                        for (i = 0; i < this._forms.length; i++) {
                            this._forms[i].submit();
                            if (!this._forms[i].isValid()) {
                                isContentFormValid = false;
                            }
                            this.setBillingBusinessAreas(i);
                        }
                        for (i = 0; i < this._serviceForms.length; i++) {
                            this._serviceForms[i].submit();
                            if (!this._serviceForms[i].isValid()) {
                                isServicesFormValid = false;
                            }
                            this.setEventServiceBillingBusinessAreas(this._serviceForms[i]);
                        }
                        for (i = 0; i < this._newServiceItemForms.length; i++) {
                            this._newServiceItemForms[i].submit();
                            if (!this._newServiceItemForms[i].isValid()) {
                                isNewServicesFormValid = false;
                            }
                            this.setEventServiceBillingBusinessAreas(this._newServiceItemForms[i]);
                        }
                        if (!(isContentFormValid && isServicesFormValid && isNewServicesFormValid)) return [3 /*break*/, 4];
                        if (!(this._forms.some(function (form) { return form.model.isRemoved; }) || this._serviceForms.some(function (form) { return form.model.isRemoved; }))) return [3 /*break*/, 1];
                        this.confirmationPrompt.show();
                        this.observationProvider.notifyObservers(_1.CostManagementTileActions.DeleteConfirmationPromptShown);
                        return [3 /*break*/, 3];
                    case 1: return [4 /*yield*/, this.save()];
                    case 2:
                        _a.sent();
                        _a.label = 3;
                    case 3: return [3 /*break*/, 5];
                    case 4:
                        if (!isContentFormValid) {
                            this.showContentItemsPane();
                        }
                        else if (!isServicesFormValid || !isNewServicesFormValid) {
                            this.showServiceItemsPane();
                        }
                        _a.label = 5;
                    case 5:
                        this.saveButton.setProcessing(false);
                        return [2 /*return*/];
                }
            });
        }); };
        _this.save = function () { return __awaiter(_this, void 0, void 0, function () {
            var err_1, combinedServiceItemForms, i, err_2;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (this.confirmationPrompt.isVisible) {
                            this.confirmationPrompt.deleteButton.setProcessing(false);
                            this.confirmationPrompt.hide();
                        }
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 3, , 4]);
                        return [4 /*yield*/, this.api.put("".concat(__1.EndpointUrls.Events, "/CostManagementItems"), this.eventId, this._forms.map(function (form) { return form.model; }))];
                    case 2:
                        _a.sent();
                        return [3 /*break*/, 4];
                    case 3:
                        err_1 = _a.sent();
                        this.alert.show({
                            alertType: components_1.AlertType.Error,
                            heading: 'Unable to Save',
                            message: 'An error occurred trying to save content items'
                        });
                        return [3 /*break*/, 4];
                    case 4:
                        _a.trys.push([4, 6, , 7]);
                        combinedServiceItemForms = __spreadArray(__spreadArray([], this._serviceForms, true), this._newServiceItemForms, true);
                        for (i = 0; i < combinedServiceItemForms.length; i++) {
                            if (this.isServiceACredit(combinedServiceItemForms[i].model)) {
                                combinedServiceItemForms[i].model.expenseType = settings_1.ExpenseType.Credit;
                                combinedServiceItemForms[i].model.allowablePercent = 100;
                            }
                            else {
                                combinedServiceItemForms[i].model.expenseType = settings_1.ExpenseType.Expense;
                            }
                        }
                        return [4 /*yield*/, this.api.put("".concat(__1.EndpointUrls.Events, "/EventServices"), this.eventId, combinedServiceItemForms.map(function (form) { return form.model; }))];
                    case 5:
                        _a.sent();
                        this._newServiceItemForms = [];
                        return [3 /*break*/, 7];
                    case 6:
                        err_2 = _a.sent();
                        this.alert.show({
                            alertType: components_1.AlertType.Error,
                            heading: 'Unable to Save',
                            message: 'An error occurred trying to save service items'
                        });
                        return [3 /*break*/, 7];
                    case 7:
                        this.deactivateEditMode();
                        return [2 /*return*/];
                }
            });
        }); };
        _this.isServiceACredit = function (service) {
            return _this._costManagmentServices.filter(function (costManagementService) { return costManagementService.id === service.costManagementServiceId; })[0].expenseType === settings_1.ExpenseType.Credit;
        };
        _this.getServicePrimaryFactor = function (service) {
            return _this._costManagmentServices.filter(function (costManagementService) { return costManagementService.id === service.costManagementServiceId; })[0].primaryCostFactor;
        };
        _this.buildDeleteItemButton = function (item, index) {
            return new button_1.default({
                leftIcon: __1.Icons.Trash,
                onClick: function () {
                    if (item.eventRentedItemId || item.eventInventoryId) {
                        item.isRemoved = item.isRemoved !== true;
                    }
                    else {
                        _this._forms.splice(index, 1);
                    }
                    _this.observationProvider.notifyObservers(_1.CostManagementTileActions.ItemRemoved);
                }
            });
        };
        _this.buildDeleteNewServiceButton = function (item, index) {
            return new button_1.default({
                leftIcon: __1.Icons.Trash,
                onClick: function () {
                    _this._newServiceItemForms.splice(index, 1);
                    _this.observationProvider.notifyObservers(_1.CostManagementTileActions.ServiceItemRemoved);
                }
            });
        };
        _this.buildDeleteServiceButton = function (item, index) {
            return new button_1.default({
                leftIcon: __1.Icons.Trash,
                onClick: function () {
                    item.isRemoved = item.isRemoved !== true;
                    _this.observationProvider.notifyObservers(_1.CostManagementTileActions.ServiceItemRemoved);
                }
            });
        };
        _this.loadData = function () { return __awaiter(_this, void 0, void 0, function () {
            var data, i, i;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.alert.hide();
                        return [4 /*yield*/, this.api.get("".concat(__1.EndpointUrls.Events, "/").concat(this.eventId, "/GetCostManagementTileData"))];
                    case 1:
                        data = _a.sent();
                        for (i = 0; i < data.items.length; i++) {
                            data.items[i].mostRecentPoints = data.items[i].points;
                        }
                        return [4 /*yield*/, this.loadServiceData()];
                    case 2:
                        _a.sent();
                        this._isEditModeLocked = data.isCostManagementTileLocked;
                        if (this._isEditModeLocked) {
                            this.alert.show({
                                alertType: components_1.AlertType.Error,
                                heading: 'Cost Management Locked',
                                message: 'Only the Event Producer (' + data.eventProducerName + ') can edit cost management after the cutoff date.'
                            });
                        }
                        this._forms = [];
                        for (i = 0; i < data.items.length; i++) {
                            this._forms.push(this.buildForm(data.items[i]));
                        }
                        this._exhibitSpaceBreadowns = data.eventExhibitSpaces;
                        this._businessAreaDistributions = data.businessAreaDistributions;
                        this._distributionTotalActualCost = data.totalActualCost;
                        this._distributionTotalBudgetedCost = data.totalBudgetedCost;
                        this._distributionTotalCredits = data.totalCredits;
                        this._distributionTotalDifference = data.totalDifference;
                        return [2 /*return*/];
                }
            });
        }); };
        _this.buildPointAdjustmentCommentsButton = function (form) {
            if (form.model.pointsAdjustmentComments) {
                return new button_1.default({
                    leftIcon: __1.Icons.Info,
                    onClick: function () { return _this.showPointOverideModal(form); }
                });
            }
            else {
                return null;
            }
        };
        _this.buildAreaSquareFeetAdjustmentCommentsButton = function (form) {
            if (form.model.inventoryItemAdjustmentOriginalAreaSquareFeet &&
                form.model.areaSquareFeet !== form.model.inventoryItemAdjustmentOriginalAreaSquareFeet) {
                return new button_1.default({
                    leftIcon: __1.Icons.Info,
                    onClick: function () { return _this.showInventoryItemAreaShareFootChangeModal(form); }
                });
            }
            else {
                return null;
            }
        };
        _this.buildWeightAdjustmentCommentsButton = function (form) {
            if (form.model.inventoryItemAdjustmentOriginalWeight &&
                form.model.weight !== form.model.inventoryItemAdjustmentOriginalWeight) {
                return new button_1.default({
                    leftIcon: __1.Icons.Info,
                    onClick: function () { return _this.showInventoryItemWeightChangeModal(form); }
                });
            }
            else {
                return null;
            }
        };
        _this.alert = new __1.Alert();
        _this._newServiceItemForms = [];
        _this._helpButton = new helpButton_1.default(helpUrls_1.HelpUrlType.CostManagementTile, null, navigator, api);
        _this.addNewItemButton = new button_1.default({
            text: 'Add Item',
            leftIcon: __1.Icons.Plus,
            onClick: function () { return _this.addItem(); },
            isVisible: false
        });
        _this.observationProvider.observeMany(function () {
            _this.addNewItemButton.setVisibility(_this.isEditMode);
        }, [_1.CostManagementTileActions.EditModeActivated, _1.CostManagementTileActions.EditModeDeactivated]);
        _this.addNewServiceItemButton = new button_1.default({
            text: 'Add Item',
            leftIcon: __1.Icons.Plus,
            onClick: function () { return _this.addNewServiceItem(); },
            isVisible: false
        });
        _this.observationProvider.observeMany(function () {
            _this.addNewServiceItemButton.setVisibility(_this.isEditMode);
        }, [_1.CostManagementTileActions.EditModeActivated, _1.CostManagementTileActions.EditModeDeactivated]);
        _this.showInventoryItemsPaneButton = new button_1.default({
            text: 'Content Items',
            onClick: function () { return _this.showContentItemsPane(); }
        });
        _this.showServiceItemsPaneButton = new button_1.default({
            text: 'Service Items',
            onClick: function () { return _this.showServiceItemsPane(); }
        });
        _this.showCostDistributionPaneButon = new button_1.default({
            text: 'Cost Distribution',
            onClick: function () { return _this.showCostDistributionPane(); }
        });
        _this.observationProvider.observe(function () {
            _this.processLoadData();
        }, _1.CostManagementTileActions.EditModeDeactivated);
        _this.showContentItemsPane();
        _this.loadExhibitSpaceOptions();
        _this.loadBillableBusinessAreas();
        _this.applyPointOverrideModalButton = new button_1.default({
            text: 'Set Comment',
            onClick: function () { return _this.applyPointOverrideComment(); }
        });
        _this.cancelPointOverrideModalButton = new button_1.default({
            text: 'Cancel',
            onClick: function () { return _this.hidePointOverrideModal(); }
        });
        _this.hideWeightChangeModalButton = new button_1.default({
            text: 'Close',
            onClick: function () { return _this.hideWeightChangeModal(); }
        });
        _this.hideAreaSquareFootChangeModalButton = new button_1.default({
            text: 'Close',
            onClick: function () { return _this.hideAreaSquareFootChangeModal(); }
        });
        _this.pointsOverrideAlert = new __1.Alert();
        _this.confirmationPrompt = new components_1.ConfirmationPrompt(true, 'Removing Items', 'Saving will remove the items you chose to be deleted. Are you sure you want to proceed and these remove items?', 'Proceed', 'Cancel');
        _this.confirmationPrompt.observationProvider.observe(_this.save, components_1.ConfirmationPromptActions.Confirmed);
        _this.confirmationPrompt.observationProvider.observe(function () { _this.observationProvider.notifyObservers(_1.CostManagementTileActions.DeleteConfirmationPromptHidden); });
        return _this;
    }
    Object.defineProperty(CostManagementTile.prototype, "pointOverrideForm", {
        get: function () {
            return this._pointOverrideForm;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(CostManagementTile.prototype, "pointOverrideCommentField", {
        get: function () {
            return this._pointOverrideCommentField;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(CostManagementTile.prototype, "pointOverrideModalIsVisible", {
        get: function () {
            return this._pointOverrideModalIsVisible;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(CostManagementTile.prototype, "weightChangeModalIsVisible", {
        get: function () {
            return this._weightChangeModalIsVisible;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(CostManagementTile.prototype, "weightChangeModalTableItem", {
        get: function () {
            return this._weightChangeModalTableItem;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(CostManagementTile.prototype, "areaSquareFootChangeModalIsVisible", {
        get: function () {
            return this._areaSquareFootChangeModalIsVisible;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(CostManagementTile.prototype, "areaSquareFootChangeModalTableItem", {
        get: function () {
            return this._areaSquareFootChangeModalTableItem;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(CostManagementTile.prototype, "isEditModeLocked", {
        get: function () {
            return this._isEditModeLocked;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(CostManagementTile.prototype, "forms", {
        get: function () {
            return this._forms;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(CostManagementTile.prototype, "serviceForms", {
        get: function () {
            return this._serviceForms;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(CostManagementTile.prototype, "totalEstimatedCost", {
        get: function () {
            return this._totalEstimatedCost;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(CostManagementTile.prototype, "totalActualCost", {
        get: function () {
            return this._totalActualCost;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(CostManagementTile.prototype, "totalCostDifference", {
        get: function () {
            return this._totalCostDifference;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(CostManagementTile.prototype, "currentPane", {
        get: function () {
            return this._currentPane;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(CostManagementTile.prototype, "exhibitSpaceBreakdowns", {
        get: function () {
            return this._exhibitSpaceBreadowns;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(CostManagementTile.prototype, "billableBusinessAreas", {
        get: function () {
            return this._billableBusinessAreas;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(CostManagementTile.prototype, "businessAreaDistributions", {
        get: function () {
            return this._businessAreaDistributions;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(CostManagementTile.prototype, "distributionTotalActualCost", {
        get: function () {
            return this._distributionTotalActualCost;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(CostManagementTile.prototype, "distributionTotalBudgetedCost", {
        get: function () {
            return this._distributionTotalBudgetedCost;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(CostManagementTile.prototype, "distributionTotalCredits", {
        get: function () {
            return this._distributionTotalCredits;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(CostManagementTile.prototype, "distributionTotalDifference", {
        get: function () {
            return this._distributionTotalDifference;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(CostManagementTile.prototype, "newServiceItemForms", {
        get: function () {
            return this._newServiceItemForms;
        },
        enumerable: false,
        configurable: true
    });
    CostManagementTile.prototype.calculateServiceTotals = function () {
        this._totalEstimatedCost = this.getEstimatedCostTotal();
        this._totalActualCost = this.getActualCostTotal();
        this._totalCostDifference = this.totalActualCost - this.totalEstimatedCost;
    };
    CostManagementTile.prototype.getEstimatedCostTotal = function () {
        var total = 0;
        for (var i = 0; i < this.serviceForms.length; i++) {
            var field = this.serviceForms[i].getFieldByKey('estimatedCost');
            if (this.isServiceACredit(this.serviceForms[i].model)) {
                total -= field.value;
            }
            else {
                total += field.value;
            }
        }
        return total;
    };
    CostManagementTile.prototype.getActualCostTotal = function () {
        var total = 0;
        for (var i = 0; i < this.serviceForms.length; i++) {
            var field = this.serviceForms[i].getFieldByKey('actualCost');
            if (this.isServiceACredit(this.serviceForms[i].model)) {
                total -= field.value;
            }
            else {
                total += field.value;
            }
        }
        return total;
    };
    CostManagementTile.prototype.setEventServiceBillingBusinessAreas = function (form) {
        var selectedBusinessAreas = form.getFieldByKey('businessAreas').selectedBusinessAreas;
        // set is deleted
        for (var i = 0; i < form.model.billedBusinessAreas.length; i++) {
            var isBusinessAreaSelected = false;
            for (var j = 0; j < selectedBusinessAreas.length; j++) {
                if (selectedBusinessAreas[j].businessAreaId === form.model.billedBusinessAreas[i].businessAreaId) {
                    isBusinessAreaSelected = true;
                    break;
                }
            }
            if (form.model.billedBusinessAreas[i].id && !isBusinessAreaSelected) {
                form.model.billedBusinessAreas[i].isRemoved = true;
            }
            if (isBusinessAreaSelected) {
                form.model.billedBusinessAreas[i].isRemoved = false;
            }
        }
        // add new BA's
        var newBusinessAreas = [];
        var _loop_1 = function (i) {
            var selected = selectedBusinessAreas[i];
            if (form.model.billedBusinessAreas.findIndex(function (existingBusinessArea) { return existingBusinessArea.businessAreaId === selected.businessAreaId; }) < 0) {
                newBusinessAreas.push({
                    businessAreaId: selected.businessAreaId,
                    businessArea: selected.name,
                });
            }
        };
        for (var i = 0; i < selectedBusinessAreas.length; i++) {
            _loop_1(i);
        }
        for (var i = 0; i < newBusinessAreas.length; i++) {
            form.model.billedBusinessAreas.push(newBusinessAreas[i]);
        }
    };
    CostManagementTile.prototype.setBillingBusinessAreas = function (index) {
        var form = this._forms[index];
        var selectedBusinessAreas = form.fields[11].selectedBusinessAreas;
        // set is deleted
        for (var i = 0; i < form.model.billedBusinessAreas.length; i++) {
            var isBusinessAreaSelected = false;
            for (var j = 0; j < selectedBusinessAreas.length; j++) {
                if (selectedBusinessAreas[j].businessAreaId === form.model.billedBusinessAreas[i].businessAreaId) {
                    isBusinessAreaSelected = true;
                    break;
                }
            }
            if (form.model.billedBusinessAreas[i].id && !isBusinessAreaSelected) {
                form.model.billedBusinessAreas[i].isRemoved = true;
            }
            if (isBusinessAreaSelected) {
                form.model.billedBusinessAreas[i].isRemoved = false;
            }
        }
        // add new BA's
        var newBusinessAreas = [];
        var _loop_2 = function (i) {
            var selected = selectedBusinessAreas[i];
            if (form.model.billedBusinessAreas.findIndex(function (existingBusinessArea) { return existingBusinessArea.businessAreaId === selected.businessAreaId; }) < 0) {
                newBusinessAreas.push({
                    businessAreaId: selected.businessAreaId,
                    businessArea: selected.name,
                });
            }
        };
        for (var i = 0; i < selectedBusinessAreas.length; i++) {
            _loop_2(i);
        }
        for (var i = 0; i < newBusinessAreas.length; i++) {
            form.model.billedBusinessAreas.push(newBusinessAreas[i]);
        }
    };
    return CostManagementTile;
}(__1.EventLandingTile));
exports.default = CostManagementTile;
