"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
var __1 = require("../..");
var SsoLoginPage = /** @class */ (function (_super) {
    __extends(SsoLoginPage, _super);
    function SsoLoginPage(navigator, currentUserManager) {
        var _this = _super.call(this, __1.PageTypes.SsoLogin, 'Login') || this;
        _this.navigator = navigator;
        _this.currentUserManager = currentUserManager;
        _this.SSO_USER_COOKIE = 'SSO-User';
        _this.parseCookies = function (cookieHeader) {
            return cookieHeader
                .split(';')
                .map(function (cookie) { return cookie.split('='); })
                .map(function (_a) {
                var name = _a[0], value = _a[1];
                return ({ name: decodeURIComponent(name), value: decodeURIComponent(value) });
            })
                .filter(function (cookie) { return cookie.name && cookie.value; });
        };
        _this.deleteCookie = function (name) {
            document.cookie = "".concat(name, "=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;");
        };
        if (_this.currentUserManager.isLoggedIn) {
            setTimeout(function () { return _this.navigator.goToEventList(); }, 1000);
        }
        else {
            var cookies = _this.parseCookies(document.cookie);
            var ssoUserCookie = cookies.find(function (cookie) { return cookie.name === _this.SSO_USER_COOKIE; });
            if (ssoUserCookie && ssoUserCookie.value) {
                var ssoUser_1 = JSON.parse(ssoUserCookie.value);
                setTimeout(function () { return _this.currentUserManager.login(ssoUser_1); }, 1000);
                _this.deleteCookie(_this.SSO_USER_COOKIE);
            }
            else {
                setTimeout(function () { return _this.navigator.goToLogin(); }, 1000);
            }
        }
        return _this;
    }
    return SsoLoginPage;
}(__1.BasePage));
exports.default = SsoLoginPage;
