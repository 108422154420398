"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g = Object.create((typeof Iterator === "function" ? Iterator : Object).prototype);
    return g.next = verb(0), g["throw"] = verb(1), g["return"] = verb(2), typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
var __1 = require("../..");
var button_1 = require("../../components/button/button");
var api_1 = require("../../api");
var components_1 = require("../../components");
var helpUrls_1 = require("../helpUrls");
var helpButton_1 = require("../../components/button/helpButton");
var UserGroupDetailPage = /** @class */ (function (_super) {
    __extends(UserGroupDetailPage, _super);
    function UserGroupDetailPage(api, navigator, currentUserManager, _recordId) {
        var _this = _super.call(this, __1.PageTypes.UserGroupDetail, '') || this;
        _this.api = api;
        _this.navigator = navigator;
        _this.currentUserManager = currentUserManager;
        _this._recordId = _recordId;
        _this.delete = function () { return __awaiter(_this, void 0, void 0, function () {
            var err_1;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this._isLoaded = false;
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 3, , 4]);
                        return [4 /*yield*/, this.api.delete(__1.EndpointUrls.UserGroups, this.recordId)];
                    case 2:
                        _a.sent();
                        this.navigator.goToUserGroupList();
                        this.observationProvider.notifyObservers(__1.UserGroupDetailPageActions.RecordDeleted, this.form.model);
                        return [3 /*break*/, 4];
                    case 3:
                        err_1 = _a.sent();
                        this.alert.show({
                            heading: 'Unable to Delete',
                            message: 'An error occurred while trying to delete the user group',
                            alertType: components_1.AlertType.Error
                        });
                        this._isLoaded = true;
                        this._confirmationPrompt.hide();
                        return [3 /*break*/, 4];
                    case 4: return [2 /*return*/];
                }
            });
        }); };
        _this.formSubmitted = function () { return __awaiter(_this, void 0, void 0, function () {
            var _a, err_2;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        this.alert.hide();
                        _b.label = 1;
                    case 1:
                        _b.trys.push([1, 6, , 7]);
                        if (!this._recordId) return [3 /*break*/, 3];
                        this.observationProvider.notifyObservers(__1.UserGroupDetailPageActions.RecordEditing, this._form.model);
                        return [4 /*yield*/, this.api.put(__1.EndpointUrls.UserGroups, this._recordId, this._form.model)];
                    case 2:
                        _b.sent();
                        this.observationProvider.notifyObservers(__1.UserGroupDetailPageActions.RecordEdited, this._form.model);
                        this.saveButton.setProcessing(false);
                        this.navigator.goToUserGroupList();
                        return [3 /*break*/, 5];
                    case 3:
                        this.observationProvider.notifyObservers(__1.UserGroupDetailPageActions.RecordAdding, this._form.model);
                        _a = this._form.model;
                        return [4 /*yield*/, this.api.post(__1.EndpointUrls.UserGroups, this._form.model)];
                    case 4:
                        _a.id = _b.sent();
                        this.observationProvider.notifyObservers(__1.UserGroupDetailPageActions.RecordAdded, this._form.model);
                        this.saveButton.setProcessing(false);
                        this.navigator.goToUserGroupList();
                        _b.label = 5;
                    case 5: return [3 /*break*/, 7];
                    case 6:
                        err_2 = _b.sent();
                        this.saveButton.setProcessing(false);
                        this.alert.show({
                            alertType: components_1.AlertType.Error,
                            message: 'An error occurred while trying to save this user group',
                            heading: 'Unable to Save'
                        });
                        return [3 /*break*/, 7];
                    case 7: return [2 /*return*/];
                }
            });
        }); };
        _this.buildUserLinkButton = function (user) {
            return new button_1.default({
                text: user.fullName,
                onClick: function () { return _this.navigator.goToUserDetail(user.id); }
            });
        };
        _this.helpButton = new helpButton_1.default(helpUrls_1.HelpUrlType.UserGroupDetailPage, null, navigator, api);
        _this.observationProvider = new __1.ObservationProvider();
        _this.buildForm();
        if (_recordId) {
            _this.title = 'Edit User Group';
            _this.loadRecord();
        }
        else {
            _this.title = 'New User Group';
            _this._isLoaded = true;
            _this.form.setModel({
                users: []
            });
            _this.observationProvider.notifyObservers(__1.UserGroupDetailPageActions.RecordLoaded);
        }
        _this.cancelButton = new button_1.default({
            text: 'Cancel',
            onClick: _this.navigator.goBack
        });
        _this.saveButton = new button_1.default({
            text: 'Save',
            onClick: _this.form.submit,
            processingText: 'Saving...'
        });
        _this._confirmationPrompt = new components_1.ConfirmationPrompt(!!_recordId, 'Delete Group', 'Deleting this user group will remove all users from the group. Are you sure you want to delete this user group?', 'Delete', 'Cancel');
        _this._confirmationPrompt.observationProvider.observe(_this.delete, components_1.ConfirmationPromptActions.Confirmed);
        _this.deleteButton = new button_1.default({
            onClick: _this._confirmationPrompt.show,
            leftIcon: components_1.Icons.Trash,
            isVisible: !!_this.recordId
        });
        return _this;
    }
    Object.defineProperty(UserGroupDetailPage.prototype, "form", {
        get: function () {
            return this._form;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(UserGroupDetailPage.prototype, "isLoaded", {
        get: function () {
            return this._isLoaded;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(UserGroupDetailPage.prototype, "recordId", {
        get: function () {
            return this._recordId;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(UserGroupDetailPage.prototype, "confirmationPrompt", {
        get: function () {
            return this._confirmationPrompt;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(UserGroupDetailPage.prototype, "isApp", {
        get: function () {
            return this.currentUserManager.clientChecker.isMobileApp();
        },
        enumerable: false,
        configurable: true
    });
    UserGroupDetailPage.prototype.loadRecord = function () {
        return __awaiter(this, void 0, void 0, function () {
            var group;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.api.get(__1.EndpointUrls.UserGroups + '/' + this.recordId)];
                    case 1:
                        group = _a.sent();
                        this._form.setModel(group);
                        this._isLoaded = true;
                        this.observationProvider.notifyObservers(__1.UserGroupDetailPageActions.RecordAdded);
                        return [2 /*return*/];
                }
            });
        });
    };
    UserGroupDetailPage.prototype.buildForm = function () {
        var editInventoryCheckbox = new __1.CheckBoxField('editInventory', 'Edit inventory');
        var scheduleInventoryCheckbox = new __1.CheckBoxField('scheduleInventory', 'Schedule Inventory');
        var viewInventoryForecastCheckbox = new __1.CheckBoxField('viewInventoryForecast', 'View Inventory Forecast');
        var meetingNotesCheckbox = new __1.CheckBoxField('viewAllMeetingNotes', 'View / Add Meeting notes to all meetings');
        if (!this.currentUserManager.hasFeatureEnabled(api_1.FeatureType.Inventory)) {
            editInventoryCheckbox.setVisibility(false);
            scheduleInventoryCheckbox.setVisibility(false);
            viewInventoryForecastCheckbox.setVisibility(false);
        }
        if (!this.currentUserManager.hasFeatureEnabled(api_1.FeatureType.MeetingNotes)) {
            meetingNotesCheckbox.setVisibility(false);
        }
        var fields = [
            new __1.TextField('name', 'Group Name', true, 100, 'Group name is required'),
            new __1.CheckBoxField('editUsers', 'Edit Users'),
            new __1.CheckBoxField('editUsersAdvanced', 'Edit user permissions'),
            new __1.CheckBoxField('editUserGroups', 'Edit user groups'),
            new __1.CheckBoxField('editCustomers', 'Edit customers'),
            new __1.CheckBoxField('addEvents', 'Add Events'),
            new __1.CheckBoxField('editAllEvents', 'Edit events'),
            new __1.CheckBoxField('accessAllEvents', 'Access all events'),
            new __1.CheckBoxField('eventProducer', 'Can be event producer'),
            editInventoryCheckbox,
            scheduleInventoryCheckbox,
            viewInventoryForecastCheckbox,
            new __1.CheckBoxField('editSystemSettings', 'Edit System Settings'),
            new __1.CheckBoxField('editCustomerCompanies', 'Edit Customer Companies'),
            meetingNotesCheckbox,
            new __1.CheckBoxField('editVendors', 'Edit Vendor Companies and Users')
        ];
        this._form = new __1.Form(fields);
        this._form.observationProvider.observe(this.formSubmitted, __1.FormActions.FormSubmitted);
    };
    return UserGroupDetailPage;
}(__1.BasePage));
exports.default = UserGroupDetailPage;
