"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
var _1 = require(".");
var RadioButtonField = /** @class */ (function (_super) {
    __extends(RadioButtonField, _super);
    function RadioButtonField(key, label, isRequired, options, requiredValidationMessage) {
        var _this = _super.call(this, _1.FieldType.RadioButton, key, label, isRequired, null, requiredValidationMessage) || this;
        _this.key = key;
        _this.options = options;
        return _this;
    }
    Object.defineProperty(RadioButtonField.prototype, "errorMessage", {
        get: function () {
            return _super.prototype.getErrorMessage.call(this);
        },
        enumerable: false,
        configurable: true
    });
    RadioButtonField.prototype.setValue = function (newValue) {
        if (newValue) {
            this.setStringValue(newValue);
        }
        else {
            this.setStringValue(null);
        }
    };
    RadioButtonField.prototype.isValid = function () {
        var isValid = true;
        if (!this.checkRequiredStatus()) {
            isValid = false;
        }
        this.setIsValid(isValid);
        return isValid;
    };
    Object.defineProperty(RadioButtonField.prototype, "value", {
        get: function () {
            return this.stringValue;
        },
        enumerable: false,
        configurable: true
    });
    return RadioButtonField;
}(_1.BaseField));
exports.default = RadioButtonField;
