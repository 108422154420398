"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g = Object.create((typeof Iterator === "function" ? Iterator : Object).prototype);
    return g.next = verb(0), g["throw"] = verb(1), g["return"] = verb(2), typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
var __1 = require("../..");
var api_1 = require("../../api");
var components_1 = require("../../components");
var button_1 = require("../../components/button/button");
var TenantList = /** @class */ (function (_super) {
    __extends(TenantList, _super);
    function TenantList(api, navigator, currentUserManager) {
        var _this = _super.call(this, __1.PageTypes.TenantList, 'TEMS Tenants') || this;
        _this.api = api;
        _this.navigator = navigator;
        _this.currentUserManager = currentUserManager;
        _this.loadData = function () {
            return _this.api.getTablePage(api_1.EndpointUrls.Tenants, _this._table.recordsPerPage, _this._table.currentPage, _this._table.sortExpression, _this._table.searchPhrase);
        };
        _this.buildEditTenantButton = function (tenant) {
            return new button_1.default({
                text: tenant.name,
                onClick: function () { return _this.navigator.goToTenantDetailPage(tenant.id); }
            });
        };
        _this.buildSetAsActiveTenantButton = function (tenant) {
            return new button_1.default({
                text: 'Set as Active Tenant',
                onClick: function () { return _this.setActiveTenant(tenant); },
                processingText: 'Activating...'
            });
        };
        _this.setActiveTenant = function (tenant) { return __awaiter(_this, void 0, void 0, function () {
            var _a, _b;
            return __generator(this, function (_c) {
                switch (_c.label) {
                    case 0:
                        _b = (_a = this.currentUserManager).setActiveTenant;
                        return [4 /*yield*/, this.api.get(api_1.EndpointUrls.Tenants + '/' + tenant.id + '/SetActive')];
                    case 1:
                        _b.apply(_a, [_c.sent()]);
                        this.observationProvider.notifyObservers(__1.TenantListActions.ActiveTenantChanged);
                        window.location.reload();
                        return [2 /*return*/];
                }
            });
        }); };
        _this.observationProvider = new __1.ObservationProvider();
        _this.newTenantButton = new button_1.default({
            text: 'New Tenant',
            leftIcon: components_1.Icons.Plus,
            onClick: function () { return _this.navigator.goToTenantDetailPage(); }
        });
        _this._table = new __1.Table(_this.loadData, api);
        _this._table.searchBoxPlaceholder = 'By name';
        _this._table.load();
        return _this;
    }
    Object.defineProperty(TenantList.prototype, "table", {
        get: function () {
            return this._table;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(TenantList.prototype, "activeTenantId", {
        get: function () {
            return this.currentUserManager.tenantId;
        },
        enumerable: false,
        configurable: true
    });
    return TenantList;
}(__1.BasePage));
exports.default = TenantList;
