"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g = Object.create((typeof Iterator === "function" ? Iterator : Object).prototype);
    return g.next = verb(0), g["throw"] = verb(1), g["return"] = verb(2), typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
var __1 = require("../../../../..");
var components_1 = require("../../../../../components");
var helpButton_1 = require("../../../../../components/button/helpButton");
var helpUrls_1 = require("../../../../helpUrls");
var button_1 = require("../../../../../components/button/button");
var BadgingTile = /** @class */ (function (_super) {
    __extends(BadgingTile, _super);
    function BadgingTile(eventId, api, currentUserManager, visible, canEdit, navigator) {
        var _this = _super.call(this, 'Badging', api, currentUserManager.clientChecker.isMobileApp(), visible, canEdit) || this;
        _this.eventId = eventId;
        _this.currentUserManager = currentUserManager;
        _this.canEdit = canEdit;
        _this.navigator = navigator;
        _this.loadData = function () { return __awaiter(_this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                this.alert.hide();
                return [2 /*return*/, this.searchResultsTable.load()];
            });
        }); };
        _this.buildAssignNewCustomerForm = function () {
            _this._newCustomerMeetingSearchDateField = new components_1.MeetingDateDropdownField(_this.api, _this.eventId, 'meetingDate', 'Date', false);
            _this._newCustomerMeetingSearchTextField = new components_1.TextField('meetingSearch', 'Search', false, 100);
        };
        _this.searchNewCustomerMeetings = function () { return __awaiter(_this, void 0, void 0, function () {
            var _a, err_1;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        this._isSearchingNewCustomerMeetings = true;
                        this._newCustomerHasSearchedMeetings = true;
                        _b.label = 1;
                    case 1:
                        _b.trys.push([1, 3, 4, 5]);
                        this.observationProvider.notifyObservers(__1.BadgingTileActions.SearchingNewCustomerMeetingsStarted);
                        _a = this;
                        return [4 /*yield*/, this.api.get('Events/' + this.eventId + '/Meetings/Search', { date: this.newCustomerMeetingSearchDateField.value, searchPhrase: this.newCustomerMeetingSearchTextField.value })];
                    case 2:
                        _a._newCustomerMeetings = _b.sent();
                        this.observationProvider.notifyObservers(__1.BadgingTileActions.SearchingNewCustomerMeetingsComplete);
                        return [3 /*break*/, 5];
                    case 3:
                        err_1 = _b.sent();
                        return [3 /*break*/, 5];
                    case 4:
                        this._isSearchingNewCustomerMeetings = false;
                        return [7 /*endfinally*/];
                    case 5: return [2 /*return*/];
                }
            });
        }); };
        _this.loadBadingSearchResults = function () { return __awaiter(_this, void 0, void 0, function () {
            var results, err_2;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        results = {
                            totalRecordCount: 0,
                            items: []
                        };
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 3, , 4]);
                        return [4 /*yield*/, this.api.get('Events/' + this.eventId + '/Badging/Search', this._searchForm.model)];
                    case 2:
                        results = _a.sent();
                        this._searchResultForms = results.items.map(function (item) {
                            return new components_1.Form([
                                new components_1.CheckBoxField('isSelected', '', false, null),
                                new components_1.TextField('rank', '', false, 100),
                                new components_1.TextField('title', '', false, 100),
                                new components_1.TextField('firstName', '', false, 100),
                                new components_1.TextField('lastName', '', false, 100),
                                new components_1.TextField('customerCompanyName', '', false, 200),
                            ], item);
                        });
                        return [2 /*return*/, results];
                    case 3:
                        err_2 = _a.sent();
                        this.alert.show({
                            alertType: components_1.AlertType.Error,
                            heading: 'Unable to load',
                            message: 'An error occurred trying to load attendees'
                        });
                        return [3 /*break*/, 4];
                    case 4: return [2 /*return*/];
                }
            });
        }); };
        _this.update = function () { return __awaiter(_this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                return [2 /*return*/];
            });
        }); };
        _this.buildSearchForm = function () {
            _this._searchForm = new components_1.Form([
                new components_1.MeetingDateDropdownField(_this.api, _this.eventId, 'meetingDate', 'Date', false),
                new components_1.TimeField('meetingStartTime', 'From', false),
                new components_1.TimeField('meetingEndTime', 'To', false),
                new components_1.NumberDropdownField('badgingSearchType', 'Include', false, [{ label: 'Attendees', value: __1.BadgingSearchType.Attendees }, { label: 'Customers', value: __1.BadgingSearchType.Guests }], null, __1.BadgingSearchType.Guests, components_1.FieldType.DropDown, 'All'),
                new components_1.IntField('meetingNumber', '#', false),
                new components_1.TextField('meetingName', 'Meeting Name', false, 100),
                new components_1.TextField('customerName', 'Customer', false, 100)
            ]);
            _this._searchForm.observationProvider.observe(_this.loadData, components_1.FormActions.FormSubmitted);
            _this._searchForm.model.eventId = _this.eventId;
        };
        _this.newCustomerClicked = function () {
            _this.quickAddCustomerForm.open();
            _this.quickAddCustomerForm.saveButton.setText('Next');
        };
        _this.printButtonClicked = function () { return __awaiter(_this, void 0, void 0, function () {
            var err_3;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.alert.hide();
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 3, 4, 5]);
                        this._itemsToPrint = this._searchResultForms.filter(function (form) {
                            return form.fields[0].value === true;
                        })
                            .map(function (form) {
                            form.submit();
                            return form.model;
                        });
                        this.printButton.setDisabled(true);
                        this.printButton.setText('Printing...');
                        return [4 /*yield*/, this.api.post('Events/' + this.eventId + '/Badging/Print', this._itemsToPrint)];
                    case 2:
                        _a.sent();
                        this.observationProvider.notifyObservers(__1.BadgingTileActions.BadgesPrinted);
                        return [3 /*break*/, 5];
                    case 3:
                        err_3 = _a.sent();
                        this.alert.show({
                            alertType: components_1.AlertType.Error,
                            heading: 'Unable to Print',
                            message: 'An error occurred trying to print the selected badges'
                        });
                        return [3 /*break*/, 5];
                    case 4:
                        this.printButton.setDisabled(false);
                        this.printButton.setText('Print Badges');
                        return [7 /*endfinally*/];
                    case 5: return [2 /*return*/];
                }
            });
        }); };
        _this.printGuestBadgeClicked = function () { return __awaiter(_this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                this.observationProvider.notifyObservers(__1.BadgingTileActions.GuestBadgePrinted);
                return [2 /*return*/];
            });
        }); };
        _this.customerAdded = function () {
            var customerToAdd = _this.quickAddCustomerForm.customer;
            _this._newCustomersToAddToMeetings = [];
            _this._newCustomerHasSearchedMeetings = false;
            _this._newCustomerMeetings = [];
            var newCustomer = {
                badgingSearchType: __1.BadgingSearchType.Guests,
                eventName: _this._eventManager.eventLandingData.name,
                guestCount: null,
                id: 0,
                meetingName: 'Meeting not assigned',
                meetingNumber: null,
                badgePrintDateTime: null,
                rank: customerToAdd.rank,
                customerId: customerToAdd.id,
                eventAttendeeId: null,
                isSelected: true,
                firstName: customerToAdd.firstName,
                lastName: customerToAdd.lastName,
                title: customerToAdd.title,
                meetingEndDateTime: null,
                meetingStartDateTime: null,
                meetingTotalAttendees: 0,
                isNewCustomer: true
            };
            if (customerToAdd.newCustomerCompanyName) {
                newCustomer.customerCompanyName = customerToAdd.newCustomerCompanyName;
            }
            else {
                newCustomer.customerCompanyName = customerToAdd.customerCompanyName;
            }
            _this._searchResultForms.unshift(new components_1.Form([
                new components_1.CheckBoxField('isSelected', '', false, null),
                new components_1.TextField('rank', '', false, 100),
                new components_1.TextField('title', '', false, 100),
                new components_1.TextField('firstName', '', false, 100),
                new components_1.TextField('lastName', '', false, 100),
                new components_1.TextField('customerCompanyName', '', false, 200),
            ], newCustomer));
            _this._newCustomersToAddToMeetings.push(newCustomer);
            _this._searchResultsTable.items.unshift(newCustomer);
            _this.observationProvider.notifyObservers(__1.BadgingTileActions.NewCustomersAdded);
        };
        _this.selectNewCustomersMeeting = function (meeting) {
            _this._selectedMeetingForNewCustomers = meeting;
            _this.observationProvider.notifyObservers(__1.BadgingTileActions.NewCustomersMeetingSelected);
        };
        _this.saveNewCustomersToMeeting = function () { return __awaiter(_this, void 0, void 0, function () {
            var _loop_1, this_1, i, err_4;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!this._selectedMeetingForNewCustomers) {
                            this.closeAddNewCustomerToMeeting();
                            return [2 /*return*/];
                        }
                        this._isSearchingNewCustomerMeetings = true;
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 6, 7, 8]);
                        this.observationProvider.notifyObservers(__1.BadgingTileActions.SearchingNewCustomerMeetingsStarted);
                        _loop_1 = function () {
                            var customerId, existingSearchResultItem, existingSearchResultForm;
                            return __generator(this, function (_b) {
                                switch (_b.label) {
                                    case 0:
                                        customerId = this_1._newCustomersToAddToMeetings[i].customerId;
                                        return [4 /*yield*/, this_1.api.post('Events/' + this_1.eventId + '/Meetings/AssignCustomer', {
                                                meetingId: this_1._selectedMeetingForNewCustomers.id,
                                                customerId: customerId
                                            })];
                                    case 1:
                                        _b.sent();
                                        existingSearchResultItem = this_1._searchResultsTable.items.find(function (searchItem) { return searchItem.customerId === customerId && searchItem.isNewCustomer; });
                                        existingSearchResultItem.meetingName = this_1._selectedMeetingForNewCustomers.name;
                                        existingSearchResultItem.meetingNumber = this_1._selectedMeetingForNewCustomers.meetingNumber;
                                        existingSearchResultItem.meetingStartDateTime = this_1._selectedMeetingForNewCustomers.startDateTime;
                                        existingSearchResultItem.meetingEndDateTime = this_1._selectedMeetingForNewCustomers.endDateTime;
                                        existingSearchResultItem.meetingTotalAttendees = this_1._selectedMeetingForNewCustomers.totalAttendees;
                                        existingSearchResultItem.guestCount = this_1._selectedMeetingForNewCustomers.guestCount;
                                        existingSearchResultForm = this_1._searchResultForms.find(function (existingForm) { return existingForm.model.customerId === customerId && existingForm.model.isNewCustomer; });
                                        existingSearchResultForm.setModel(existingSearchResultItem);
                                        return [2 /*return*/];
                                }
                            });
                        };
                        this_1 = this;
                        i = 0;
                        _a.label = 2;
                    case 2:
                        if (!(i < this._newCustomersToAddToMeetings.length)) return [3 /*break*/, 5];
                        return [5 /*yield**/, _loop_1()];
                    case 3:
                        _a.sent();
                        _a.label = 4;
                    case 4:
                        i++;
                        return [3 /*break*/, 2];
                    case 5:
                        this.observationProvider.notifyObservers(__1.BadgingTileActions.SearchingNewCustomerMeetingsComplete);
                        return [3 /*break*/, 8];
                    case 6:
                        err_4 = _a.sent();
                        return [3 /*break*/, 8];
                    case 7:
                        this._isSearchingNewCustomerMeetings = false;
                        return [7 /*endfinally*/];
                    case 8:
                        this.closeAddNewCustomerToMeeting();
                        return [2 /*return*/];
                }
            });
        }); };
        _this.closeAddNewCustomerToMeeting = function () {
            _this._newCustomersToAddToMeetings = [];
            _this._selectedMeetingForNewCustomers = null;
            _this.observationProvider.notifyObservers(__1.BadgingTileActions.AddNewCustomerToMeetingClosed);
        };
        _this.storePrintSettings = function () {
            _this.currentUserManager.setBadgePrintSettings({
                printRank: _this.printRankCheckbox.value,
                printTitle: _this.printTitleCheckbox.value,
                printName: _this.printNameCheckbox.value,
                printCompanyLogo: _this.printCompanyLogoCheckbox.value,
                printCompanyName: _this.printCompanyNameCheckbox.value,
                printDayOfWeek: _this.printDayOfWeekCheckbox.value
            });
        };
        _this.logoDataUrl = currentUserManager.headerLogoImageUrl;
        _this._helpButton = new helpButton_1.default(helpUrls_1.HelpUrlType.BadgingTile, null, navigator, api);
        _this._searchResultsTable = new components_1.Table(_this.loadBadingSearchResults, _this.api, true, null, false, false);
        _this._searchResultForms = [];
        _this.buildSearchForm();
        _this.buildAssignNewCustomerForm();
        _this.newCustomerButton = new button_1.default({
            text: 'New Customer',
            onClick: _this.newCustomerClicked
        });
        _this.searchButton = new button_1.default({
            text: 'Search',
            onClick: _this.searchForm.submit
        });
        _this.printButton = new button_1.default({
            text: 'Print Badges',
            onClick: _this.printButtonClicked
        });
        _this.printGuestBadgeButton = new button_1.default({
            text: 'Print Guest Badge',
            onClick: _this.printGuestBadgeClicked
        });
        _this.newCustomerMeetingSearchButton = new button_1.default({
            text: 'Search',
            onClick: _this.searchNewCustomerMeetings
        });
        _this._newCustomerMeetings = [];
        _this.saveNewCustomersToMeetingButton = new button_1.default({
            text: 'Save',
            onClick: _this.saveNewCustomersToMeeting
        });
        _this.quickAddCustomerForm = new components_1.QuickAddCustomerForm(_this.api);
        _this.quickAddCustomerForm.observationProvider.observe(_this.customerAdded, components_1.QuickAddCustomerFormActions.CustomerCreated);
        _this.printButton.setDisabled(_this.currentUserManager.clientChecker.isMobileApp());
        _this.includeSeparatorCardCheckbox = new components_1.CheckBoxField('includeSeparator', 'Include Meeting Info Separator Card');
        var printSettings = _this.currentUserManager.getBadgePrintSettings();
        _this.printRankCheckbox = new components_1.CheckBoxField('printRank', 'Rank', false, null, true);
        _this.printTitleCheckbox = new components_1.CheckBoxField('printTitle', 'Title', false, null, true);
        _this.printNameCheckbox = new components_1.CheckBoxField('printName', 'Name', false, null, true);
        _this.printCompanyNameCheckbox = new components_1.CheckBoxField('printCompanyName', 'Company Name', false, null, true);
        _this.printCompanyLogoCheckbox = new components_1.CheckBoxField('printCompanyLogo', 'Company Logo', false, null, false);
        _this.printDayOfWeekCheckbox = new components_1.CheckBoxField('printDayOfWeek', 'Abbreviated Week Day', false, null, false);
        _this.printRankCheckbox.observationProvider.observe(_this.storePrintSettings, components_1.FieldActions.ValueChanged);
        _this.printTitleCheckbox.observationProvider.observe(_this.storePrintSettings, components_1.FieldActions.ValueChanged);
        _this.printNameCheckbox.observationProvider.observe(_this.storePrintSettings, components_1.FieldActions.ValueChanged);
        _this.printCompanyNameCheckbox.observationProvider.observe(_this.storePrintSettings, components_1.FieldActions.ValueChanged);
        _this.printCompanyLogoCheckbox.observationProvider.observe(_this.storePrintSettings, components_1.FieldActions.ValueChanged);
        _this.printDayOfWeekCheckbox.observationProvider.observe(_this.storePrintSettings, components_1.FieldActions.ValueChanged);
        if (printSettings) {
            _this.printRankCheckbox.setValue(printSettings.printRank);
            _this.printTitleCheckbox.setValue(printSettings.printTitle);
            _this.printNameCheckbox.setValue(printSettings.printName);
            _this.printCompanyNameCheckbox.setValue(printSettings.printCompanyName);
            _this.printCompanyLogoCheckbox.setValue(printSettings.printCompanyLogo);
            _this.printDayOfWeekCheckbox.setValue(printSettings.printDayOfWeek);
        }
        _this.alert = new __1.Alert();
        _this.closeAddNewCustomerToMeetingButton = new button_1.default({
            text: 'Don\'t assign to a meeting',
            onClick: _this.closeAddNewCustomerToMeeting
        });
        _this.api.getEventManager(eventId)
            .then(function (eventManager) {
            _this._eventManager = eventManager;
        });
        return _this;
    }
    Object.defineProperty(BadgingTile.prototype, "isSearchingNewCustomerMeetings", {
        get: function () {
            return this._isSearchingNewCustomerMeetings;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(BadgingTile.prototype, "newCustomerMeetings", {
        get: function () {
            return this._newCustomerMeetings;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(BadgingTile.prototype, "newCustomerHasSearchedMeetings", {
        get: function () {
            return this._newCustomerHasSearchedMeetings;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(BadgingTile.prototype, "itemsToPrint", {
        get: function () {
            return this._itemsToPrint;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(BadgingTile.prototype, "searchResultForms", {
        get: function () {
            return this._searchResultForms;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(BadgingTile.prototype, "searchForm", {
        get: function () {
            return this._searchForm;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(BadgingTile.prototype, "searchResultsTable", {
        get: function () {
            return this._searchResultsTable;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(BadgingTile.prototype, "newCustomersToAddToMeetings", {
        get: function () {
            return this._newCustomersToAddToMeetings;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(BadgingTile.prototype, "newCustomerMeetingSearchTextField", {
        get: function () {
            return this._newCustomerMeetingSearchTextField;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(BadgingTile.prototype, "newCustomerMeetingSearchDateField", {
        get: function () {
            return this._newCustomerMeetingSearchDateField;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(BadgingTile.prototype, "selectedMeetingForNewCustomers", {
        get: function () {
            return this._selectedMeetingForNewCustomers;
        },
        enumerable: false,
        configurable: true
    });
    return BadgingTile;
}(__1.EventLandingTile));
exports.default = BadgingTile;
