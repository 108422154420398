"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var MeetingCalendarNotificationModalActions;
(function (MeetingCalendarNotificationModalActions) {
    MeetingCalendarNotificationModalActions["DataLoaded"] = "DataLoaded";
    MeetingCalendarNotificationModalActions["Sending"] = "Sending";
    MeetingCalendarNotificationModalActions["Sent"] = "Sent";
    MeetingCalendarNotificationModalActions["Opened"] = "Opened";
    MeetingCalendarNotificationModalActions["Closing"] = "Closing";
})(MeetingCalendarNotificationModalActions || (MeetingCalendarNotificationModalActions = {}));
exports.default = MeetingCalendarNotificationModalActions;
