"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g = Object.create((typeof Iterator === "function" ? Iterator : Object).prototype);
    return g.next = verb(0), g["throw"] = verb(1), g["return"] = verb(2), typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
var __1 = require("../../..");
var components_1 = require("../../../components");
var fulfillment_1 = require("../fulfillment");
var helpButton_1 = require("../../../components/button/helpButton");
var button_1 = require("../../../components/button/button");
var EmailAndServicesTile = /** @class */ (function (_super) {
    __extends(EmailAndServicesTile, _super);
    function EmailAndServicesTile(eventId, tenantId, currentUserManager, api, isVisible, canEdit, navigator) {
        var _this = _super.call(this, 'Email / Services', api, currentUserManager.clientChecker.isMobileApp(), isVisible, canEdit) || this;
        _this.eventId = eventId;
        _this.tenantId = tenantId;
        _this.currentUserManager = currentUserManager;
        _this.api = api;
        _this.navigator = navigator;
        _this.editModeChanged = function () {
            _this.alert.hide();
            if (_this._checkAllCheckbox) {
                _this._checkAllCheckbox.setVisibility(_this.isEditMode);
            }
            _this.setAttendeeCheckboxVisibility();
        };
        _this.setAttendeeCheckboxVisibility = function () {
            if (!_this._tileData || !_this._tileData.attendees) {
                return;
            }
            for (var i = 0; i < _this._tileData.attendees.length; i++) {
                var checkbox = _this._tileData.attendees[i].checkbox;
                if (checkbox) {
                    checkbox.setVisibility(_this.isEditMode && _this.shouldShowAttendeeDetails(_this._tileData.attendees[i]));
                }
            }
        };
        _this.loadData = function () { return __awaiter(_this, void 0, void 0, function () {
            var _a, i, attendee;
            var _this = this;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _a = this;
                        return [4 /*yield*/, this.api.get("".concat(__1.EndpointUrls.Events, "/GetEmailAndServicesTileData"), this.listOptionSlideContainer.appliedFiltersModel)];
                    case 1:
                        _a._tileData = _b.sent();
                        for (i = 0; i < this._tileData.attendees.length; i++) {
                            this._tileData.attendees[i].checkbox = new __1.CheckBoxField('selected', '');
                            this._tileData.attendees[i].checkbox.observationProvider.observe(function () {
                                if (_this._isSelectingAll) {
                                    return;
                                }
                                _this._isSelectingSingleItem = true;
                                _this._checkAllCheckbox.setValue(false);
                                _this._isSelectingSingleItem = false;
                            }, components_1.FieldActions.ValueChanged);
                            attendee = this._tileData.attendees[i];
                            this._tileData.attendees[i].requiresAccommodations =
                                attendee.isAttending &&
                                    attendee.needsAccommodations &&
                                    attendee.isActive && (attendee.dailyTransportStatus != __1.DailyTransportStatus.Cancelled ||
                                    attendee.lodgingStatus != __1.LodgingStatus.Cancelled);
                            if (attendee.requiresAccommodations) {
                                attendee.requiresAccommodationsGroup = 'Requires Accommodations';
                            }
                            else {
                                attendee.requiresAccommodationsGroup = 'No Accommodations Required';
                                if (attendee.dailyTransportStatus == __1.DailyTransportStatus.Cancelled || attendee.lodgingStatus == __1.LodgingStatus.Cancelled) {
                                    attendee.accommodationsNotRequiredSubGroup = 'Cancelled';
                                }
                                else if (!attendee.isAttending || !attendee.isActive) {
                                    attendee.accommodationsNotRequiredSubGroup = 'Not Attending';
                                }
                                else if (!attendee.needsAccommodations) {
                                    attendee.accommodationsNotRequiredSubGroup = 'Attending without accommodations';
                                }
                            }
                            this._tileData.attendees[i].emailField = new __1.EmailField('email', true, 'Contact Email');
                            if (this._tileData.attendees[i].contactEmail) {
                                this._tileData.attendees[i].emailField.setValue(this._tileData.attendees[i].contactEmail);
                            }
                            else {
                                this._tileData.attendees[i].emailField.setValue(this._tileData.attendees[i].usersContactEmail);
                            }
                        }
                        this.setAttendeeCheckboxVisibility();
                        this._attendeeGroups = __1.ArrayUtils.groupBy([
                            {
                                key: 'requiresAccommodationsGroup',
                                enabled: true,
                                defaultHeading: 'No Accommodations Needed'
                            },
                            {
                                key: 'accommodationsNotRequiredSubGroup',
                                enabled: true
                            }
                        ], this._tileData.attendees, 'sortName').reverse();
                        this._noResults = !this._tileData.attendees || this._tileData.attendees.length === 0;
                        return [2 /*return*/];
                }
            });
        }); };
        _this.sendEmails = function () { return __awaiter(_this, void 0, void 0, function () {
            var selectedAttendees, i, request, e_1;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!this._emailTemplateDropdown.value) {
                            this.sendEmailsButton.setProcessing(false);
                            this.alert.show({
                                alertType: components_1.AlertType.Error,
                                heading: 'Select Template',
                                message: 'You must select an email template'
                            });
                            return [2 /*return*/];
                        }
                        selectedAttendees = this._tileData.attendees
                            .filter(function (attendee) { return attendee.checkbox.value == true; });
                        for (i = 0; i < selectedAttendees.length; i++) {
                            if (!selectedAttendees[i].emailField.isValid()) {
                                this.sendEmailsButton.setProcessing(false);
                                this.alert.show({
                                    alertType: components_1.AlertType.Error,
                                    heading: 'Invalid Emails',
                                    message: 'One or more selected attendees has an invalid email address'
                                });
                                return [2 /*return*/];
                            }
                        }
                        if (!selectedAttendees.length) {
                            this.sendEmailsButton.setProcessing(false);
                            this.alert.show({
                                alertType: components_1.AlertType.Error,
                                heading: 'Select Attendees',
                                message: 'No attendees are selected'
                            });
                            return [2 /*return*/];
                        }
                        this.observationProvider.notifyObservers(__1.EmailAndServicesTileActions.SendingEmails);
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 3, , 4]);
                        request = {
                            emailTemplateId: this._emailTemplateDropdown.value,
                            receipients: selectedAttendees.map(function (attendee) {
                                return {
                                    emailAddress: attendee.emailField.value,
                                    eventAttendeeId: attendee.eventAttendeeId
                                };
                            }),
                            ccEmailAddresses: this._ccEmailField.value
                        };
                        return [4 /*yield*/, this.api.post('Vendors/VendorPortal/SendEmails', request)];
                    case 2:
                        _a.sent();
                        this.sendEmailsButton.setProcessing(false);
                        this.alert.show({
                            alertType: components_1.AlertType.Success,
                            heading: 'Email(s) Sent',
                            message: 'Emails were sent to the selected attendees'
                        });
                        return [3 /*break*/, 4];
                    case 3:
                        e_1 = _a.sent();
                        this.sendEmailsButton.setProcessing(false);
                        this.alert.show({
                            alertType: components_1.AlertType.Error,
                            heading: 'Unable to Send',
                            message: 'An error occurred trying to send emails'
                        });
                        return [3 /*break*/, 4];
                    case 4:
                        this.observationProvider.notifyObservers(__1.EmailAndServicesTileActions.EmailsSent);
                        return [2 /*return*/];
                }
            });
        }); };
        _this.selectAllCheckboxChanged = function () {
            if (_this._isSelectingSingleItem) {
                return;
            }
            _this._isSelectingAll = true;
            for (var i = 0; i < _this._tileData.attendees.length; i++) {
                if (_this._tileData.attendees[i].needsAccommodations === true && _this._tileData.attendees[i].isActive) {
                    _this._tileData.attendees[i].checkbox.setValue(_this._checkAllCheckbox.value);
                }
            }
            _this._isSelectingAll = false;
        };
        _this.buildFulfillButton = function (attendee) {
            var btn = new button_1.default({
                onClick: function () { return _this.fulfillmentForm.show(attendee.eventAttendeeId); },
                text: attendee.fullName
            });
            btn.setDisabled(!_this.shouldShowAttendeeDetails(attendee));
            return btn;
        };
        // todo get tenant email templates
        _this._emailTemplateDropdown = new __1.EmailTemplateDropdown(api, 'emailTemplateId');
        _this._helpButton = new helpButton_1.default(__1.HelpUrlType.EmailAndServicesTile, null, navigator, api);
        _this._checkAllCheckbox = new __1.CheckBoxField('selectAll', 'Select All');
        _this._checkAllCheckbox.observationProvider.observe(_this.selectAllCheckboxChanged, components_1.FieldActions.ValueChanged);
        _this._ccEmailField = new components_1.MultipleEmailField('', false, 'Email cc');
        _this.sendEmailsButton = new button_1.default({
            onClick: _this.sendEmails,
            text: 'Send Email(s)',
            processingText: 'Sending...'
        });
        _this.alert = new components_1.Alert();
        // todo fulfillment form w/o vendor company id
        _this.fulfillmentForm = new fulfillment_1.FulfillmentForm(_this.api, _this.eventId, _this.currentUserManager.vendorCompanyId);
        _this.fulfillmentForm.observationProvider.observe(function () {
            _this.deactivateEditMode();
            _this.processLoadData();
        }, fulfillment_1.VendorFulfillmentFormActions.Saved);
        var statusOptions = [
            { label: 'Not Yet Submitted', value: __1.DailyTransportStatus.NotSubmitted },
            { label: 'Pending', value: __1.DailyTransportStatus.Pending },
            { label: 'Confirmed', value: __1.DailyTransportStatus.Confirmed },
            { label: 'Confirmed with Changes', value: __1.DailyTransportStatus.ConfirmedWithChanges },
            { label: 'Cancelled', value: __1.DailyTransportStatus.Cancelled },
        ];
        var filters = [
            new components_1.TextField('name', 'Attendee name', false, 200),
            new __1.EmailField('email', false, 'Attendee email'),
            new components_1.NumberDropdownField('dailyTransportStatus', 'Transportation Status', false, statusOptions, null, null, components_1.FieldType.DropDown, 'All'),
            new components_1.NumberDropdownField('lodgingStatus', 'Hotel Status', false, statusOptions, null, null, components_1.FieldType.DropDown, 'All')
        ];
        var defaultFilters = {
            tenantId: tenantId,
            eventId: eventId
        };
        _this.listOptionSlideContainer = new components_1.ListOptionSlideContainer(filters, defaultFilters, [], ['eventId', 'tenantId']);
        _this.listOptionSlideContainer.observationProvider.observe(_this.processLoadData, components_1.ListOptionSlideContainerActions.OptionsApplied);
        _this.observationProvider.observeMany(_this.editModeChanged, [__1.EmailAndServicesTileActions.EditModeActivated, __1.EmailAndServicesTileActions.EditModeDeactivated]);
        return _this;
    }
    Object.defineProperty(EmailAndServicesTile.prototype, "noResults", {
        get: function () {
            return this._noResults;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(EmailAndServicesTile.prototype, "attendeeGroups", {
        get: function () {
            return this._attendeeGroups;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(EmailAndServicesTile.prototype, "tileData", {
        get: function () {
            return this._tileData;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(EmailAndServicesTile.prototype, "checkAllCheckbox", {
        get: function () {
            return this._checkAllCheckbox;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(EmailAndServicesTile.prototype, "emailTemplateDropdown", {
        get: function () {
            return this._emailTemplateDropdown;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(EmailAndServicesTile.prototype, "ccEmailField", {
        get: function () {
            return this._ccEmailField;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(EmailAndServicesTile.prototype, "isHotelVendor", {
        get: function () {
            return this._isHotelVendor;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(EmailAndServicesTile.prototype, "isTransportationVendor", {
        get: function () {
            return this._isTransportationVendor;
        },
        enumerable: false,
        configurable: true
    });
    EmailAndServicesTile.prototype.setIsTransportationVendor = function (isTransportationVendor) {
        this._isTransportationVendor = isTransportationVendor;
        this.fulfillmentForm.setIsTransportationVendor(isTransportationVendor);
        this.observationProvider.notifyObservers(__1.EmailAndServicesTileActions.VendorTypeChanged);
    };
    EmailAndServicesTile.prototype.setIsHotelVendor = function (isHotelVendor) {
        this._isHotelVendor = isHotelVendor;
        this.fulfillmentForm.setIsHotelVendor(isHotelVendor);
        this.observationProvider.notifyObservers(__1.EmailAndServicesTileActions.VendorTypeChanged);
    };
    EmailAndServicesTile.prototype.shouldShowAttendeeDetails = function (attendee) {
        return (attendee.isActive &&
            attendee.needsAccommodations) ||
            attendee.lodgingStatus == __1.LodgingStatus.Cancelled ||
            attendee.dailyTransportStatus == __1.DailyTransportStatus.Cancelled;
    };
    return EmailAndServicesTile;
}(__1.EventLandingTile));
exports.default = EmailAndServicesTile;
