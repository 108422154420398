import { FieldActions, ActionUtils, BaseField, FieldType } from 'core.frontend';
import { Component, h, JSX } from 'preact';
import ObservingComponent from '../componentBases/observingComponent';
import FieldIcon from './fieldIcon';
import PhoneInput, { parsePhoneNumber, getCountryCallingCode, FlagProps } from 'react-phone-number-input';
import 'react-phone-number-input/style.css'

export interface InternationalPhoneFieldProps {
    id?: string;
    field: BaseField<string>;
    labelClassName?: string;
    className?: string;
    leftIcon?: string;
    isLoading?: boolean;
}

const CustomFlag = ({country, countryName, flagUrl, flags}: FlagProps): JSX.Element => {

    const flagIconUrl = flagUrl.replace('{XX}', country);
    let countryCode = '';

    if (country) {
        countryCode = getCountryCallingCode(country);
    }

    return (
        <div class="country-selection-icon-wrapper">
            {countryCode &&
                <img src={flagIconUrl} />
            }

            {countryCode &&
                <span>+{countryCode}</span>
            }
        </div>
    )
};

class InternationalPhoneField extends ObservingComponent<InternationalPhoneFieldProps> {

    public componentWillMount(): void {
        const { field } = this.props;

        this.registerUpdateObserver(field.observationProvider, ActionUtils.actionsToArray(FieldActions, [FieldActions.Hidden, FieldActions.Shown]));
    }

    public valueChanged = (newValue: string) => {
        const { field } = this.props;
        field.setValue(newValue);
    }

    public render({ field, labelClassName, className = '', leftIcon = '', id }: InternationalPhoneFieldProps): JSX.Element {

        if (!id)
        {
            id = field.uniqueKey;
        }

        let value = field.stringValue;
        let countryCodePrefix = '';
        const phoneNumber = parsePhoneNumber(field.stringValue);

        if (phoneNumber && phoneNumber.countryCallingCode) {
            countryCodePrefix = '+' + phoneNumber.countryCallingCode;
        }


        return (
            <div className={`input-field ${className}`}>

                { leftIcon && <FieldIcon icon={leftIcon} invalid={field.isInvalid} /> }

                <label
                    className={labelClassName}
                    for={id}>

                    <span class="fill">{field.label}</span>

                    {field.labelButtonText && <a onClick={field.handleLabelButtonClick}>{field.labelButtonText}</a>}

                    { field.errorMessage && (
                        <span className="invalid">{field.errorMessage}</span>
                    )}
                </label>

                <PhoneInput
                    onFocus={field.focus}
                    onBlur={field.blur}
                    onChange={this.valueChanged}
                    className={field.isInvalid ? 'invalid' : ''}
                    disabled={field.isDisabled}
                    id={id}
                    name={field.uniqueKey}
                    value={value}
                    international={false}
                    defaultCountry="US"
                    initialValueFormat="national"
                    useNationalFormatForDefaultCountryValue={true}
                    flagComponent={CustomFlag} />

                { field.isActive && !field.stringValue && field.placeholderLabel && <label class="placeholder-label">{ field.placeholderLabel }</label> }

            </div>
        );
    }
}

export default InternationalPhoneField;
