"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var ActionUtils = /** @class */ (function () {
    function ActionUtils() {
    }
    ActionUtils.actionsToArray = function (action, exclusions) {
        var arr = [];
        for (var key in action) {
            if (action.hasOwnProperty(key)) {
                if (!exclusions || exclusions.indexOf(action[key]) === -1) {
                    arr.push(action[key]);
                }
            }
        }
        return arr;
    };
    return ActionUtils;
}());
exports.default = ActionUtils;
