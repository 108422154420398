"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g = Object.create((typeof Iterator === "function" ? Iterator : Object).prototype);
    return g.next = verb(0), g["throw"] = verb(1), g["return"] = verb(2), typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
var endpointUrls_1 = require("./endpointUrls");
var _1 = require(".");
var utils_1 = require("../utils");
var pages_1 = require("../pages");
var __1 = require("..");
var app_1 = require("../app");
var Api = /** @class */ (function () {
    function Api(apiPlugin, config, currentUserManager) {
        var _this = this;
        this.apiPlugin = apiPlugin;
        this.config = config;
        this.currentUserManager = currentUserManager;
        this.buildListQueryString = function (recordsPerPage, pageNumber, sort, searchPhrase, parameters) {
            if (pageNumber === void 0) { pageNumber = 1; }
            return "?recordsPerPage=".concat(recordsPerPage)
                + "".concat(_this.buildPageNumberExpression(pageNumber))
                + "".concat(_this.buildSortExpression(sort))
                + "".concat(_this.buildSearchExpression(searchPhrase))
                + "".concat(_this.buildAdditionalParameterString(parameters));
        };
        this.buildSearchExpression = function (searchPhrase) {
            if (!searchPhrase) {
                return '';
            }
            return "&searchPhrase=".concat(searchPhrase);
        };
        this.buildSortExpression = function (sort) {
            if (!sort) {
                return '';
            }
            return "&sort=".concat(sort);
        };
        this.clearAllCache = function () {
            _this.clearCache(_1.CacheType.UserGroups);
            _this.clearCache(_1.CacheType.BusinessAreas);
            _this.clearCache(_1.CacheType.CustomerCompanies);
            _this.clearCache(_1.CacheType.LinesOfBusiness);
            _this.clearCache(_1.CacheType.Hotels);
            _this.clearCache(_1.CacheType.VendorCompanies);
            _this.clearCache(_1.CacheType.Users);
            _this.clearCache(_1.CacheType.InventoryItemTypes);
            _this.clearCache(_1.CacheType.Venues);
            _this.clearCache(_1.CacheType.VenuePreferences);
            _this.clearCache(_1.CacheType.MeetingCategories);
            _this.clearCache(_1.CacheType.MeetingDates);
            _this.clearCache(_1.CacheType.DefaultMeetingStatus);
            _this.clearCache(_1.CacheType.EventTransportVehicleOptions);
            _this.clearCache(_1.CacheType.UserTitles);
            _this.clearCache(_1.CacheType.EventLeadResponsibilities);
            _this.clearCache(_1.CacheType.DefaultMeetingCategories);
            _this.clearCache(_1.CacheType.InventoryItemGroups);
            _this.clearCache(_1.CacheType.InventoryItemCategories);
            _this.clearCache(_1.CacheType.MeetingCreators);
            _this.clearCache(_1.CacheType.MeetingConfigurations);
            _this.clearCache(_1.CacheType.EventAttendeeAccessLevels);
            _this.clearCache(_1.CacheType.ProfileImages);
            _this.clearCache(_1.CacheType.HeaderLogoImages);
            _this.clearCache(_1.CacheType.EventAttendees);
            _this.clearCache(_1.CacheType.TravelAndLodgingConfigurations);
            _this.clearCache(_1.CacheType.EmailTemplateOptions);
            _this.clearCache(_1.CacheType.BillableBusinessAreas);
            _this.clearCache(_1.CacheType.EventLandingPageData);
            _this.clearCache(_1.CacheType.HelpUrls);
            _this.clearCache(_1.CacheType.SystemAdminContactInfo);
            _this.clearCache(_1.CacheType.MeetingNotesDisclaimer);
            _this.clearCache(_1.CacheType.MeetingScheduledByOptions);
        };
        this.processError = function (err) {
            if (err && err.response && err.response.status === 401) {
                _this.currentUserManager.logout();
                throw { message: 'Unauthorized request' };
            }
            else if (err && err.response && err.response.data && err.response.data.message) {
                throw err.response.data;
            }
            else {
                throw { message: 'Unable to process request' };
            }
        };
        this.getCopyrightText = function () {
            if (!_this._copyrightText) {
                _this._copyrightText = _this.get(endpointUrls_1.default.Public + '/CopyrightText');
            }
            return _this._copyrightText;
        };
        this.getEventLeadResponsibilities = function () {
            if (!_this._eventLeadResponsibilities) {
                _this._eventLeadResponsibilities = _this.get("".concat(endpointUrls_1.default.Events, "/GetEventLeadResponsibilities"));
            }
            return _this._eventLeadResponsibilities;
        };
        this.getAllUsersForDropdown = function () {
            if (!_this._usersForDropdown) {
                _this._usersForDropdown = _this.get("".concat(endpointUrls_1.default.Users, "/GetAllForDropdown"));
            }
            return _this._usersForDropdown;
        };
        this.getLineOfBusinessName = function (lineOfBusinessId) { return __awaiter(_this, void 0, void 0, function () {
            var lineOfBusiness;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.getLinesOfBusiness()];
                    case 1:
                        lineOfBusiness = (_a.sent()).find(function (lob) { return lob.id === lineOfBusinessId; });
                        if (lineOfBusiness) {
                            return [2 /*return*/, lineOfBusiness.name];
                        }
                        else {
                            return [2 /*return*/, ''];
                        }
                        return [2 /*return*/];
                }
            });
        }); };
        this.getCustomerCompanyName = function (customerCompanyId) { return __awaiter(_this, void 0, void 0, function () {
            var customerCompany;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.getCustomerCompanies()];
                    case 1:
                        customerCompany = (_a.sent()).find(function (customerCompany) { return customerCompany.id === customerCompanyId; });
                        if (customerCompany) {
                            return [2 /*return*/, customerCompany.name];
                        }
                        else {
                            return [2 /*return*/, ''];
                        }
                        return [2 /*return*/];
                }
            });
        }); };
        this.getInventoryItemTypeName = function (inventoryItemTypeId) { return __awaiter(_this, void 0, void 0, function () {
            var inventoryItemType;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.getInventoryItemTypes()];
                    case 1:
                        inventoryItemType = (_a.sent()).find(function (type) { return type.id === inventoryItemTypeId; });
                        if (inventoryItemType) {
                            return [2 /*return*/, inventoryItemType.name];
                        }
                        else {
                            return [2 /*return*/, ''];
                        }
                        return [2 /*return*/];
                }
            });
        }); };
        this.getDepartmentName = function (departmentId) { return __awaiter(_this, void 0, void 0, function () {
            var departmentAndBusinessAreas, _i, departmentAndBusinessAreas_1, businessArea, _a, _b, department;
            return __generator(this, function (_c) {
                switch (_c.label) {
                    case 0: return [4 /*yield*/, this.getBusinessAreasWithDepartments()];
                    case 1:
                        departmentAndBusinessAreas = _c.sent();
                        for (_i = 0, departmentAndBusinessAreas_1 = departmentAndBusinessAreas; _i < departmentAndBusinessAreas_1.length; _i++) {
                            businessArea = departmentAndBusinessAreas_1[_i];
                            for (_a = 0, _b = businessArea.departments; _a < _b.length; _a++) {
                                department = _b[_a];
                                if (department.id === departmentId) {
                                    return [2 /*return*/, department.name];
                                }
                            }
                        }
                        return [2 /*return*/, ''];
                }
            });
        }); };
        this.getBusinessAreaNameByDepartmentId = function (businessAreaId) { return __awaiter(_this, void 0, void 0, function () {
            var departmentAndBusinessAreas, _i, departmentAndBusinessAreas_2, businessArea;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.getBusinessAreasWithDepartments()];
                    case 1:
                        departmentAndBusinessAreas = _a.sent();
                        for (_i = 0, departmentAndBusinessAreas_2 = departmentAndBusinessAreas; _i < departmentAndBusinessAreas_2.length; _i++) {
                            businessArea = departmentAndBusinessAreas_2[_i];
                            if (businessArea.id === businessAreaId) {
                                return [2 /*return*/, businessArea.name];
                            }
                        }
                        return [2 /*return*/, ''];
                }
            });
        }); };
        this.getVendorCompanyName = function (vendorCompanyId) { return __awaiter(_this, void 0, void 0, function () {
            var vendorCompanies, _i, vendorCompanies_1, vendorCompany;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.getVendorCompanies()];
                    case 1:
                        vendorCompanies = _a.sent();
                        for (_i = 0, vendorCompanies_1 = vendorCompanies; _i < vendorCompanies_1.length; _i++) {
                            vendorCompany = vendorCompanies_1[_i];
                            if (vendorCompany.id === vendorCompanyId) {
                                return [2 /*return*/, vendorCompany.name];
                            }
                        }
                        return [2 /*return*/, ''];
                }
            });
        }); };
        this.getVenueNameById = function (venueId, eventId) { return __awaiter(_this, void 0, void 0, function () {
            var venues, _i, venues_1, venue;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.getVenues(eventId)];
                    case 1:
                        venues = _a.sent();
                        for (_i = 0, venues_1 = venues; _i < venues_1.length; _i++) {
                            venue = venues_1[_i];
                            if (venue.id === venueId) {
                                return [2 /*return*/, venue.name];
                            }
                        }
                        return [2 /*return*/, ''];
                }
            });
        }); };
        this.getInventoryItemGroups = function () {
            if (!_this._inventoryItemGroups) {
                _this._inventoryItemGroups = _this.get("".concat(endpointUrls_1.default.Inventory, "/Groups"));
            }
            return _this._inventoryItemGroups;
        };
        this.getInventoryItemCategories = function () {
            if (!_this._inventoryItemCategories) {
                _this._inventoryItemCategories = _this.get("".concat(endpointUrls_1.default.Inventory, "/Categories"));
            }
            return _this._inventoryItemCategories;
        };
        this.getVenues = function (eventId) {
            if (!_this._venues[eventId]) {
                _this._venues[eventId] = _this.get("".concat(endpointUrls_1.default.Events, "/Venues/").concat(eventId));
            }
            return _this._venues[eventId];
        };
        this.getVenuesByPasskey = function (eventId, passKey) {
            if (!_this._venues[eventId]) {
                _this._venues[eventId] = _this.get("".concat(endpointUrls_1.default.Events, "/").concat(eventId, "/").concat(passKey, "/Venues"));
            }
            return _this._venues[eventId];
        };
        this.getVenuePreferences = function (eventId) {
            if (!_this._venuePreferences[eventId]) {
                _this._venuePreferences[eventId] = _this.get("".concat(endpointUrls_1.default.Events, "/VenuePreferences/").concat(eventId));
            }
            return _this._venuePreferences[eventId];
        };
        this.getMeetingCategories = function (eventId) {
            if (!_this._meetingCategories[eventId]) {
                _this._meetingCategories[eventId] = _this.get("".concat(endpointUrls_1.default.Events, "/MeetingCategories/").concat(eventId));
            }
            return _this._meetingCategories[eventId];
        };
        this.getMeetingDates = function (eventId) {
            if (!_this._meetingDates[eventId]) {
                _this._meetingDates[eventId] = _this.get("".concat(endpointUrls_1.default.Events, "/MeetingDates/").concat(eventId));
            }
            return _this._meetingDates[eventId];
        };
        this.getEventDefaultMeetingStatus = function (eventId) {
            if (!_this._defaultMeetingStatus[eventId]) {
                _this._defaultMeetingStatus[eventId] = _this.get("".concat(endpointUrls_1.default.Events, "/DefaultMeetingStatus/").concat(eventId));
            }
            return _this._defaultMeetingStatus[eventId];
        };
        this.getEventTransportVehicleOptions = function (eventId) {
            if (!_this._eventTransportVehicleOptions[eventId]) {
                _this._eventTransportVehicleOptions[eventId] = _this.get("".concat(endpointUrls_1.default.Events, "/TransportVehicleOptions/").concat(eventId));
            }
            return _this._eventTransportVehicleOptions[eventId];
        };
        this.getUserTitles = function () {
            if (_this._tenantIdOverride) {
                return _this.get("".concat(endpointUrls_1.default.UserTitles, "/GetByTenantId/").concat(_this._tenantIdOverride));
            }
            if (!_this._userTitles) {
                _this._userTitles = _this.get("".concat(endpointUrls_1.default.UserTitles));
            }
            return _this._userTitles;
        };
        this.getMeetingCreatorsByEventId = function (eventId) {
            if (!_this._meetingCreators[eventId]) {
                _this._meetingCreators[eventId] = _this.get(endpointUrls_1.default.Events + '/' + eventId + '/MeetingCreators');
            }
            return _this._meetingCreators[eventId];
        };
        this.getMeetingConfigurations = function (eventId) {
            if (!_this._meetingConfigurations[eventId]) {
                _this._meetingConfigurations[eventId] = _this.get(endpointUrls_1.default.Events + '/' + eventId + '/MeetingConfigurations');
            }
            return _this._meetingConfigurations[eventId];
        };
        this.getTravelAndLodingConfigurations = function (eventId) {
            if (!_this._travelAndLodingConfigurations[eventId]) {
                _this._travelAndLodingConfigurations[eventId] = _this.get(endpointUrls_1.default.Events + '/' + eventId + '/TravelAndLodgingConfigurations');
            }
            return _this._travelAndLodingConfigurations[eventId];
        };
        this.getEventAttendeeAccessLevels = function (eventId) {
            if (!_this._eventAttendeeAccessLevels[eventId]) {
                _this._eventAttendeeAccessLevels[eventId] = _this.get(endpointUrls_1.default.Events + '/' + eventId + '/EventAttendeeAccessLevels');
            }
            return _this._eventAttendeeAccessLevels[eventId];
        };
        this.getEventPermissions = function (eventId) {
            if (!_this._eventPermissions[eventId]) {
                _this._eventPermissions[eventId] = _this.get(endpointUrls_1.default.Events + '/' + eventId + '/CurrentUserEventPermissions');
            }
            return _this._eventPermissions[eventId];
        };
        this.getEventLandingData = function (eventId) { return __awaiter(_this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                if (this._eventLandingData[eventId]) {
                    return [2 /*return*/, this._eventLandingData[eventId]];
                }
                this._eventLandingData[eventId] = this.get(endpointUrls_1.default.Events + '/GetEventLandingData?eventId=' + eventId);
                this._eventPermissions[eventId] = this._eventLandingData[eventId].then(function (eventLandingData) {
                    return eventLandingData.currentUserEventPermissions;
                });
                return [2 /*return*/, this._eventLandingData[eventId]];
            });
        }); };
        this.getEventManager = function (eventId) { return __awaiter(_this, void 0, void 0, function () {
            var _a, _b;
            return __generator(this, function (_c) {
                switch (_c.label) {
                    case 0:
                        _a = __1.EventManager.bind;
                        return [4 /*yield*/, this.getEventLandingData(eventId)];
                    case 1:
                        _b = [void 0, _c.sent()];
                        return [4 /*yield*/, this.getEventPermissions(eventId)];
                    case 2:
                        _b = _b.concat([_c.sent(), this.currentUserManager]);
                        return [4 /*yield*/, this.getMeetingConfigurations(eventId)];
                    case 3:
                        _b = _b.concat([_c.sent()]);
                        return [4 /*yield*/, this.getTravelAndLodingConfigurations(eventId)];
                    case 4: return [2 /*return*/, new (_a.apply(__1.EventManager, _b.concat([_c.sent()])))()];
                }
            });
        }); };
        this.getProfileImageByUserId = function (userId) {
            if (!_this._profileImageUrls[userId]) {
                _this._profileImageUrls[userId] = _this.get("".concat(endpointUrls_1.default.Users, "/").concat(userId, "/ProfileImage"));
            }
            return _this._profileImageUrls[userId];
        };
        this.getCostManagementServices = function () {
            if (!_this._costManagmentServices) {
                _this._costManagmentServices = _this.get("".concat(endpointUrls_1.default.Settings, "/CostManagementServices"));
            }
            return _this._costManagmentServices;
        };
        this.getCostManagementServiceOptions = function () { return __awaiter(_this, void 0, void 0, function () {
            var costManagementServices, options, i, isCredit;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.getCostManagementServices()];
                    case 1:
                        costManagementServices = _a.sent();
                        options = [];
                        for (i = 0; i < costManagementServices.length; i++) {
                            isCredit = costManagementServices[i].expenseType == pages_1.ExpenseType.Credit;
                            options.push({
                                label: costManagementServices[i].name + (isCredit ? ' (Credit)' : ''),
                                value: costManagementServices[i].id
                            });
                        }
                        return [2 /*return*/, options];
                }
            });
        }); };
        this.getEventAttendeesForDropdownWhereIsAttending = function (eventId) {
            if (!_this._eventAttendeesForDropdownWhereIsAttending[eventId]) {
                _this._eventAttendeesForDropdownWhereIsAttending[eventId] = _this.get("".concat(endpointUrls_1.default.Events, "/").concat(eventId, "/AttendeesForDropdownWhereIsAttending"));
            }
            return _this._eventAttendeesForDropdownWhereIsAttending[eventId];
        };
        this.getEventAttendeesForDropdownAllowedInMeetings = function (eventId) {
            if (!_this._eventAttendeesForDropdownAllowedInMeetings[eventId]) {
                _this._eventAttendeesForDropdownAllowedInMeetings[eventId] = _this.get("".concat(endpointUrls_1.default.Events, "/").concat(eventId, "/AttendeesForDropdownAllowedInMeetings"));
            }
            return _this._eventAttendeesForDropdownAllowedInMeetings[eventId];
        };
        this.getAllEventAttendeesForDropdown = function (eventId) {
            if (!_this._allEventAttendeesForDropdown[eventId]) {
                _this._allEventAttendeesForDropdown[eventId] = _this.get("".concat(endpointUrls_1.default.Events, "/").concat(eventId, "/AttendeesForDropdown"));
            }
            return _this._allEventAttendeesForDropdown[eventId];
        };
        this.getEventAttendeesRequiringAccommodations = function (eventId) {
            if (!_this._eventAttendeesRequiringAccommodations[eventId]) {
                _this._eventAttendeesRequiringAccommodations[eventId] = _this.get("".concat(endpointUrls_1.default.Events, "/").concat(eventId, "/AttendeesForDropdownRequiringAccommodations"));
            }
            return _this._eventAttendeesRequiringAccommodations[eventId];
        };
        this.getProductKnowledgeOptions = function () {
            if (_this._tenantIdOverride) {
                return _this.get(endpointUrls_1.default.Settings + '/ProductKnowledgeOptions/' + _this._tenantIdOverride);
            }
            if (!_this._productKnowledgeOptions) {
                _this._productKnowledgeOptions = _this.get("".concat(endpointUrls_1.default.Settings, "/ProductKnowledgeOptions"));
            }
            return _this._productKnowledgeOptions;
        };
        this.getEmailTemplateOptions = function () {
            var vendorCompanyId = _this.currentUserManager.vendorCompanyId;
            if (vendorCompanyId && !_this._emailTemplates[vendorCompanyId]) {
                _this._emailTemplates[vendorCompanyId] = _this.get("".concat(endpointUrls_1.default.VendorEmailTemplateOptions, "/").concat(vendorCompanyId));
            }
            else if (!vendorCompanyId && !_this._emailTemplates[0]) {
                _this._emailTemplates[vendorCompanyId] = _this.get("".concat(endpointUrls_1.default.EmailTemplateOptions));
            }
            return _this._emailTemplates[vendorCompanyId];
        };
        this.getHotelRoomOptions = function (eventId, vendorCompanyId) { return __awaiter(_this, void 0, void 0, function () {
            var rooms, options, i, option;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!!this._hotelRoomOptions["".concat(eventId, "|").concat(vendorCompanyId)]) return [3 /*break*/, 2];
                        return [4 /*yield*/, this.get("".concat(endpointUrls_1.default.Events, "/").concat(eventId, "/HotelRooms/").concat(vendorCompanyId))];
                    case 1:
                        rooms = _a.sent();
                        options = [];
                        for (i = 0; i < rooms.length; i++) {
                            option = {
                                label: rooms[i].hotelName + ' - ' + rooms[i].name,
                                value: rooms[i].id
                            };
                            if (rooms[i].hotelCurrency || rooms[i].price) {
                                option.label += " (".concat(rooms[i].price, " ").concat(rooms[i].hotelCurrency, ")");
                            }
                            options.push(option);
                        }
                        this._hotelRoomOptions["".concat(eventId, "|").concat(vendorCompanyId)] = options;
                        _a.label = 2;
                    case 2: return [2 /*return*/, this._hotelRoomOptions["".concat(eventId, "|").concat(vendorCompanyId)]];
                }
            });
        }); };
        this.getMeetingAttendeeCsvUrl = function (eventId) {
            return "".concat(_this.baseUrl).concat(endpointUrls_1.default.Events, "/").concat(eventId, "/MeetingAttendeeCsv?token=").concat(_this.currentUserManager.authenticationToken);
        };
        this.getMeetingNoteAttachmentUrl = function (meetingNoteAttachmentId) {
            return "".concat(_this.baseUrl).concat(endpointUrls_1.default.Meetings, "/NoteAttachment/").concat(meetingNoteAttachmentId, "?token=").concat(_this.currentUserManager.authenticationToken);
        };
        this.getExhibitSpaces = function (eventId) {
            if (!_this._exhibitSpaces[eventId]) {
                _this._exhibitSpaces[eventId] = _this.get("".concat(endpointUrls_1.default.Events, "/").concat(eventId, "/ExhibitSpaceOptions"));
            }
            return _this._exhibitSpaces[eventId];
        };
        this.getEventHotels = function (eventId, vendorCompanyId) {
            if (!_this._eventHotels[eventId]) {
                _this._eventHotels[eventId] = _this.get("".concat(endpointUrls_1.default.Events, "/").concat(eventId, "/EventHotels/").concat(vendorCompanyId))
                    .then(function (hotels) {
                    var options = [];
                    for (var i = 0; i < hotels.length; i++) {
                        options.push({ label: hotels[i].name, value: hotels[i].id });
                    }
                    return options;
                });
            }
            return _this._eventHotels[eventId];
        };
        this.getEventBillableBusinessAreas = function (eventId) {
            if (!_this._eventBillableBusinessAreas[eventId]) {
                _this._eventBillableBusinessAreas[eventId] = _this.get("".concat(endpointUrls_1.default.Events, "/").concat(eventId, "/BillableBusinessAreas"));
            }
            return _this._eventBillableBusinessAreas[eventId];
        };
        this.getAllHelpUrls = function () {
            if (!_this._helpUrls) {
                _this._helpUrls = _this.get("".concat(endpointUrls_1.default.HelpUrls, "/GetAll"));
            }
            return _this._helpUrls;
        };
        this.getHelpUrls = function () {
            if (!_this._helpUrls) {
                _this._helpUrls = _this.get("".concat(endpointUrls_1.default.HelpUrls, "/GetAllWithNames"));
            }
            return _this._helpUrls;
        };
        this.getSystemNotifications = function () {
            return _this.get("".concat(endpointUrls_1.default.Users, "/GetSystemNotifications"));
        };
        this.getSystemAdminContactInfo = function () {
            if (!_this._systemAdminContactInfo) {
                _this._systemAdminContactInfo = _this.get("".concat(endpointUrls_1.default.Tenants, "/GetSystemAdministratorContactInfo"));
            }
            return _this._systemAdminContactInfo;
        };
        this.markAllNotificationsAsRead = function () {
            return _this.post("".concat(endpointUrls_1.default.Notifications, "/MarkAllAsRead"), null);
        };
        this.getMeetingNotesDisclaimer = function () {
            if (!_this._meetingnotesDisclaimer) {
                _this._meetingnotesDisclaimer = _this.get("".concat(endpointUrls_1.default.Settings, "/MeetingNotesDisclaimer"));
            }
            return _this._meetingnotesDisclaimer;
        };
        this.getMeetingScheduledByOptions = function () {
            if (!_this._meetingScheduledByOptions) {
                _this._meetingScheduledByOptions = _this.get("".concat(endpointUrls_1.default.Settings, "/MeetingScheduledByOptions"));
            }
            return _this._meetingScheduledByOptions;
        };
        this.baseUrl = this.config.apiBaseUrl;
        this._venues = [];
        this._venuePreferences = [];
        this._meetingCategories = [];
        this._meetingDates = [];
        this._defaultMeetingStatus = [];
        this._eventTransportVehicleOptions = [];
        this._meetingConfigurations = [];
        this._meetingCreators = [];
        this._travelAndLodingConfigurations = [];
        this._eventAttendeeAccessLevels = [];
        this._eventPermissions = [];
        this._profileImageUrls = [];
        this._headerLogoImageUrls = [];
        this._eventAttendeesForDropdownAllowedInMeetings = [];
        this._eventAttendeesForSharingTransportDropdown = [];
        this._eventAttendeesForDropdownWhereIsAttending = [];
        this._eventAttendeesRequiringAccommodations = [];
        this._allEventAttendeesForDropdown = [];
        this._emailTemplates = [];
        this._exhibitSpaces = [];
        this._eventHotels = [];
        this._eventBillableBusinessAreas = [];
        this._eventLandingData = [];
        this._hotelRoomOptions = [];
        this._systemAdminContactInfo = null;
        this._meetingnotesDisclaimer = null;
        this._meetingScheduledByOptions = null;
        this.currentUserManager.observationProvider.observe(this.clearAllCache, app_1.CurrentUserManagerActions.LoggedOut);
        this.currentUserManager.observationProvider.observe(this.clearAllCache, app_1.CurrentUserManagerActions.LoggedIn);
    }
    Api.prototype.getTablePage = function (endpointUrl, recordsPerPage, pageNumber, sort, searchPhrase, parameters) {
        if (pageNumber === void 0) { pageNumber = 0; }
        var url = endpointUrl + this.buildListQueryString(recordsPerPage, pageNumber, sort, searchPhrase, parameters);
        return this.apiPlugin.getTablePage(this.baseUrl + url, this.currentUserManager.authenticationToken)
            .catch(this.processError);
    };
    Api.prototype.get = function (endpointUrl, params) {
        if (endpointUrl.indexOf(endpointUrls_1.default.Tenants) >= 0 && endpointUrl.indexOf('/SetActive') >= 0) {
            this.clearAllCache();
        }
        var url = this.baseUrl + endpointUrl;
        if (params) {
            url += '?' + this.buildAdditionalParameterString(params);
        }
        return this.apiPlugin.getSingle(url, this.currentUserManager.authenticationToken)
            .catch(this.processError);
    };
    Api.prototype.getById = function (endpointUrl, id) {
        return this.apiPlugin.getSingle(this.baseUrl + endpointUrl + '\\' + id, this.currentUserManager.authenticationToken)
            .catch(this.processError);
    };
    Api.prototype.buildAdditionalParameterString = function (parameters) {
        if (!parameters) {
            return '';
        }
        var paramString = '&';
        for (var key in parameters) {
            if (parameters.hasOwnProperty(key)) {
                if (parameters[key] instanceof Date) {
                    var formatedValue = utils_1.DateUtils.formatDate(parameters[key]);
                    paramString += "".concat(key, "=").concat(formatedValue, "&");
                }
                else if (Array.isArray(parameters[key])) {
                    for (var _i = 0, _a = parameters[key]; _i < _a.length; _i++) {
                        var item = _a[_i];
                        paramString += "".concat(key, "=").concat(item, "&");
                    }
                }
                else {
                    paramString += "".concat(key, "=").concat(parameters[key], "&");
                }
            }
        }
        return paramString.substring(0, paramString.length - 1);
    };
    Api.prototype.buildPageNumberExpression = function (pageNumber) {
        if (pageNumber <= 1) {
            return '';
        }
        return "&pageNumber=".concat(pageNumber);
    };
    Api.prototype.post = function (endpointUrl, payload, options) {
        if (options === void 0) { options = null; }
        this.checkForCacheClearing(endpointUrl);
        return this.apiPlugin.post(this.baseUrl + endpointUrl, this.currentUserManager.authenticationToken, payload, options)
            .catch(this.processError);
    };
    Api.prototype.checkForCacheClearing = function (endpointUrl) {
        if (endpointUrl.lastIndexOf(endpointUrls_1.default.Users) >= 0) {
            this.clearCache(_1.CacheType.Users);
            this.clearCache(_1.CacheType.UserTitles);
            this.clearCache(_1.CacheType.ProfileImages);
        }
        else if (endpointUrl.lastIndexOf(endpointUrls_1.default.EventConfiguration) >= 0) {
            this.clearCache(_1.CacheType.Venues);
            this.clearCache(_1.CacheType.VenuePreferences);
            this.clearCache(_1.CacheType.MeetingCategories);
            this.clearCache(_1.CacheType.MeetingDates);
            this.clearCache(_1.CacheType.DefaultMeetingStatus);
            this.clearCache(_1.CacheType.EventTransportVehicleOptions);
            this.clearCache(_1.CacheType.MeetingConfigurations);
            this.clearCache(_1.CacheType.EventAttendeeAccessLevels);
            this.clearCache(_1.CacheType.TravelAndLodgingConfigurations);
            this.clearCache(_1.CacheType.BillableBusinessAreas);
            this.clearCache(_1.CacheType.EventLandingPageData);
        }
        else if (endpointUrl.lastIndexOf(endpointUrls_1.default.Settings) >= 0) {
            this.clearCache(_1.CacheType.BusinessAreas);
            this.clearCache(_1.CacheType.LinesOfBusiness);
            this.clearCache(_1.CacheType.UserTitles);
            this.clearCache(_1.CacheType.Hotels);
            this.clearCache(_1.CacheType.EventLeadResponsibilities);
            this.clearCache(_1.CacheType.DefaultMeetingCategories);
            this.clearCache(_1.CacheType.InventoryItemCategories);
            this.clearCache(_1.CacheType.InventoryItemTypes);
            this.clearCache(_1.CacheType.InventoryItemGroups);
            this.clearCache(_1.CacheType.ProductKnowledgeOptions);
            this.clearCache(_1.CacheType.MeetingNotesDisclaimer);
            this.clearCache(_1.CacheType.MeetingScheduledByOptions);
        }
        else if (endpointUrl.lastIndexOf(endpointUrls_1.default.Customers) >= 0) {
            this.clearCache(_1.CacheType.CustomerCompanies);
        }
        else if (endpointUrl.lastIndexOf(endpointUrls_1.default.Meetings) >= 0) {
            this.clearCache(_1.CacheType.MeetingCreators);
        }
        else if (endpointUrl.lastIndexOf(endpointUrls_1.default.UpdateEventAttendees) >= 0) {
            this.clearCache(_1.CacheType.EventAttendees);
        }
        else if (endpointUrl.lastIndexOf(endpointUrls_1.default.VendorEmailTemplateOptions) >= 0) {
            this.clearCache(_1.CacheType.EmailTemplateOptions);
        }
        else if (endpointUrl.lastIndexOf(endpointUrls_1.default.HelpUrls) >= 0) {
            this.clearCache(_1.CacheType.HelpUrls);
        }
    };
    Api.prototype.uploadFile = function (endpointUrl, file) {
        return this.apiPlugin.uploadFile("".concat(this.baseUrl).concat(endpointUrl), this.currentUserManager.authenticationToken, file)
            .catch(this.processError);
    };
    Api.prototype.put = function (endpointUrl, id, payload) {
        this.checkForCacheClearing(endpointUrl);
        return this.apiPlugin.put("".concat(this.baseUrl).concat(endpointUrl, "/").concat(id), this.currentUserManager.authenticationToken, id, payload)
            .catch(this.processError);
    };
    Api.prototype.delete = function (endpointUrl, id) {
        return this.apiPlugin.delete("".concat(this.baseUrl).concat(endpointUrl, "/").concat(id), this.currentUserManager.authenticationToken, id)
            .catch(this.processError);
    };
    Api.prototype.getEventFileDownloadUrl = function (eventId, fileId) {
        return "".concat(this.baseUrl).concat(endpointUrls_1.default.Events, "/").concat(eventId, "/Files/").concat(fileId, "?token=").concat(this.currentUserManager.authenticationToken);
    };
    Api.prototype.getMeetingFileDownloadUrl = function (fileId) {
        return "".concat(this.baseUrl).concat(endpointUrls_1.default.Meetings, "/Files/").concat(fileId, "?token=").concat(this.currentUserManager.authenticationToken);
    };
    Api.prototype.getInventoryFileDownloadUrl = function (inventoryFileId) {
        return "".concat(this.baseUrl).concat(endpointUrls_1.default.Inventory, "/Files/").concat(inventoryFileId, "?token=").concat(this.currentUserManager.authenticationToken);
    };
    Api.prototype.getBusinessAreasWithDepartments = function () {
        if (this._tenantIdOverride) {
            return this.get(endpointUrls_1.default.BusinessAreasWithDepartments + '/' + this._tenantIdOverride);
        }
        if (!this._businessAreas) {
            this._businessAreas = this.get(endpointUrls_1.default.BusinessAreasWithDepartments);
        }
        return this._businessAreas;
    };
    Api.prototype.getLinesOfBusiness = function () {
        if (this._tenantIdOverride) {
            return this.get(endpointUrls_1.default.LinesOfBusiness + '/' + this._tenantIdOverride);
        }
        if (!this._linesOfBusiness) {
            this._linesOfBusiness = this.get(endpointUrls_1.default.LinesOfBusiness);
        }
        return this._linesOfBusiness;
    };
    Api.prototype.getUserGroups = function () {
        if (!this._userGroups) {
            this._userGroups = this.get(endpointUrls_1.default.UserGroups + '/GetAllForDropdown');
        }
        return this._userGroups;
    };
    Api.prototype.getHotels = function () {
        if (this._tenantIdOverride) {
            return this.getHotelsByTenantId();
        }
        if (!this._hotels) {
            this._hotels = this.get(endpointUrls_1.default.Hotels);
        }
        return this._hotels;
    };
    Api.prototype.getHotelsByTenantId = function () {
        return this.get(endpointUrls_1.default.Hotels + '/' + this._tenantIdOverride);
    };
    Api.prototype.getVendorCompanies = function () {
        if (!this._vendorCompanies) {
            this._vendorCompanies = this.get(endpointUrls_1.default.Vendors + '/VendorCompanies');
        }
        return this._vendorCompanies;
    };
    Api.prototype.getCustomerCompanies = function () {
        if (!this._customerCompanies) {
            this._customerCompanies = this.get(endpointUrls_1.default.Customers + '/CustomerCompanies');
        }
        return this._customerCompanies;
    };
    Api.prototype.getInventoryItemTypes = function () {
        if (!this._inventoryItemTypes) {
            this._inventoryItemTypes = this.get(endpointUrls_1.default.Inventory + '/InventoryItemTypes');
        }
        return this._inventoryItemTypes;
    };
    Api.prototype.getDefaultMeetingCategories = function () {
        if (!this._defaultMeetingCategories) {
            this._defaultMeetingCategories = this.get(endpointUrls_1.default.Settings + '/DefaultMeetingCategories/false');
        }
        return this._defaultMeetingCategories;
    };
    Api.prototype.clearCache = function (cacheType) {
        switch (cacheType) {
            case _1.CacheType.UserGroups:
                this._userGroups = null;
                break;
            case _1.CacheType.BusinessAreas:
                this._businessAreas = null;
                break;
            case _1.CacheType.LinesOfBusiness:
                this._linesOfBusiness = null;
                break;
            case _1.CacheType.Hotels:
                this._hotels = null;
                break;
            case _1.CacheType.VendorCompanies:
                this._vendorCompanies = null;
                break;
            case _1.CacheType.CustomerCompanies:
                this._customerCompanies = null;
                break;
            case _1.CacheType.Users:
                this._usersForDropdown = null;
                break;
            case _1.CacheType.InventoryItemTypes:
                this._inventoryItemTypes = null;
                break;
            case _1.CacheType.Venues:
                this._venues = [];
                break;
            case _1.CacheType.VenuePreferences:
                this._venuePreferences = [];
                break;
            case _1.CacheType.MeetingCategories:
                this._meetingCategories = [];
                break;
            case _1.CacheType.MeetingDates:
                this._meetingDates = [];
                break;
            case _1.CacheType.DefaultMeetingStatus:
                this._defaultMeetingStatus = [];
                break;
            case _1.CacheType.EventTransportVehicleOptions:
                this._eventTransportVehicleOptions = [];
                break;
            case _1.CacheType.UserTitles:
                this._userTitles = null;
                break;
            case _1.CacheType.EventLeadResponsibilities:
                this._eventLeadResponsibilities = null;
                break;
            case _1.CacheType.DefaultMeetingCategories:
                this._defaultMeetingCategories = null;
                break;
            case _1.CacheType.InventoryItemGroups:
                this._inventoryItemGroups = null;
                break;
            case _1.CacheType.InventoryItemCategories:
                this._inventoryItemCategories = null;
                break;
            case _1.CacheType.MeetingCreators:
                this._meetingCreators = [];
                break;
            case _1.CacheType.MeetingConfigurations:
                this._meetingConfigurations = [];
                break;
            case _1.CacheType.TravelAndLodgingConfigurations:
                this._travelAndLodingConfigurations = [];
                break;
            case _1.CacheType.EventAttendeeAccessLevels:
                this._eventAttendeeAccessLevels = [];
                break;
            case _1.CacheType.ProfileImages:
                this._profileImageUrls = [];
                break;
            case _1.CacheType.HeaderLogoImages:
                this._headerLogoImageUrls = [];
            case _1.CacheType.EventAttendees:
                this._eventAttendeesForDropdownAllowedInMeetings = [];
                this._eventAttendeesForSharingTransportDropdown = [];
                this._eventAttendeesForDropdownWhereIsAttending = [];
                this._allEventAttendeesForDropdown = [];
                this._eventAttendeesRequiringAccommodations = [];
                break;
            case _1.CacheType.ProductKnowledgeOptions:
                this._productKnowledgeOptions = null;
                break;
            case _1.CacheType.EmailTemplateOptions:
                this._emailTemplates = [];
                break;
            case _1.CacheType.BillableBusinessAreas:
                this._eventBillableBusinessAreas = [];
                break;
            case _1.CacheType.EventLandingPageData:
                this._eventLandingData = [];
                break;
            case _1.CacheType.SystemAdminContactInfo:
                this._systemAdminContactInfo = null;
                break;
            case _1.CacheType.MeetingNotesDisclaimer:
                this._meetingnotesDisclaimer = null;
                break;
            case _1.CacheType.MeetingScheduledByOptions:
                this._meetingScheduledByOptions = null;
                break;
        }
    };
    Api.prototype.getHeaderLogoImageUrl = function (tenantId) {
        if (!this._headerLogoImageUrls[tenantId]) {
            this._headerLogoImageUrls[tenantId] = this.get("".concat(endpointUrls_1.default.Tenants, "/GetHeaderLogoImage/").concat(tenantId));
        }
        return this._headerLogoImageUrls[tenantId];
    };
    Api.prototype.getMeetingsExcelReportUrl = function (eventId) {
        return "".concat(this.baseUrl).concat(endpointUrls_1.default.Events, "/Reports/MeetingsReport/Excel?eventId=").concat(eventId);
    };
    ;
    Api.prototype.getAttendeeExcelReportUrl = function (eventId) {
        return "".concat(this.baseUrl).concat(endpointUrls_1.default.Events, "/Reports/AttendeeReport/Excel?eventId=").concat(eventId);
    };
    ;
    Api.prototype.getHotelExcelReportUrl = function (eventId) {
        return "".concat(this.baseUrl).concat(endpointUrls_1.default.Events, "/Reports/HotelReport/Excel?eventId=").concat(eventId);
    };
    ;
    Api.prototype.getTransportationExcelReportUrl = function (eventId) {
        return "".concat(this.baseUrl).concat(endpointUrls_1.default.Events, "/Reports/TransportationReport/Excel?eventId=").concat(eventId);
    };
    ;
    Api.prototype.setTenantIdOverride = function (tenantId) {
        this._tenantIdOverride = tenantId;
    };
    Api.prototype.getCustomerCompaniesForSearchDropdown = function (searchPhrase) {
        throw new Error('Method not implemented');
    };
    return Api;
}());
exports.default = Api;
