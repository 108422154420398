"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g = Object.create((typeof Iterator === "function" ? Iterator : Object).prototype);
    return g.next = verb(0), g["throw"] = verb(1), g["return"] = verb(2), typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
var __1 = require("../..");
var button_1 = require("../../components/button/button");
var RequestPasswordResetPage = /** @class */ (function (_super) {
    __extends(RequestPasswordResetPage, _super);
    function RequestPasswordResetPage(api, navigator, currentUserManager, _errorMessage) {
        var _this = _super.call(this, __1.PageTypes.RequestPasswordReset, 'Reset Password') || this;
        _this.api = api;
        _this.navigator = navigator;
        _this.currentUserManager = currentUserManager;
        _this._errorMessage = _errorMessage;
        _this.goToLoginPage = function () {
            _this.navigator.goToLogin();
        };
        _this.notifyFormInvalid = function () {
            _this.submitButton.setProcessing(false);
            _this.sendSmsButton.setProcessing(false);
            _this.sendEmailButton.setProcessing(false);
            _this.observationProvider.notifyObservers(__1.RequestPasswordResetPageActions.FormInvalid);
        };
        _this.sendSms = function () { return __awaiter(_this, void 0, void 0, function () {
            var _a, err_1;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        this.form.submit();
                        if (!this.form.isValid()) {
                            this.sendSmsButton.setProcessing(false);
                            return [2 /*return*/];
                        }
                        this.submitButton.setProcessing(true);
                        this.sendSmsButton.setProcessing(true);
                        this.sendEmailButton.setProcessing(true);
                        _b.label = 1;
                    case 1:
                        _b.trys.push([1, 3, 4, 5]);
                        this._isLoading = true;
                        this.observationProvider.notifyObservers(__1.RequestPasswordResetPageActions.FormSubmitted);
                        _a = this;
                        return [4 /*yield*/, this.api.post(__1.EndpointUrls.ValidatePasswordResetAccessCode, this.form.model)];
                    case 2:
                        _a._sendAccessCodeResponse = _b.sent();
                        this.alert.hide();
                        this._requestSent = true;
                        this.observationProvider.notifyObservers(__1.RequestPasswordResetPageActions.SubmittedSuccessfully);
                        return [3 /*break*/, 5];
                    case 3:
                        err_1 = _b.sent();
                        this.alert.show({
                            alertType: __1.AlertType.Error,
                            heading: 'Unable to process request',
                            message: 'An error occurred trying to send you an access code. Please contact your system administrator.'
                        });
                        this.observationProvider.notifyObservers(__1.RequestPasswordResetPageActions.ErrorSubmitting);
                        return [3 /*break*/, 5];
                    case 4:
                        this._isLoading = false;
                        this.submitButton.setProcessing(false);
                        this.sendSmsButton.setProcessing(false);
                        this.sendEmailButton.setProcessing(false);
                        return [7 /*endfinally*/];
                    case 5: return [2 /*return*/];
                }
            });
        }); };
        _this.sendEmail = function () { return __awaiter(_this, void 0, void 0, function () {
            var _a, err_2;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        this.form.submit();
                        if (!this.form.isValid()) {
                            this.sendEmailButton.setProcessing(false);
                            return [2 /*return*/];
                        }
                        this.submitButton.setProcessing(true);
                        this.sendSmsButton.setProcessing(true);
                        this.sendEmailButton.setProcessing(true);
                        _b.label = 1;
                    case 1:
                        _b.trys.push([1, 3, 4, 5]);
                        this._isLoading = true;
                        this.observationProvider.notifyObservers(__1.RequestPasswordResetPageActions.FormSubmitted);
                        _a = this;
                        return [4 /*yield*/, this.api.post(__1.EndpointUrls.ForgotPassword, this._form.model)];
                    case 2:
                        _a._sendAccessCodeResponse = _b.sent();
                        this.alert.hide();
                        this._requestSent = true;
                        this.observationProvider.notifyObservers(__1.RequestPasswordResetPageActions.SubmittedSuccessfully);
                        return [3 /*break*/, 5];
                    case 3:
                        err_2 = _b.sent();
                        this.alert.show({
                            alertType: __1.AlertType.Error,
                            heading: 'Unable to process request',
                            message: 'An error occurred trying to send you an access code. Please contact your system administrator.'
                        });
                        this.observationProvider.notifyObservers(__1.RequestPasswordResetPageActions.ErrorSubmitting);
                        return [3 /*break*/, 5];
                    case 4:
                        this._isLoading = false;
                        this.submitButton.setProcessing(false);
                        this.sendSmsButton.setProcessing(false);
                        this.sendEmailButton.setProcessing(false);
                        return [7 /*endfinally*/];
                    case 5: return [2 /*return*/];
                }
            });
        }); };
        _this.verifyAccessCode = function () { return __awaiter(_this, void 0, void 0, function () {
            var request, passwordResetKey, err_3;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!this.accessCodeField.isValid()) {
                            this.submitButton.setProcessing(false);
                            return [2 /*return*/];
                        }
                        this._isLoading = true;
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 3, 4, 5]);
                        request = {
                            emailAddress: this.emailField.value,
                            accessCode: this.accessCodeField.value
                        };
                        return [4 /*yield*/, this.api.post(__1.EndpointUrls.ValidatePasswordResetAccessCode, request)];
                    case 2:
                        passwordResetKey = _a.sent();
                        this.navigator.goToResetPassword({ passwordResetKey: passwordResetKey });
                        return [3 /*break*/, 5];
                    case 3:
                        err_3 = _a.sent();
                        this.alert.show({
                            alertType: __1.AlertType.Error,
                            heading: 'Unable to validate',
                            message: 'We were unable to validate your access code. Please recheck the code you received on your device.'
                        });
                        this.observationProvider.notifyObservers(__1.RequestPasswordResetPageActions.ErrorSubmitting);
                        return [3 /*break*/, 5];
                    case 4:
                        this.submitButton.setProcessing(false);
                        this._isLoading = false;
                        return [7 /*endfinally*/];
                    case 5: return [2 /*return*/];
                }
            });
        }); };
        _this.observationProvider = new __1.ObservationProvider();
        _this.buildRequestCodeForm();
        _this.buildSubmitCodeForm();
        if (_this.currentUserManager.isLoggedIn) {
            _this.navigator.goToEventList();
        }
        _this.submitButton = new button_1.default({
            buttonType: __1.ButtonTypes.Submit,
            text: 'Submit',
            onClick: _this.submitAccessCodeForm.submit,
            processingText: 'Submitting...'
        });
        _this.sendSmsButton = new button_1.default({
            text: 'Send Code in Text Message',
            onClick: _this.sendSms,
            processingText: 'Sending...'
        });
        _this.sendEmailButton = new button_1.default({
            text: 'Send Code in Email',
            onClick: _this.sendEmail,
            processingText: 'Sending...'
        });
        _this.loginButton = new button_1.default({
            text: 'Back to Login',
            onClick: _this.goToLoginPage
        });
        if (_errorMessage) {
            _this.alert.show({
                alertType: __1.AlertType.Error,
                heading: 'Password Reset',
                message: _this._errorMessage
            });
        }
        return _this;
    }
    Object.defineProperty(RequestPasswordResetPage.prototype, "form", {
        get: function () {
            return this._form;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(RequestPasswordResetPage.prototype, "submitAccessCodeForm", {
        get: function () {
            return this._submitAccessCodeForm;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(RequestPasswordResetPage.prototype, "isLoading", {
        get: function () {
            return this._isLoading;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(RequestPasswordResetPage.prototype, "requestSent", {
        get: function () {
            return this._requestSent;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(RequestPasswordResetPage.prototype, "emailField", {
        get: function () {
            return this._form.fields[0];
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(RequestPasswordResetPage.prototype, "accessCodeField", {
        get: function () {
            return this._submitAccessCodeForm.fields[0];
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(RequestPasswordResetPage.prototype, "sendAccessCodeResponse", {
        get: function () {
            return this._sendAccessCodeResponse;
        },
        enumerable: false,
        configurable: true
    });
    RequestPasswordResetPage.prototype.buildRequestCodeForm = function () {
        var fields = [
            new __1.EmailField('emailAddress', true),
        ];
        this._form = new __1.Form(fields);
    };
    RequestPasswordResetPage.prototype.buildSubmitCodeForm = function () {
        var fields = [
            new __1.TextField('accessCode', 'Access Code', true, 10)
        ];
        this._submitAccessCodeForm = new __1.Form(fields);
        this._submitAccessCodeForm.observationProvider.observe(this.notifyFormInvalid, __1.FormActions.FormSubmissionFailure);
        this._submitAccessCodeForm.observationProvider.observe(this.verifyAccessCode, __1.FormActions.FormSubmitted);
    };
    return RequestPasswordResetPage;
}(__1.BasePage));
exports.default = RequestPasswordResetPage;
