"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var CustomerDetailPageActions;
(function (CustomerDetailPageActions) {
    CustomerDetailPageActions["RecordAdding"] = "RecordAdding";
    CustomerDetailPageActions["RecordAdded"] = "RecordAdded";
    CustomerDetailPageActions["RecordDeleting"] = "RecordDeleting";
    CustomerDetailPageActions["RecordDeleted"] = "RecordDeleted";
    CustomerDetailPageActions["RecordLoading"] = "RecordLoading";
    CustomerDetailPageActions["RecordLoaded"] = "RecordLoaded";
    CustomerDetailPageActions["RecordEditing"] = "RecordEditing";
    CustomerDetailPageActions["RecordEdited"] = "RecordEdited";
    CustomerDetailPageActions["RecordLoadFailure"] = "RecordLoadFailure";
    CustomerDetailPageActions["Cancel"] = "Cancel";
    CustomerDetailPageActions["RecordDeactivated"] = "RecordDeactivated";
    CustomerDetailPageActions["RecordActivated"] = "RecordActivated";
    CustomerDetailPageActions["SubmissionError"] = "SubmissionError";
})(CustomerDetailPageActions || (CustomerDetailPageActions = {}));
exports.default = CustomerDetailPageActions;
