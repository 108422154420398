"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var EventHotelConfigurationFormActions;
(function (EventHotelConfigurationFormActions) {
    EventHotelConfigurationFormActions["ActiveHotelAccommadationsPaneChanged"] = "ActiveHotelAccommadationsPaneChanged";
    EventHotelConfigurationFormActions["ConfigurationLoading"] = "ConfigurationLoading";
    EventHotelConfigurationFormActions["HotelsEnabledChanged"] = "HotelsEnabledChanged";
    EventHotelConfigurationFormActions["HotelFormShown"] = "HotelFormShown";
    EventHotelConfigurationFormActions["HotelFormHidden"] = "HotelFormHidden";
    EventHotelConfigurationFormActions["Loaded"] = "Loaded";
})(EventHotelConfigurationFormActions || (EventHotelConfigurationFormActions = {}));
exports.default = EventHotelConfigurationFormActions;
