"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
var __1 = require("../..");
var components_1 = require("../../components");
var button_1 = require("../../components/button/button");
var VendorEventListPage = /** @class */ (function (_super) {
    __extends(VendorEventListPage, _super);
    function VendorEventListPage(api, navigator, currentUserManager) {
        var _this = _super.call(this, __1.PageTypes.VendorEventList, 'Events') || this;
        _this.api = api;
        _this.navigator = navigator;
        _this.currentUserManager = currentUserManager;
        _this.loadData = function () {
            return _this.api.getTablePage(__1.EndpointUrls.Vendors + '/VendorPortal/Events', _this._table.recordsPerPage, _this._table.currentPage, _this._table.sortExpression, _this._table.searchPhrase, __assign({}, _this.listOptionSlideContainer.appliedFiltersModel));
        };
        _this.buildEventLandingButton = function (event) {
            return new button_1.default({
                onClick: function () { return _this.goToVendorEventLanding(event.id, event.tenantId); },
                text: event.name
            });
        };
        _this.goToVendorEventLanding = function (eventId, tenantId) {
            _this.navigator.goToVendorEventLandingPage(eventId, tenantId);
        };
        _this.observationProvider = new __1.ObservationProvider();
        _this._table = new __1.Table(_this.loadData, api);
        var filters = [
            new components_1.EventStatusDropdown('status', false),
            new components_1.DateField('minimumStartDate', 'Minimum Start Date', false),
            new components_1.DateField('maximumStartDate', 'Maximum Start Date', false),
        ];
        var defaultFilters = { status: __1.EventStatus.Active };
        _this.listOptionSlideContainer = new components_1.ListOptionSlideContainer(filters, defaultFilters);
        _this.listOptionSlideContainer.observationProvider.observe(function () { return _this._table.load(); }, components_1.ListOptionSlideContainerActions.OptionsApplied);
        _this._table.load();
        return _this;
    }
    Object.defineProperty(VendorEventListPage.prototype, "table", {
        get: function () {
            return this._table;
        },
        enumerable: false,
        configurable: true
    });
    return VendorEventListPage;
}(__1.BasePage));
exports.default = VendorEventListPage;
