"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g = Object.create((typeof Iterator === "function" ? Iterator : Object).prototype);
    return g.next = verb(0), g["throw"] = verb(1), g["return"] = verb(2), typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
var __1 = require("../../..");
var button_1 = require("../../../components/button/button");
var vendorCompanyInfoPageActions_1 = require("./vendorCompanyInfoPageActions");
var VendorCompanyInfoPage = /** @class */ (function (_super) {
    __extends(VendorCompanyInfoPage, _super);
    function VendorCompanyInfoPage(api, navigator, currentUserManager) {
        var _this = _super.call(this, __1.PageTypes.VendorCompanyInfo, 'Company & Users') || this;
        _this.api = api;
        _this.navigator = navigator;
        _this.currentUserManager = currentUserManager;
        _this.buildForm = function () {
            _this._form = new __1.Form([
                new __1.TextField('name', 'Company name', true, 200, 'Required'),
                new __1.TextField('address', 'Address', false, 250),
                new __1.TextField('city', 'City', false, 250),
                new __1.TextField('state', 'State', false, 20),
                new __1.TextField('zip', 'Zip code', false, 10),
                new __1.TextField('phone', 'Phone', false, 25),
                new __1.CheckBoxField('offersHotels', 'Offers Hotels'),
                new __1.CheckBoxField('offersAuto', 'Offers Transportation')
            ]);
        };
        _this.loadData = function () { return __awaiter(_this, void 0, void 0, function () {
            var _a, _b;
            return __generator(this, function (_c) {
                switch (_c.label) {
                    case 0:
                        this._isLoading = true;
                        _b = (_a = this._form).setModel;
                        return [4 /*yield*/, this.api.get(__1.EndpointUrls.Vendors + '/CompanyInfoPageData')];
                    case 1:
                        _b.apply(_a, [_c.sent()]);
                        this.usersDataList.setModels(this._form.model.users);
                        this.usersDataList.isEditable;
                        this._isLoading = false;
                        this.observationProvider.notifyObservers(vendorCompanyInfoPageActions_1.default.DataLoaded);
                        return [2 /*return*/];
                }
            });
        }); };
        _this.save = function () { return __awaiter(_this, void 0, void 0, function () {
            var successMessage, err_1;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!this._form.isValid() ||
                            !this.usersDataList.areAllValid()) {
                            this.saveButton.setProcessing(false);
                            return [2 /*return*/];
                        }
                        this._form.model.users = this.usersDataList.getModels();
                        this._form.submit();
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 4, , 5]);
                        return [4 /*yield*/, this.api.post(__1.EndpointUrls.VendorCompanies + '/VendorPortal/UpdateCompanyInfo', this._form.model)];
                    case 2:
                        successMessage = _a.sent();
                        this.observationProvider.notifyObservers(vendorCompanyInfoPageActions_1.default.RecordUpdated);
                        this.saveButton.setProcessing(false);
                        return [4 /*yield*/, this.loadData()];
                    case 3:
                        _a.sent();
                        this.alert.show({
                            alertType: __1.AlertType.Success,
                            heading: 'Saved Successfully',
                            message: successMessage ? successMessage : 'Your company info has been saved.'
                        });
                        return [3 /*break*/, 5];
                    case 4:
                        err_1 = _a.sent();
                        this.alert.show({
                            alertType: __1.AlertType.Error,
                            heading: 'Unable to save',
                            message: err_1.message ? err_1.message : 'An error occurred trying to save your company info.'
                        });
                        this.saveButton.setProcessing(false);
                        return [3 /*break*/, 5];
                    case 5: return [2 /*return*/];
                }
            });
        }); };
        _this.observationProvider = new __1.ObservationProvider();
        _this.buildForm();
        _this.loadData();
        _this.usersDataList = new __1.DataList(function () { return [
            new __1.TextField('title', 'Title', false, 250),
            new __1.TextField('firstName', 'First Name', true, 100, 'required'),
            new __1.TextField('lastName', 'Last Name', true, 100, 'required'),
            new __1.TextField('phone', 'Phone', true, 50, 'required'),
            new __1.EmailField('email', true, 'Login Email'),
            new __1.CheckBoxField('lockedOut', 'Locked Out')
        ]; }, true, "Add User");
        _this.saveButton = new button_1.default({
            buttonType: __1.ButtonTypes.Submit,
            text: 'Save',
            onClick: _this.save,
            processingText: 'Saving...'
        });
        return _this;
    }
    Object.defineProperty(VendorCompanyInfoPage.prototype, "form", {
        get: function () {
            return this._form;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(VendorCompanyInfoPage.prototype, "isLoading", {
        get: function () {
            return this._isLoading;
        },
        enumerable: false,
        configurable: true
    });
    return VendorCompanyInfoPage;
}(__1.BasePage));
exports.default = VendorCompanyInfoPage;
