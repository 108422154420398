"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
var _1 = require(".");
var baseField_1 = require("./baseField");
var RichTextField = /** @class */ (function (_super) {
    __extends(RichTextField, _super);
    function RichTextField(key, label, isRequired, requiredValidationMessage, initialValue, fieldType) {
        if (fieldType === void 0) { fieldType = _1.FieldType.TextBox; }
        var _this = _super.call(this, fieldType, key, label, isRequired, 999999, requiredValidationMessage) || this;
        _this.key = key;
        if (initialValue) {
            _this.setValue(initialValue);
        }
        return _this;
    }
    Object.defineProperty(RichTextField.prototype, "placeHolderText", {
        get: function () {
            return this._placeHolderText;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(RichTextField.prototype, "errorMessage", {
        get: function () {
            return _super.prototype.getErrorMessage.call(this);
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(RichTextField.prototype, "value", {
        get: function () {
            return this.stringValue;
        },
        enumerable: false,
        configurable: true
    });
    RichTextField.prototype.isValid = function () {
        if (!this.isRequired) {
            return true;
        }
        var isValid = !!this.stringValue;
        this.setIsValid(isValid);
        return isValid;
    };
    RichTextField.prototype.setValue = function (newValue) {
        this.setStringValue(newValue);
    };
    return RichTextField;
}(baseField_1.default));
exports.default = RichTextField;
