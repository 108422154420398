"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
var __1 = require("../..");
var button_1 = require("../button/button");
var api_1 = require("../../api");
var _1 = require(".");
var icons_1 = require("../icons");
var CustomerSearchField = /** @class */ (function (_super) {
    __extends(CustomerSearchField, _super);
    function CustomerSearchField(api, label, isRequired, requiredValidationMessage, allowMultipleSelect, key, textKey) {
        if (isRequired === void 0) { isRequired = false; }
        if (allowMultipleSelect === void 0) { allowMultipleSelect = false; }
        var _this = _super.call(this, api, label, api_1.EndpointUrls.Customers + '/SearchForDropdown', 'customerId', 'label', 'label', isRequired, requiredValidationMessage, allowMultipleSelect, key, textKey) || this;
        _this.setPlaceholderLabel('Search by company or name...');
        _this.quickAddCustomerForm = new __1.QuickAddCustomerForm(api);
        _this.showQuickAddCustomerButton = new button_1.default({
            onClick: function () { return _this.quickAddCustomerForm.open(); },
            leftIcon: icons_1.Icons.Plus,
            isVisible: false,
            text: 'Create new customer'
        });
        return _this;
    }
    CustomerSearchField.prototype.setQuickAddEnabled = function (enabled) {
        this.showQuickAddCustomerButton.setVisibility(enabled);
    };
    return CustomerSearchField;
}(_1.BaseSearchField));
exports.default = CustomerSearchField;
