"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
var _1 = require(".");
var fieldType_1 = require("./fieldType");
var TimezoneField = /** @class */ (function (_super) {
    __extends(TimezoneField, _super);
    function TimezoneField(key, isRequired, initialValue) {
        var _this = _super.call(this, key, 'Time Zone', isRequired, [
            new _1.FieldOption('GMT -11:00', '-11:00'),
            new _1.FieldOption('GMT -10:00', '-10:00'),
            new _1.FieldOption('GMT -09:30', '-09:30'),
            new _1.FieldOption('GMT -08:00', '-08:00'),
            new _1.FieldOption('GMT -07:00', '-07:00'),
            new _1.FieldOption('GMT -06:00', '-06:00'),
            new _1.FieldOption('GMT -05:00', '-05:00'),
            new _1.FieldOption('GMT -04:00', '-04:00'),
            new _1.FieldOption('GMT -03:30', '-03:30'),
            new _1.FieldOption('GMT -03:00', '-03:00'),
            new _1.FieldOption('GMT -02:00', '-02:00'),
            new _1.FieldOption('GMT -01:00', '-01:00'),
            new _1.FieldOption('GMT +00:00', '+00:00'),
            new _1.FieldOption('GMT +01:00', '+01:00'),
            new _1.FieldOption('GMT +02:00', '+02:00'),
            new _1.FieldOption('GMT +03:00', '+03:00'),
            new _1.FieldOption('GMT +03:30', '+03:30'),
            new _1.FieldOption('GMT +04:00', '+04:00'),
            new _1.FieldOption('GMT +04:30', '+04:30'),
            new _1.FieldOption('GMT +05:00', '+05:00'),
            new _1.FieldOption('GMT +05:30', '+05:30'),
            new _1.FieldOption('GMT +05:45', '+05:45'),
            new _1.FieldOption('GMT +06:00', '+06:00'),
            new _1.FieldOption('GMT +06:30', '+06:30'),
            new _1.FieldOption('GMT +07:00', '+07:00'),
            new _1.FieldOption('GMT +08:00', '+08:00'),
            new _1.FieldOption('GMT +08:45', '+08:45'),
            new _1.FieldOption('GMT +09:00', '+09:00'),
            new _1.FieldOption('GMT +09:30', '+09:30'),
            new _1.FieldOption('GMT +10:00', '+10:00'),
            new _1.FieldOption('GMT +10:30', '+10:30'),
            new _1.FieldOption('GMT +11:00', '+11:00'),
            new _1.FieldOption('GMT +12:00', '+12:00'),
            new _1.FieldOption('GMT +13:00', '+13:00'),
            new _1.FieldOption('GMT +13:45', '+13:45'),
            new _1.FieldOption('GMT +14:00', '+14:00'),
        ], 'Timezone is required', initialValue, fieldType_1.default.DropDown) || this;
        _this.key = key;
        _this.setPlaceholderLabel('timezone');
        return _this;
    }
    return TimezoneField;
}(_1.StringDropdownField));
exports.default = TimezoneField;
