"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var InteractiveVenueAssignerActions;
(function (InteractiveVenueAssignerActions) {
    InteractiveVenueAssignerActions["VisibilityChanged"] = "VisibilityChanged";
    InteractiveVenueAssignerActions["MeetingsLoaded"] = "MeetingsLoaded";
    InteractiveVenueAssignerActions["MeetingsLoading"] = "MeetingsLoading";
    InteractiveVenueAssignerActions["MeetingDetailLoading"] = "MeetinigDetailLoading";
    InteractiveVenueAssignerActions["MeetingDetailLoaded"] = "MeetingDetailLoaded";
    InteractiveVenueAssignerActions["MeetingDetailCleared"] = "MeetingDetailCleared";
    InteractiveVenueAssignerActions["VenueChanged"] = "VenueChanged";
})(InteractiveVenueAssignerActions || (InteractiveVenueAssignerActions = {}));
exports.default = InteractiveVenueAssignerActions;
