"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
var api_1 = require("../../api");
var _1 = require(".");
var UserSearchField = /** @class */ (function (_super) {
    __extends(UserSearchField, _super);
    function UserSearchField(api, label, isRequired, requiredValidationMessage, allowMultipleSelect, key, textKey) {
        if (isRequired === void 0) { isRequired = false; }
        if (allowMultipleSelect === void 0) { allowMultipleSelect = false; }
        if (textKey === void 0) { textKey = 'email'; }
        var _this = _super.call(this, api, label, api_1.EndpointUrls.Users + '/SearchForDropdown', 'id', 'fullName', 'label', isRequired, requiredValidationMessage, allowMultipleSelect, key, textKey, true) || this;
        _this.setPlaceholderLabel('Search users by name or email address');
        return _this;
    }
    return UserSearchField;
}(_1.BaseSearchField));
exports.default = UserSearchField;
