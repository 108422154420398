import { h, JSX } from 'preact';
import ObservingComponent from '../componentBases/observingComponent';
import MeetingNotificationModalProps from './meetingNotificationModalProps';
import Button from '../buttons/button';
import Modal from '../containers/modal';
import ButtonColors from '../buttons/buttonColors';
import Alert from '../alerts/alert';
import RichTextField from '../fields/richTextField';
import FieldLoadingPlaceholder from '../fields/fieldLoadingPlaceholder';
import Field from '../fields/field';

class MeetingNotificationModal extends ObservingComponent<MeetingNotificationModalProps> {

    public componentWillMount() {
        this.registerUpdateObserver(this.props.meetingNotificationModal.observationProvider);
    }

    public render({ meetingNotificationModal }: MeetingNotificationModalProps): JSX.Element {
        return (
            <div>
                {!!meetingNotificationModal.isOpen &&
                    <Modal
                        heading={meetingNotificationModal.title}
                        close={meetingNotificationModal.hide}
                        headerButtons={[]}
                        footerLeftButtons={[]}
                        footerRightButtons={[
                            <Button
                                color={ButtonColors.Secondary}
                                button={meetingNotificationModal.closeButton} />,
                            <Button
                                color={ButtonColors.Primary}
                                button={meetingNotificationModal.sendButton} />
                        ]}>
                        <div>
                            <Alert
                                className="mt-1"
                                alert={meetingNotificationModal.alert} />

                            {meetingNotificationModal.isLoading &&
                                <div>
                                    <h4 class="section-header text-sm">Attendees</h4>
                                    <FieldLoadingPlaceholder />
                                    <FieldLoadingPlaceholder />


                                    <h4 class="section-header text-sm">Guests</h4>
                                    <FieldLoadingPlaceholder />
                                    <FieldLoadingPlaceholder />

                                    <h4 class="section-header text-sm">Email</h4>
                                    <FieldLoadingPlaceholder />
                                    <FieldLoadingPlaceholder />
                                    <FieldLoadingPlaceholder />
                                </div>
                            }

                            {!meetingNotificationModal.isLoading &&
                                <div class="form-container">
                                    <h4 class="section-header text-sm">Attendees</h4>
                                    {meetingNotificationModal.data.attendees.map((attendee, index) => {
                                        return (
                                            <div class="flex">
                                                <Field
                                                    className="v-center label-right"
                                                    field={meetingNotificationModal.attendeeCheckBoxes[index]} />

                                                <Field
                                                    className="v-center no-label input-sm ml-1"
                                                    field={meetingNotificationModal.attendeeEmailFields[index]} />
                                            </div>
                                    )})}

                                    <h4 class="section-header text-sm">Guests</h4>
                                    {meetingNotificationModal.data.guests.map((guest, index) => {
                                        return (
                                            <div class="flex">
                                                <Field
                                                    className="v-center label-right"
                                                    field={meetingNotificationModal.guestCheckBoxes[index]} />

                                                <Field
                                                    className="v-center no-label input-sm ml-1"
                                                    field={meetingNotificationModal.guestEmailFields[index]} />
                                            </div>
                                    )})}

                                    <h4 class="section-header text-sm">Email</h4>
                                    <Field field={meetingNotificationModal.ccEmailField} />

                                    <div class="flex mt-1">
                                        <Field
                                            className="v-center mr-1 fill"
                                            field={meetingNotificationModal.subjectField} />

                                        <Field
                                            className="v-center"
                                            field={meetingNotificationModal.includeCalendarFileField} />
                                    </div>

                                    <RichTextField field={meetingNotificationModal.emailTemplateField} />
                                </div>
                            }
                        </div>
                    </Modal>
                }
            </div>
        );
    }
}

export default MeetingNotificationModal;
