"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g = Object.create((typeof Iterator === "function" ? Iterator : Object).prototype);
    return g.next = verb(0), g["throw"] = verb(1), g["return"] = verb(2), typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
var _1 = require(".");
var __1 = require("../../../..");
var button_1 = require("../../../../components/button/button");
var EventHotelConfigurationForm = /** @class */ (function () {
    function EventHotelConfigurationForm(api, eventStartDate, vendorCompanyId, tenantId) {
        var _this = this;
        this.api = api;
        this.eventStartDate = eventStartDate;
        this.vendorCompanyId = vendorCompanyId;
        this.tenantId = tenantId;
        this.addHotelVendor = function () {
            if (!_this.hotelVendorCompanyDropdown.value) {
                return;
            }
            if (!_this.hotelVendorDataList.exists(function (item) { return item.vendorCompanyId === _this.hotelVendorCompanyDropdown.value; })) {
                _this.hotelVendorDataList.addModel({
                    isDeletable: true,
                    references: [],
                    vendorCompany: _this.hotelVendorCompanyDropdown.selectedOptionLabel,
                    vendorCompanyId: _this.hotelVendorCompanyDropdown.value
                });
            }
            _this.hotelVendorCompanyDropdown.clearValue();
        };
        this.showLodgingOptionsPane = function () {
            _this._activeHotelPane = __1.EventConfigurationPageHotelPanes.LodgingOptions;
            _this.observationProvider.notifyObservers(_1.EventHotelConfigurationFormActions.ActiveHotelAccommadationsPaneChanged);
        };
        this.showHotelTermsAndConditionsPane = function () {
            _this._activeHotelPane = __1.EventConfigurationPageHotelPanes.TermsAndConditions;
            _this.observationProvider.notifyObservers(_1.EventHotelConfigurationFormActions.ActiveHotelAccommadationsPaneChanged);
        };
        this.showHotelVendorAccessPane = function () {
            _this._activeHotelPane = __1.EventConfigurationPageHotelPanes.VendorAccess;
            _this.observationProvider.notifyObservers(_1.EventHotelConfigurationFormActions.ActiveHotelAccommadationsPaneChanged);
        };
        this.hotelsEnabledChanged = function () { return __awaiter(_this, void 0, void 0, function () {
            var i;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        for (i = 1; i < this._hotelFields.length; i++) {
                            this._hotelFields[i].setVisibility(this._hotelsEnabledCheckbox.value);
                        }
                        return [4 /*yield*/, this.setTermsAndConditions(this._hotelTermsAndConditionsField.value)];
                    case 1:
                        _a.sent();
                        this.accordian.isOpen = this._hotelsEnabledCheckbox.value;
                        this.accordian.toggleButton.setDisabled(!this._hotelsEnabledCheckbox.value);
                        this.observationProvider.notifyObservers(_1.EventHotelConfigurationFormActions.HotelsEnabledChanged);
                        return [2 /*return*/];
                }
            });
        }); };
        this.setTermsAndConditions = function (termsAndConditions) { return __awaiter(_this, void 0, void 0, function () {
            var defaultTermsAndConditions;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!(this._hotelsEnabledCheckbox.value && !termsAndConditions)) return [3 /*break*/, 2];
                        this._isLoaded = false;
                        this.observationProvider.notifyObservers(_1.EventHotelConfigurationFormActions.ConfigurationLoading);
                        return [4 /*yield*/, this.api.get("".concat(__1.EndpointUrls.Settings, "/DefaultLodgingTermsAndConditions/").concat(this.tenantId))];
                    case 1:
                        defaultTermsAndConditions = _a.sent();
                        if (defaultTermsAndConditions) {
                            this._hotelTermsAndConditionsField.setValue(defaultTermsAndConditions);
                        }
                        this.observationProvider.notifyObservers(_1.EventHotelConfigurationFormActions.Loaded);
                        this._isLoaded = true;
                        return [3 /*break*/, 3];
                    case 2:
                        this.hotelTermsAndConditionsField.setValue(termsAndConditions);
                        this._isLoaded = true;
                        _a.label = 3;
                    case 3: return [2 /*return*/];
                }
            });
        }); };
        this.setIsDisabled = function (isDisabled) {
            _this._isDisabled = isDisabled;
            _this.hotelTermsAndConditionsField.isDisabled = isDisabled;
            for (var i = 0; i < _this._hotelFields.length; i++) {
                _this._hotelFields[i].isDisabled = isDisabled;
            }
            _this.hotelDataList.setIsEditable(!isDisabled);
            _this.addLodgingButton.setVisibility(!isDisabled);
        };
        this.showHotelForm = function (hotel) {
            var vendorCompanyDropdown = new __1.VendorCompanyDropdown(_this.api, 'vendorCompanyId', false, __1.VendorCompanyDropdownOptionType.Hotel, null, 'Self-Manage');
            var cutOffDateField = new __1.DateField('cutOffDate', 'Cut-off date', true, 'required');
            if (_this.isVendorPortalMode) {
                vendorCompanyDropdown.setVisibility(false);
            }
            if (!hotel) {
                hotel = {
                    cutOffDate: _this.eventStartDate,
                    eventHotelRooms: []
                };
                if (_this.isVendorPortalMode) {
                    hotel.vendorCompanyId = _this.vendorCompanyId;
                }
                else if (_this.hotelVendorDataList.items.length === 1) {
                    hotel.vendorCompanyId = _this.hotelVendorDataList.items[0].form.model.vendorCompanyId;
                }
            }
            _this._hotelForm = new __1.Form([
                vendorCompanyDropdown,
                cutOffDateField,
                new __1.TextField('name', 'Hotel Name', true, 100),
                new __1.HotelDropdown(_this.api, 'hotelId', 'Hotel Rewards Chain', false),
                new __1.TextField('hotelCurrency', 'Hotel Currency', false, 100),
                new __1.TimeField('checkInTime', 'Check-In Time', true, 'required'),
                new __1.TimeField('checkOutTime', 'Check-Out Time', true, 'required'),
                new __1.TextField('address', 'Street Address', false, 250),
                new __1.TextField('city', 'City', false, 250),
                new __1.TextField('state', 'State', false, 100),
                new __1.TextField('zip', 'ZipCode', false, 10),
                new __1.TextField('country', 'Country', false, 100),
                new __1.TextField('contactName', 'Event Hotel Contact Name', true, 100),
                new __1.TextField('phone', 'Event Hotel Contact Phone', true, 50),
                new __1.TextField('comments', 'Event Hotel Comments', false, 1000, null, null, __1.FieldType.TextArea)
            ]);
            _this._hotelForm.setModel(hotel);
            _this._hotelForm.isDisabled = _this._isDisabled;
            var currency = hotel.hotelCurrency ? hotel.hotelCurrency : '';
            _this._hotelRoomDataList = new __1.DataList(function () { return [
                new __1.TextField('name', 'Room Type', true, 100),
                new __1.MoneyField('price', 'Room Rate  ' + currency, false),
                new __1.NumberDropdownField('priceInterval', 'Per', false, [
                    { label: 'Daily', value: 1 },
                    { label: 'Weely', value: 2 },
                    { label: 'Montly', value: 3 }
                ], null, 1, __1.FieldType.DropDown, ''),
                new __1.IntField('numberReserved', '# Reserved', false),
            ]; }, true, 'Add Room Type', 'No room types assigned');
            _this._hotelRoomDataList.setModels(hotel.eventHotelRooms);
            _this._hotelRoomDataList.setIsEditable(!_this._isDisabled);
            _this._hotelRoomDataList.addItemButton.setVisibility(!_this._isDisabled);
            _this.updateHotelFormButton.setDisabled(_this._isDisabled);
            _this._hotelFormIsVisible = true;
            _this.observationProvider.notifyObservers(_1.EventHotelConfigurationFormActions.HotelFormShown);
        };
        this.hideHotelForm = function () {
            _this._hotelForm = null;
            _this._hotelFormIsVisible = false;
            _this.observationProvider.notifyObservers(_1.EventHotelConfigurationFormActions.HotelFormHidden);
        };
        this.updateHotels = function () { return __awaiter(_this, void 0, void 0, function () {
            var isValid, _a, hotelIndex;
            var _this = this;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        this.hotelForm.submit();
                        isValid = this.hotelForm.isValid();
                        isValid = this.hotelRoomDataList.areAllValid() && isValid;
                        if (!isValid) {
                            return [2 /*return*/];
                        }
                        if (!this.hotelForm.model.vendorCompanyId) return [3 /*break*/, 2];
                        _a = this.hotelForm.model;
                        return [4 /*yield*/, this.api.getVendorCompanyName(this.hotelForm.model.vendorCompanyId)];
                    case 1:
                        _a.vendorCompanyName = _b.sent();
                        return [3 /*break*/, 3];
                    case 2:
                        this.hotelForm.model.vendorCompanyName = 'Self-Manage';
                        _b.label = 3;
                    case 3:
                        this.hotelForm.model.eventHotelRooms = this._hotelRoomDataList.getModels();
                        if (this.hotelForm.model.id) {
                            hotelIndex = this.hotelDataList.findIndex(function (hotel) { return hotel.id === _this.hotelForm.model.id; });
                            this.hotelDataList.items[hotelIndex].form.setModel(this.hotelForm.model);
                        }
                        else {
                            this.hotelDataList.addModel(this.hotelForm.model, true);
                        }
                        this.hideHotelForm();
                        return [2 /*return*/];
                }
            });
        }); };
        this.buildEditHotelButton = function (hotelItem) {
            return new button_1.default({
                text: hotelItem.form.model.name,
                onClick: function () { return _this.showHotelForm(hotelItem.form.model); }
            });
        };
        this.accordian = new __1.Accordian(false);
        if (!this.isVendorPortalMode) {
            this._activeHotelPane = __1.EventConfigurationPageHotelPanes.VendorAccess;
        }
        else {
            this._activeHotelPane = __1.EventConfigurationPageHotelPanes.LodgingOptions;
        }
        this.observationProvider = new __1.ObservationProvider();
        this.showHotelTermsAndConditionsPaneButton = new button_1.default({
            text: 'Terms & Conditions',
            onClick: this.showHotelTermsAndConditionsPane
        });
        this.hotelVendorDataList = new __1.DataList(function () { return []; }, true, 'Add Vendor', 'No hotel vendors assigned');
        this.addHotelVendorButton = new button_1.default({
            leftIcon: __1.Icons.Plus,
            text: 'Allow vendor access to event',
            onClick: this.addHotelVendor
        });
        this.addLodgingButton = new button_1.default({
            text: 'Add Hotel',
            leftIcon: __1.Icons.Plus,
            onClick: function () { return _this.showHotelForm(); }
        });
        this.hideHotelFormButton = new button_1.default({
            text: 'Cancel',
            onClick: this.hideHotelForm
        });
        this.updateHotelFormButton = new button_1.default({
            text: 'Update',
            onClick: this.updateHotels
        });
        this.hotelDataList = new __1.DataList(function () { return []; }, true, null, 'No hotels assigned');
        this.showVendorAccessPaneButton = new button_1.default({
            text: 'Vendor Access',
            onClick: this.showHotelVendorAccessPane,
            isVisible: !this.isVendorPortalMode
        });
        this.showLodgingOptionsButton = new button_1.default({
            text: 'Hotel Options',
            onClick: this.showLodgingOptionsPane
        });
        this.buildFields();
    }
    Object.defineProperty(EventHotelConfigurationForm.prototype, "isDisabled", {
        get: function () {
            return this._isDisabled;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(EventHotelConfigurationForm.prototype, "hotelsEnabledCheckbox", {
        get: function () {
            return this._hotelsEnabledCheckbox;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(EventHotelConfigurationForm.prototype, "isLoaded", {
        get: function () {
            return this._isLoaded;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(EventHotelConfigurationForm.prototype, "hotelFields", {
        get: function () {
            return this._hotelFields;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(EventHotelConfigurationForm.prototype, "hotelVendorCompanyDropdown", {
        get: function () {
            return this._hotelVendorCompanyDropdown;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(EventHotelConfigurationForm.prototype, "activeHotelPane", {
        get: function () {
            return this._activeHotelPane;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(EventHotelConfigurationForm.prototype, "hotelFormIsVisible", {
        get: function () {
            return this._hotelFormIsVisible;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(EventHotelConfigurationForm.prototype, "hotelForm", {
        get: function () {
            return this._hotelForm;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(EventHotelConfigurationForm.prototype, "hotelRoomDataList", {
        get: function () {
            return this._hotelRoomDataList;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(EventHotelConfigurationForm.prototype, "hotelTermsAndConditionsField", {
        get: function () {
            return this._hotelTermsAndConditionsField;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(EventHotelConfigurationForm.prototype, "isVendorPortalMode", {
        get: function () {
            return !!this.vendorCompanyId;
        },
        enumerable: false,
        configurable: true
    });
    EventHotelConfigurationForm.prototype.buildFields = function () {
        this._hotelsEnabledCheckbox = new __1.CheckBoxField('isHotelsEnabled', 'Enabled');
        this._hotelVendorCompanyDropdown = new __1.VendorCompanyDropdown(this.api, 'newHotelVendorId', false, __1.VendorCompanyDropdownOptionType.Hotel);
        this._hotelTermsAndConditionsField = new __1.RichTextField('hotelTermsAndConditions', 'Terms & Conditions', false);
        this._hotelFields = [
            this._hotelsEnabledCheckbox,
            this._hotelTermsAndConditionsField
        ];
        if (this.isVendorPortalMode) {
            this._hotelsEnabledCheckbox.setValue(true);
            this._hotelsEnabledCheckbox.setVisibility(false);
        }
        else {
            for (var i = 1; i < this._hotelFields.length; i++) {
                this._hotelFields[i].setVisibility(false);
            }
        }
        this._hotelsEnabledCheckbox.observationProvider.observe(this.hotelsEnabledChanged, __1.FieldActions.ValueChanged);
    };
    EventHotelConfigurationForm.prototype.setEventHotelVendorCompanies = function (hotelVendorCompanies) {
        this.hotelVendorDataList.setModels(hotelVendorCompanies);
    };
    EventHotelConfigurationForm.prototype.getEventHotelVendorCompnies = function () {
        return this.hotelVendorDataList.getModels();
    };
    EventHotelConfigurationForm.prototype.setHotelModels = function (hotels) {
        this.hotelDataList.setModels(hotels);
    };
    EventHotelConfigurationForm.prototype.getHotelModels = function () {
        return this.hotelDataList.getModels();
    };
    return EventHotelConfigurationForm;
}());
exports.default = EventHotelConfigurationForm;
