"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var ConfirmationPromptActions;
(function (ConfirmationPromptActions) {
    ConfirmationPromptActions["Shown"] = "Shown";
    ConfirmationPromptActions["Hidden"] = "Hidden";
    ConfirmationPromptActions["Confirmed"] = "Confirmed";
    ConfirmationPromptActions["Cancelled"] = "Canceled";
    ConfirmationPromptActions["MessageChanged"] = "MessageChanged";
})(ConfirmationPromptActions || (ConfirmationPromptActions = {}));
exports.default = ConfirmationPromptActions;
