"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var __1 = require("..");
var Navigator = /** @class */ (function () {
    function Navigator(pageFactory, config, urlBuilder, api) {
        var _this = this;
        this.pageFactory = pageFactory;
        this.config = config;
        this.urlBuilder = urlBuilder;
        this.api = api;
        this.goToUrl = function (url) {
            _this.observationProvider.notifyObservers(__1.NavigatorActions.NavigatingToUrl, url);
        };
        this.setUserType = function (userType) {
            _this._userType = userType;
        };
        this.setDefaultEventId = function (eventId) {
            _this._defaultEventId = eventId;
        };
        this.goToDefaultPage = function () {
            if (_this._userType == __1.UserTypes.User && _this._defaultEventId) {
                _this.goToEventLanding(_this._defaultEventId);
            }
            else if (_this._userType == __1.UserTypes.VendorUser) {
                _this.goToVendorEventList();
            }
            else {
                _this.goToEventList();
            }
        };
        this.goToSsoLoginPage = function () {
            _this.goToPage(__1.PageTypes.SsoLogin);
        };
        this.toggleUserDropdown = function () {
            _this._userDropdownOpen = !_this._userDropdownOpen;
            _this.notifyOfUserDropdownChange();
        };
        this.closeUserDropdown = function () {
            if (_this._userDropdownOpen) {
                _this._userDropdownOpen = false;
                _this.notifyOfUserDropdownChange();
            }
        };
        this.openUserDropdown = function () {
            if (!_this._userDropdownOpen) {
                _this._userDropdownOpen = true;
                _this.notifyOfUserDropdownChange();
            }
        };
        this.notifyOfUserDropdownChange = function () {
            if (_this._userDropdownOpen) {
                _this.observationProvider.notifyObservers(__1.NavigatorActions.UserDropdownOpened);
            }
            else {
                _this.observationProvider.notifyObservers(__1.NavigatorActions.UserDropdownClosed);
            }
        };
        this.goBack = function () {
            var previousPage = _this._pageHistory[_this._pageHistory.length - 2];
            if (previousPage) {
                _this.goToPage(previousPage.pageType, previousPage.param);
            }
        };
        this.goToLogin = function () {
            _this.goToPage(__1.PageTypes.Login);
        };
        this.goToEventList = function () {
            _this.goToPage(__1.PageTypes.EventList);
        };
        this.goToUserList = function () {
            _this.goToPage(__1.PageTypes.UserList);
        };
        this.goToUserDetail = function (userId) {
            _this.goToPage(__1.PageTypes.UserDetail, userId);
        };
        this.goToCustomerList = function () {
            _this.goToPage(__1.PageTypes.CustomerList);
        };
        this.goToCustomerDetail = function (customerId) {
            _this.goToPage(__1.PageTypes.CustomerDetail, customerId);
        };
        this.goToPasswordResetRequest = function (message) {
            _this.goToPage(__1.PageTypes.RequestPasswordReset, message);
        };
        this.goToPasswordResetRequestText = function (message) {
            _this.goToPage(__1.PageTypes.PasswordResetRequestText, message);
        };
        this.goToResetPassword = function (params) {
            _this.goToPage(__1.PageTypes.ResetPassword, params);
        };
        this.goToCompleteAccessRequestPage = function (requestKey) {
            _this.goToPage(__1.PageTypes.CompleteAccessRequest, requestKey);
        };
        this.goToRequestAccessPage = function () {
            _this.goToPage(__1.PageTypes.RequestAccess);
        };
        this.goToEventLanding = function (eventId, meetingId) {
            _this.goToPage(__1.PageTypes.EventLanding, { eventId: eventId, meetingId: meetingId });
        };
        this.goToHistoryPage = function (params) {
            _this.goToPage(__1.PageTypes.History, params);
        };
        this.goToVendorUserList = function () {
            _this.goToPage(__1.PageTypes.VendorUserList);
        };
        this.goToVendorUserDetailPage = function (vendorUserId) {
            _this.goToPage(__1.PageTypes.VendorUserDetail, vendorUserId);
        };
        this.goToEventConfiguration = function (eventId) {
            _this.goToPage(__1.PageTypes.EventConfiguration, eventId);
        };
        this.goToUserProfile = function () {
            _this.goToPage(__1.PageTypes.UserProfile);
        };
        this.goToNewEventPage = function () {
            _this.goToPage(__1.PageTypes.NewEvent);
        };
        this.goToInventoryListPage = function () {
            _this.goToPage(__1.PageTypes.InventoryList);
        };
        this.goToInventoryDetailPage = function (inventoryItemId) {
            _this.goToPage(__1.PageTypes.InventoryDetailPage, inventoryItemId);
        };
        this.goToUserGroupList = function () {
            _this.goToPage(__1.PageTypes.UserGroupList);
        };
        this.goToUserGroupDetail = function (userGroupId) {
            _this.goToPage(__1.PageTypes.UserGroupDetail, userGroupId);
        };
        this.goToInventoryScheduler = function () {
            _this.goToPage(__1.PageTypes.InventorySchedulerPage);
        };
        this.goToEventOverviewReport = function (reportOptions) {
            _this.goToPage(__1.PageTypes.EventOverviewReport, reportOptions);
        };
        this.goToInventoryForecast = function () {
            _this.goToPage(__1.PageTypes.InventoryForecast);
        };
        this.goToSystemSettingsPage = function () {
            _this.goToPage(__1.PageTypes.SystemSettings);
        };
        this.goToEventHistoryPage = function (params) {
            _this.goToPage(__1.PageTypes.EventHistory, params);
        };
        this.goToCustomerCustomerCompanyList = function () {
            _this.goToPage(__1.PageTypes.CustomerCompanyList);
        };
        this.goToCustomerCompanyDetailPage = function (customerCompanyId) {
            _this.goToPage(__1.PageTypes.CustomerCompanyDetail, customerCompanyId);
        };
        this.goToVendorCompaniesPage = function () {
            _this.goToPage(__1.PageTypes.VendorCompaniesPage);
        };
        this.goToVendorCompanyDetailPage = function (vendorCompanyId) {
            _this.goToPage(__1.PageTypes.VendorCompanyDetailPage, vendorCompanyId);
        };
        this.goToEventAttendeeReport = function (reportOptions) {
            _this.goToPage(__1.PageTypes.AttendeeReport, reportOptions);
        };
        this.goToMeetingsReportPage = function (reportOptions) {
            _this.goToPage(__1.PageTypes.MeetingsReportPage, reportOptions);
        };
        this.goToTenantList = function () {
            _this.goToPage(__1.PageTypes.TenantList);
        };
        this.goToTenantDetailPage = function (tenantId) {
            _this.goToPage(__1.PageTypes.TenantDetailPage, tenantId);
        };
        this.goToHotelReport = function (reportOptions) {
            _this.goToPage(__1.PageTypes.HotelReportPage, reportOptions);
        };
        this.goToTransportationReport = function (reportOptions) {
            _this.goToPage(__1.PageTypes.TransportationReportPage, reportOptions);
        };
        this.goToVendorUserProfile = function () {
            _this.goToPage(__1.PageTypes.VendorUserProfile);
        };
        this.goToVendorEventList = function () {
            _this.goToPage(__1.PageTypes.VendorEventList);
        };
        this.goToVendorEmailTemplates = function () {
            _this.goToPage(__1.PageTypes.VendorEmailTemplates);
        };
        this.goToVendorEmailTemplate = function (id) {
            _this.goToPage(__1.PageTypes.VendorEmailTemplate, id);
        };
        this.goToVendorEventLandingPage = function (eventId, tenantId) {
            _this.goToPage(__1.PageTypes.VendorEventLandingPage, {
                eventId: eventId,
                tenantId: tenantId
            });
        };
        this.goToUrlAssignerPage = function (eventId) {
            _this.goToPage(__1.PageTypes.UrlAssignerPage, eventId);
        };
        this.goToMeetingPlacardPage = function (eventId, passKey) {
            _this.goToPage(__1.PageTypes.MeetingPlacard, { eventId: eventId, passKey: passKey });
        };
        this.goToRoomDisplayPage = function (eventId, passKey) {
            _this.goToPage(__1.PageTypes.RoomDisplayPage, { eventId: eventId, passKey: passKey });
        };
        this.goToAttendeeDataGridReportPage = function (reportOptions) {
            _this.goToPage(__1.PageTypes.AttendeeDataGridReport, reportOptions);
        };
        this.goToVenueDataGridReportPage = function (reportOptions) {
            _this.goToPage(__1.PageTypes.VenueDataGridReport, reportOptions);
        };
        this.goToEmailTemplates = function () {
            _this.goToPage(__1.PageTypes.EmailTemplates);
        };
        this.goToEmailTemplate = function (id) {
            _this.goToPage(__1.PageTypes.EmailTemplate, id);
        };
        this.goToVendorCompanyInfoPage = function () {
            _this.goToPage(__1.PageTypes.VendorCompanyInfo);
        };
        this.goToGolfCartReportPage = function (reportOptions) {
            _this.goToPage(__1.PageTypes.GolfCartReport, reportOptions);
        };
        this.goToDiningReportPage = function (reportOptions) {
            _this.goToPage(__1.PageTypes.DiningReport, reportOptions);
        };
        this.goToHelpUrlsPage = function () {
            _this.goToPage(__1.PageTypes.HelpUrls);
        };
        this.goToDeviceRegistrationPage = function (userDeviceId) {
            _this.goToPage(__1.PageTypes.DeviceRegistration, userDeviceId);
        };
        //<NEXT>
        this.cancelPageChange = function () {
            _this._cancelPageChange = true;
        };
        this.goToPage = function (pageType, param) {
            if (_this._pageHistory &&
                _this._pageHistory.length &&
                _this._pageHistory[_this._pageHistory.length - 1].pageType === pageType &&
                _this._pageHistory[_this._pageHistory.length - 1].param === param) {
                _this.observationProvider.notifyObservers(__1.NavigatorActions.NavigateToSamePageCanceled);
                return;
            }
            _this.observationProvider.notifyObservers(__1.NavigatorActions.PageChanging, pageType);
            if (_this._cancelPageChange) {
                _this._cancelPageChange = false;
                _this.goToDefaultPage();
                return;
            }
            else {
                _this.closeUserDropdown();
                var newPage = _this.pageFactory.createPage(pageType, _this, param);
                _this._currentPage = newPage;
                _this.observationProvider.notifyObservers(__1.NavigatorActions.PageChanged, newPage);
                _this._pageHistory.push({ pageType: pageType, param: param });
            }
        };
        this._pageHistory = [];
        this.observationProvider = new __1.ObservationProvider();
        this._helpListModal = new __1.HelpListModal(api);
    }
    Object.defineProperty(Navigator.prototype, "webBaseUrl", {
        get: function () {
            return this.config.webBaseUrl;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(Navigator.prototype, "helpListModal", {
        get: function () {
            return this._helpListModal;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(Navigator.prototype, "userDropdownOpen", {
        get: function () {
            return this._userDropdownOpen;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(Navigator.prototype, "currentPage", {
        get: function () {
            return this._currentPage;
        },
        enumerable: false,
        configurable: true
    });
    return Navigator;
}());
exports.default = Navigator;
