"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var EventFilesTileActions;
(function (EventFilesTileActions) {
    EventFilesTileActions["Toggled"] = "Toggled";
    EventFilesTileActions["DataLoading"] = "DataLoading";
    EventFilesTileActions["DataLoaded"] = "DataLoaded";
    EventFilesTileActions["EditModeActivated"] = "EditModeEnabled";
    EventFilesTileActions["EditModeDeactivated"] = "EditModeDeactivated";
    EventFilesTileActions["FileUploading"] = "FileUploading";
    EventFilesTileActions["FileUploaded"] = "FileUploaded";
    EventFilesTileActions["FileUploadError"] = "FileUploadError";
    EventFilesTileActions["FileDeleting"] = "FileDeleting";
    EventFilesTileActions["FileDeleted"] = "FileDeleted";
    EventFilesTileActions["FileDeleteError"] = "FileDeleteError";
})(EventFilesTileActions || (EventFilesTileActions = {}));
exports.default = EventFilesTileActions;
