"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var VendorEmailTemplatesPageActions;
(function (VendorEmailTemplatesPageActions) {
    VendorEmailTemplatesPageActions["DataLoaded"] = "DataLoaded";
    VendorEmailTemplatesPageActions["ErrorSaving"] = "ErrorSaving";
    VendorEmailTemplatesPageActions["ConfirmationPromptCancelled"] = "ConfirmationPromptCancelled";
    VendorEmailTemplatesPageActions["TemplateDeleting"] = "TemplateDeleting";
    VendorEmailTemplatesPageActions["TemplateDeleted"] = "TemplateDeleted";
    VendorEmailTemplatesPageActions["ConfirmationPromptShown"] = "ConfirmationPromptShown";
})(VendorEmailTemplatesPageActions || (VendorEmailTemplatesPageActions = {}));
exports.default = VendorEmailTemplatesPageActions;
