"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var StringUtils = /** @class */ (function () {
    function StringUtils() {
    }
    StringUtils.createRandomIdentifier = function () {
        return "".concat(StringUtils.createRandomIdentifierPart()).concat(StringUtils.createRandomIdentifierPart(), "-").concat(StringUtils.createRandomIdentifierPart(), "-").concat(StringUtils.createRandomIdentifierPart(), "-").concat(StringUtils.createRandomIdentifierPart(), "-").concat(StringUtils.createRandomIdentifierPart()).concat(StringUtils.createRandomIdentifierPart()).concat(StringUtils.createRandomIdentifierPart());
    };
    StringUtils.createRandomIdentifierPart = function () {
        return Math.floor((1 + Math.random()) * 0x10000).toString(16).substring(1);
    };
    StringUtils.truncate = function (str, num, keepFileExtension) {
        if (str.length <= num) {
            return str;
        }
        if (keepFileExtension) {
            var nameParts = str.split('.');
            var fileExtension = '';
            if (nameParts[1]) {
                fileExtension = nameParts[nameParts.length - 1];
            }
            return str.slice(0, num) + '...' + fileExtension;
        }
        else {
            return str.slice(0, num) + '...';
        }
    };
    return StringUtils;
}());
exports.default = StringUtils;
