"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
var _1 = require(".");
var utils_1 = require("../../utils");
var fieldType_1 = require("./fieldType");
var TimeField = /** @class */ (function (_super) {
    __extends(TimeField, _super);
    function TimeField(key, label, isRequired, requiredValidationMessage, initialValue) {
        var _this = _super.call(this, key, label, isRequired, 4, requiredValidationMessage, initialValue, fieldType_1.default.Time) || this;
        _this.key = key;
        _this.setPlaceholderLabel('hhmm');
        _this._invalidMessage = "Time is invalid (".concat(_this.placeholderLabel, ")");
        return _this;
    }
    Object.defineProperty(TimeField.prototype, "errorMessage", {
        get: function () {
            var baseErrorMessage = _super.prototype.getErrorMessage.call(this);
            if (baseErrorMessage) {
                return baseErrorMessage;
            }
            if (this.value && !this.isValidTime() && this.isDirty) {
                return this._invalidMessage;
            }
            return baseErrorMessage;
        },
        enumerable: false,
        configurable: true
    });
    TimeField.prototype.isValidTime = function () {
        return utils_1.DateUtils.isValidTime(this.stringValue);
    };
    TimeField.prototype.isValid = function () {
        var isValid = true;
        if (!this.checkRequiredStatus()) {
            isValid = false;
        }
        if (this.stringValue && !this.isValidTime()) {
            isValid = false;
        }
        this.setIsValid(isValid);
        return isValid;
    };
    TimeField.prototype.setValue = function (newValue) {
        if (!newValue) {
            this.setStringValue(null);
        }
        else {
            this.setStringValue(utils_1.DateUtils.formatTimespan(newValue));
        }
    };
    return TimeField;
}(_1.TextField));
exports.default = TimeField;
