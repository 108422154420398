"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g = Object.create((typeof Iterator === "function" ? Iterator : Object).prototype);
    return g.next = verb(0), g["throw"] = verb(1), g["return"] = verb(2), typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.MeetingTilePanes = void 0;
var __1 = require("../../../../..");
var button_1 = require("../../../../../components/button/button");
var components_1 = require("../../../../../components");
var helpButton_1 = require("../../../../../components/button/helpButton");
var helpUrls_1 = require("../../../../helpUrls");
var MeetingTilePanes;
(function (MeetingTilePanes) {
    MeetingTilePanes["Table"] = "Table";
    MeetingTilePanes["MeetingSchedule"] = "MeetingSchedule";
})(MeetingTilePanes || (exports.MeetingTilePanes = MeetingTilePanes = {}));
var EventMeetingsTile = /** @class */ (function (_super) {
    __extends(EventMeetingsTile, _super);
    function EventMeetingsTile(eventId, meetingId, api, _navigator, currentUserManager, navigator) {
        var _this = _super.call(this, 'Meetings', api, currentUserManager.clientChecker.isMobileApp(), false, false) || this;
        _this.eventId = eventId;
        _this.meetingId = meetingId;
        _this._navigator = _navigator;
        _this.currentUserManager = currentUserManager;
        _this.navigator = navigator;
        _this.refreshVenueAssignmentData = function () {
            if (_this._interactiveVenueAssigner.isVisible) {
                _this._interactiveVenueAssigner.loadMeetings();
            }
            else {
                _this.table.unlock();
                _this.table.load(true)
                    .then(function () {
                    _this.table.lock();
                });
            }
        };
        _this.setOriginalIsEditable = function (isEditable) {
            _this._originalEditability = isEditable;
            if (_this._activePane === MeetingTilePanes.Table) {
                _this.setIsEditable(isEditable);
            }
        };
        _this.showMeetingScheduler = function () {
            _this._activePane = MeetingTilePanes.MeetingSchedule;
            _this._originalEditability = _this.isEditable;
            _this.setIsEditable(false);
            _this._meetingSchedule.loadMeetings();
            _this.observationProvider.notifyObservers(__1.EventMeetingsTileActions.ActivePaneChanged);
        };
        _this.showMeetingTable = function () {
            _this._activePane = MeetingTilePanes.Table;
            _this.loadData();
            _this.setIsEditable(_this._originalEditability);
            _this.observationProvider.notifyObservers(__1.EventMeetingsTileActions.ActivePaneChanged);
        };
        _this.optionsApplied = function () {
            _this.table.load();
        };
        _this.loadExapndedContent = function (meeting) { return __awaiter(_this, void 0, void 0, function () {
            var _a;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _a = meeting;
                        return [4 /*yield*/, this.api.get("".concat(__1.EndpointUrls.Events, "/").concat(this.eventId, "/MeetingTableItemDetail/").concat(meeting.id))];
                    case 1:
                        _a.expandedContent = _b.sent();
                        return [2 /*return*/];
                }
            });
        }); };
        _this.toggleVenueAssignmentMode = function () { return __awaiter(_this, void 0, void 0, function () {
            var i;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.saveEditVenues()];
                    case 1:
                        _a.sent();
                        if (this._interactiveVenueAssignerActive) {
                            this.buildVenueAssignmentForms();
                            this.interactiveVenueAssignerActive = false;
                            this.venueAssignmentModeButton.setText('Switch to Interactive Venue Assigner');
                            this.venueAssignmentDropdown.setVisibility(true);
                        }
                        else {
                            if (this._interactiveVenueAssigner.meetings) {
                                for (i = 0; i < this._editVenueForms.length; i++) {
                                    this._interactiveVenueAssigner.changeVenue(this._editVenueForms[i].model.meetingId, this._editVenueForms[i].model.venueId);
                                }
                            }
                            this.venueAssignmentDropdown.setVisibility(false);
                            this.venueAssignmentDropdown.setValue(null);
                            this.interactiveVenueAssignerActive = true;
                            this.venueAssignmentModeButton.setText('Switch to In-line Venue Assigner');
                        }
                        this.table.isVisible = !this.interactiveVenueAssignerActive;
                        this.interactiveVenueAssigner.isVisible = this.interactiveVenueAssignerActive;
                        this.venueAssignmentModeButton.setProcessing(false);
                        return [2 /*return*/];
                }
            });
        }); };
        _this.setAutoRefresh = function () {
            if (_this._autoRefreshIntervalId == null) {
                _this._autoRefreshIntervalId = setInterval(function () {
                    if (_this.autoRefrehsCheckbox.value) {
                        _this.processLoadData();
                    }
                }, 180000);
            }
            else {
                clearInterval(_this._autoRefreshIntervalId);
                _this._autoRefreshIntervalId = null;
            }
        };
        _this.loadData = function () { return __awaiter(_this, void 0, void 0, function () {
            var _a, loadPromise;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        if (!(this._activePane === MeetingTilePanes.MeetingSchedule)) return [3 /*break*/, 1];
                        this.meetingSchedule.loadMeetings();
                        return [3 /*break*/, 3];
                    case 1:
                        _a = this;
                        return [4 /*yield*/, this.api.getEventManager(this.eventId)];
                    case 2:
                        _a._eventManager = _b.sent();
                        this.setMeetingStatusFilter();
                        this.setNewMeetingVisibility();
                        this._sendMeetingInvitesCheckbox.setVisibility(this._eventManager.isMeetingInvitesEnabled);
                        this._sendMeetingInvitesCheckbox.setValue(this._eventManager.isMeetingInvitesEnabled);
                        loadPromise = this.table.load(true);
                        this._loadedDateTime = new Date();
                        if (this.meetingId != null && this.meetingId != undefined) {
                            this._meetingDetailForm.open(this.meetingId);
                        }
                        return [2 /*return*/, loadPromise];
                    case 3: return [2 /*return*/];
                }
            });
        }); };
        _this.setMeetingStatusFilter = function () {
            if (_this.listOptionSlideContainer.filterForm) {
                var field = _this.listOptionSlideContainer.filterForm.getFieldByKey('status');
                field.isRequestDisabled = !_this._eventManager.meetingConfigurations.meetingRequestsEnabled;
            }
        };
        _this.setNewMeetingVisibility = function () {
            var newMeetingButtonIsVisible = _this._eventManager && _this._eventManager.canCreateMeetings && !_this._isVenueEditMode;
            _this.newMeetingButton.setVisibility(newMeetingButtonIsVisible);
        };
        _this.tableLocked = function () {
            _this.listOptionSlideContainer.lock();
            _this.newMeetingButton.setDisabled(true);
        };
        _this.tableUnlocked = function () {
            _this.listOptionSlideContainer.unlock();
            _this.newMeetingButton.setDisabled(false);
        };
        _this.loadMeetingTableData = function () {
            return _this.api.getTablePage(__1.EndpointUrls.Meetings, _this.table.recordsPerPage, _this.table.currentPage, _this.table.sortExpression, _this.table.searchPhrase, __assign({}, _this.listOptionSlideContainer.appliedFiltersModel));
        };
        _this.update = function () {
            _this.saveEditVenues();
            _this.disableVenueAssignment();
            _this.deactivateEditMode();
        };
        _this.setGuestsArrived = function (meetingId, arrived) { return __awaiter(_this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.api.put(__1.EndpointUrls.Meetings + '/GuestsArrived', meetingId, { arrived: arrived })];
                    case 1:
                        _a.sent();
                        this.table.items.filter(function (meeting) { return meeting.id == meetingId; })[0].guestsArrived = arrived;
                        this.observationProvider.notifyObservers(__1.EventMeetingsTileActions.GuestArrivedChanged);
                        return [2 /*return*/];
                }
            });
        }); };
        _this.editVenues = function () {
            _this.venueAssignmentDropdown.setValue(null);
            _this.venueAssignmentModeButton.setVisibility(true);
            _this.refreshVenueAssignmentButton.setVisibility(true);
            _this.table.isVisible = !_this.interactiveVenueAssignerActive;
            _this.interactiveVenueAssigner.isVisible = _this.interactiveVenueAssignerActive;
            _this.buildVenueAssignmentForms();
            _this.table.lock();
            _this._isVenueEditMode = true;
            _this.setNewMeetingVisibility();
            _this.observationProvider.notifyObservers(__1.EventMeetingsTileActions.EditVenueModeChanged);
        };
        _this.cancelEditVenues = function () {
            _this.venueAssignmentModeButton.setVisibility(false);
            _this.refreshVenueAssignmentButton.setVisibility(false);
            _this._interactiveVenueAssigner.isVisible = false;
            _this._isVenueEditMode = false;
            _this.table.isVisible = true;
            _this.table.unlock();
            _this.setNewMeetingVisibility();
            _this.observationProvider.notifyObservers(__1.EventMeetingsTileActions.EditVenuesCancelled);
        };
        _this.saveEditVenues = function () { return __awaiter(_this, void 0, void 0, function () {
            var request, promiseCounter, _loop_1, this_1, _i, _a, venueAssignment;
            var _this = this;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        request = {
                            sendMeetingInvites: this._sendMeetingInvitesCheckbox.value
                        };
                        if (this.interactiveVenueAssignerActive) {
                            request.venueAssignments = this._interactiveVenueAssigner.meetings.map(function (meeting) {
                                return {
                                    venueId: meeting.venueId,
                                    meetingId: meeting.id
                                };
                            });
                        }
                        else {
                            request.venueAssignments = this._editVenueForms.map(function (form) {
                                form.submit();
                                return form.model;
                            });
                        }
                        return [4 /*yield*/, this.api.post(__1.EndpointUrls.Meetings + '/AssignVenues', request)];
                    case 1:
                        _b.sent();
                        promiseCounter = 0;
                        _loop_1 = function (venueAssignment) {
                            return __generator(this, function (_c) {
                                switch (_c.label) {
                                    case 0: return [4 /*yield*/, this_1.api.getVenueNameById(venueAssignment.venueId, this_1.eventId)
                                            .then(function (venueName) {
                                            _this.table.updateRecord(venueAssignment.meetingId, {
                                                venueId: venueAssignment.venueId,
                                                venue: venueName
                                            });
                                            promiseCounter++;
                                            if (promiseCounter === request.venueAssignments.length) {
                                                _this.observationProvider.notifyObservers(__1.EventMeetingsTileActions.VenuesSaved);
                                            }
                                        })];
                                    case 1:
                                        _c.sent();
                                        return [2 /*return*/];
                                }
                            });
                        };
                        this_1 = this;
                        _i = 0, _a = request.venueAssignments;
                        _b.label = 2;
                    case 2:
                        if (!(_i < _a.length)) return [3 /*break*/, 5];
                        venueAssignment = _a[_i];
                        return [5 /*yield**/, _loop_1(venueAssignment)];
                    case 3:
                        _b.sent();
                        _b.label = 4;
                    case 4:
                        _i++;
                        return [3 /*break*/, 2];
                    case 5: return [2 /*return*/];
                }
            });
        }); };
        _this.meetingAdding = function (meeting) {
            var newMeeting = {
                id: meeting.id,
                name: meeting.name,
                venueId: meeting.venueId,
                isActive: true
            };
            _this.table.insertAddingRecord(newMeeting);
        };
        _this.meetingAdded = function (meeting) {
            _this.table.load(true);
            _this.alert.show({
                heading: 'New Meeting Created',
                message: "".concat(meeting.name, " (#").concat(meeting.meetingNumber, ") on ").concat(meeting.date, " at ").concat(meeting.startTime, " - ").concat(meeting.endTime),
                alertType: __1.AlertType.Success,
                button: _this.dismissAlertButton
            });
        };
        _this.meetingUpdated = function (meeting) {
            _this.table.load(true);
            _this.alert.show({
                heading: 'Meeting Updated',
                message: "Meeting #".concat(meeting.meetingNumber, " was saved successfully"),
                alertType: __1.AlertType.Success,
                button: _this.dismissAlertButton
            });
        };
        _this.meetingDeactivated = function (meeting) {
            _this.table.load(true);
            _this.alert.show({
                heading: 'Meeting Deactivated',
                message: "Meeting #".concat(meeting.meetingNumber, " has been deactivated"),
                alertType: __1.AlertType.Success,
                button: _this.dismissAlertButton
            });
        };
        _this.meetingReactivated = function (meeting) {
            _this.table.load(true);
            _this.alert.show({
                heading: 'Meeting Reactivated',
                message: "Meeting #".concat(meeting.meetingNumber, " has been reactivated"),
                alertType: __1.AlertType.Success,
                button: _this.dismissAlertButton
            });
        };
        _this.getGuestsArrivedInfo = function (meeting) {
            if (!meeting.guestsArrivedCheckedBy && !meeting.guestsArrivedIsLoading) {
                meeting.guestsArrivedIsLoading = true;
                _this.api.get("".concat(__1.EndpointUrls.Meetings, "/").concat(meeting.id, "/GuestsArrived"))
                    .then(function (response) {
                    meeting.guestsArrivedCheckedBy = response.guestsArrivedCheckedBy;
                    meeting.guestsArrivedDateTime = response.guestsArrivedDateTime;
                    _this.observationProvider.notifyObservers(__1.EventMeetingsTileActions.GuestArrivedChanged);
                });
            }
        };
        _this.buildMeetingNumberDetailButton = function (meeting) {
            var btn = new button_1.default({
                onClick: function () { return _this.meetingDetailForm.open(meeting.id); },
                text: "#".concat(meeting.meetingNumber)
            });
            btn.setDisabled(_this.table.isLocked);
            return btn;
        };
        _this.buildMeetingDetailButton = function (meeting, truncateName) {
            var buttonText;
            if (truncateName) {
                buttonText = __1.StringUtils.truncate(meeting.name, 25);
            }
            else {
                buttonText = __1.StringUtils.truncate(meeting.name, 30);
            }
            var btn = new button_1.default({
                onClick: function () { return _this.meetingDetailForm.open(meeting.id); },
                text: buttonText
            });
            btn.setDisabled(_this.table.isLocked);
            return btn;
        };
        _this.buildGuestArrivedButton = function (meeting) {
            if (!_this._eventManager || !_this._eventManager.canChangeMeetingGuestArrived) {
                return null;
            }
            if (meeting.guestsArrived) {
                return new button_1.default({
                    leftIcon: __1.Icons.Checked,
                    text: 'Yes',
                    isDisabled: !meeting.isActive,
                    onClick: function () { return _this.setGuestsArrived(meeting.id, false); }
                });
            }
            return new button_1.default({
                leftIcon: __1.Icons.Unchecked,
                text: 'No',
                isDisabled: !meeting.isActive,
                onClick: function () { return _this.setGuestsArrived(meeting.id, true); }
            });
        };
        _this._interactiveVenueAssignerActive = true;
        _this._helpButton = new helpButton_1.default(helpUrls_1.HelpUrlType.MeetingsTile, null, navigator, api);
        var filters = [
            new __1.StringDropdownField('isActive', 'Is Active', false, [{ label: 'All', value: '' }, { label: 'Active', value: 'true' }, { label: 'Inactive', value: 'false' }]),
            new __1.CheckBoxField('myMeetings', 'My meetings'),
            new __1.CheckBoxField('upcoming', 'Upcoming Meetings', false),
            new __1.TextField('meetingNumber', 'Meeting #', false, 5, null, null, __1.FieldType.Int),
            new __1.TextField('meetingName', 'Meeting Name', false, 100),
            new __1.MeetingStatusField('status', 'Status', false),
            new __1.EventAttendeesAssignableToMeetingsDropdown(_this.api, _this.eventId, 'eventAttendeeId', false),
            new __1.TextField('customerName', 'Customer Name', false, 100),
            new __1.BusinessAreaDropdown(_this.api, 'businessAreaId', 'Business Area', false, null),
            new __1.DepartmentDropdown(_this.api, 'departmentId', false),
            new __1.VenueField(_this.api, _this.eventId, 'venueId', 'Assigned Venue', false, '', null, 'Any', null, true),
            new __1.MeetingCreatorsField(_this.api, _this.eventId, 'createdByUserId', false, null, 'Any'),
            new __1.MeetingCategoryField(_this.api, 'defaultMeetingCategoryId', 'Category', false),
            new __1.MeetingDateDropdownField(_this.api, _this.eventId, 'startDate', 'Start Date', false),
            new __1.MeetingDateDropdownField(_this.api, _this.eventId, 'endDate', 'End Date', false),
            new __1.TimeField('startTime', 'Start Time', false),
            new __1.TimeField('endTime', 'End Time', false),
            new __1.VenuePreferenceField(_this.api, _this.eventId, 'venuePreferenceId', 'Venue Preference', false),
            new __1.StringDropdownField('attendeesNotified', 'Attendees Notified', false, [{ label: 'All', value: '' }, { label: 'Yes', value: 'true' }, { label: 'No', value: 'false' }]),
            new components_1.NumberDropdownField('daysSinceCreation', 'Days Since Creation', false, [{ label: 'Any', value: null }, { label: '0-1', value: 1 }, { label: '0-3', value: 3 }, { label: '0-7', value: 7 }])
        ];
        var myMeetingsFilterEnabled = false;
        if (currentUserManager.userPreferences &&
            currentUserManager.userPreferences.defaultMyMeetingsFilterEnabled) {
            myMeetingsFilterEnabled = true;
        }
        var defaultFilters = {
            eventId: _this.eventId,
            isActive: true,
            upcoming: true,
            myMeetings: myMeetingsFilterEnabled,
        };
        _this.listOptionSlideContainer = new __1.ListOptionSlideContainer(filters, defaultFilters, [], ['eventId', 'attendeeUserFullName', 'createdByUserFullName']);
        _this._sendMeetingInvitesCheckbox = new __1.CheckBoxField('sendMeetingInvites', 'Send Calendar Update on Save', false);
        _this.table = new __1.Table(_this.loadMeetingTableData, _this.api, true, _this.loadExapndedContent);
        _this.table.searchBoxPlaceholder = 'By meeting name, #, customer name';
        _this.listOptionSlideContainer.observationProvider.observe(_this.optionsApplied, __1.ListOptionSlideContainerActions.OptionsApplied);
        _this._meetingDetailForm = new __1.MeetingDetailForm(_this.api, _this.eventId, _this._navigator, _this.currentUserManager);
        _this._meetingDetailForm.observationProvider.observe(_this.meetingAdding, __1.MeetingDetailFormActions.RecordAdding);
        _this._meetingDetailForm.observationProvider.observe(_this.meetingAdded, __1.MeetingDetailFormActions.RecordAdded);
        _this._meetingDetailForm.observationProvider.observe(_this.meetingUpdated, __1.MeetingDetailFormActions.RecordUpdated);
        _this._meetingDetailForm.observationProvider.observe(_this.meetingDeactivated, __1.MeetingDetailFormActions.RecordDeactivated);
        _this._meetingDetailForm.observationProvider.observe(_this.meetingReactivated, __1.MeetingDetailFormActions.RecordReactivated);
        _this.observationProvider.observe(_this.editVenues, __1.EventLandingTileActions.EditModeActivated);
        _this.observationProvider.observe(_this.cancelEditVenues, __1.EventLandingTileActions.EditModeDeactivated);
        _this.observationProvider.observe(_this.cancelEditVenues, __1.EventLandingTileActions.Toggled);
        _this.table.observationProvider.observe(_this.tableLocked, __1.TableActions.Locked);
        _this.table.observationProvider.observe(_this.tableUnlocked, __1.TableActions.Unlocked);
        _this.newMeetingButton = new button_1.default({
            text: 'New Meeting',
            leftIcon: __1.Icons.Plus,
            onClick: function () { return _this.meetingDetailForm.open(); },
            isDisabled: _this.table.isLocked,
            isVisible: false
        });
        _this._interactiveVenueAssigner = new __1.InteractiveVenueAssigner(_this.api, _this.eventId, false);
        _this.venueAssignmentModeButton = new button_1.default({
            onClick: _this.toggleVenueAssignmentMode,
            text: 'Switch to In-line Venue Assigner',
            isVisible: false,
            processingText: 'Saving...'
        });
        _this.refreshVenueAssignmentButton = new button_1.default({
            onClick: _this.refreshVenueAssignmentData,
            text: 'Refresh',
            isVisible: false
        });
        _this.meetingScheduleButton = new button_1.default({
            onClick: _this.showMeetingScheduler,
            text: 'Schedule',
        });
        _this.meetingTableButton = new button_1.default({
            onClick: _this.showMeetingTable,
            text: 'List',
        });
        _this.alert = new __1.Alert();
        _this.dismissAlertButton = new button_1.default({
            text: 'Dismiss',
            onClick: _this.alert.hide
        });
        _this._meetingSchedule = new __1.MeetingSchedule(_this.api, _this.eventId, _this.currentUserManager);
        _this._meetingSchedule.observationProvider.observe(function (meetingId) {
            _this.meetingDetailForm.open(meetingId);
        }, __1.MeetingScheduleActions.MeetingClicked);
        if (currentUserManager.userPreferences &&
            currentUserManager.userPreferences.defaultMeetingScheduleEnabled) {
            _this.showMeetingScheduler();
        }
        else {
            _this.showMeetingTable();
        }
        _this.autoRefrehsCheckbox = new __1.CheckBoxField('autoRefresh', 'Auto refresh every 3 minutes', false, null, true);
        _this.setAutoRefresh();
        _this.venueAssignmentDropdown = new __1.VenueField(_this.api, _this.eventId, 'venueId', 'Assign venue to all visible meetings', false, '', null, '', null, true);
        _this.venueAssignmentDropdown.observationProvider.observe(function () {
            for (var i = 0; i < _this.table.items.length; i++) {
                if (_this.editVenueForms && _this.editVenueForms[i]) {
                    _this.editVenueForms[i].fields[0].setValue(_this.venueAssignmentDropdown.value);
                }
            }
        }, components_1.FieldActions.ValueChanged);
        _this.venueAssignmentDropdown.setVisibility(false);
        return _this;
    }
    Object.defineProperty(EventMeetingsTile.prototype, "isVenueEditMode", {
        get: function () {
            return this._isVenueEditMode;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(EventMeetingsTile.prototype, "meetingDetailForm", {
        get: function () {
            return this._meetingDetailForm;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(EventMeetingsTile.prototype, "editVenueForms", {
        get: function () {
            return this._editVenueForms;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(EventMeetingsTile.prototype, "loadedDateTime", {
        get: function () {
            return this._loadedDateTime;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(EventMeetingsTile.prototype, "activePane", {
        get: function () {
            return this._activePane;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(EventMeetingsTile.prototype, "interactiveVenueAssignerActive", {
        get: function () {
            return this._interactiveVenueAssignerActive;
        },
        set: function (value) {
            this._interactiveVenueAssignerActive = value;
            this.observationProvider.notifyObservers(__1.EventMeetingsTileActions.VenueAssignmentModeChanged);
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(EventMeetingsTile.prototype, "interactiveVenueAssigner", {
        get: function () {
            return this._interactiveVenueAssigner;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(EventMeetingsTile.prototype, "meetingSchedule", {
        get: function () {
            return this._meetingSchedule;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(EventMeetingsTile.prototype, "sendMeetingInvitesCheckbox", {
        get: function () {
            return this._sendMeetingInvitesCheckbox;
        },
        enumerable: false,
        configurable: true
    });
    EventMeetingsTile.prototype.disableVenueAssignment = function () {
        this.venueAssignmentModeButton.setVisibility(false);
        this.refreshVenueAssignmentButton.setVisibility(false);
        this.table.isVisible = true;
        this._interactiveVenueAssigner.isVisible = false;
        this._isVenueEditMode = false;
        this.table.unlock();
        this.setNewMeetingVisibility();
        this.observationProvider.notifyObservers(__1.EventMeetingsTileActions.VenueAssignmentModeChanged);
    };
    EventMeetingsTile.prototype.buildVenueAssignmentForms = function () {
        this._editVenueForms = [];
        for (var _i = 0, _a = this.table.items; _i < _a.length; _i++) {
            var meeting = _a[_i];
            var form = new __1.Form([
                new __1.VenueField(this.api, this.eventId, 'venueId', 'Venue', false, null, null, 'Unassigned')
            ]);
            form.setModel({
                meetingId: meeting.id,
                venueId: meeting.venueId
            });
            this._editVenueForms.push(form);
        }
    };
    return EventMeetingsTile;
}(__1.EventLandingTile));
exports.default = EventMeetingsTile;
