"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g = Object.create((typeof Iterator === "function" ? Iterator : Object).prototype);
    return g.next = verb(0), g["throw"] = verb(1), g["return"] = verb(2), typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
var __1 = require("../..");
var api_1 = require("../../api");
var tiles_1 = require("./tiles");
var events_1 = require("../events");
var button_1 = require("../../components/button/button");
var VendorEventLandingPage = /** @class */ (function (_super) {
    __extends(VendorEventLandingPage, _super);
    function VendorEventLandingPage(api, navigator, currentUserManager, params) {
        var _this = _super.call(this, __1.PageTypes.VendorEventLandingPage, 'Event Landing') || this;
        _this.api = api;
        _this.navigator = navigator;
        _this.currentUserManager = currentUserManager;
        _this.params = params;
        _this.loadData = function () { return __awaiter(_this, void 0, void 0, function () {
            var _a;
            var _this = this;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        this._isLoading = true;
                        _a = this;
                        return [4 /*yield*/, this.api.get(api_1.EndpointUrls.Vendors + '/VendorPortal/Events/' + this.eventId)];
                    case 1:
                        _a._landingPageData = _b.sent();
                        this.emailAndServicesTile.setIsTransportationVendor(this._landingPageData.isTransportationVendor);
                        return [4 /*yield*/, this.emailAndServicesTile.setIsHotelVendor(this._landingPageData.isHotelVendor)];
                    case 2:
                        _b.sent();
                        this.reportsTile.setIsTransportationVendor(this._landingPageData.isTransportationVendor);
                        return [4 /*yield*/, this.reportsTile.setIsHotelVendor(this._landingPageData.isHotelVendor)];
                    case 3:
                        _b.sent();
                        this.hotelConfigurationsTile.setVisibility(this._landingPageData.isHotelVendor);
                        this.transportationConfigurationTile.setVisibility(this._landingPageData.isTransportationVendor);
                        this.dropdown.setItems(this.getEventDropdownItems(), this._landingPageData.eventSelectionDropdownItems.findIndex(function (item) { return item.eventId === _this.eventId; }));
                        if (this._landingPageData.status === api_1.EventStatus.Deleted) {
                            this.showDeletedAlert();
                        }
                        else if (this._landingPageData.status === api_1.EventStatus.Archived) {
                            this.showArchivedAlert();
                        }
                        this._isLoading = false;
                        this.observationProvider.notifyObservers(__1.VendorEventLandingPageActions.DataLoaded);
                        return [2 /*return*/];
                }
            });
        }); };
        _this.showDeletedAlert = function () {
            _this.hotelConfigurationsTile.setIsEditable(false);
            _this.transportationConfigurationTile.setIsEditable(false);
            _this.emailAndServicesTile.setIsEditable(false);
            _this.alert.show({
                alertType: __1.AlertType.Error,
                message: _this._deletedAlertMessage,
                heading: 'Deleted Event'
            });
        };
        _this.showArchivedAlert = function () {
            _this.hotelConfigurationsTile.setIsEditable(false);
            _this.transportationConfigurationTile.setIsEditable(false);
            _this.emailAndServicesTile.setIsEditable(false);
            _this.alert.show({
                alertType: __1.AlertType.Error,
                message: _this._archivedAlertMessage,
                heading: 'Archived Event'
            });
        };
        _this.getEventDropdownItems = function () {
            var dropdownItems = [];
            for (var _i = 0, _a = _this._landingPageData.eventSelectionDropdownItems; _i < _a.length; _i++) {
                var event_1 = _a[_i];
                dropdownItems.push({
                    data: event_1,
                    button: new button_1.default({
                        onClick: _this.goToEventLanding(event_1.eventId, event_1.tenantId),
                        text: event_1.name + ' - ' + event_1.tenantName,
                        subText: __1.DateUtils.formatDate(event_1.startDate, __1.DateFormats.MMMdYYYY) + ' - ' + __1.DateUtils.formatDate(event_1.endDate, __1.DateFormats.MMMdYYYY)
                    })
                });
            }
            if (dropdownItems.length) {
                dropdownItems.push({
                    isSeparator: true
                });
            }
            dropdownItems.push({
                button: new button_1.default({
                    onClick: _this.goToEventList,
                    text: 'All Events'
                })
            });
            return dropdownItems;
        };
        _this.goToEventLanding = function (eventId, tenantId) { return function () {
            _this.navigator.goToVendorEventLandingPage(eventId, tenantId);
        }; };
        _this.goToEventList = function () {
            _this.navigator.goToVendorEventList();
        };
        _this._archivedAlertMessage = 'This event has passed and is now archived.';
        _this._deletedAlertMessage = 'This event has been deleted.';
        _this.observationProvider = new __1.ObservationProvider();
        _this.eventInfoTile = new __1.EventInfoTile(_this.eventId, _this.api, false, currentUserManager, navigator);
        _this.emailAndServicesTile = new tiles_1.EmailAndServicesTile(_this.eventId, _this.tenantId, _this.currentUserManager, _this.api, true, true, navigator);
        _this.reportsTile = new events_1.ReportsTile(_this.eventId, _this.api, _this.navigator, currentUserManager, true);
        _this.hotelConfigurationsTile = new tiles_1.HotelConfigurationsTile(_this.eventId, _this.tenantId, _this.currentUserManager, _this.api, true);
        _this.transportationConfigurationTile = new tiles_1.TransportationConfigurationTile(_this.eventId, _this.tenantId, _this.currentUserManager, _this.api, true);
        _this.loadData();
        _this.dropdown = new __1.Dropdown([]);
        return _this;
    }
    Object.defineProperty(VendorEventLandingPage.prototype, "isLoading", {
        get: function () {
            return this._isLoading;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(VendorEventLandingPage.prototype, "landingPageData", {
        get: function () {
            return this._landingPageData;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(VendorEventLandingPage.prototype, "eventId", {
        get: function () {
            return this.params.eventId;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(VendorEventLandingPage.prototype, "tenantId", {
        get: function () {
            return this.params.tenantId;
        },
        enumerable: false,
        configurable: true
    });
    return VendorEventLandingPage;
}(__1.BasePage));
exports.default = VendorEventLandingPage;
