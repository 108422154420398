import { h, JSX } from 'preact';
import ObservingComponent from '../componentBases/observingComponent';
import EmailTemplatePageProps from './emailTemplatePageProps';
import ConfirmationPrompt from '../containers/confirmationPrompt';
import FormButtonContainer from '../containers/formButtonContainer';
import Button from '../buttons/button';
import ButtonColors from '../buttons/buttonColors';
import EmailTemplateField from '../fields/emailTemplateField';
import Field from '../fields/field';
import Alert from '../alerts/alert';

class EmailTemplatePage extends ObservingComponent<EmailTemplatePageProps> {

    public componentWillMount(): void {
        this.registerUpdateObserver(this.props.page.observationProvider);
    }

    public render({ page }: EmailTemplatePageProps): JSX.Element {

        return (
            <div>
                <div class="page-heading">
                    <h3>{page.title}</h3>
                </div>

                <ConfirmationPrompt confirmationPrompt={page.confirmationPrompt} />
                <Alert alert={page.alert} />

                <div class="form-container">
                    <Field
                        className="stacked-label"
                        field={page.form.fields[0]}
                        isLoading={page.isLoading} />

                    <Field
                        className="stacked-label"
                        field={page.form.fields[1]}
                        isLoading={page.isLoading} />


                    <h4 class="section-header">Template Content</h4>

                    <EmailTemplateField
                        isLoading={page.isLoading}
                        field={page.templateContentField}
                        emailTemplateSystemType={page.form.model.systemType} />
                </div>

                <FormButtonContainer
                    leftButtons={[
                        <Button
                            className="btn-icon"
                            color={ButtonColors.Secondary}
                            button={page.showConfirmationPromptButton} />
                    ]}
                    rightButtons={[
                        <Button
                            color={ButtonColors.Secondary}
                            button={page.cancelButton} />,
                        <Button
                            color={ButtonColors.Primary}
                            button={page.saveButton} />
                    ]} />
            </div>
        );
    }
}

export default EmailTemplatePage;
