"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g = Object.create((typeof Iterator === "function" ? Iterator : Object).prototype);
    return g.next = verb(0), g["throw"] = verb(1), g["return"] = verb(2), typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
var __1 = require("../../../../..");
var button_1 = require("../../../../../components/button/button");
var history_1 = require("../../../../history");
var components_1 = require("../../../../../components");
var lodgingStatus_1 = require("./lodgingStatus");
var dailyTransport_1 = require("../dailyTransport");
var bases_1 = require("../../../../../bases");
var helpUrls_1 = require("../../../../helpUrls");
var helpButton_1 = require("../../../../../components/button/helpButton");
var TravelAndAccommodationsTile = /** @class */ (function (_super) {
    __extends(TravelAndAccommodationsTile, _super);
    function TravelAndAccommodationsTile(eventId, api, currentUserManager, navigator) {
        var _this = _super.call(this, 'Travel & Hotel', api, currentUserManager.clientChecker.isMobileApp(), false, false) || this;
        _this.eventId = eventId;
        _this.currentUserManager = currentUserManager;
        _this.navigator = navigator;
        _this.showHistory = function () {
            _this._historyTableIsVisible = true;
            _this._historyTable.load();
            _this.observationProvider.notifyObservers(__1.TravelAndAccommodationsTileActions.HistoryVisibilityChanged);
        };
        _this.hideHistory = function () {
            _this._historyTableIsVisible = false;
            _this.observationProvider.notifyObservers(__1.TravelAndAccommodationsTileActions.HistoryVisibilityChanged);
        };
        _this.showLodgingTermsAndConditions = function () {
            _this._isLodingTermsAndConditionsVisible = true;
            _this.observationProvider.notifyObservers(__1.TravelAndAccommodationsTileActions.LodgingTermsAndConfigurationsVisibilityChanged);
        };
        _this.showTransportTermsAndConditions = function () {
            _this._isTransportTermsAndConditionsVisible = true;
            _this.observationProvider.notifyObservers(__1.TravelAndAccommodationsTileActions.TransportTermsAndConfigurationsVisibilityChanged);
        };
        _this.hideLodgingTermsAndConditions = function () {
            _this._isLodingTermsAndConditionsVisible = false;
            _this.observationProvider.notifyObservers(__1.TravelAndAccommodationsTileActions.LodgingTermsAndConfigurationsVisibilityChanged);
        };
        _this.hideTransportTermsAndConditions = function () {
            _this._isTransportTermsAndConditionsVisible = false;
            _this.observationProvider.notifyObservers(__1.TravelAndAccommodationsTileActions.TransportTermsAndConfigurationsVisibilityChanged);
        };
        _this.saveLastPage = function () {
            if (_this._activePane === __1.TravelAndAccommodationTilePanes.HotelInfo) {
                if (!_this.isHotelInfoValid()) {
                    return;
                }
                _this.saveHotelInfo();
            }
            if (_this._activePane === __1.TravelAndAccommodationTilePanes.DailyTransport) {
                if (!_this.isDailyTransportValid()) {
                    return;
                }
                _this.saveDailyTransport();
            }
            if (_this._activePane === __1.TravelAndAccommodationTilePanes.Departure) {
                if (!_this.isDepartureInfoValid()) {
                    return;
                }
                _this.saveDeparture();
            }
            if (_this._activePane === __1.TravelAndAccommodationTilePanes.Arrival) {
                if (!_this.isArrivalFormValid()) {
                    return;
                }
                _this.saveArrival();
            }
            if (_this._activePane === __1.TravelAndAccommodationTilePanes.AttendeeInfo) {
                if (!_this.isAttendeeInfoFormValid()) {
                    return;
                }
                _this.saveAttendeeInfo();
            }
            _this._activePane = __1.TravelAndAccommodationTilePanes.Completed;
            _this.previousButton.setVisibility(false);
            _this.nextButton.setVisibility(false);
            _this.saveLastPageButton.setVisibility(false);
        };
        _this.guestCountChanged = function () {
            _this.totalInPartyField.setValue(_this.guestDataList.items.length + 1);
        };
        _this.loadData = function () { return __awaiter(_this, void 0, void 0, function () {
            var _a, _b;
            return __generator(this, function (_c) {
                switch (_c.label) {
                    case 0:
                        if (!!this._eventManager) return [3 /*break*/, 3];
                        _a = this;
                        return [4 /*yield*/, this.api.getEventManager(this.eventId)];
                    case 1:
                        _a._eventManager = _c.sent();
                        return [4 /*yield*/, this.buildForm()];
                    case 2:
                        _c.sent();
                        _c.label = 3;
                    case 3:
                        if (!this._eventAttendeeId) {
                            return [2 /*return*/];
                        }
                        _b = this;
                        return [4 /*yield*/, this.api.get("".concat(__1.EndpointUrls.Accommodations, "/").concat(this.eventId, "/EventAttendeeAccommodations/").concat(this._eventAttendeeId))];
                    case 4:
                        _b._usersAccommodation = _c.sent();
                        if (!this._usersAccommodation.totalInParty) {
                            this._usersAccommodation.totalInParty = 1;
                        }
                        this._airportToHotelTransportRequiredField.setVisibility(this._eventManager.isAirportToHotelTransportEnabled);
                        this._hotelToAirportTransportRequiredField.setVisibility(this._eventManager.isHotelToAirportTransportEnabled);
                        this._canViewHistory = this._eventManager.canViewTravelAndLodgingHistory(this.usersAccommodation.userId);
                        if (this._canViewHistory) {
                            this._historyTable = new history_1.HistoryTable(history_1.HistoryTypes.AccommodationHistory, this.usersAccommodation.eventAttendeeId, this.api);
                        }
                        this.setIsEditable(this._eventManager.canEditTravelAndLodging(this.usersAccommodation.userId));
                        if (this._eventManager.isAccommodationCutoffPast) {
                            this.alert.show({
                                alertType: components_1.AlertType.Error,
                                heading: 'Travel & Hotel Cut-off Date Has Passed',
                                message: 'The cut-off date for editing hotel & travel information has passed. Please contact your event producer for more information on changing your travel & hotel information.'
                            });
                        }
                        this.setAttendeeInfoForm();
                        this.setArrivalInfoForm();
                        this.setDepartureForm();
                        this.setDailyTransportForm();
                        this.setHotelInfoForm();
                        this.observationProvider.notifyObservers(__1.TravelAndAccommodationsTileActions.AccommodationsLoaded);
                        return [2 /*return*/];
                }
            });
        }); };
        _this.attendeeSelectionChanged = function () {
            if (!_this.attendeeDropdown.value ||
                _this.attendeeDropdown.value == _this._eventAttendeeId) {
                return;
            }
            _this._eventAttendeeId = _this.attendeeDropdown.value;
            _this.processLoadData();
        };
        _this.setActivePane = function (pane) {
            _this._activePane = pane;
            _this.previousButton.setVisibility(_this._activePane !== __1.TravelAndAccommodationTilePanes.AttendeeInfo);
            if (_this.configurations.isHotelsEnabled) {
                _this.nextButton.setVisibility(_this._activePane !== __1.TravelAndAccommodationTilePanes.HotelInfo);
                _this.saveLastPageButton.setVisibility(_this._activePane === __1.TravelAndAccommodationTilePanes.HotelInfo);
            }
            else if (_this._eventManager.showAccommodationTileTransportPane) {
                _this.nextButton.setVisibility(_this._activePane !== __1.TravelAndAccommodationTilePanes.DailyTransport);
                _this.saveLastPageButton.setVisibility(_this._activePane === __1.TravelAndAccommodationTilePanes.DailyTransport);
            }
            _this.observationProvider.notifyObservers(__1.TravelAndAccommodationsTileActions.ActivePaneChanged);
        };
        _this.update = function () { };
        _this.setAttendeeInfoForm = function () {
            _this._attendeeInfoForm.setModel(_this.usersAccommodation);
            if (!_this.usersAccommodation.contactPhone) {
                _this._contactPhoneField.setValue(_this.usersAccommodation.defaultContactPhone);
            }
            if (!_this._usersAccommodation.email) {
                _this._emailField.setValue(_this.usersAccommodation.defaultEmail);
            }
            if (!_this._usersAccommodation.contactEmail) {
                _this._contactEmailField.setValue(_this.usersAccommodation.defaultContactEmail);
            }
            _this.guestDataList.setModels(_this.usersAccommodation.guests);
        };
        _this.setArrivalInfoForm = function () {
            _this._arrivalForm.setModel(_this.usersAccommodation);
        };
        _this.setDepartureForm = function () {
            _this._departureForm.setModel(_this.usersAccommodation);
        };
        _this.setDailyTransportForm = function () {
            _this._transportationTermsAndConditionsAcceptedField.setVisibility(!_this.usersAccommodation.transportationTermsAndConditionsAccepted);
            _this._isDailyTransportTermsLabelVisible = _this.usersAccommodation.transportationTermsAndConditionsAccepted;
            _this._dailyTransportForm.setModel(_this.usersAccommodation);
            if (_this.usersAccommodation.rentalCarSharingWithEventAttendee) {
                _this._rentalCarSharingWithEventAttendeeIdField.setSearchFieldValue(_this.usersAccommodation.rentalCarSharingWithEventAttendee);
            }
        };
        _this.setHotelInfoForm = function () {
            _this._hotelTermsAndConditionsAcceptedField.setVisibility(!_this.usersAccommodation.hotelTermsAndConditionsAccepted);
            _this._isLodgingTermsLabelVisible = _this.usersAccommodation.hotelTermsAndConditionsAccepted;
            _this._hotelForm.setModel(_this.usersAccommodation);
            if (_this.usersAccommodation.hotelBookingType == null || _this.usersAccommodation.hotelBookingType == undefined) {
                _this._hotelBookingTypeField.setValue(__1.AccommodationHotelBookingType.CompanyProvided);
            }
        };
        _this.buildForm = function () { return __awaiter(_this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                this.buildAttendeeInfoFields();
                this.buildArrivalFields();
                this.buildDepartureFields();
                this.buildDailyTransportFields();
                this.buildHotelFields();
                return [2 /*return*/];
            });
        }); };
        _this.buildAttendeeInfoFields = function () {
            _this._contactPhoneField = new __1.TextField('contactPhone', 'Mobile Phone', true, 50, 'required');
            _this._emailField = new __1.EmailField('email', true, 'Email');
            _this._contactEmailField = new __1.EmailField('contactEmail', true, 'Contact email');
            _this._totalInPartyField = new __1.IntField('totalInParty', 'Total in party', true);
            _this._guestCommentsField = new __1.TextField('guestComments', 'Attendee/Guest Comments', false, 1000, null, null, __1.FieldType.TextArea);
            _this._totalInPartyField.observationProvider.observe(_this.totalInPartyChanged, __1.FieldActions.ValueChanged);
            _this._attendeeInfoForm = new __1.Form([
                _this._contactPhoneField,
                _this._contactEmailField,
                _this._emailField,
                _this._totalInPartyField,
                _this._guestCommentsField
            ]);
        };
        _this.totalInPartyChanged = function () {
            if (!_this.usersAccommodation.lodgingStatus) {
                _this.hotelTotalInRoomField.setValue(_this._totalInPartyField.value);
            }
        };
        _this.buildArrivalFields = function () {
            _this._arrivalDateField = new __1.DateField('arrivalDate', 'Arrival Date', true, 'required', null, _this._eventManager.eventLandingData.startDate);
            _this._arrivalTimeField = new __1.TimeField('arrivalTime', 'Arrival Time', true, 'required');
            _this._arrivalTypeField = new __1.NumberDropdownField('arrivalType', 'Arrival type', true, [{ label: 'Air', value: __1.AccommodationArrivalType.Air }, { label: 'Bus', value: __1.AccommodationArrivalType.Bus }, { label: 'Rail', value: __1.AccommodationArrivalType.Rail }, { label: 'Other', value: __1.AccommodationArrivalType.Other }], 'required');
            _this._arrivalCarrierField = new __1.TextField('arrivalCarrier', 'Arrival carrier', false, 100);
            _this._arrivalCarrierField.setPlaceholderLabel('Ex: AA for American Airlines');
            _this._arrivalOriginField = new __1.TextField('arrivalOrigin', 'Final Leg Origin (Origin of final leg of trip)', false, 100);
            _this._arrivalOriginField.setPlaceholderLabel('Ex: DFW for Dallas / Fort Worth');
            _this._arrivalDestinationField = new __1.TextField('arrivalDestination', 'Arrival destination', false, 100);
            _this._arrivalDestinationField.setPlaceholderLabel('Ex: DFW for Dallas / Fort Worth');
            _this._arrivalNumberField = new __1.TextField('arrivalNumber', 'Arrival number (Final leg of trip)', false, 100);
            _this._arrivalNumberField.setPlaceholderLabel('Ex: AA3323 for American Ailines flight 3323');
            _this._arrivalCommentsField = new __1.TextField('arrivalComments', 'Arrival comments', false, 1000, null, null, __1.FieldType.TextArea);
            _this._airportToHotelTransportRequiredField = new components_1.BooleanDropdownField('airportToHotelTransportRequired', 'Require transportation from arrival destination listed above TO hotel', true, 'required');
            _this._arrivalForm = new __1.Form([
                _this._arrivalDateField,
                _this._arrivalTimeField,
                _this._arrivalTypeField,
                _this._arrivalCarrierField,
                _this._arrivalOriginField,
                _this._arrivalDestinationField,
                _this._arrivalNumberField,
                _this._arrivalCommentsField,
                _this._airportToHotelTransportRequiredField
            ]);
        };
        _this.buildDepartureFields = function () {
            _this._departureDateField = new __1.DateField('departureDate', 'Departure Date', true, 'required', null, _this._eventManager.eventLandingData.endDate);
            _this._departureTimeField = new __1.TimeField('departureTime', 'Departure Time', true);
            _this._departureTypeField = new __1.NumberDropdownField('departureType', 'Departure type', true, [{ label: 'Air', value: __1.AccommodationDestinationType.Air }, { label: 'Bus', value: __1.AccommodationDestinationType.Bus }, { label: 'Rail', value: __1.AccommodationDestinationType.Rail }, { label: 'Other', value: __1.AccommodationDestinationType.Other }], 'required');
            _this._departureCarrierField = new __1.TextField('departureCarrier', 'Departure carrier', false, 100);
            _this._departureCarrierField.setPlaceholderLabel('Ex: AA for American Airlines');
            _this._departureOriginField = new __1.TextField('departureOrigin', 'Departure origin', false, 100);
            _this._departureOriginField.setPlaceholderLabel('Ex: DFW for Dallas / Fort Worth');
            _this._departureDestinationField = new __1.TextField('departureDestination', 'Initial Leg Destination (Destination of initial leg of trip)', false, 100);
            _this._departureDestinationField.setPlaceholderLabel('Ex: DFW for Dallas / Fort Worth');
            _this._departureNumberField = new __1.TextField('departureNumber', 'Departure number (Initial leg of trip)', false, 100);
            _this._departureNumberField.setPlaceholderLabel('Ex: AA3323 for American Ailines flight 3323');
            _this._departureCommentsField = new __1.TextField('departureComments', 'Departure comments', false, 1000, null, null, __1.FieldType.TextArea);
            _this._hotelToAirportTransportRequiredField = new components_1.BooleanDropdownField('hotelToAirportTransportRequired', 'Require transportation from hotel TO departure origin listed above', true, 'required');
            _this._departureForm = new __1.Form([
                _this._departureDateField,
                _this._departureTimeField,
                _this._departureTypeField,
                _this._departureCarrierField,
                _this._departureOriginField,
                _this._departureDestinationField,
                _this._departureNumberField,
                _this._departureCommentsField,
                _this._hotelToAirportTransportRequiredField
            ]);
        };
        _this.buildDailyTransportFields = function () { return __awaiter(_this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                this._companyProvidedDailyTransportRequiredField = new __1.CheckBoxField('companyProvidedDailyTransportRequired', 'Company provided daily transport');
                this._companyProvidedDailyTransportStartDateField = new __1.DateField('companyProvidedDailyTransportStartDate', 'Start date', true, 'required', null, this._eventManager.eventLandingData.startDate);
                this._companyProvidedDailyTransportEndDateField = new __1.DateField('companyProvidedDailyTransportEndDate', 'End date', true, 'required', null, this._eventManager.eventLandingData.endDate);
                this._rentalCarRequiredField = new __1.CheckBoxField('rentalCarRequired', 'Individual Transport');
                this._rentalCarVehicleIdField = new __1.RentalCarOptionDropdown(this.api, this.eventId, 'rentalCarVehicleId', 'Car option', true, null, null);
                this._rentalCarStartDateField = new __1.DateField('rentalCarStartDate', 'Start date', true, 'required', null, this._eventManager.eventLandingData.startDate);
                this._rentalCarEndDateField = new __1.DateField('rentalCarEndDate', 'End date', true, 'required', null, this._eventManager.eventLandingData.endDate);
                this._sharingRentalCarField = new __1.CheckBoxField('sharingRentalCar', 'Individual transport sharing with');
                this._rentalCarSharingWithEventAttendeeIdField = new __1.EventAttendeeSearchField(this.api, this.eventId, 'Attendee sharing with', true, 'required', false, 'rentalCarSharingWithEventAttendeeId', 'fullName', { searchPhrase: '', hasRentalCarVehicle: true });
                this._dailyTransportOtherField = new __1.CheckBoxField('dailyTransportOther', 'Other daily transport');
                this._dailyTransportCommentsField = new __1.TextField('dailyTransportComments', 'Transportation Comments', false, 1000, null, null, __1.FieldType.TextArea);
                this._transportationTermsAndConditionsAcceptedField = new __1.CheckBoxField('transportationTermsAndConditionsAccepted', 'Accept terms and conditions', true, 'You must agree to the terms & conditions');
                this._companyProvidedDailyTransportRequiredField.observationProvider.observe(this.companyProvideDailyTransportChanged, __1.FieldActions.ValueChanged);
                this._companyProvidedDailyTransportStartDateField.setVisibility(false);
                this._companyProvidedDailyTransportEndDateField.setVisibility(false);
                this._rentalCarRequiredField.observationProvider.observe(this.rentalCarRequiredChanged, __1.FieldActions.ValueChanged);
                this._rentalCarVehicleIdField.setVisibility(false);
                this._rentalCarStartDateField.setVisibility(false);
                this._rentalCarEndDateField.setVisibility(false);
                this._sharingRentalCarField.observationProvider.observe(this.rentalCarSharedWithChanged, __1.FieldActions.ValueChanged);
                this._rentalCarSharingWithEventAttendeeIdField.setVisibility(false);
                this._dailyTransportForm = new __1.Form([
                    this._companyProvidedDailyTransportRequiredField,
                    this._companyProvidedDailyTransportStartDateField,
                    this._companyProvidedDailyTransportEndDateField,
                    this._rentalCarRequiredField,
                    this._rentalCarVehicleIdField,
                    this._rentalCarStartDateField,
                    this._rentalCarEndDateField,
                    this._sharingRentalCarField,
                    this._rentalCarSharingWithEventAttendeeIdField,
                    this._dailyTransportOtherField,
                    this._dailyTransportCommentsField,
                    this._transportationTermsAndConditionsAcceptedField
                ]);
                this._dailyTransportFormObserverId = null;
                return [2 /*return*/];
            });
        }); };
        _this.companyProvideDailyTransportChanged = function () {
            var transportRequired = _this._companyProvidedDailyTransportRequiredField.value;
            _this._companyProvidedDailyTransportStartDateField.setVisibility(transportRequired);
            _this._companyProvidedDailyTransportEndDateField.setVisibility(transportRequired);
        };
        _this.rentalCarRequiredChanged = function () {
            var transportRequired = _this._rentalCarRequiredField.value;
            _this._rentalCarVehicleIdField.setVisibility(transportRequired);
            _this._rentalCarStartDateField.setVisibility(transportRequired);
            _this._rentalCarEndDateField.setVisibility(transportRequired);
        };
        _this.rentalCarSharedWithChanged = function () {
            var sharing = _this._sharingRentalCarField.value;
            _this._rentalCarSharingWithEventAttendeeIdField.setVisibility(sharing);
        };
        _this.buildHotelFields = function () {
            _this._hotelTermsAndConditionsAcceptedField = new __1.CheckBoxField('hotelTermsAndConditionsAccepted', 'Accept terms and conditions', true, 'You must agree to the terms & conditions');
            _this._hotelBookingTypeField = new __1.NumberDropdownField('hotelBookingType', 'Booking Type', true, [{ label: 'I will need a company provided room', value: __1.AccommodationHotelBookingType.CompanyProvided }, { label: 'I will get my own room', value: __1.AccommodationHotelBookingType.Own }], 'required', __1.AccommodationHotelBookingType.CompanyProvided);
            _this._hotelCheckInDateField = new __1.DateField('hotelCheckInDate', 'Check-in', true, 'required', null, _this._eventManager.eventLandingData.startDate);
            _this._hotelCheckOutDateField = new __1.DateField('hotelCheckOutDate', 'Check-out', true, 'required', null, _this._eventManager.eventLandingData.endDate);
            _this._hotelEarlyArrivalRequiredField = new __1.StringDropdownField('hotelEarlyArrivalRequired', 'Hold room for early arrival', true, [{ label: 'Yes', value: 'true' }, { label: 'No', value: 'false' }], 'required');
            _this._hotelLateCheckoutRequiredField = new __1.CheckBoxField('hotelLateCheckoutRequired', 'Late check-out');
            _this._hotelTotalInRoomField = new __1.IntField('hotelTotalInRoom', 'Total in room', true);
            _this._hotelComments = new __1.TextField('hotelComments', 'Hotel comments', false, 1000, null, null, __1.FieldType.TextArea);
            _this._hotelConfirmationField = new __1.TextField('lodgingConfirmationNumber', 'Confirmation Number', false, 250);
            _this._hotelForm = new __1.Form([
                _this._hotelTermsAndConditionsAcceptedField,
                _this._hotelBookingTypeField,
                _this._hotelCheckInDateField,
                _this._hotelCheckOutDateField,
                _this._hotelEarlyArrivalRequiredField,
                _this._hotelLateCheckoutRequiredField,
                _this._hotelTotalInRoomField,
                _this._hotelComments,
                _this._hotelConfirmationField
            ]);
            _this._lodingFormObserverId = null;
        };
        _this.movePreviousPane = function () {
            switch (_this._activePane) {
                case __1.TravelAndAccommodationTilePanes.AttendeeInfo:
                    break;
                case __1.TravelAndAccommodationTilePanes.HotelInfo:
                    if (_this._eventManager.showAccommodationTileTransportPane) {
                        _this.setActivePane(__1.TravelAndAccommodationTilePanes.DailyTransport);
                    }
                    else {
                        if (_this._eventManager.requireArrivalDepartureInformation) {
                            _this.setActivePane(__1.TravelAndAccommodationTilePanes.Departure);
                        }
                        else {
                            _this.setActivePane(__1.TravelAndAccommodationTilePanes.AttendeeInfo);
                        }
                    }
                    break;
                case __1.TravelAndAccommodationTilePanes.DailyTransport:
                    if (_this._eventManager.requireArrivalDepartureInformation) {
                        _this.setActivePane(__1.TravelAndAccommodationTilePanes.Departure);
                    }
                    else {
                        _this.setActivePane(__1.TravelAndAccommodationTilePanes.AttendeeInfo);
                    }
                    break;
                case __1.TravelAndAccommodationTilePanes.Departure:
                    _this.setActivePane(__1.TravelAndAccommodationTilePanes.Arrival);
                    break;
                case __1.TravelAndAccommodationTilePanes.Arrival:
                    _this.setActivePane(__1.TravelAndAccommodationTilePanes.AttendeeInfo);
                    break;
            }
        };
        _this.moveNextPane = function () {
            switch (_this._activePane) {
                case __1.TravelAndAccommodationTilePanes.AttendeeInfo:
                    if (_this.isAttendeeInfoFormValid()) {
                        _this.saveAttendeeInfo();
                        if (_this._eventManager.requireArrivalDepartureInformation) {
                            _this.setActivePane(__1.TravelAndAccommodationTilePanes.Arrival);
                        }
                        else {
                            if (_this._eventManager.showAccommodationTileTransportPane) {
                                _this.setActivePane(__1.TravelAndAccommodationTilePanes.DailyTransport);
                            }
                            else if (_this.configurations.isHotelsEnabled) {
                                _this.setActivePane(__1.TravelAndAccommodationTilePanes.HotelInfo);
                            }
                        }
                    }
                    break;
                case __1.TravelAndAccommodationTilePanes.Arrival:
                    if (_this.isArrivalFormValid()) {
                        _this.saveArrival();
                        _this.setActivePane(__1.TravelAndAccommodationTilePanes.Departure);
                    }
                    break;
                case __1.TravelAndAccommodationTilePanes.Departure:
                    if (_this.isDepartureInfoValid()) {
                        _this.saveDeparture();
                        if (_this._eventManager.showAccommodationTileTransportPane) {
                            _this.setActivePane(__1.TravelAndAccommodationTilePanes.DailyTransport);
                        }
                        else if (_this.configurations.isHotelsEnabled) {
                            _this.setActivePane(__1.TravelAndAccommodationTilePanes.HotelInfo);
                        }
                    }
                    break;
                case __1.TravelAndAccommodationTilePanes.DailyTransport:
                    if (_this.isDailyTransportValid()) {
                        _this.saveDailyTransport();
                        if (_this.configurations.isHotelsEnabled) {
                            _this.setActivePane(__1.TravelAndAccommodationTilePanes.HotelInfo);
                        }
                    }
                    break;
                case __1.TravelAndAccommodationTilePanes.HotelInfo:
                    if (_this.isHotelInfoValid()) {
                        _this.saveHotelInfo();
                    }
                    break;
            }
        };
        _this.isAttendeeInfoFormValid = function () {
            _this._attendeeInfoForm.submit();
            return _this._attendeeInfoForm.isValid() &&
                _this.guestDataList.areAllValid();
        };
        _this.saveAttendeeInfo = function () { return __awaiter(_this, void 0, void 0, function () {
            var err_1;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!this.isAttendeeInfoFormValid()) return [3 /*break*/, 4];
                        this._attendeeInfoForm.model.guests = this.guestDataList.getModels();
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 3, , 4]);
                        return [4 /*yield*/, this.api.post(__1.EndpointUrls.Accommodations + '/' + this._attendeeInfoForm.model.eventAttendeeId + '/AttendeeInfo', this._attendeeInfoForm.model)];
                    case 2:
                        _a.sent();
                        this._attendeeInfoForm.model.guests = this.guestDataList.getModels().filter(function (guest) { return guest.isRemoved !== true; });
                        this.usersAccommodation.attendeeInfoSubmitted = true;
                        this.alert.hide();
                        this.observationProvider.notifyObservers(__1.TravelAndAccommodationsTileActions.EventRequirementsSaved);
                        return [3 /*break*/, 4];
                    case 3:
                        err_1 = _a.sent();
                        this.handleError();
                        return [3 /*break*/, 4];
                    case 4: return [2 /*return*/];
                }
            });
        }); };
        _this.isArrivalFormValid = function () {
            _this._arrivalForm.submit();
            return _this._arrivalForm.isValid();
        };
        _this.saveArrival = function () { return __awaiter(_this, void 0, void 0, function () {
            var err_2;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!this.isArrivalFormValid()) return [3 /*break*/, 4];
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 3, , 4]);
                        return [4 /*yield*/, this.api.post(__1.EndpointUrls.Accommodations + '/' + this._arrivalForm.model.eventAttendeeId + '/ArrivalInfo', this._arrivalForm.model)];
                    case 2:
                        _a.sent();
                        this.usersAccommodation.arrivalInfoSubmitted = true;
                        this.observationProvider.notifyObservers(__1.TravelAndAccommodationsTileActions.ArrivalInfoSubmitted);
                        this.alert.hide();
                        return [3 /*break*/, 4];
                    case 3:
                        err_2 = _a.sent();
                        this.handleError();
                        return [3 /*break*/, 4];
                    case 4: return [2 /*return*/];
                }
            });
        }); };
        _this.isDepartureInfoValid = function () {
            _this._departureForm.submit();
            var isValid = _this._departureForm.isValid();
            if (isValid) {
                _this._isArrivalDateGreaterThanDepartureDate = __1.DateUtils.isFirstDateGreater(_this._arrivalDateField.value, _this._departureDateField.value);
                _this.observationProvider.notifyObservers(__1.TravelAndAccommodationsTileActions.ArrivalDepartureDatesCompared);
            }
            isValid = isValid && !_this._isArrivalDateGreaterThanDepartureDate;
            return isValid;
        };
        _this.saveDeparture = function () { return __awaiter(_this, void 0, void 0, function () {
            var err_3;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!this.isDepartureInfoValid()) return [3 /*break*/, 4];
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 3, , 4]);
                        return [4 /*yield*/, this.api.post(__1.EndpointUrls.Accommodations + '/' + this._departureForm.model.eventAttendeeId + '/DepartureInfo', this._departureForm.model)];
                    case 2:
                        _a.sent();
                        this.usersAccommodation.departureInfoSubmitted = true;
                        this.observationProvider.notifyObservers(__1.TravelAndAccommodationsTileActions.DepartureInfoSubmitted);
                        this.alert.hide();
                        return [3 /*break*/, 4];
                    case 3:
                        err_3 = _a.sent();
                        this.handleError();
                        return [3 /*break*/, 4];
                    case 4: return [2 /*return*/];
                }
            });
        }); };
        _this.isDailyTransportValid = function () {
            _this._dailyTransportForm.submit();
            return _this._dailyTransportForm.isValid();
        };
        _this.saveDailyTransport = function () { return __awaiter(_this, void 0, void 0, function () {
            var err_4;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!this.isDailyTransportValid()) return [3 /*break*/, 4];
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 3, , 4]);
                        return [4 /*yield*/, this.api.post(__1.EndpointUrls.Accommodations + '/' + this._dailyTransportForm.model.eventAttendeeId + '/DailyTransport', this._dailyTransportForm.model)];
                    case 2:
                        _a.sent();
                        if (this._dailyTransportForm.model.rentalCarSharingWithEventAttendee) {
                            this._rentalCarSharingWithEventAttendeeIdField.setSearchFieldValue(this._dailyTransportForm.model.rentalCarSharingWithEventAttendee);
                        }
                        this.alert.hide();
                        this.observationProvider.notifyObservers(__1.TravelAndAccommodationsTileActions.DailyTransportInfoSubmitted);
                        return [3 /*break*/, 4];
                    case 3:
                        err_4 = _a.sent();
                        this.handleError();
                        return [3 /*break*/, 4];
                    case 4: return [2 /*return*/];
                }
            });
        }); };
        _this.isHotelInfoValid = function () {
            _this._hotelForm.submit();
            return _this._hotelForm.isValid();
        };
        _this.saveHotelInfo = function () { return __awaiter(_this, void 0, void 0, function () {
            var err_5;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!this.isHotelInfoValid()) return [3 /*break*/, 4];
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 3, , 4]);
                        return [4 /*yield*/, this.api.post(__1.EndpointUrls.Accommodations + '/' + this._hotelForm.model.eventAttendeeId + '/Lodging', this._hotelForm.model)];
                    case 2:
                        _a.sent();
                        this.alert.hide();
                        this.observationProvider.notifyObservers(__1.TravelAndAccommodationsTileActions.LodingInfoSubmitted);
                        return [3 /*break*/, 4];
                    case 3:
                        err_5 = _a.sent();
                        this.handleError();
                        return [3 /*break*/, 4];
                    case 4: return [2 /*return*/];
                }
            });
        }); };
        _this.handleError = function () {
            _this.alert.show({
                alertType: components_1.AlertType.Error,
                heading: 'Unable to Save',
                message: 'An error occurred trying to save the travel & lodging information for this attendee'
            });
        };
        _this.attendeeInfoHelpButton = new helpButton_1.default(helpUrls_1.HelpUrlType.TravelAndAccommodationTileAttendeeInfoPane, null, navigator, api);
        _this.arrivalHelpButton = new helpButton_1.default(helpUrls_1.HelpUrlType.TravelAndAccommodationTileArrivalPane, null, navigator, api);
        _this.hotelHelpButton = new helpButton_1.default(helpUrls_1.HelpUrlType.TravelAndAccommodationTileDeparturePane, null, navigator, api);
        _this.departureHelpButton = new helpButton_1.default(helpUrls_1.HelpUrlType.TravelAndAccommodationTileDailyTransportPane, null, navigator, api);
        _this.hotelHelpButton = new helpButton_1.default(helpUrls_1.HelpUrlType.TravelAndAccommodationTileHotelInfo, null, navigator, api);
        _this._usersAccommodation = {
            guests: []
        };
        _this._activePane = __1.TravelAndAccommodationTilePanes.AttendeeInfo;
        _this.nextButton = new button_1.default({
            onClick: _this.moveNextPane,
            text: 'Save & Next',
            rightIcon: __1.Icons.ChevronRight
        });
        _this.previousButton = new button_1.default({
            onClick: _this.movePreviousPane,
            text: 'Previous',
            leftIcon: __1.Icons.ChevronLeft,
            isVisible: false
        });
        _this.guestDataList = new __1.DataList(function () { return [
            new __1.TextField('name', 'Guest Name', true, 400, 'required')
        ]; }, true, 'Add Guest', 'Attendee has no guests');
        _this.guestDataList.observationProvider.observe(_this.guestCountChanged);
        _this.viewTransportTermsAndConditionsButton = new button_1.default({
            text: 'Terms & Conditions',
            onClick: _this.showTransportTermsAndConditions
        });
        _this.viewLodgingTermsAndConditionsButton = new button_1.default({
            text: 'Terms & Conditions',
            onClick: _this.showLodgingTermsAndConditions
        });
        _this.saveLastPageButton = new button_1.default({
            text: 'Save & Next',
            onClick: _this.saveLastPage,
            rightIcon: __1.Icons.ChevronRight,
            isVisible: false
        });
        _this.showHistoryButton = new button_1.default({
            leftIcon: __1.Icons.Clock,
            onClick: _this.showHistory
        });
        _this.alert = new __1.Alert();
        _this.attendeeDropdown = new __1.EventAttendeesRequiringAccommodationsDropdown(_this.api, _this.eventId, 'eventAttendeeId', true);
        _this.attendeeDropdown.observationProvider.observe(_this.attendeeSelectionChanged, __1.FieldActions.ValueChanged);
        _this.saveButton.setVisibility(false);
        _this.observationProvider.observe(function () { return _this.setActivePane(__1.TravelAndAccommodationTilePanes.AttendeeInfo); }, bases_1.EventLandingTileActions.EditModeActivated);
        _this.observationProvider.observe(function () {
            _this.attendeeDropdown.isDisabled = false;
            _this.processLoadData();
        }, bases_1.EventLandingTileActions.EditModeDeactivated);
        _this.observationProvider.observe(function () {
            _this.attendeeDropdown.isDisabled = true;
        }, bases_1.EventLandingTileActions.EditModeActivated);
        return _this;
    }
    Object.defineProperty(TravelAndAccommodationsTile.prototype, "isArrivalDateGreaterThanDepartureDate", {
        get: function () {
            return this._isArrivalDateGreaterThanDepartureDate;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(TravelAndAccommodationsTile.prototype, "usersAccommodation", {
        get: function () {
            return this._usersAccommodation;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(TravelAndAccommodationsTile.prototype, "activePane", {
        get: function () {
            return this._activePane;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(TravelAndAccommodationsTile.prototype, "contactPhoneField", {
        get: function () {
            return this._contactPhoneField;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(TravelAndAccommodationsTile.prototype, "emailField", {
        get: function () {
            return this._emailField;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(TravelAndAccommodationsTile.prototype, "contactEmailField", {
        get: function () {
            return this._contactEmailField;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(TravelAndAccommodationsTile.prototype, "totalInPartyField", {
        get: function () {
            return this._totalInPartyField;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(TravelAndAccommodationsTile.prototype, "guestCommentsField", {
        get: function () {
            return this._guestCommentsField;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(TravelAndAccommodationsTile.prototype, "arrivalDateField", {
        get: function () {
            return this._arrivalDateField;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(TravelAndAccommodationsTile.prototype, "arrivalTimeField", {
        get: function () {
            return this._arrivalTimeField;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(TravelAndAccommodationsTile.prototype, "arrivalTypeField", {
        get: function () {
            return this._arrivalTypeField;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(TravelAndAccommodationsTile.prototype, "arrivalCarrierField", {
        get: function () {
            return this._arrivalCarrierField;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(TravelAndAccommodationsTile.prototype, "arrivalOriginField", {
        get: function () {
            return this._arrivalOriginField;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(TravelAndAccommodationsTile.prototype, "arrivalDestinationField", {
        get: function () {
            return this._arrivalDestinationField;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(TravelAndAccommodationsTile.prototype, "arrivalNumberField", {
        get: function () {
            return this._arrivalNumberField;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(TravelAndAccommodationsTile.prototype, "arrivalCommentsField", {
        get: function () {
            return this._arrivalCommentsField;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(TravelAndAccommodationsTile.prototype, "departureDateField", {
        get: function () {
            return this._departureDateField;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(TravelAndAccommodationsTile.prototype, "departureTimeField", {
        get: function () {
            return this._departureTimeField;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(TravelAndAccommodationsTile.prototype, "departureTypeField", {
        get: function () {
            return this._departureTypeField;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(TravelAndAccommodationsTile.prototype, "departureCarrierField", {
        get: function () {
            return this._departureCarrierField;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(TravelAndAccommodationsTile.prototype, "departureOriginField", {
        get: function () {
            return this._departureOriginField;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(TravelAndAccommodationsTile.prototype, "departureDestinationField", {
        get: function () {
            return this._departureDestinationField;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(TravelAndAccommodationsTile.prototype, "departureNumberField", {
        get: function () {
            return this._departureNumberField;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(TravelAndAccommodationsTile.prototype, "departureCommentsField", {
        get: function () {
            return this._departureCommentsField;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(TravelAndAccommodationsTile.prototype, "airportToHotelTransportRequiredField", {
        get: function () {
            return this._airportToHotelTransportRequiredField;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(TravelAndAccommodationsTile.prototype, "hotelToAirportTransportRequiredField", {
        get: function () {
            return this._hotelToAirportTransportRequiredField;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(TravelAndAccommodationsTile.prototype, "airportTransportCommentsField", {
        get: function () {
            return this._airportTransportCommentsField;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(TravelAndAccommodationsTile.prototype, "transportationTermsAndConditionsAcceptedField", {
        get: function () {
            return this._transportationTermsAndConditionsAcceptedField;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(TravelAndAccommodationsTile.prototype, "companyProvidedDailyTransportRequiredField", {
        get: function () {
            return this._companyProvidedDailyTransportRequiredField;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(TravelAndAccommodationsTile.prototype, "companyProvidedDailyTransportStartDateField", {
        get: function () {
            return this._companyProvidedDailyTransportStartDateField;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(TravelAndAccommodationsTile.prototype, "companyProvidedDailyTransportEndDateField", {
        get: function () {
            return this._companyProvidedDailyTransportEndDateField;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(TravelAndAccommodationsTile.prototype, "rentalCarRequiredField", {
        get: function () {
            return this._rentalCarRequiredField;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(TravelAndAccommodationsTile.prototype, "rentalCarVehicleIdField", {
        get: function () {
            return this._rentalCarVehicleIdField;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(TravelAndAccommodationsTile.prototype, "rentalCarStartDateField", {
        get: function () {
            return this._rentalCarStartDateField;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(TravelAndAccommodationsTile.prototype, "rentalCarEndDateField", {
        get: function () {
            return this._rentalCarEndDateField;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(TravelAndAccommodationsTile.prototype, "sharingRentalCarField", {
        get: function () {
            return this._sharingRentalCarField;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(TravelAndAccommodationsTile.prototype, "rentalCarSharingWithEventAttendeeIdField", {
        get: function () {
            return this._rentalCarSharingWithEventAttendeeIdField;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(TravelAndAccommodationsTile.prototype, "dailyTransportOtherField", {
        get: function () {
            return this._dailyTransportOtherField;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(TravelAndAccommodationsTile.prototype, "dailyTransportCommentsField", {
        get: function () {
            return this._dailyTransportCommentsField;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(TravelAndAccommodationsTile.prototype, "hotelTermsAndConditionsAcceptedField", {
        get: function () {
            return this._hotelTermsAndConditionsAcceptedField;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(TravelAndAccommodationsTile.prototype, "hotelBookingTypeField", {
        get: function () {
            return this._hotelBookingTypeField;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(TravelAndAccommodationsTile.prototype, "hotelCheckInDateField", {
        get: function () {
            return this._hotelCheckInDateField;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(TravelAndAccommodationsTile.prototype, "hotelCheckOutDateField", {
        get: function () {
            return this._hotelCheckOutDateField;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(TravelAndAccommodationsTile.prototype, "hotelEarlyArrivalRequiredField", {
        get: function () {
            return this._hotelEarlyArrivalRequiredField;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(TravelAndAccommodationsTile.prototype, "hotelLateCheckoutRequiredField", {
        get: function () {
            return this._hotelLateCheckoutRequiredField;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(TravelAndAccommodationsTile.prototype, "hotelTotalInRoomField", {
        get: function () {
            return this._hotelTotalInRoomField;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(TravelAndAccommodationsTile.prototype, "hotelComments", {
        get: function () {
            return this._hotelComments;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(TravelAndAccommodationsTile.prototype, "hotelConfirmationField", {
        get: function () {
            return this._hotelConfirmationField;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(TravelAndAccommodationsTile.prototype, "isLodingTermsAndConditionsVisible", {
        get: function () {
            return this._isLodingTermsAndConditionsVisible;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(TravelAndAccommodationsTile.prototype, "isTransportTermsAndConditionsVisible", {
        get: function () {
            return this._isTransportTermsAndConditionsVisible;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(TravelAndAccommodationsTile.prototype, "isDailyIndividualTransportAvailable", {
        get: function () {
            return this._eventManager &&
                this._eventManager.isDailyIndividualTransportAvailable;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(TravelAndAccommodationsTile.prototype, "isDailyHotelEventTransportEnabled", {
        get: function () {
            return this._eventManager &&
                this._eventManager.isDailyHotelEventTransportEnabled;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(TravelAndAccommodationsTile.prototype, "arrivalDepartureComments", {
        get: function () {
            if (this._eventManager) {
                return this._eventManager.arrivalDepartureComments;
            }
            return '';
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(TravelAndAccommodationsTile.prototype, "dailyShuttleComments", {
        get: function () {
            if (this._eventManager) {
                return this._eventManager.dailySuttleComments;
            }
            return '';
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(TravelAndAccommodationsTile.prototype, "isAnyInfoSubmitted", {
        get: function () {
            if (!this.usersAccommodation) {
                return false;
            }
            return this.usersAccommodation.attendeeInfoSubmitted ||
                this.usersAccommodation.arrivalInfoSubmitted ||
                this.usersAccommodation.departureInfoSubmitted ||
                this.usersAccommodation.lodgingStatus != lodgingStatus_1.default.NotSubmitted ||
                this.usersAccommodation.dailyTransportStatus != dailyTransport_1.DailyTransportStatus.NotSubmitted;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(TravelAndAccommodationsTile.prototype, "isDailyTransportTermsLabelVisible", {
        get: function () {
            return this._isDailyTransportTermsLabelVisible;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(TravelAndAccommodationsTile.prototype, "isLodgingTermsLabelVisible", {
        get: function () {
            return this._isLodgingTermsLabelVisible;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(TravelAndAccommodationsTile.prototype, "historyTableIsVisible", {
        get: function () {
            return this._historyTableIsVisible;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(TravelAndAccommodationsTile.prototype, "historyTable", {
        get: function () {
            return this._historyTable;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(TravelAndAccommodationsTile.prototype, "canViewHistory", {
        get: function () {
            return this._canViewHistory;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(TravelAndAccommodationsTile.prototype, "configurations", {
        get: function () {
            if (!this._eventManager) {
                return {};
            }
            return this._eventManager.travelAndLodingConfigurations;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(TravelAndAccommodationsTile.prototype, "attendeeContactPhone", {
        get: function () {
            if (this.usersAccommodation && this.usersAccommodation.contactPhone) {
                return this.usersAccommodation.contactPhone;
            }
            if (this.usersAccommodation && this.usersAccommodation.defaultContactPhone) {
                return this.usersAccommodation.defaultContactPhone;
            }
            return '';
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(TravelAndAccommodationsTile.prototype, "attendeeContactEmail", {
        get: function () {
            if (this.usersAccommodation && this.usersAccommodation.contactEmail) {
                return this.usersAccommodation.contactEmail;
            }
            if (this.usersAccommodation && this.usersAccommodation.defaultContactEmail) {
                return this.usersAccommodation.defaultContactEmail;
            }
            return '';
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(TravelAndAccommodationsTile.prototype, "isHotelToAirportTransportEnabled", {
        get: function () {
            return this._eventManager && this._eventManager.isHotelToAirportTransportEnabled;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(TravelAndAccommodationsTile.prototype, "isAirportToHotelTransportEnabled", {
        get: function () {
            return this._eventManager && this._eventManager.isAirportToHotelTransportEnabled;
        },
        enumerable: false,
        configurable: true
    });
    return TravelAndAccommodationsTile;
}(__1.EventLandingTile));
exports.default = TravelAndAccommodationsTile;
