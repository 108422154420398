import { h, JSX } from 'preact';
import ButtonProps from './buttonProps';
import ObservingComponent from '../componentBases/observingComponent';
import { ButtonTypes } from 'core.frontend';
import Icon from '../icons/icon';

class Button extends ObservingComponent<ButtonProps> {

    public componentWillMount(): void {
        if (this.props.button) {
            this.registerUpdateObserver(this.props.button.observationProvider);
        }
    }

    public handleClick = (e: Event) => {
        const { click } = this.props.button;

        if (this.props.stopPropagation) {
            e.stopPropagation();
        }

        e.preventDefault();

        if (typeof click === 'function') {
            click();
        }
    }

    private getButtonContent(): JSX.Element {
        const { children, textClassName } = this.props;
        const { leftIcon, rightIcon, text, isProcessing, processingText, subText } = this.props.button;

        const leftIconEnabled = !!leftIcon;
        const rightIconEnabled = !!rightIcon;

        return <span class="btn-content">
            {!isProcessing && leftIconEnabled && <Icon icon={leftIcon} />}
            {isProcessing && <span class="fill v-center">{processingText}</span>}

            {!isProcessing && !!text &&
                <span className={"fill v-center " + textClassName}>
                    {text}

                    {!!subText && <span class="text-sm"><br/>{subText}</span>}
                </span>
            }

            {!isProcessing && !!children && children}
            {!isProcessing && rightIconEnabled && <Icon icon={rightIcon} />}
        </span>;
    }

    public render({ className, button, size, color, id, badge }: ButtonProps): JSX.Element {
        if (!button || !button.isVisible) {
            return;
        }

        const { buttonType, isLoading, isDisabled, isProcessing } = button;

        className += ' btn button inline-flex v-center ';

        className += ` ${size} `;
        className += ` ${color} `;

        if (button.isActiveState === true) {
            className += ' active ';
        }

        if (buttonType === ButtonTypes.Icon) {
            className += ' btn-icon btn-secondary v-center ';
        }

        if (isLoading) {
            return <div class="loading-placeholder shimmer button"></div>
        }

        if (buttonType === ButtonTypes.Submit) {
            return (
                <button
                    title={button.hoverText}
                    id={id}
                    class={className + (!!button.badge ? ' has-badge' : '')}
                    type="submit"
                    onClick={this.handleClick}
                    disabled={isProcessing || isDisabled}>
                        {this.getButtonContent()}

                        {!!button.badge &&
                            <span class="button-badge">
                                {button.badge}
                            </span>
                        }
                </button>
            );
        } else {
            return (
                <a
                    title={button.hoverText}
                    id={id}
                    class={className + (!!button.badge ? ' has-badge' : '') + (isDisabled || isProcessing? ' disabled' : '')}
                    onClick={this.handleClick}>
                        {this.getButtonContent()}

                        {!!button.badge &&
                            <span class="button-badge">{button.badge}</span>
                        }
                </a>
            );
        }
    }
}

export default Button;
