"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var AppActions;
(function (AppActions) {
    AppActions["CopyrightTextLoading"] = "CopyrightTextLoading";
    AppActions["CopyrightTextLoaded"] = "CopyrightTextLoaded";
    AppActions["SystemNotificationsUpdated"] = "SystemNotificationsUpdated";
    AppActions["SystemAdminContactInfoShown"] = "SystemAdminContactInfoShown";
    AppActions["SystemAdminContactInfoLoaded"] = "SystemAdminContactInfoLoaded";
    AppActions["SystemAdminContactInfoHidden"] = "SystemAdminContactInfoHidden";
    AppActions["NotificationsDropdownOpened"] = "NotificationsDropdownOpened";
    AppActions["NotificationsDropdownClosed"] = "NotificationsDropdownClosed";
    AppActions["NotificationsLoaded"] = "NotificationsLoaded";
})(AppActions || (AppActions = {}));
exports.default = AppActions;
