"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
var utils_1 = require("../../utils");
var lodash_1 = require("lodash");
var _1 = require(".");
var fieldType_1 = require("./fieldType");
var fieldActions_1 = require("./fieldActions");
var BaseSearchField = /** @class */ (function (_super) {
    __extends(BaseSearchField, _super);
    function BaseSearchField(_api, label, searchUrl, selectedResultIdKey, selectedResultLabelKey, searchResultLabelKey, isRequired, requiredValidationMessage, _allowMultipleSelect, key, textKey, enableDynamicLoading, _searchParams) {
        if (isRequired === void 0) { isRequired = false; }
        if (_allowMultipleSelect === void 0) { _allowMultipleSelect = false; }
        if (enableDynamicLoading === void 0) { enableDynamicLoading = false; }
        if (_searchParams === void 0) { _searchParams = { searchPhrase: '', pageNumber: 1 }; }
        var _this = _super.call(this, fieldType_1.default.SearchField, key, label, isRequired, null, requiredValidationMessage) || this;
        _this._api = _api;
        _this.searchUrl = searchUrl;
        _this.selectedResultIdKey = selectedResultIdKey;
        _this.selectedResultLabelKey = selectedResultLabelKey;
        _this.searchResultLabelKey = searchResultLabelKey;
        _this._allowMultipleSelect = _allowMultipleSelect;
        _this.textKey = textKey;
        _this.enableDynamicLoading = enableDynamicLoading;
        _this._searchParams = _searchParams;
        _this.clearSearchPhraseOnBlur = true;
        _this.openOnNoResultsFound = true;
        _this.setSearchFieldValue = function (searchPhrase) {
            if (searchPhrase !== undefined && searchPhrase !== null) {
                _this._searchBoxValue = searchPhrase;
                return;
            }
            if (_this._allowMultipleSelect) {
                if (_this.selectedResults.length > 0 && !_this.isFocused) {
                    _this._searchBoxValue = "".concat(_this.selectedResults.length, " selected");
                }
                else {
                    _this._searchBoxValue = '';
                }
            }
            else {
                if (_this.selectedResults.length > 0) {
                    _this._searchBoxValue = _this.selectedResults[0][_this.selectedResultLabelKey];
                }
                else {
                    _this._searchBoxValue = '';
                }
            }
        };
        _this.deselectItem = function (selectedItem) {
            if (_this._disabledOptionIds && _this._disabledOptionIds.indexOf(selectedItem[_this.selectedResultIdKey]) >= 0) {
                return;
            }
            var index = _this._selectedResults.findIndex(function (result) { return result[_this.selectedResultIdKey] === selectedItem[_this.selectedResultIdKey]; });
            if (index > -1) {
                _this._selectedResults.splice(index, 1);
            }
            if (!_this._allowMultipleSelect) {
                _this.setSearchFieldValue();
                _this.clearSearchResults();
            }
            _this.observationProvider.notifyObservers(fieldActions_1.default.ValueChanged);
        };
        _this.selectItem = function (selectedItem) {
            if (_this._disabledOptionIds && _this._disabledOptionIds.indexOf(selectedItem[_this.selectedResultIdKey]) >= 0) {
                return;
            }
            _this.markAsDirty();
            if (!_this._allowMultipleSelect) {
                _this.clearSearchResults();
                _this._selectedResults = [];
                _this._selectedResults.push(selectedItem);
                _this.setSearchFieldValue();
                _this.blur();
            }
            else {
                _this._selectedResults.push(selectedItem);
            }
            _this.observationProvider.notifyObservers(fieldActions_1.default.ValueChanged);
        };
        _this.search = function (searchPhrase) {
            var trimmedSearchPhrase = searchPhrase.trim();
            _this.observationProvider.notifyObservers(fieldActions_1.default.SearchValueChanged, trimmedSearchPhrase);
            if (searchPhrase == _this._searchPhrase) {
                return;
            }
            _this._searchPhrase = searchPhrase;
            _this._searchBoxValue = searchPhrase;
            if (!trimmedSearchPhrase) {
                _this.clearSearchResults();
            }
            else {
                _this._pageNumber = 1;
                _this._showNoMoreSearchResults = false;
                _this.setSearching();
                _this._debounceSearch(searchPhrase);
            }
        };
        _this.getData = function (searchPhrase) {
            _this._searchParams.searchPhrase = searchPhrase || '';
            _this._searchParams.pageNumber = _this._pageNumber || 1;
            _this._api.get(_this.searchUrl, _this._searchParams)
                .then(function (searchResults) {
                if (_this._pageNumber === 1) {
                    _this._searchResults = searchResults;
                    if ((!searchResults || searchResults.length === 0)) {
                        _this._showEmptyState = true && _this.openOnNoResultsFound;
                    }
                    else {
                        _this._showEmptyState = false;
                    }
                }
                else {
                    if ((!searchResults || searchResults.length === 0)) {
                        _this._showNoMoreSearchResults = true;
                    }
                    else {
                        for (var i = 0; i < searchResults.length; i++) {
                            _this._searchResults.push(searchResults[i]);
                        }
                    }
                }
                _this.setSearchingComplete();
            });
        };
        _this.clearSearchResults = function () {
            _this._searchResults = [];
            _this._showEmptyState = false;
            _this._pageNumber = 1;
            _this._showNoMoreSearchResults;
            _this.observationProvider.notifyObservers(fieldActions_1.default.SearchResultsCleared);
        };
        _this.clearSelected = function () {
            _this._selectedResults = [];
            _this.setSearchFieldValue();
            _this.observationProvider.notifyObservers(fieldActions_1.default.ValueChanged);
        };
        _this.setSearching = function () {
            _this._searching = true;
            _this.observationProvider.notifyObservers(fieldActions_1.default.Searching);
        };
        _this.setSearchingComplete = function () {
            _this._searching = false;
            _this.observationProvider.notifyObservers(fieldActions_1.default.SearchCompleted);
        };
        _this.handleBlur = function () {
            if (_this.clearSearchPhraseOnBlur) {
                _this.setSearchFieldValue();
                _this._searchPhrase = '';
            }
            _this.clearSearchResults();
        };
        _this.handleFocus = function () {
            _this.setSearchFieldValue(_this._searchPhrase);
            _this.getData(_this._searchPhrase);
        };
        _this.loadNextPage = function () {
            if (_this.enableDynamicLoading && !_this._showNoMoreSearchResults) {
                _this._pageNumber++;
                _this.setSearching();
                _this.getData(_this._searchPhrase);
            }
        };
        _this._searchResults = [];
        _this._selectedResults = [];
        _this._disabledOptionIds = [];
        _this._pageNumber = 1;
        _this._debounceSearch = (0, lodash_1.debounce)(_this.getData.bind(_this), 400);
        _this.observationProvider.observe(_this.handleBlur, fieldActions_1.default.Blured);
        _this.observationProvider.observe(_this.handleFocus, fieldActions_1.default.Focus);
        return _this;
    }
    Object.defineProperty(BaseSearchField.prototype, "showNoMoreSearchResults", {
        get: function () {
            return this._showNoMoreSearchResults;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(BaseSearchField.prototype, "pageNumber", {
        get: function () {
            return this._pageNumber;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(BaseSearchField.prototype, "searching", {
        get: function () {
            return this._searching;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(BaseSearchField.prototype, "searchResults", {
        get: function () {
            return this._searchResults;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(BaseSearchField.prototype, "selectedResults", {
        get: function () {
            return this._selectedResults;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(BaseSearchField.prototype, "searchBoxValue", {
        get: function () {
            return this._searchBoxValue;
        },
        enumerable: false,
        configurable: true
    });
    BaseSearchField.prototype.isValid = function () {
        return !this.isRequired || this.selectedResults.length > 0;
    };
    Object.defineProperty(BaseSearchField.prototype, "errorMessage", {
        get: function () {
            return !this.isValid() && this.isDirty ? this.requiredValidationMessage : '';
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(BaseSearchField.prototype, "value", {
        get: function () {
            return this.selectedResults;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(BaseSearchField.prototype, "showSearchResults", {
        get: function () {
            return this.searchResults.length > 0 && this.isFocused;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(BaseSearchField.prototype, "showEmptyState", {
        get: function () {
            return this._showEmptyState && !this.searching && this.isFocused;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(BaseSearchField.prototype, "dropdownOpen", {
        get: function () {
            return this.showSearchResults || this.showEmptyState || (this.selectedResults.length > 0 && this.isFocused);
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(BaseSearchField.prototype, "disabledOptionIds", {
        get: function () {
            return this._disabledOptionIds;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(BaseSearchField.prototype, "selectedResultsNotInSearchResults", {
        get: function () {
            var _this = this;
            var items = [];
            var _loop_1 = function (selectedResultItem) {
                var selectedExistsInSearchResults = this_1._searchResults.findIndex(function (searchResult) { return searchResult[_this.selectedResultIdKey] === selectedResultItem[_this.selectedResultIdKey]; }) > -1;
                if (!selectedExistsInSearchResults) {
                    items.push(selectedResultItem);
                }
            };
            var this_1 = this;
            for (var _i = 0, _a = this._selectedResults; _i < _a.length; _i++) {
                var selectedResultItem = _a[_i];
                _loop_1(selectedResultItem);
            }
            return items;
        },
        enumerable: false,
        configurable: true
    });
    BaseSearchField.prototype.setDisabledOptionIds = function (disabledIds) {
        this._disabledOptionIds = [];
        if (disabledIds && disabledIds.length) {
            for (var i = 0; i < disabledIds.length; i++) {
                if (disabledIds[i]) {
                    this._disabledOptionIds.push(disabledIds[i]);
                }
            }
        }
        this.observationProvider.notifyObservers(fieldActions_1.default.SearchDisabledIdsChanged);
    };
    BaseSearchField.prototype.setValue = function (valueArray) {
        if (valueArray === null) {
            this.clearSelected();
        }
        else {
            valueArray = utils_1.ArrayUtils.arrayify(valueArray);
            for (var _i = 0, valueArray_1 = valueArray; _i < valueArray_1.length; _i++) {
                var value = valueArray_1[_i];
                this.selectItem(value);
            }
        }
    };
    return BaseSearchField;
}(_1.BaseField));
exports.default = BaseSearchField;
