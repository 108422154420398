"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g = Object.create((typeof Iterator === "function" ? Iterator : Object).prototype);
    return g.next = verb(0), g["throw"] = verb(1), g["return"] = verb(2), typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
var __1 = require("../..");
var button_1 = require("../button/button");
var fields_1 = require("../fields");
var MeetingNotificationModal = /** @class */ (function () {
    function MeetingNotificationModal(api) {
        var _this = this;
        this.api = api;
        this.buildForm = function () {
            _this._subjectField = new fields_1.TextField('subject', 'Subject', true, 500, 'Required');
            _this._emailTemplateField = new fields_1.RichTextField('message', 'Message', true, 'required');
            _this._ccEmailField = new fields_1.MultipleEmailField('CcAddresses', false, 'CC Address');
            _this._includeCalendarFileField = new fields_1.CheckBoxField('includeCalendarFile', 'Include Calendar File', false, null, true);
            _this._includeCalendarFileField.setVisibility(false);
            _this._form = new __1.Form([
                _this._subjectField,
                _this._emailTemplateField,
                _this._ccEmailField,
                _this._includeCalendarFileField
            ], {
                meetingId: _this._meetingId,
                subject: _this._defaultSubject
            });
            _this._emailTemplateField.setValue(_this._defaultEmailTemplate);
            for (var i = 0; i < _this._data.attendees.length; i++) {
                _this._attendeeCheckBoxes.push(new fields_1.CheckBoxField(i.toString(), _this._data.attendees[i].fullName, false, null, true));
                _this._attendeeEmailFields.push(new fields_1.EmailField(i.toString(), false, 'Email', _this.data.attendees[i].email));
            }
            for (var i = 0; i < _this._data.guests.length; i++) {
                _this._guestCheckBoxes.push(new fields_1.CheckBoxField(i.toString(), _this._data.guests[i].fullName, false, null, true));
                _this._guestEmailFields.push(new fields_1.EmailField(i.toString(), false, 'Email', _this.data.guests[i].email));
            }
        };
        this.show = function (meetingNumber) { return __awaiter(_this, void 0, void 0, function () {
            var _a;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        this._meetingId = meetingNumber;
                        this._isLoading = true;
                        this._isOpen = true;
                        this.observationProvider.notifyObservers(__1.MeetingNotificationModalActions.VisibilityChanged);
                        this._attendeeCheckBoxes = [];
                        this._guestCheckBoxes = [];
                        this._attendeeEmailFields = [];
                        this._guestEmailFields = [];
                        _a = this;
                        return [4 /*yield*/, this.api.get("".concat(__1.EndpointUrls.Meetings, "/").concat(this._meetingId, "/MeetingNotificationModalData"))];
                    case 1:
                        _a._data = _b.sent();
                        if (!this._data.hasAttendeeLead) {
                            this.alert.show({
                                alertType: __1.AlertType.Error,
                                heading: 'No Meeting POC',
                                message: 'This meeting does not have a POC attendee. Notifications may be formed incorrectly without as designated POC.'
                            });
                        }
                        this.buildForm();
                        this._isLoading = false;
                        this.observationProvider.notifyObservers(__1.MeetingNotificationModalActions.DataLoaded);
                        return [2 /*return*/];
                }
            });
        }); };
        this.hide = function () {
            _this._isOpen = false;
            _this.alert.hide();
            _this.sendButton.setProcessing(false);
            _this._data = null;
            _this.observationProvider.notifyObservers(__1.MeetingNotificationModalActions.VisibilityChanged);
        };
        this.send = function () { return __awaiter(_this, void 0, void 0, function () {
            var request, e_1;
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this._form.submit();
                        if (!this._form.isValid()) {
                            this.sendButton.setProcessing(false);
                            return [2 /*return*/];
                        }
                        if (!this.getEmailFieldsAreValid()) {
                            this.alert.show({
                                alertType: __1.AlertType.Error,
                                heading: 'Invalid Email',
                                message: 'One or more email addresses are invalid'
                            });
                            this.sendButton.setProcessing(false);
                            return [2 /*return*/];
                        }
                        request = this._form.model;
                        request.attendees = this._attendeeCheckBoxes
                            .filter(function (checkbox) { return checkbox.value; })
                            .map(function (checkbox) {
                            return {
                                id: _this._data.attendees[checkbox.key].id,
                                fullName: _this._data.attendees[checkbox.key].fullName,
                                email: _this._attendeeEmailFields[checkbox.key].value
                            };
                        });
                        request.guests = this._guestCheckBoxes
                            .filter(function (checkbox) { return checkbox.value; })
                            .map(function (checkbox) {
                            return {
                                id: _this._data.guests[checkbox.key].id,
                                fullName: _this._data.guests[checkbox.key].fullName,
                                email: _this._guestEmailFields[checkbox.key].value
                            };
                        });
                        if (!request.attendees.length && !request.guests.length) {
                            this.alert.show({
                                alertType: __1.AlertType.Error,
                                heading: 'Unable to send',
                                message: 'You must select at least one recipient to send a notification'
                            });
                            this.sendButton.setProcessing(false);
                            return [2 /*return*/];
                        }
                        this.observationProvider.notifyObservers(__1.MeetingNotificationModalActions.Sending);
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 3, , 4]);
                        return [4 /*yield*/, this.api.post("".concat(__1.EndpointUrls.Meetings, "/").concat(this._meetingId, "/SendNotifications"), request)];
                    case 2:
                        _a.sent();
                        this.observationProvider.notifyObservers(__1.MeetingNotificationModalActions.Sent);
                        this.hide();
                        return [3 /*break*/, 4];
                    case 3:
                        e_1 = _a.sent();
                        this.sendButton.setProcessing(false);
                        this.alert.show({
                            alertType: __1.AlertType.Error,
                            heading: 'Unable to send',
                            message: 'An error occurred trying to send the notification'
                        });
                        return [3 /*break*/, 4];
                    case 4: return [2 /*return*/];
                }
            });
        }); };
        this.observationProvider = new __1.ObservationProvider();
        this._title = "Meeting Notifications";
        this.closeButton = new button_1.default({
            onClick: this.hide,
            text: 'Close'
        });
        this.sendButton = new button_1.default({
            onClick: this.send,
            text: 'Send',
            processingText: 'Sending...'
        });
        this.alert = new __1.Alert();
        this._defaultSubject = '[CompanyName] Meeting: [Meeting_Name]';
        this._defaultEmailTemplate = 'Hello [Prefix] [First_Name] [Last_Name],' +
            '<br/>' +
            '<br/>' +
            'This email confirms your meeting with [CompanyName] during [Event_Name] on [Meeting_StartDate] from [Meeting_StartTime] to [Meeting_EndTime].' +
            '<br/>' +
            '<br/>' +
            'Please acknowledge receipt by emailing the meeting host at least 24-48 hours prior to your meeting: [POC_First_Name] [POC_Last_Name], [POC_Email]. ' +
            '<br/>' +
            '<br/>' +
            'Please let your meeting host know if you have any questions. We are looking forward to meeting with you soon. ' +
            '<br/>' +
            '<br/>' +
            'Best regards, ' +
            '<br/>' +
            '[CompanyName]';
    }
    Object.defineProperty(MeetingNotificationModal.prototype, "title", {
        get: function () {
            return this._title;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(MeetingNotificationModal.prototype, "isLoading", {
        get: function () {
            return this._isLoading;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(MeetingNotificationModal.prototype, "isOpen", {
        get: function () {
            return this._isOpen;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(MeetingNotificationModal.prototype, "subjectField", {
        get: function () {
            return this._subjectField;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(MeetingNotificationModal.prototype, "ccEmailField", {
        get: function () {
            return this._ccEmailField;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(MeetingNotificationModal.prototype, "includeCalendarFileField", {
        get: function () {
            return this._includeCalendarFileField;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(MeetingNotificationModal.prototype, "emailTemplateField", {
        get: function () {
            return this._emailTemplateField;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(MeetingNotificationModal.prototype, "form", {
        get: function () {
            return this._form;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(MeetingNotificationModal.prototype, "data", {
        get: function () {
            return this._data;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(MeetingNotificationModal.prototype, "attendeeCheckBoxes", {
        get: function () {
            return this._attendeeCheckBoxes;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(MeetingNotificationModal.prototype, "guestCheckBoxes", {
        get: function () {
            return this._guestCheckBoxes;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(MeetingNotificationModal.prototype, "attendeeEmailFields", {
        get: function () {
            return this._attendeeEmailFields;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(MeetingNotificationModal.prototype, "guestEmailFields", {
        get: function () {
            return this._guestEmailFields;
        },
        enumerable: false,
        configurable: true
    });
    MeetingNotificationModal.prototype.getEmailFieldsAreValid = function () {
        for (var i = 0; i < this._attendeeEmailFields.length; i++) {
            if (this._attendeeCheckBoxes[i].value && !this._attendeeEmailFields[i].isValid()) {
                return false;
            }
        }
        for (var i = 0; i < this._guestEmailFields.length; i++) {
            if (this._guestCheckBoxes[i].value && !this._guestEmailFields[i].isValid()) {
                return false;
            }
        }
        return true;
    };
    return MeetingNotificationModal;
}());
exports.default = MeetingNotificationModal;
