"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var EventTransportationConfigurationFormActions;
(function (EventTransportationConfigurationFormActions) {
    EventTransportationConfigurationFormActions["ActiveHotelAccommadationsPaneChanged"] = "ActiveHotelAccommadationsPaneChanged";
    EventTransportationConfigurationFormActions["ConfigurationLoading"] = "ConfigurationLoading";
    EventTransportationConfigurationFormActions["Loaded"] = "Loaded";
    EventTransportationConfigurationFormActions["ActiveTransportationPaneChanged"] = "ActiveTransportationPaneChanged";
    EventTransportationConfigurationFormActions["TransportationEnabledChanged"] = "TransportationEnabledChanged";
    EventTransportationConfigurationFormActions["TransportOptionsShown"] = "TransportOptionsShown";
    EventTransportationConfigurationFormActions["TransportOptionsHidden"] = "TransportOptionsHidden";
})(EventTransportationConfigurationFormActions || (EventTransportationConfigurationFormActions = {}));
exports.default = EventTransportationConfigurationFormActions;
