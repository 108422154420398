"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g = Object.create((typeof Iterator === "function" ? Iterator : Object).prototype);
    return g.next = verb(0), g["throw"] = verb(1), g["return"] = verb(2), typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
var _1 = require(".");
var __1 = require("../../../..");
var components_1 = require("../../../../components");
var button_1 = require("../../../../components/button/button");
var EventTransportationConfigurationForm = /** @class */ (function () {
    function EventTransportationConfigurationForm(api, vendorCompanyId, tenantId) {
        var _this = this;
        this.api = api;
        this.vendorCompanyId = vendorCompanyId;
        this.tenantId = tenantId;
        this.addTransportationVendor = function () {
            if (!_this.transportationVendorCompanyDropdown.value) {
                return;
            }
            if (!_this.eventTransportationVendorCompaniesDataList.exists(function (item) { return item.vendorCompanyId === _this.transportationVendorCompanyDropdown.value; })) {
                _this.eventTransportationVendorCompaniesDataList.addModel({
                    isDeletable: true,
                    references: [],
                    vendorCompany: _this.transportationVendorCompanyDropdown.selectedOptionLabel,
                    vendorCompanyId: _this.transportationVendorCompanyDropdown.value,
                });
            }
            _this.transportationVendorCompanyDropdown.clearValue();
        };
        this.showTransportationVendorAccessPane = function () {
            _this._activeTransportationPane = __1.EventConfigurationPageTransportationPanes.VendorAccess;
            _this.observationProvider.notifyObservers(_1.EventTransportationConfigurationFormActions.ActiveTransportationPaneChanged);
        };
        this.showTransportOptionsPane = function () {
            _this._activeTransportationPane = __1.EventConfigurationPageTransportationPanes.TransportOptions;
            _this.observationProvider.notifyObservers(_1.EventTransportationConfigurationFormActions.ActiveTransportationPaneChanged);
        };
        this.showTransportationTermsAndConditionsPane = function () {
            _this._activeTransportationPane = __1.EventConfigurationPageTransportationPanes.TermsAndConditions;
            _this.observationProvider.notifyObservers(_1.EventTransportationConfigurationFormActions.ActiveTransportationPaneChanged);
        };
        this.buildTransportationFields = function () {
            _this._transportationEnabledCheckbox = new __1.CheckBoxField('isTransportationEnabled', 'Enabled');
            _this._transportationVendorCompanyDropdown = new __1.VendorCompanyDropdown(_this.api, 'newTransportationVendorId', false, __1.VendorCompanyDropdownOptionType.Transportation);
            _this._transportTermsAndConditionsField = new __1.RichTextField('transportationTermsAndConditions', 'Transport Terms & Conditions', false);
            _this._transportationFields = [
                _this._transportationEnabledCheckbox,
                _this._transportTermsAndConditionsField
            ];
            if (_this.isVendorPortalMode) {
                _this._transportationEnabledCheckbox.setValue(true);
                _this._transportationEnabledCheckbox.setVisibility(false);
            }
            else {
                for (var i = 1; i < _this._transportationFields.length; i++) {
                    _this._transportationFields[i].setVisibility(false);
                }
            }
            _this._transportationEnabledCheckbox.observationProvider.observe(_this.transportationEnabledChanged, __1.FieldActions.ValueChanged);
        };
        this.transportationEnabledChanged = function () { return __awaiter(_this, void 0, void 0, function () {
            var i;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        for (i = 1; i < this._transportationFields.length; i++) {
                            this._transportationFields[i].setVisibility(this._transportationEnabledCheckbox.value);
                        }
                        return [4 /*yield*/, this.setTermsAndConditions(this.transportTermsAndConditionsField.value)];
                    case 1:
                        _a.sent();
                        this.accordian.isOpen = this._transportationEnabledCheckbox.value;
                        this.accordian.toggleButton.setDisabled(!this._transportationEnabledCheckbox.value);
                        this.observationProvider.notifyObservers(_1.EventTransportationConfigurationFormActions.TransportationEnabledChanged);
                        return [2 /*return*/];
                }
            });
        }); };
        this.setTermsAndConditions = function (termsAndConditions) { return __awaiter(_this, void 0, void 0, function () {
            var defaultTermsAndConditions;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!(this._transportationEnabledCheckbox.value && !termsAndConditions)) return [3 /*break*/, 2];
                        this._isLoaded = false;
                        this.observationProvider.notifyObservers(_1.EventTransportationConfigurationFormActions.ConfigurationLoading);
                        return [4 /*yield*/, this.api.get("".concat(__1.EndpointUrls.Settings, "/DefaultTransportTermsAndConditions/").concat(this.tenantId))];
                    case 1:
                        defaultTermsAndConditions = _a.sent();
                        if (defaultTermsAndConditions) {
                            this._transportTermsAndConditionsField.setValue(defaultTermsAndConditions);
                        }
                        this.observationProvider.notifyObservers(_1.EventTransportationConfigurationFormActions.Loaded);
                        this._isLoaded = true;
                        return [3 /*break*/, 3];
                    case 2:
                        this._transportTermsAndConditionsField.setValue(termsAndConditions);
                        this._isLoaded = true;
                        _a.label = 3;
                    case 3: return [2 /*return*/];
                }
            });
        }); };
        this.transportationVendorAccessRemovable = function (vendorCompanyId) {
            return _this.eventTransportationOptionDataList.items.filter(function (transportOptionsItem) { return transportOptionsItem.form.model.vendorCompanyId === vendorCompanyId; }).length === 0;
        };
        this.setTransportationVendorCompanies = function (vendorCompanies) {
            _this.eventTransportationVendorCompaniesDataList.setModels(vendorCompanies);
        };
        this.setTransportationOptions = function (transportationOptions) {
            _this.eventTransportationOptionDataList.setModels(transportationOptions);
        };
        this.getTransportationVendorCompanies = function () {
            return _this.eventTransportationVendorCompaniesDataList.getModels();
        };
        this.getTransportationOptions = function () {
            return _this.eventTransportationOptionDataList.getModels();
        };
        this.setIsDisabled = function (isDisabled) {
            _this._isDisabled = isDisabled;
            _this._transportTermsAndConditionsField.isDisabled = isDisabled;
            for (var i = 0; i < _this._transportationFields.length; i++) {
                _this._transportationFields[i].isDisabled = isDisabled;
            }
            _this.eventTransportationOptionDataList.setIsEditable(!isDisabled);
            _this.addTransportationOptionButton.setVisibility(!isDisabled);
        };
        this.showTransportationOptionsForm = function (transportOption) {
            var vendorCompanyDropdown = new __1.VendorCompanyDropdown(_this.api, 'vendorCompanyId', false, __1.VendorCompanyDropdownOptionType.Transportation, null, 'Self-Manage');
            if (_this.isVendorPortalMode) {
                vendorCompanyDropdown.setVisibility(false);
            }
            if (!transportOption) {
                transportOption = {
                    eventTransportVehicleOptions: []
                };
                if (_this.isVendorPortalMode) {
                    transportOption.vendorCompanyId = _this.vendorCompanyId;
                }
                else if (_this.eventTransportationVendorCompaniesDataList.items.length === 1) {
                    transportOption.vendorCompanyId = _this.eventTransportationVendorCompaniesDataList.items[0].form.model.vendorCompanyId;
                }
            }
            var hotelToAirportTransportEnabledCheckbox = new __1.CheckBoxField('hotelToAirportTransportEnabled', 'Offer Transport from Arrival Destination to hotel');
            var airportToHotelTransportEnabledCheckbox = new __1.CheckBoxField('airportToHotelTransportEnabled', 'Offer Transport from hotel to Departure Desination');
            var arrivalDepartureTransportComments = new __1.TextField('arrivalDepartureTransportComments', 'Arrival/Departure Comments', false, 1000);
            var dailyHotelEventTransportEnabledCheckbox = new __1.CheckBoxField('dailyHotelEventTransportEnabled', 'Provide Daily Shuttle');
            var dailyHotelEventTransportSchedule = new __1.TextField('dailyHotelEventTransportSchedule', 'Daily Shuttle Schedule', false, 1000, null, null, __1.FieldType.TextArea);
            var dailyHotelEventTransportComments = new __1.TextField('dailyHotelEventTransportComments', 'Daily Shuttle Comments', false, 1000, null, null, __1.FieldType.TextArea);
            _this._transportationOptionsForm = new __1.Form([
                vendorCompanyDropdown,
                hotelToAirportTransportEnabledCheckbox,
                airportToHotelTransportEnabledCheckbox,
                arrivalDepartureTransportComments,
                dailyHotelEventTransportEnabledCheckbox,
                dailyHotelEventTransportSchedule,
                dailyHotelEventTransportComments
            ]);
            _this._transportationOptionsForm.setModel(transportOption);
            _this._transportationOptionsForm.isDisabled = _this._isDisabled;
            _this._individualTransportTypeDataList = new __1.DataList(function () { return [
                new __1.TextField('name', 'Individual Transportation Type', true, 100, 'Required'),
                new __1.MoneyField('price', 'Vehicle Price', false),
                new components_1.NumberDropdownField('priceInterval', 'Per', false, [
                    { label: 'Daily', value: 1 },
                    { label: 'Weely', value: 2 },
                    { label: 'Montly', value: 3 }
                ], null, 1, __1.FieldType.DropDown, '')
            ]; }, true, 'Add option', 'No individual transport options assigned');
            _this._individualTransportTypeDataList.setModels(transportOption.eventTransportVehicleOptions);
            _this._individualTransportTypeDataList.setIsEditable(!_this._isDisabled);
            _this._individualTransportTypeDataList.addItemButton.setVisibility(!_this._isDisabled);
            _this.updateTransportOptionsButton.setDisabled(_this._isDisabled);
            _this._transportationOptionsFormIsVisible = true;
            _this.observationProvider.notifyObservers(_1.EventTransportationConfigurationFormActions.TransportOptionsShown);
        };
        this.hideTransportOptionsForm = function () {
            _this._transportationOptionsForm = null;
            _this._transportationOptionsFormIsVisible = false;
            _this.observationProvider.notifyObservers(_1.EventTransportationConfigurationFormActions.TransportOptionsHidden);
        };
        this.updateTransportOptions = function () { return __awaiter(_this, void 0, void 0, function () {
            var isValid, _a, transportOptionsIndex;
            var _this = this;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        this.transportationOptionsForm.submit();
                        isValid = this.transportationOptionsForm.isValid();
                        isValid = this._individualTransportTypeDataList.areAllValid() && isValid;
                        if (!isValid) {
                            return [2 /*return*/];
                        }
                        if (!this.transportationOptionsForm.model.vendorCompanyId) return [3 /*break*/, 2];
                        _a = this.transportationOptionsForm.model;
                        return [4 /*yield*/, this.api.getVendorCompanyName(this.transportationOptionsForm.model.vendorCompanyId)];
                    case 1:
                        _a.vendorCompany = _b.sent();
                        return [3 /*break*/, 3];
                    case 2:
                        this.transportationOptionsForm.model.vendorCompany = 'Self-Manage';
                        _b.label = 3;
                    case 3:
                        this.transportationOptionsForm.model.eventTransportVehicleOptions = this._individualTransportTypeDataList.getModels();
                        if (this.transportationOptionsForm.model.id) {
                            transportOptionsIndex = this.eventTransportationOptionDataList.findIndex(function (item) { return item.id === _this.transportationOptionsForm.model.id; });
                            this.eventTransportationOptionDataList.items[transportOptionsIndex].form.setModel(this.transportationOptionsForm.model);
                        }
                        else {
                            this.eventTransportationOptionDataList.addModel(this.transportationOptionsForm.model, true);
                        }
                        this.hideTransportOptionsForm();
                        return [2 /*return*/];
                }
            });
        }); };
        this.buildEditTransportationOptionsButton = function (transportationOptionsItem) {
            return new button_1.default({
                text: transportationOptionsItem.form.model.vendorCompany,
                onClick: function () { return _this.showTransportationOptionsForm(transportationOptionsItem.form.model); }
            });
        };
        this.accordian = new components_1.Accordian(false);
        this.observationProvider = new __1.ObservationProvider();
        if (!this.isVendorPortalMode) {
            this._activeTransportationPane = __1.EventConfigurationPageTransportationPanes.VendorAccess;
        }
        else {
            this._activeTransportationPane = __1.EventConfigurationPageTransportationPanes.TransportOptions;
        }
        this.showTransportationVendorAccessPaneButton = new button_1.default({
            text: 'Vendor Access',
            onClick: this.showTransportationVendorAccessPane,
            isVisible: !this.isVendorPortalMode
        });
        this.showTransportationTermsAndConditionsPaneButton = new button_1.default({
            text: 'Terms & Conditions',
            onClick: this.showTransportationTermsAndConditionsPane
        });
        this.eventTransportationVendorCompaniesDataList = new __1.DataList(function () { return []; }, true, 'No transportation vendors assigned');
        this.addTransportationVendorButton = new button_1.default({
            text: 'Allow vendor to access the event',
            leftIcon: __1.Icons.Plus,
            onClick: this.addTransportationVendor
        });
        this.addTransportationOptionButton = new button_1.default({
            text: 'Add Transportation Options',
            leftIcon: __1.Icons.Plus,
            onClick: function () { return _this.showTransportationOptionsForm(); }
        });
        this.hideTransportOptionsButton = new button_1.default({
            text: 'Cancel',
            onClick: this.hideTransportOptionsForm
        });
        this.updateTransportOptionsButton = new button_1.default({
            text: 'Update',
            onClick: this.updateTransportOptions
        });
        this.showTransportOptionsPaneButton = new button_1.default({
            text: 'Transportation Vendor/Options',
            onClick: this.showTransportOptionsPane
        });
        this.eventTransportationOptionDataList = new __1.DataList(function () { return []; }, true, null, 'No transportation options set');
        this.buildTransportationFields();
    }
    Object.defineProperty(EventTransportationConfigurationForm.prototype, "isDisabled", {
        get: function () {
            return this._isDisabled;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(EventTransportationConfigurationForm.prototype, "transportationEnabledCheckbox", {
        get: function () {
            return this._transportationEnabledCheckbox;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(EventTransportationConfigurationForm.prototype, "isLoaded", {
        get: function () {
            return this._isLoaded;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(EventTransportationConfigurationForm.prototype, "transportTermsAndConditionsField", {
        get: function () {
            return this._transportTermsAndConditionsField;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(EventTransportationConfigurationForm.prototype, "transportationFields", {
        get: function () {
            return this._transportationFields;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(EventTransportationConfigurationForm.prototype, "transportationVendorCompanyDropdown", {
        get: function () {
            return this._transportationVendorCompanyDropdown;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(EventTransportationConfigurationForm.prototype, "activeTransportationPane", {
        get: function () {
            return this._activeTransportationPane;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(EventTransportationConfigurationForm.prototype, "transportationOptionsFormIsVisible", {
        get: function () {
            return this._transportationOptionsFormIsVisible;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(EventTransportationConfigurationForm.prototype, "transportationOptionsForm", {
        get: function () {
            return this._transportationOptionsForm;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(EventTransportationConfigurationForm.prototype, "individualTransportTypeDataList", {
        get: function () {
            return this._individualTransportTypeDataList;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(EventTransportationConfigurationForm.prototype, "isVendorPortalMode", {
        get: function () {
            return !!this.vendorCompanyId;
        },
        enumerable: false,
        configurable: true
    });
    return EventTransportationConfigurationForm;
}());
exports.default = EventTransportationConfigurationForm;
