"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g = Object.create((typeof Iterator === "function" ? Iterator : Object).prototype);
    return g.next = verb(0), g["throw"] = verb(1), g["return"] = verb(2), typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
var api_1 = require("../../../../../api");
var bases_1 = require("../../../../../bases");
var components_1 = require("../../../../../components");
var meetings_1 = require("../../../../meetings");
var dinintTileActions_1 = require("./dinintTileActions");
var button_1 = require("../../../../../components/button/button");
var scheduledByField_1 = require("../../../../../components/fields/scheduledByField");
var DiningTile = /** @class */ (function (_super) {
    __extends(DiningTile, _super);
    function DiningTile(eventId, api, canEdit, isVisible, currentUserManager) {
        var _this = _super.call(this, 'Dining', api, currentUserManager.clientChecker.isMobileApp(), isVisible, canEdit) || this;
        _this.eventId = eventId;
        _this.currentUserManager = currentUserManager;
        _this.createAutoLayout = function () {
            _this.autoLayoutForm.submit();
            if (!_this.autoLayoutForm.isValid()) {
                return;
            }
            var columns = _this.autoLayoutForm.model.columnCount;
            var rows = _this.autoLayoutForm.model.rowCount;
            var tableNumber = 0;
            // Adjusted calculations to include the space between tables as part of each table's width and height.
            var tableWidth = 100 / columns;
            var tableHeight = 100 / rows;
            var spaceWidth = tableWidth * 0.05; // 5% of each table's width
            var spaceHeight = tableHeight * 0.05; // 5% of each table's height
            for (var i = 0; i < rows; i++) {
                for (var j = 0; j < columns; j++) {
                    var layoutX = j * tableWidth + spaceWidth / 2;
                    var layoutY = i * tableHeight + spaceHeight / 2;
                    _this.addTableForms({
                        tableName: 'Table ' + ++tableNumber,
                        eventId: _this.eventId,
                        seatCount: 4,
                        layoutX: parseInt(layoutX.toString(), 10),
                        layoutY: parseInt(layoutY.toString(), 10),
                        layoutWidth: parseInt((tableWidth - spaceWidth).toString(), 10), // subtract space from table's width
                        layoutHeight: parseInt((tableHeight - spaceHeight).toString(), 10), // subtract space from table's height
                        isDeletable: true,
                    });
                }
            }
        };
        _this.setScheduledByVisibility = function () {
            if (_this._categoryField.selectedOption && _this._categoryField.selectedOption.requireScheduledBy) {
                _this._scheduledByField.setVisibility(true);
            }
            else {
                _this._scheduledByField.setVisibility(false);
            }
            _this.observationProvider.notifyObservers(dinintTileActions_1.default.NewMeetingCategoryChanged);
        };
        _this.saveNewMeetingButtonClicked = function () {
            _this.addMeetingForm.submit();
            if (!_this.addMeetingForm.isValid()) {
                _this.saveNewMeetingButton.setProcessing(false);
            }
        };
        _this.closeNewMeetingModal = function () {
            _this._addMeetingModalIsVisible = false;
            _this.observationProvider.notifyObservers(dinintTileActions_1.default.AddMeetingModalHidden);
        };
        _this.addMeetingButtonClicked = function () {
            _this._addMeetingModalIsVisible = true;
            _this._categoryField = new components_1.MeetingCategoryField(_this.api, 'defaultMeetingCategoryId', 'Meeting Category', true, 'required');
            _this._categoryField.observationProvider.observe(_this.setScheduledByVisibility, components_1.FieldActions.ValueChanged);
            _this._scheduledByField = new scheduledByField_1.default(_this.api, 'scheduledById', 'Scheduled By', true, 'required');
            _this._addMeetingForm = new components_1.Form([
                new components_1.TextField('name', 'Meeting Name', true, 100, 'required'),
                new components_1.MeetingDateDropdownField(_this.api, _this.eventId, 'date', 'Date', true, 'required'),
                new components_1.MeetingStatusField('status', 'Status', true, meetings_1.MeetingStatuses.Finalized),
                new components_1.TimeField('startTime', 'Start Time', true, 'required'),
                new components_1.TimeField('endTime', 'End Time', true, 'required'),
                new components_1.VenuePreferenceField(_this.api, _this.eventId, 'venuePreferenceId', 'Venue Preference', true, 'required'),
                new components_1.VenueField(_this.api, _this.eventId, 'venueId', 'Venue', true, 'required'),
                _this._categoryField,
                _this._scheduledByField,
                new components_1.TextField('printedComments', 'Comments', true, 2000, 'required', '', components_1.FieldType.TextArea),
            ]);
            _this._addMeetingForm.observationProvider.observe(function () {
                _this._addMeetingModalIsVisible = false;
                var request = __assign(__assign({}, _this._addMeetingForm.model), { additionalNotificationRecipients: [], attendees: [], guests: [], eventId: _this.eventId });
                _this.api.post(api_1.EndpointUrls.Meetings, request)
                    .then(function () {
                    _this.saveNewMeetingButton.setProcessing(false);
                    _this.refresh();
                    _this.alert.show({
                        alertType: components_1.AlertType.Success,
                        heading: 'Meeting Added',
                        message: 'The new meeting has been added'
                    });
                    _this.observationProvider.notifyObservers(dinintTileActions_1.default.AddMeetingModalHidden);
                })
                    .catch(function () {
                    _this.alert.show({
                        alertType: components_1.AlertType.Error,
                        heading: 'Unable to Save',
                        message: 'An error occurred trying to save the new meeting'
                    });
                    _this.saveNewMeetingButton.setProcessing(false);
                });
            }, components_1.FormActions.FormSubmitted);
            _this.observationProvider.notifyObservers(dinintTileActions_1.default.AddMeetingModalShown);
        };
        _this.addTableForms = function (tableLayout) {
            if (!tableLayout) {
                tableLayout = {
                    tableName: '',
                    eventId: _this.eventId,
                    seatCount: 4,
                    layoutHeight: 25,
                    layoutWidth: 25,
                    layoutX: 0,
                    layoutY: 0,
                    isDeletable: true
                };
            }
            var tableNameField = new components_1.TextField('tableName', 'Name', true, 100, '');
            var seatCountField = new components_1.IntField('seatCount', 'Seat Count', true, '', 4, 100, 0);
            var fields = [
                tableNameField,
                seatCountField
            ];
            var form = new components_1.Form(fields, tableLayout);
            _this.tableLayoutForms.push(form);
            _this.observationProvider.notifyObservers(dinintTileActions_1.default.TableAdded);
        };
        _this.buildRemoveTableButton = function (tableLayoutForm, index) {
            return new button_1.default({
                leftIcon: components_1.Icons.Trash,
                onClick: function () { return _this.toggleTableRemoved(tableLayoutForm, index); }
            });
        };
        _this.toggleTableRemoved = function (tableLayoutForm, index) {
            if (!tableLayoutForm.tableId) {
                _this.tableLayoutForms.splice(index, 1);
            }
            else {
                tableLayoutForm.isRemoved = tableLayoutForm.isRemoved !== true;
            }
            _this.observationProvider.notifyObservers(dinintTileActions_1.default.TableRemovedToggled);
        };
        _this.closeLayoutModal = function () {
            _this._tableLayoutModalIsVisible = false;
            _this.observationProvider.notifyObservers(dinintTileActions_1.default.TableLayoutHidden);
        };
        _this.saveLayout = function () { return __awaiter(_this, void 0, void 0, function () {
            var allFormsValid, err_1;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        allFormsValid = true;
                        this.tableLayoutForms.forEach(function (form) {
                            form.submit();
                            if (!form.isValid()) {
                                allFormsValid = false;
                            }
                        });
                        if (!allFormsValid) return [3 /*break*/, 5];
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 3, , 4]);
                        return [4 /*yield*/, this.api.post("".concat(api_1.EndpointUrls.Events, "/").concat(this.eventId, "/MeetingTables"), this.tableLayoutForms.map(function (form) { return form.model; }))];
                    case 2:
                        _a.sent();
                        this.saveLayoutButton.setProcessing(false);
                        this.closeLayoutModal();
                        this.deactivateEditMode();
                        this.processLoadData();
                        return [3 /*break*/, 4];
                    case 3:
                        err_1 = _a.sent();
                        this.saveLayoutButton.setProcessing(false);
                        return [3 /*break*/, 4];
                    case 4: return [3 /*break*/, 6];
                    case 5:
                        this.saveLayoutButton.setProcessing(false);
                        _a.label = 6;
                    case 6: return [2 /*return*/];
                }
            });
        }); };
        _this.setTableLayout = function (table, index) {
            _this.tableLayoutForms[index].model.layoutX = parseInt(table.layoutX.toString(), 10);
            _this.tableLayoutForms[index].model.layoutY = parseInt(table.layoutY.toString(), 10);
            _this.tableLayoutForms[index].model.layoutHeight = parseInt(table.layoutHeight.toString(), 10);
            _this.tableLayoutForms[index].model.layoutWidth = parseInt(table.layoutWidth.toString(), 10);
        };
        _this.loadData = function () { return __awaiter(_this, void 0, void 0, function () {
            var _a, _b;
            return __generator(this, function (_c) {
                switch (_c.label) {
                    case 0:
                        this.alert.hide();
                        if (!!this._initialized) return [3 /*break*/, 2];
                        _a = this;
                        return [4 /*yield*/, this.api.getEventManager(this.eventId)];
                    case 1:
                        _a._eventManager = _c.sent();
                        this.searchForm.setModel({
                            date: this._eventManager.meetingConfigurations.meetingsStartDate,
                            startTime: '08:00',
                            endTime: '22:00'
                        });
                        this._initialized = true;
                        _c.label = 2;
                    case 2:
                        _b = this;
                        return [4 /*yield*/, this.api.get("".concat(api_1.EndpointUrls.Events, "/").concat(this.eventId, "/MeetingTables"))];
                    case 3:
                        _b._tables = _c.sent();
                        return [2 /*return*/, this.meetingsTable.load()];
                }
            });
        }); };
        _this.showTableLayoutModal = function () {
            _this.tableLayoutForms = [];
            for (var i = 0; i < _this.tables.length; i++) {
                _this.addTableForms(_this.tables[i]);
            }
            _this._tableLayoutModalIsVisible = true;
            _this.observationProvider.notifyObservers(dinintTileActions_1.default.TableLayoutVisible);
        };
        _this.loadExapndedContent = function (meeting) { return __awaiter(_this, void 0, void 0, function () {
            var _a;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _a = meeting;
                        return [4 /*yield*/, this.api.get("".concat(api_1.EndpointUrls.Events, "/").concat(this.eventId, "/MeetingTableItemDetail/").concat(meeting.id))];
                    case 1:
                        _a.expandedContent = _b.sent();
                        return [2 /*return*/];
                }
            });
        }); };
        _this.tableClicked = function (table) {
            if (!_this.isEditMode) {
                return;
            }
            if (!table.meetingId) {
                // new table
                table.meetingId = _this.selectedMeeting.id;
                table.meetingEndDateTime = _this.selectedMeeting.endDateTime;
                table.meetingStartDateTime = _this.selectedMeeting.startDateTime;
                table.meetingNumber = _this.selectedMeeting.meetingNumber;
                table.meetingName = _this.selectedMeeting.name;
                _this.selectedMeeting.tables.push({
                    meetingTableId: table.tableId,
                    name: table.tableName
                });
            }
            else if (table.meetingId === _this.selectedMeeting.id) {
                // remove table selection
                table.meetingId = null;
                table.meetingEndDateTime = null;
                table.meetingStartDateTime = null;
                table.meetingNumber = null;
                table.meetingName = null;
                _this.selectedMeeting.tables = _this.selectedMeeting.tables.filter(function (t) { return t.meetingTableId !== table.tableId; });
            }
            _this._updatedTableAssignments = _this._updatedTableAssignments.filter(function (previousUpdates) { return previousUpdates.id !== _this.selectedMeeting.id; });
            _this._updatedTableAssignments.push(_this.selectedMeeting);
            _this.observationProvider.notifyObservers(dinintTileActions_1.default.TableAssignmentChanged);
        };
        _this.loadMeetings = function () { return __awaiter(_this, void 0, void 0, function () {
            var results, request, err_2;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        results = {
                            totalRecordCount: 0,
                            items: []
                        };
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 3, , 4]);
                        request = {
                            date: this.searchForm.model.date,
                            startTime: this.searchForm.model.startTime,
                            endTime: this.searchForm.model.endTime
                        };
                        return [4 /*yield*/, this.api.get(api_1.EndpointUrls.Events + '/' + this.eventId + '/Dining/SearchMeetings', request)];
                    case 2:
                        results = _a.sent();
                        this._updatedTableAssignments = [];
                        return [3 /*break*/, 4];
                    case 3:
                        err_2 = _a.sent();
                        this.alert.show({
                            alertType: components_1.AlertType.Error,
                            heading: 'Unable to Load',
                            message: 'An error occurred trying to load dining meetings'
                        });
                        return [3 /*break*/, 4];
                    case 4: return [2 /*return*/, results];
                }
            });
        }); };
        _this.update = function () { return __awaiter(_this, void 0, void 0, function () {
            var err_3;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 3, , 4]);
                        if (!this._updatedTableAssignments.length) return [3 /*break*/, 2];
                        return [4 /*yield*/, this.api.post("".concat(api_1.EndpointUrls.Events, "/").concat(this.eventId, "/Dining/AssignTables"), this._updatedTableAssignments)];
                    case 1:
                        _a.sent();
                        this._updatedTableAssignments = [];
                        this.previouslySelectedIndex = this.meetingsTable.selectedIndex;
                        this.deactivateEditMode();
                        this.processLoadData();
                        _a.label = 2;
                    case 2: return [3 /*break*/, 4];
                    case 3:
                        err_3 = _a.sent();
                        this.alert.show({
                            alertType: components_1.AlertType.Error,
                            heading: 'Unable to Save',
                            message: 'An error occurred trying to save meeting table assignments'
                        });
                        this.saveButton.setProcessing(false);
                        return [3 /*break*/, 4];
                    case 4: return [2 /*return*/];
                }
            });
        }); };
        _this._updatedTableAssignments = [];
        _this.dateField = new components_1.MeetingDateDropdownField(api, eventId, 'date', 'Date', true, 'required');
        _this.startTimeField = new components_1.TimeField('startTime', 'Start Time', true, 'required');
        _this.endTimeField = new components_1.TimeField('endTime', 'End Time', true, 'required');
        _this.autoLayoutForm = new components_1.Form([
            new components_1.IntField('columnCount', '# of Columns', true, 'required'),
            new components_1.IntField('rowCount', '# of Rows', true, 'required')
        ]);
        _this.autoLayoutFormSubmitButton = new button_1.default({
            text: 'Submit',
            onClick: _this.createAutoLayout
        });
        _this._searchForm = new components_1.Form([
            _this.dateField,
            _this.startTimeField,
            _this.endTimeField
        ]);
        _this.configureLayoutButton = new button_1.default({
            text: 'Configure',
            leftIcon: components_1.Icons.Gear,
            onClick: _this.showTableLayoutModal,
            isVisible: false
        });
        _this.searchMeetingsButton = new button_1.default({
            text: 'Search',
            onClick: _this._searchForm.submit
        });
        _this.saveLayoutButton = new button_1.default({
            text: 'Save',
            onClick: _this.saveLayout,
            processingText: 'Saving...'
        });
        _this.cancelLayoutButton = new button_1.default({
            text: 'Cancel',
            onClick: _this.closeLayoutModal
        });
        _this.addTableButton = new button_1.default({
            text: 'Add Table',
            onClick: function () { return _this.addTableForms(); }
        });
        _this.addMeetingButton = new button_1.default({
            text: 'Add Meeting',
            onClick: function () { return _this.addMeetingButtonClicked(); }
        });
        _this.alert = new components_1.Alert();
        _this.meetingsTable = new components_1.Table(_this.loadMeetings, api, true, _this.loadExapndedContent, false, false);
        _this.meetingsTable.observationProvider.observe(function () {
            _this._selectedMeeting = _this.meetingsTable.items[_this.meetingsTable.selectedIndex];
            _this.observationProvider.notifyObservers(dinintTileActions_1.default.MeetingSelected);
        }, components_1.TableActions.RowSelected);
        _this.meetingsTable.observationProvider.observe(function () {
            if (_this.meetingsTable.items.length &&
                _this.previouslySelectedIndex !== null) {
                _this.meetingsTable.selectRow(_this.previouslySelectedIndex);
                _this.previouslySelectedIndex = null;
            }
            else if (_this.meetingsTable.items.length) {
                _this.meetingsTable.selectRow(0);
            }
            else {
                _this.meetingsTable.selectRow(null);
            }
        }, components_1.TableActions.Loaded);
        _this.searchForm.observationProvider.observe(function () {
            _this.processLoadData();
        }, components_1.FormActions.FormSubmitted);
        _this.observationProvider.observe(function () {
            _this.dateField.isDisabled = true;
            _this.startTimeField.isDisabled = true;
            _this.endTimeField.isDisabled = true;
            _this.searchMeetingsButton.setDisabled(true);
            _this.configureLayoutButton.setVisibility(true);
        }, dinintTileActions_1.default.EditModeActivated);
        _this.observationProvider.observe(function () {
            _this.dateField.isDisabled = false;
            _this.startTimeField.isDisabled = false;
            _this.endTimeField.isDisabled = false;
            _this.searchMeetingsButton.setDisabled(false);
            _this.configureLayoutButton.setVisibility(false);
        }, dinintTileActions_1.default.EditModeDeactivated);
        _this.previouslySelectedIndex = null;
        _this.tableLayoutForms = [];
        _this.closeNewMeetingModalButton = new button_1.default({
            text: 'Cancel',
            onClick: _this.closeNewMeetingModal
        });
        _this.saveNewMeetingButton = new button_1.default({
            text: 'Save',
            onClick: _this.saveNewMeetingButtonClicked,
            processingText: 'Saving...'
        });
        return _this;
    }
    Object.defineProperty(DiningTile.prototype, "tables", {
        get: function () {
            return this._tables;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(DiningTile.prototype, "selectedMeeting", {
        get: function () {
            return this._selectedMeeting;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(DiningTile.prototype, "searchForm", {
        get: function () {
            return this._searchForm;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(DiningTile.prototype, "updatedTableAssignments", {
        get: function () {
            return this._updatedTableAssignments;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(DiningTile.prototype, "tableLayoutModalIsVisible", {
        get: function () {
            return this._tableLayoutModalIsVisible;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(DiningTile.prototype, "addMeetingForm", {
        get: function () {
            return this._addMeetingForm;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(DiningTile.prototype, "addMeetingModalIsVisible", {
        get: function () {
            return this._addMeetingModalIsVisible;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(DiningTile.prototype, "scheduledByField", {
        get: function () {
            return this._scheduledByField;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(DiningTile.prototype, "categoryField", {
        get: function () {
            return this._categoryField;
        },
        enumerable: false,
        configurable: true
    });
    return DiningTile;
}(bases_1.EventLandingTile));
exports.default = DiningTile;
