"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var ReferenceRule;
(function (ReferenceRule) {
    ReferenceRule[ReferenceRule["Cascade"] = 0] = "Cascade";
    ReferenceRule[ReferenceRule["NoAction"] = 1] = "NoAction";
    ReferenceRule[ReferenceRule["SetNull"] = 2] = "SetNull";
    ReferenceRule[ReferenceRule["SetDefault"] = 3] = "SetDefault";
})(ReferenceRule || (ReferenceRule = {}));
exports.default = ReferenceRule;
