"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g = Object.create((typeof Iterator === "function" ? Iterator : Object).prototype);
    return g.next = verb(0), g["throw"] = verb(1), g["return"] = verb(2), typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
var __1 = require("../../../../..");
var helpButton_1 = require("../../../../../components/button/helpButton");
var EventInfoTile = /** @class */ (function (_super) {
    __extends(EventInfoTile, _super);
    function EventInfoTile(eventId, api, canEdit, currentUserManager, navigator) {
        var _this = _super.call(this, 'Event Info', api, currentUserManager.clientChecker.isMobileApp(), true, canEdit) || this;
        _this.eventId = eventId;
        _this.currentUserManager = currentUserManager;
        _this.navigator = navigator;
        _this.loadData = function () { return __awaiter(_this, void 0, void 0, function () {
            var _a, isNegativeOffset, offsetHours, offsetMinutes;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _a = this;
                        return [4 /*yield*/, this.api.get("".concat(__1.EndpointUrls.Events, "/GetEventInfoTileData?eventId=").concat(this.eventId))];
                    case 1:
                        _a._tileData = _b.sent();
                        isNegativeOffset = this._tileData.timezone.indexOf('-') === 0;
                        offsetHours = parseInt(this._tileData.timezone.substring(1, 3));
                        offsetMinutes = parseInt(this._tileData.timezone.substring(4, 6)) + (60 * offsetHours);
                        if (isNegativeOffset) {
                            offsetMinutes = 0 - offsetMinutes;
                        }
                        this._currentEventDateTime = __1.DateUtils.getDateWithUTCOffset(offsetMinutes);
                        this.setFormModel();
                        return [2 /*return*/];
                }
            });
        }); };
        _this.setFormModel = function () {
            _this._eventInfoForm.setModel(_this._tileData);
        };
        _this.update = function () {
            _this._eventInfoForm.submit();
        };
        _this.formSubmitted = function () {
            _this._tileData = _this._eventInfoForm.editForm.model;
            _this.removeWebLinks();
            _this.removeEventLeads();
            _this.setNewEventLeadNames();
            _this.deactivateEditMode();
        };
        _this.removeWebLinks = function () {
            _this._tileData.webLinks = _this._tileData.webLinks.filter(function (link) { return !link.isRemoved; });
        };
        _this.removeEventLeads = function () {
            _this._tileData.eventLeads = _this._tileData.eventLeads.filter(function (link) { return !link.isRemoved; });
        };
        _this.setNewEventLeadNames = function () {
            var _loop_1 = function (lead) {
                if (!lead.fullName) {
                    lead.fullName = _this._userOptions.filter(function (user) { return user.value === lead.userId; })[0].label;
                }
            };
            for (var _i = 0, _a = _this._tileData.eventLeads; _i < _a.length; _i++) {
                var lead = _a[_i];
                _loop_1(lead);
            }
        };
        _this.formSaved = function () {
            _this.loadData()
                .then(function () {
                _this.observationProvider.notifyObservers(__1.EventInfoTileActions.DataLoaded);
            });
        };
        _this._eventInfoForm = new __1.EventInfoForm(api);
        _this._helpButton = new helpButton_1.default(__1.HelpUrlType.EventInfoTile, null, navigator, api);
        _this.observationProvider.observe(_this.setFormModel, __1.EventInfoTileActions.EditModeDeactivated);
        _this._eventInfoForm.observationProvider.observe(_this.formSubmitted, __1.EventInfoFormActions.Submitted);
        _this._eventInfoForm.observationProvider.observe(_this.formSaved, __1.EventInfoFormActions.FormSaved);
        return _this;
    }
    Object.defineProperty(EventInfoTile.prototype, "currentEventDateTime", {
        get: function () {
            return this._currentEventDateTime;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(EventInfoTile.prototype, "tileData", {
        get: function () {
            return this._tileData;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(EventInfoTile.prototype, "eventInfoForm", {
        get: function () {
            return this._eventInfoForm;
        },
        enumerable: false,
        configurable: true
    });
    return EventInfoTile;
}(__1.EventLandingTile));
exports.default = EventInfoTile;
