"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var EventInventoryTileActions;
(function (EventInventoryTileActions) {
    EventInventoryTileActions["Toggled"] = "Toggled";
    EventInventoryTileActions["DataLoading"] = "DataLoading";
    EventInventoryTileActions["DataLoaded"] = "DataLoaded";
    EventInventoryTileActions["EditModeActivated"] = "EditModeEnabled";
    EventInventoryTileActions["EditModeDeactivated"] = "EditModeDeactivated";
    EventInventoryTileActions["GroupingChanged"] = "GroupingChanged";
    EventInventoryTileActions["ItemRemoved"] = "ItemRemoved";
    EventInventoryTileActions["ItemRemovedCancelled"] = "ItemRemovedCancelled";
    EventInventoryTileActions["ImageCarouselClosed"] = "ImageCarouselClosed";
    EventInventoryTileActions["LoadingImages"] = "LoadingImages";
    EventInventoryTileActions["ImageCarouselLoaded"] = "ImageCarouselLoaded";
    EventInventoryTileActions["RequestRemoved"] = "RequestRemoved";
    EventInventoryTileActions["RequestRemovalCancelled"] = "RequestRemovalCancelled";
    EventInventoryTileActions["RequestAdded"] = "RequestAdded";
    EventInventoryTileActions["ActivePaneChanged"] = "ActivePaneChanged";
})(EventInventoryTileActions || (EventInventoryTileActions = {}));
exports.default = EventInventoryTileActions;
