import { Fragment, h, JSX } from 'preact';
import ObservingComponent from '../componentBases/observingComponent';
import MeetingDetailFormProps from './meetingDetailFormProps';
import MeetingCreatedByLabel from './meetingCreatedByLabel';
import ReadonlyMeetingDetail from './readonlyMeetingDetail';
import Field from '../fields/field';
import Button from '../buttons/button';
import EventAttendeeMultiselectField from '../fields/eventAttendeeMultiselectField';
import CustomerSearchField from '../fields/CustomerSearchField';
import CheckboxField from '../fields/checkboxField';
import SearchField from '../fields/searchField';
import BinaryFileInputField from '../fields/binaryFileInputField';
import EmptyState from '../uiStates/emptyState';
import Modal from '../containers/modal';
import ConfirmationPrompt from '../containers/confirmationPrompt';
import ButtonColors from '../buttons/buttonColors';
import ButtonSizes from '../buttons/buttonSizes';
import Alert from '../alerts/alert';
import DataList from '../dataList/dataList';
import HistoryTable from '../history/historyTable';
import MeetingNotificationModal from './meetingNotificationModal';
import MeetingCalendarNotificationModal from './meetingCalendarNotificationModal';
import MeetingNotesForm from './meetingNotesForm';
import Dropdown from '../dropdown/dropdown';
import FormattedDate from '../text/formattedDate';
import { DateFormats, Icons, TextField } from 'core.frontend';
import QuickAddCustomerForm from '../customers/quickAddCustomerForm';
import FileListItem from '../dataList/fileListItem/fileListItem';
import VideoModalButton from '../buttons/videoModalButton';
import { MeetingDetailFormPane } from 'core.frontend/dist/components/meetinigs/meetingDetailForm';

class MeetingDetailForm extends ObservingComponent<MeetingDetailFormProps> {

    public componentWillMount() {
        this.registerUpdateObserver(this.props.meetingDetailForm.observationProvider);
    }

    public cancelRemoveAttendee = (index: number) => () => {
        this.props.meetingDetailForm.cancelRemoveAttendee(index);
    }

    public removeAttendee = (index: number) => () => {
        this.props.meetingDetailForm.removeAttendee(index);
    }

    public render({ meetingDetailForm }: MeetingDetailFormProps): JSX.Element {
        return (
            <div>

                {!meetingDetailForm.attendeeConflictModalOpen &&
                 !meetingDetailForm.quickAddCustomerForm.isVisible &&
                  meetingDetailForm.isOpen &&

                    <Modal
                        heading={meetingDetailForm.title}
                        close={meetingDetailForm.close}
                        className={meetingDetailForm.isEditMode ? 'editable-meeting-detail' : 'readonly-meeting-detail'}
                        footerClassName="d-block p-0"
                        headerButtons={[
                            <div class="flex v-center">

                                <Button
                                    className="btn-icon btn-lg"
                                    button={meetingDetailForm.helpButton} />

                                <div className="flex meeting-detail-header-buttons">
                                    <Button
                                        className="d-none d-md-inline btn-icon btn-icon-stacked btn-white"
                                        button={meetingDetailForm.meetingInfoButton} />

                                    <Button
                                        className="d-none d-md-inline btn-icon btn-icon-stacked btn-white"
                                        button={meetingDetailForm.meetingNotesButton} />

                                    <Button
                                        className="d-none d-md-inline btn-icon btn-icon-stacked btn-white"
                                        button={meetingDetailForm.actionItemsButton} />

                                    <Dropdown
                                        buttonClassName="d-none d-md-inline btn-icon-stacked btn-white"
                                        dropdown={meetingDetailForm.actionsDropdown} />

                                    <Button
                                        className="ml-p5 btn-icon btn-icon-stacked btn-white"
                                        color={ButtonColors.White}
                                        button={meetingDetailForm.editButton} />
                                </div>
                            </div>
                        ]}
                        footer={
                            <Fragment>
                                <div class="p-1 h-5 flex">
                                    <Button
                                        color={ButtonColors.Secondary}
                                        className="btn-icon v-center"
                                        button={meetingDetailForm.deactivateButton} />

                                    <div className="flex v-center ml-auto">

                                        <Field
                                            field={meetingDetailForm.meetingInvitesEnabledField}
                                            className='ml-1 v-center' />

                                        <Button
                                            className="ml-1 v-center"
                                            color={ButtonColors.Secondary}
                                            button={meetingDetailForm.closeButton} />

                                        <Button
                                            className="ml-1 v-center"
                                            color={ButtonColors.Secondary}
                                            button={meetingDetailForm.cancelEditButton} />
                                    </div>

                                    <Button
                                        className="ml-1 v-center"
                                        color={ButtonColors.Primary}
                                        button={meetingDetailForm.saveButton} />

                                    <Button
                                        className="ml-1 v-center"
                                        color={ButtonColors.Primary}
                                        button={meetingDetailForm.saveAndCloseButton} />
                                </div>

                                <div className="footer-tab-bar d-md-none">
                                    <Button
                                        className="btn-icon btn-icon-stacked"
                                        button={meetingDetailForm.meetingInfoButton} />

                                    <Button
                                        className="ml-0 btn-icon btn-icon-stacked"
                                        button={meetingDetailForm.meetingNotesButton} />

                                    <Button
                                        className="btn-icon btn-icon-stacked"
                                        button={meetingDetailForm.actionItemsButton} />

                                    <Dropdown
                                        buttonClassName="btn-icon-stacked"
                                        dropdown={meetingDetailForm.actionsDropdown} />
                                </div>
                            </Fragment>
                        }>

                        <Fragment>
                            {meetingDetailForm.activePane === MeetingDetailFormPane.MeetingInfo &&
                                <Fragment>

                                    <Alert
                                        className="mt-1"
                                        alert={meetingDetailForm.alert} />

                                    {!meetingDetailForm.isEditMode &&
                                        <ReadonlyMeetingDetail
                                            isEditable={meetingDetailForm.editButton.isVisible}
                                            meetingDetail={meetingDetailForm.form.model}
                                            meetingsDiningEnabled={meetingDetailForm.foodNeededField.isVisible}
                                            fileDataList={meetingDetailForm.fileDataList}
                                            isApp={meetingDetailForm.isApp}
                                            buildDownloadButton={meetingDetailForm.buildDownloadButton}
                                            buildFileIcon={meetingDetailForm.getIcon}
                                            guestsArrivedButton={meetingDetailForm.guestsArrivedButton} />
                                    }

                                    {meetingDetailForm.isEditMode &&
                                        <div>
                                            <ConfirmationPrompt confirmationPrompt={meetingDetailForm.confirmation} />

                                            <div class="flex mt-p5">
                                                <div class="fill mb-auto">
                                                    <div>
                                                        <span class="text-sm">
                                                            {meetingDetailForm.form.model.id > 0 &&
                                                                <span>
                                                                    <b>Meeting #: </b>
                                                                    {meetingDetailForm.form.model.meetingNumber}
                                                                </span>
                                                            }

                                                            {meetingDetailForm.form.model.id > 0 &&
                                                             meetingDetailForm.form.model.createdByUserId &&
                                                                <span>
                                                                    <br/>

                                                                    <MeetingCreatedByLabel meeting={meetingDetailForm.form.model} />
                                                                </span>
                                                            }

                                                            {meetingDetailForm.form.model.id > 0 &&
                                                            meetingDetailForm.form.model.requestedByUserId &&
                                                                <span>
                                                                    <br/>

                                                                    <b>Requested By: </b>
                                                                    {meetingDetailForm.form.model.requestedByUserName}

                                                                    <span> - </span>
                                                                    {meetingDetailForm.form.model.requestedByDepartment}

                                                                    <span> - </span>
                                                                    <a href={'tel:' + meetingDetailForm.form.model.requestedByUserPhone}>
                                                                        {meetingDetailForm.form.model.requestedByUserPhone}
                                                                    </a>

                                                                    <span> - </span>
                                                                    <a href={'mailto:' + meetingDetailForm.form.model.requestedByUserEmail}>
                                                                        {meetingDetailForm.form.model.requestedByUserEmail}
                                                                    </a>
                                                                </span>
                                                            }

                                                            {meetingDetailForm.originalMeetingTitle &&
                                                                <span>
                                                                    <b>Duplicating meeting</b>
                                                                    <br/>
                                                                    {meetingDetailForm.originalMeetingTitle}
                                                                </span>
                                                            }
                                                        </span>
                                                    </div>
                                                </div>

                                                <div>
                                                    <div class="flex">
                                                        <div class="ml-auto flex">
                                                            <Field
                                                                className="v-center mr-1"
                                                                field={meetingDetailForm.attendeesNotifiedField} />

                                                            <Field
                                                                className="v-center no-label"
                                                                field={meetingDetailForm.meetingStatusField} />
                                                        </div>
                                                    </div>

                                                    <div class="flex mt-p5">
                                                        <span class="v-center">Guests Arrived: </span>

                                                        <Button
                                                            className="btn-icon ml-1 v-center"
                                                            button={meetingDetailForm.guestsArrivedButton} />
                                                    </div>
                                                </div>
                                            </div>

                                            <hr/>

                                            <div class="form-container grid-container">

                                                {meetingDetailForm.generalInfoFields.map((field, index) => {
                                                    if (index === 10) {
                                                        return (
                                                            <div class="sm-6">
                                                                <Field
                                                                    className={
                                                                        (meetingDetailForm.form.model &&
                                                                        meetingDetailForm.form.model.assignedTables) ? "mb-0" : ''
                                                                    }
                                                                    field={field}
                                                                    isLoading={meetingDetailForm.isLoading} />

                                                                {meetingDetailForm.form.model && meetingDetailForm.form.model.assignedTables &&
                                                                    <span className='text-sm'>{meetingDetailForm.form.model.assignedTables}</span>
                                                                }
                                                            </div>
                                                        )
                                                    } else if (index !== 5) {
                                                        return (
                                                            <Field
                                                                className={[0,4,8,9].indexOf(index) >= 0 ? 'sm-6 ' :
                                                                    [].indexOf(index) >= 0 ? 'sm-6 inline-checkbox ' :
                                                                    [].indexOf(index) >= 0 ? 'sm-4 ' :
                                                                    [6,7].indexOf(index) >= 0 ? 'sm-3 ' :
                                                                    [1,2,3].indexOf(index) >= 0 ? 'sm-2 ' :
                                                                    'sm-12'}
                                                                field={field}
                                                                isLoading={meetingDetailForm.isLoading} />
                                                        )}
                                                    }
                                                )}
                                            </div>

                                            <div class="form-container mt-2">
                                                <div class="flex">
                                                    <div class="v-center fill">
                                                        <h4 class="m-0">
                                                            Files
                                                        </h4>
                                                        <span class="text-sm">
                                                            (Ensure all attached meeting content is Unrestricted. No Proprietary content should be added to a meeting.)
                                                        </span>
                                                    </div>

                                                    <div class="v-center">
                                                        <BinaryFileInputField
                                                            buttonClassName="btn btn-sm btn-primary"
                                                            leftIcon={Icons.Plus}
                                                            field={meetingDetailForm.addFileField} />
                                                    </div>
                                                </div>

                                                <hr/>

                                                <DataList
                                                    dataList={meetingDetailForm.fileDataList}
                                                    itemTemplate={(item) => (
                                                        <FileListItem
                                                            key={item.form.model.id}
                                                            icon={meetingDetailForm.getIcon(item)}
                                                            downloadButton={meetingDetailForm.buildDownloadButton(item)}
                                                            fileType={item.form.model.fileType}
                                                            isApp={meetingDetailForm.isApp}
                                                            createdDateTime={item.form.model.createdDateTime}
                                                            uploadedByUsersName={item.form.model.uploadedByUsersName}
                                                            isDownloadable={!!item.form.model.id}
                                                            fileName={item.form.model.fileName}
                                                            fileSize={item.form.model.fileSize}
                                                            friendlyNameField={item.form.fields[0] as TextField}
                                                            descriptionField={item.form.fields[1] as TextField}
                                                            allowManualEditMode={!!item.form.model.id}
                                                            isEditMode={!item.form.model.id} />
                                                    )}/>

                                            </div>

                                            {meetingDetailForm.golfCartsEnabled &&
                                                <div class="form-container mt-2">
                                                    <h4>Meeting Transport</h4>

                                                    <hr/>

                                                    <div class="mt-0 grid-container">
                                                        <div class="sm-6">
                                                            <div class="grid-container">

                                                                {meetingDetailForm.preMeetingPickupFields.map((field, index) =>
                                                                    <Field
                                                                        className={[1,2,3,4].indexOf(index) >= 0 ? 'sm-6' : 'sm-12'}
                                                                        field={field}
                                                                        isLoading={meetingDetailForm.isLoading} />
                                                                )}

                                                            </div>
                                                        </div>

                                                        <div class="sm-6">
                                                            <div class="grid-container">

                                                                {meetingDetailForm.postMeetingPickupFields.map((field, index) =>
                                                                    <Field
                                                                        className={[1,2,3,4].indexOf(index) >= 0 ? 'sm-6' : 'sm-12'}
                                                                        field={field}
                                                                        isLoading={meetingDetailForm.isLoading} />
                                                                )}

                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            }

                                            <div class="form-container mt-2">
                                                <h4>Customers</h4>
                                                <hr/>
                                                <div class="flex">
                                                    <div class="fill flex">
                                                        <CustomerSearchField
                                                            className="fill mr-1 no-label v-center inline-label"
                                                            customerSearchField={meetingDetailForm.customerSearchField} />
                                                    </div>

                                                    <Button
                                                        size={ButtonSizes.Small}
                                                        color={ButtonColors.Primary}
                                                        button={meetingDetailForm.addGuestButton} />
                                                </div>

                                                <DataList
                                                    dataList={meetingDetailForm.meetingGuestDataList}
                                                    itemTemplate={(item) => (
                                                        <div class="flex">
                                                            <Field
                                                                className="v-center"
                                                                field={item.form.fields[0]} />

                                                            <div class="v-center ml-1 text-sm">
                                                                <b>
                                                                    <Button
                                                                        className="btn-link"
                                                                        button={meetingDetailForm.buildEditCustomerButton(item.form.model)} />

                                                                    <span> - </span>
                                                                    {!!item.form.model.customerEmail && item.form.model.customerEmail}
                                                                    {!item.form.model.customerEmail && <i>Email Unknown</i>}
                                                                </b>
                                                                <div>
                                                                    {item.form.model.customerCompany}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )}/>
                                            </div>

                                            <div class="form-container mt-2">
                                                <div class="flex">
                                                    <div class="fill">
                                                        <div class="v-center">
                                                            <h4>Attendees</h4>
                                                        </div>
                                                    </div>

                                                    <Field
                                                        className="no-label mt-0 mb-0 input-sm"
                                                        field={meetingDetailForm.attendeeTypeDropdown} />

                                                    <div>
                                                        <Field
                                                            className="no-label mt-0 mb-0 input-sm ml-1"
                                                            field={meetingDetailForm.departmentAttendeesDropdown} />

                                                        <Field
                                                            className="no-label mt-0 mb-0 input-sm ml-1"
                                                            field={meetingDetailForm.businessAreaAttendeesDropdown} />
                                                    </div>
                                                </div>

                                                <hr/>

                                                <div class="flex">
                                                    <div class="fill flex">
                                                        { meetingDetailForm.individualAttendeeSelectionVisible &&
                                                            <SearchField
                                                                className="no-label fill mr-1 v-center"
                                                                searchField={meetingDetailForm.eventAttendeeDropdown} />
                                                        }

                                                    </div>

                                                    { meetingDetailForm.individualAttendeeSelectionVisible &&
                                                        <Button
                                                            color={ButtonColors.Primary}
                                                            size={ButtonSizes.Small}
                                                            button={meetingDetailForm.addAttendeeButton} />
                                                    }
                                                </div>

                                                { meetingDetailForm.individualAttendeeSelectionVisible && !!meetingDetailForm.attendeeForms.length &&
                                                    <table class="mt-1 table table-striped table-hover no-border text-sm">
                                                        <thead>
                                                            <th class="text-center fit-content">POC</th>
                                                            <th class="text-center fit-content">Meet & Greet</th>
                                                            <th colSpan={4}></th>
                                                        </thead>
                                                        <tbody>
                                                            {meetingDetailForm.attendeeForms.map((form, index) =>
                                                                <tr class={form.model.isRemoved ? 'deleting' : ''}>

                                                                    {form.fields.map((field) =>
                                                                        <td class="text-center">
                                                                            <Field
                                                                                className="no-label mt-0"
                                                                                field={field} />
                                                                        </td>
                                                                    )}

                                                                    <td class="fit-content d-none d-md-table-cell">
                                                                        {form.model.title}
                                                                    </td>

                                                                    <td>
                                                                        {form.model.fullName}
                                                                    </td>

                                                                    <td class="fit-content">
                                                                        {form.model.department}
                                                                    </td>

                                                                    <td class="fit-content">
                                                                        { !form.model.isRemoved &&
                                                                            <Button
                                                                                className="btn-remove-data-item"
                                                                                button={meetingDetailForm.buildRemoveAttendeeButton(form.model, index)} />
                                                                        }

                                                                        { form.model.isRemoved &&
                                                                            <Button
                                                                                className="btn-remove-data-item"
                                                                                button={meetingDetailForm.buildCancelRemoveAttendeeButton(form.model, index)} />
                                                                        }
                                                                    </td>
                                                                </tr>
                                                            )}
                                                        </tbody>
                                                    </table>
                                                }

                                                { meetingDetailForm.individualAttendeeSelectionVisible && !meetingDetailForm.attendeeForms.length &&
                                                    <EmptyState
                                                        title=""
                                                        message="No individual attendees assigned" />
                                                }
                                            </div>

                                            <div class="form-container mt-2">
                                                <h4>Attendee Count</h4>
                                                <hr/>

                                                <div class="grid-container">
                                                    <Field
                                                        className="sm-6"
                                                        field={meetingDetailForm.overrideAttendeeCountCheckbox} />

                                                    <Field
                                                        className="sm-6"
                                                        field={meetingDetailForm.totalAttendeeCountField} />
                                                </div>
                                            </div>

                                            <div class="form-container mt-2">
                                                <h4>Additional Notification Recipients</h4>

                                                <hr/>

                                                <p class="text-sm">
                                                    The following event attendees will be added to the notification list for system alerts when guests arrive to the meeting.
                                                </p>

                                                <div class="flex">

                                                    <EventAttendeeMultiselectField
                                                        className="v-center fill no-label mr-1"
                                                        eventAttendeeMultiselectField={meetingDetailForm.additionalNotificationRecipientAttendeeField} />

                                                    <Button
                                                        className="v-center"
                                                        size={ButtonSizes.Small}
                                                        color={ButtonColors.Primary}
                                                        button={meetingDetailForm.addAdditionalNotificationRecipientButton} />
                                                </div>

                                                <DataList
                                                    className="mt-1"
                                                    dataList={meetingDetailForm.additionalNotificationRecipientsDataList}
                                                    itemTemplate={(item) => (
                                                        <span class="text-sm">
                                                            {item.form.model.fullName}
                                                        </span>
                                                    )} />
                                            </div>

                                            <div class="form-container mt-2">
                                                <h4>Comments</h4>
                                                <hr/>

                                                {meetingDetailForm.commentFields.map((field, index) =>
                                                    <Field
                                                        field={field}
                                                        isLoading={meetingDetailForm.isLoading} />
                                                )}
                                            </div>
                                        </div>
                                    }
                                </Fragment>
                            }

                            {meetingDetailForm.activePane === MeetingDetailFormPane.Notes &&
                                <MeetingNotesForm meetingNotesForm={meetingDetailForm.meetingNotesForm} />
                            }

                            {meetingDetailForm.activePane === MeetingDetailFormPane.ActionItems &&
                                <div class="mt-2">
                                    <h3>Action Items</h3>
                                    <div>
                                        More to come for action items as we define how this should function
                                    </div>
                                </div>
                            }

                            {meetingDetailForm.activePane === MeetingDetailFormPane.History &&
                                <HistoryTable historyTable={meetingDetailForm.historyTable} />
                            }

                            <MeetingNotificationModal meetingNotificationModal={meetingDetailForm.notificationModal} />
                            <MeetingCalendarNotificationModal meetingCalendarNotificationModal={meetingDetailForm.calendarNotificationModal} />
                        </Fragment>
                    </Modal>
                }

                {!!meetingDetailForm.attendeeConflictModalOpen &&
                 !meetingDetailForm.quickAddCustomerForm.isVisible &&
                 meetingDetailForm.isOpen &&

                    <Modal
                        heading={"Attendee Schedule Conflict"}
                        close={meetingDetailForm.closeConflictModal}
                        footerRightButtons={[
                            <Button
                                color={ButtonColors.Primary}
                                button={meetingDetailForm.removeConflictsButton} />,
                            <Button
                                color={ButtonColors.Primary}
                                button={meetingDetailForm.saveWithConflictsButton} />
                        ]}>

                        <div class="mt-1">
                            <p>The following attendee(s) are scheduled in meetings that conflict with the current meeting. If you do not remove the attendees then schedule conflicts will exist.</p>

                            <p>Select attendees to remove</p>
                            <hr />

                            {meetingDetailForm.attendeeConflicts.map((conflict, index) =>
                                <div>
                                    {(
                                        index === 0 ||
                                        meetingDetailForm.attendeeConflicts[index].eventAttendeeId !== meetingDetailForm.attendeeConflicts[index - 1].eventAttendeeId
                                    ) &&
                                        <CheckboxField
                                            className="mt-1 label-right"
                                            labelClassName="text-md text-bold"
                                            field={meetingDetailForm.selectedConflictCheckboxes[index]} />
                                    }

                                    <div className="mb-p5 ml-1 text-sm">
                                        <div>
                                            Meeting: {conflict.meetingName} ({conflict.meetingNumber})
                                        </div>

                                        <div className="text-sm">
                                            Date/Time:

                                            &nbsp;

                                            <FormattedDate
                                                date={conflict.meetingDate} />

                                            &nbsp;

                                            <FormattedDate
                                                date={conflict.meetingStartDateTime}
                                                format={DateFormats.hhmm} />

                                            -

                                            <FormattedDate
                                                date={conflict.meetingEndDateTime}
                                                format={DateFormats.hhmm} />
                                        </div>
                                    </div>
                                </div>
                            )}

                        </div>
                    </Modal>
                }

                {!meetingDetailForm.attendeeConflictModalOpen &&
                 meetingDetailForm.quickAddCustomerForm.isVisible &&
                 meetingDetailForm.isOpen &&

                    <QuickAddCustomerForm
                        quickAddCustomerForm={meetingDetailForm.quickAddCustomerForm} />
                }
            </div>
        );
    }
}

export default MeetingDetailForm;
