import { h, JSX } from 'preact';
import { FieldActions, ActionUtils } from 'core.frontend';
import ObservingComponent from '../componentBases/observingComponent';
import BinaryFileInputFieldProps from './binaryFileInputFieldProps';
import Icon from '../icons/icon';

class BinaryFileInputField extends ObservingComponent<BinaryFileInputFieldProps> {

    public componentWillMount(): void {
        this.registerUpdateObserver(this.props.field.observationProvider, ActionUtils.actionsToArray(FieldActions, [FieldActions.Hidden, FieldActions.Shown]));
    }

    public valueChanged = (e: Event) => {
        const { field } = this.props;

        let target = e.target as HTMLInputElement;
        let files = target.files;

        let reader = new FileReader();
        reader.readAsArrayBuffer(files[0]);

        reader.onload = (e: any) => {
            var blob = new Blob([e.target.result as ArrayBuffer], {type: files[0].type});

            field.setValue({
                formFile: blob,
                fileName: files[0].name,
                fileSize: Math.round(files[0].size / 1024),
                fileType: files[0].type.toLowerCase()
            });
        }
    }

    private addFileButtonClicked = () => {
        document.getElementById(this.props.field.uniqueKey).click();
    }

    private fileInputClicked = (e: Event) => {
        e.stopPropagation();
    }

    public render({ field, leftIcon, buttonClassName = '', className = '' }: BinaryFileInputFieldProps): JSX.Element {

        return (
            <div class={className}>

                {field.isVisible &&
                    <a
                        class={`btn button inline-flex v-center mr-1 btn-white btn-icon ${buttonClassName} ${field.isDisabled ? 'disabled' : ''}`}
                        onClick={this.addFileButtonClicked}>

                        <span class="btn-content">
                            {leftIcon && <Icon icon={leftIcon} />}

                            {!!field.label &&
                                <span className="fill v-center ">
                                    {field.label}
                                </span>
                            }
                        </span>
                    </a>
                }

                <input
                    type="file"
                    id={field.uniqueKey}
                    name={field.uniqueKey}
                    onFocus={field.focus}
                    onBlur={field.blur}
                    value={field.stringValue}
                    onClick={this.fileInputClicked}
                    onChange={this.valueChanged}
                    className={field.isInvalid ? 'invalid' : ''}
                    disabled={field.isDisabled}
                    accept={field.acceptedFileTypes}
                    style="display:none" />

            </div>
        );
    }
}

export default BinaryFileInputField;
