"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g = Object.create((typeof Iterator === "function" ? Iterator : Object).prototype);
    return g.next = verb(0), g["throw"] = verb(1), g["return"] = verb(2), typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
var __1 = require("../..");
var button_1 = require("../../components/button/button");
var ResetPasswordPage = /** @class */ (function (_super) {
    __extends(ResetPasswordPage, _super);
    function ResetPasswordPage(api, navigator, currentUserManager, _params) {
        var _this = _super.call(this, __1.PageTypes.ResetPassword, 'Reset Password') || this;
        _this.api = api;
        _this.navigator = navigator;
        _this.currentUserManager = currentUserManager;
        _this._params = _params;
        _this.notifyFormInvalid = function () {
            _this.submitButton.setProcessing(false);
            _this.observationProvider.notifyObservers(__1.ResetPasswordPageActions.FormInvalid);
        };
        _this.formSubmitted = function () { return __awaiter(_this, void 0, void 0, function () {
            var response, err_1, errorMessage;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.observationProvider.notifyObservers(__1.ResetPasswordPageActions.FormSubmitted);
                        this._isLoading = true;
                        this._form.model.passwordResetKey = this.passwordResetKey;
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 3, , 4]);
                        return [4 /*yield*/, this.api.post(__1.EndpointUrls.ResetPassword, this._form.model)];
                    case 2:
                        response = _a.sent();
                        this.currentUserManager.login(response);
                        this.observationProvider.notifyObservers(__1.ResetPasswordPageActions.SubmittedSuccessfully);
                        return [3 /*break*/, 4];
                    case 3:
                        err_1 = _a.sent();
                        errorMessage = void 0;
                        if (err_1 && err_1.message) {
                            errorMessage = err_1.message;
                        }
                        else {
                            errorMessage = 'An error occurred trying to send you a password reset email. Please contact your system administrator.';
                        }
                        this.alert.show({
                            alertType: __1.AlertType.Error,
                            message: errorMessage,
                            heading: 'Unable to reset password'
                        });
                        this.submitButton.setProcessing(false);
                        this.submitButton.setDisabled(!err_1.canRetry);
                        this.observationProvider.notifyObservers(__1.ResetPasswordPageActions.ErrorSubmitting);
                        return [3 /*break*/, 4];
                    case 4:
                        this._isLoading = false;
                        return [2 /*return*/];
                }
            });
        }); };
        _this.observationProvider = new __1.ObservationProvider();
        _this.buildForm();
        if (_params.passwordExpired) {
            _this.alert.show({
                alertType: __1.AlertType.Error,
                heading: 'Password needs changing',
                message: 'It\'s time to reset your password. Please choose a new password before we log you in.'
            });
        }
        if (!_this.passwordResetKey) {
            _this.navigator.goToLogin();
        }
        else if (_this.currentUserManager.isLoggedIn) {
            _this.navigator.goToEventList();
        }
        _this.submitButton = new button_1.default({
            buttonType: __1.ButtonTypes.Submit,
            text: 'Submit',
            onClick: _this.form.submit,
            processingText: 'Submitting...'
        });
        return _this;
    }
    Object.defineProperty(ResetPasswordPage.prototype, "form", {
        get: function () {
            return this._form;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(ResetPasswordPage.prototype, "isLoading", {
        get: function () {
            return this._isLoading;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(ResetPasswordPage.prototype, "passwordResetKey", {
        get: function () {
            return this._params.passwordResetKey;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(ResetPasswordPage.prototype, "passwordField", {
        get: function () {
            return this._form.fields[0];
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(ResetPasswordPage.prototype, "confirmPasswordField", {
        get: function () {
            return this._form.fields[1];
        },
        enumerable: false,
        configurable: true
    });
    ResetPasswordPage.prototype.buildForm = function () {
        var passwordField = new __1.PasswordField('password', true);
        var confirmPasswordField = new __1.ConfirmPasswordField('confirmPassword', true, passwordField);
        var fields = [passwordField, confirmPasswordField];
        this._form = new __1.Form(fields);
        this._form.observationProvider.observe(this.notifyFormInvalid, __1.FormActions.FormSubmissionFailure);
        this._form.observationProvider.observe(this.formSubmitted, __1.FormActions.FormSubmitted);
    };
    return ResetPasswordPage;
}(__1.BasePage));
exports.default = ResetPasswordPage;
