"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.TenantListActions = exports.TenantList = exports.TenantDetailPageActions = exports.TenantDetailPage = void 0;
var tenantDetailPage_1 = require("./tenantDetailPage");
exports.TenantDetailPage = tenantDetailPage_1.default;
var tenantDetailPageActions_1 = require("./tenantDetailPageActions");
exports.TenantDetailPageActions = tenantDetailPageActions_1.default;
var tenantList_1 = require("./tenantList");
exports.TenantList = tenantList_1.default;
var tenantListActions_1 = require("./tenantListActions");
exports.TenantListActions = tenantListActions_1.default;
